import {observer} from "mobx-react-lite";
import {StyleSheet} from "../../../../utils/styles";
import {TypeOfChapterModal} from "../../../../types/materials/TypeOfChapterModal";
import {stores} from "../../../../pages/_app";
import React, {useEffect, useMemo} from "react";
import {Stack} from "@mui/material";
import CBInput from "../../../../uikit/baseComponent/input/input";
import CBTextarea from "../../../../uikit/baseComponent/textarea";
import CBButton from "../../../../uikit/baseComponent/buttons/mainButton";
import {ComponentSize, ComponentType, FontStyle, FontType, GrayScale} from "../../../../uikit/enums";
import CBText from "../../../../uikit/baseComponent/text";
import {baseButtonStyles} from "../../../../utils/baseButtonStyles";

const CreateChapterDialogItem = observer(() => {
  let currentChapter = stores.courseEditViewStore.currentStructure.chapters.find((item) => item.id === stores.courseEditViewStore.changeChapterId)

  useMemo(() => {
    if (currentChapter) {
      stores.courseEditViewStore.setChapterData('title', currentChapter?.title || '')
      stores.courseEditViewStore.setChapterData('description', currentChapter?.description || '')
    }
  }, [currentChapter])


  useEffect(() => {
    if (!stores.materialDialogStore.isOpen) {
      stores.courseEditViewStore.setChapterData('title', undefined)
      stores.courseEditViewStore.setChapterData('description', undefined)
    }
  }, [stores.materialDialogStore.isOpen])

  const isCantCreate = () => {
    if (stores.courseEditViewStore.currentChapterTitle === undefined) {
      return true
    }
    if (stores.courseEditViewStore.currentChapterTitle?.length > 5) {
      return (stores.courseEditViewStore.currentChapterTitle?.length || 0) < 6
        && (stores.courseEditViewStore.currentChapterDescription?.length || 0) > 200
    }

    return true
  }

  const isCantChange = () => {
    if (stores.courseEditViewStore.currentChapterTitle === undefined) {
      return true
    }
    if (stores.courseEditViewStore.currentChapterTitle.length > 5) {
      return (stores.courseEditViewStore.currentChapterTitle.length || 0) > 5
        && (stores.courseEditViewStore.currentChapterDescription?.length || 0) < 200
        && (stores.courseEditViewStore.currentChapterDescription || '') === (currentChapter?.description || '')
        && (stores.courseEditViewStore.currentChapterTitle || '') === (currentChapter?.title || '')
    }

    return true
  }

  const renderCreateChapterItem = () => (
    <Stack style={styles.container}>
      <CBText style={{...styles.title, ...(!stores.adaptiveService.isMinDesktopMini && {fontSize: '26px'})}} type={FontType.TITLE} color={GrayScale.TITLE_ACTIVE_COLOR} fontStyle={FontStyle.SMALLBOLD}>Создание раздела</CBText>
      <CBInput style={{width: '100%'}} required={true} label={'Введите название нового раздела'}
               onChange={(value: string) => stores.courseEditViewStore.setChapterData('title', value)} defaultValue={''}
               isValid={((stores.courseEditViewStore?.currentChapterTitle?.length || 0) > 5) ? 'disabled' : false}
               helperText={!((stores.courseEditViewStore?.currentChapterTitle?.length || 0) > 5) && 'Название раздела должно содержать 6 символов и более'}/>
      <CBTextarea value={''} onChange={(value) => stores.courseEditViewStore.setChapterData('description', value)} isValid={'disabled'} style={{width: '100%'}} maxLength={200} label={'Введите описание раздела'}/>

      <Stack direction='row' style={styles.buttons}>
        <CBButton
          disabled={stores.constructorMaterialsStore.isTestCreateProcess}
          onClick={() => {
            stores.materialDialogStore.setCurrentState(false);
          }
          }
          children={'Отменить'}
          size={ComponentSize.SMALL}
          type={ComponentType.PRIMARY}
          style={{...styles.cancelButton, ...baseButtonStyles.buttonDiscard}}
          width={'50%'}
        />
        <CBButton
          disabled={isCantCreate()}
          loading={stores?.courseEditViewStore.isChapterCreateProcess}
          size={ComponentSize.SMALL}
          color={GrayScale.OFF_WHITE_COLOR}
          type={ComponentType.PRIMARY}
          children={'Создать'}
          width={'50%'}
          onClick={() => {
            stores.courseEditViewStore.createChapter(stores.courseEditViewStore.currentChapterTitle, stores.courseEditViewStore.currentChapterDescription).then(
              onSuccess => {
                stores.materialDialogStore.setMessageViewState(true, 'Раздел успешно создан');
                stores.materialDialogStore.setCurrentState(false);
              },
              onError => {
                stores.materialDialogStore.setMessageViewState(true, 'Ошибка создания раздела, попробуйте позднее');
                stores.materialDialogStore.setCurrentState(false);
              }
            )
          }
          }
        />
      </Stack>
    </Stack>
  )

  const renderChangeChapterId = () => (
    <Stack style={styles.container}>
      <CBText style={{...styles.title, ...(!stores.adaptiveService.isMinDesktopMini && {fontSize: '26px'})}} type={FontType.TITLE} color={GrayScale.TITLE_ACTIVE_COLOR} fontStyle={FontStyle.SMALLBOLD}>Редактирование раздела</CBText>
      <CBInput style={{width: '100%'}} required={true} label={'Введите название нового раздела'}
               onChange={(value: string) => stores.courseEditViewStore.setChapterData('title', value)} defaultValue={currentChapter?.title || ''}
               isValid={((stores.courseEditViewStore?.currentChapterTitle?.length || 0) > 5) ? 'disabled' : false}
               helperText={!((stores.courseEditViewStore?.currentChapterTitle?.length || 0) > 5) && 'Название раздела должно содержать 6 символов и более'}/>
      <CBTextarea value={currentChapter?.description || ''} onChange={(value) => stores.courseEditViewStore.setChapterData('description', value)} style={{width: '100%'}} isValid={'disabled'} maxLength={200} label={'Введите описание раздела'}/>

      <Stack direction='row' style={styles.buttons}>
        <CBButton
          disabled={stores.constructorMaterialsStore.isTestCreateProcess}
          onClick={() => {
            stores.materialDialogStore.setCurrentState(false);
            }
          }
          children={'Отменить'}
          size={ComponentSize.SMALL}
          type={ComponentType.PRIMARY}
          style={{...styles.cancelButton, ...baseButtonStyles.buttonDiscard}}
          width={'50%'}
        />
        <CBButton
          disabled={isCantChange()}
          loading={stores?.courseEditViewStore.isChapterCreateProcess}
          size={ComponentSize.SMALL}
          color={GrayScale.OFF_WHITE_COLOR}
          type={ComponentType.PRIMARY}
          children={'Редактировать'}
          width={'50%'}
          onClick={() => {
            stores.courseEditViewStore.changeChapter(stores.courseEditViewStore.currentChapterTitle, stores.courseEditViewStore.currentChapterDescription).then(
              onSuccess => {
                stores.materialDialogStore.setMessageViewState(true, 'Раздел успешно изменен');
                stores.materialDialogStore.setCurrentState(false);
              },
              onError => {
                stores.materialDialogStore.setMessageViewState(true, 'Ошибка изменения раздела, попробуйте позднее');
                stores.materialDialogStore.setCurrentState(false);
              }
            )
          }
        }
        />
      </Stack>
    </Stack>
  )

  switch (stores.courseEditViewStore.currentOpenModal) {
    case TypeOfChapterModal.create: {
      return renderCreateChapterItem()
    }
    case TypeOfChapterModal.change: {
      return renderChangeChapterId()
    }
    default: return <></>
  }

})

export default CreateChapterDialogItem

const styles = StyleSheet.create({
  title: {
    marginBottom: '20px'
  },
  sectionTitle: {
    width: '100%',
    marginTop: '20px',
  },
  container: {
    maxWidth: '538px',
    width: '100%',
    rowGap: '20px'
  },

  input: {
    width: '100%',
    marginTop: '15px'
  },
  buttons: {
    marginTop: '30px',
    width: '100%'
  },
  cancelButton: {
    marginRight: '10px',
  }
})