import {AuthStore, IAuthStore} from "./AuthStore";
import {IUserStore, UserStore} from "./UserStore";
import MainStore, {IMainStore} from "./MainStore";
import {AdaptiveService, IAdaptiveService} from "../../services/AdaptiveService";
import {IMenuHeaderStore, MenuHeaderStore} from "../menuHeaderStore";
import {IMaterialDialogStore, MaterialDialogStore} from "../../components/MaterialDialog/MaterialDialogStore";
import {EndRegistrationStore, IEndRegistrationStore} from "../EndRegistrationStore";
import {DocumentStore, IDocumentStore} from "../DocumentsStore";
import {HomeLandingStore, IHomeLandingStore} from "../HomeLandingStore";
import {IUserProfileStore, UserProfileStore} from "../UserProfileStore";
import {IMenuProfileStore, MenuProfileStore} from "../MenuProfileStore";
import {IMenuBitoriumStore, MenuBitoriumStore} from "../bitoriumMenuStore";
import {BitoriumStore, IBitoriumStore} from "../BitoriumStore";
import {IMarketplaceArticlePageStore, MarketplaceArticlePageStore} from "../MarketplaceArticlePageStore";
import {IUserCourseStore, UserCourseStore} from "../UserCourseStore";
import {ConstructorMaterialsStore, IConstructorMaterialsStore} from "../ConstructorMaterialsStore";
import {CourseEditViewStore, ICourseEditViewStore} from "../CourseEditViewStore";
import {IUploadFileStore, UploadFileStore} from "../UploadFileStore";
import {CreateCertificateStore, ICreateCertificateStore} from "../createCertificateStore";
import {IVideoEditViewStore, VideoEditViewStore} from "../VideoEditViewStore";
import {ITestEditViewStore, TestEditViewStore} from "../TestEditViewStore";
import {ArticleEditViewStore, IArticleEditViewStore} from "../ArticleEditViewStore";
import {IUserGroupsStore, UserGroupsStore} from "../UserGroupStore";
import {CourseServiceStore, ICourseServiceStore} from "../CourseServiceStore";
import {IXAPIService, XAPIService} from "../../services/xapi/XAPIService";
import {IReCaptchaStore, ReCaptchaStore} from "../reCaptchaStore";
import {IVideoServiceStore, VideoServiceStore} from "../videoServiceStore";
import moment from "moment";
import {AnalyticsStore, IAnalyticsStore} from "../AnalyticsStore";
import {GPTStore, IGPTStore} from "../GPTStore";

export class RootStore {
  public authStore: IAuthStore
  public userStore: IUserStore
  public mainStore: IMainStore
  public adaptiveService: IAdaptiveService
  public menuHeaderStore: IMenuHeaderStore
  public materialDialogStore: IMaterialDialogStore
  public endRegistrationStore: IEndRegistrationStore
  public documentStore: IDocumentStore
  public homeLandingStore: IHomeLandingStore
  public userProfileStore: IUserProfileStore
  public menuProfileStore: IMenuProfileStore
  public menuBitoriumStore: IMenuBitoriumStore
  public bitoriumStore: IBitoriumStore
  public marketplaceArticlePageStore: IMarketplaceArticlePageStore
  public userCourseStore: IUserCourseStore
  public constructorMaterialsStore: IConstructorMaterialsStore
  public courseEditViewStore: ICourseEditViewStore
  public articleEditViewStore: IArticleEditViewStore
  public uploadFileStore: IUploadFileStore
  public createCertificateStore: ICreateCertificateStore
  public testEditViewStore: ITestEditViewStore
  public videoEditViewStore: IVideoEditViewStore
  public userGroupsStore: IUserGroupsStore
  public courseServiceStore: ICourseServiceStore
  public xAPIService: IXAPIService
  public reCaptchaStore: IReCaptchaStore
  public videoServiceStore: IVideoServiceStore
  public analyticsStore: IAnalyticsStore
  public gptStore: IGPTStore

  constructor() {
    this.authStore = new AuthStore(this)
    this.userStore = new UserStore(this)
    this.mainStore = new MainStore(this)
    this.adaptiveService = new AdaptiveService(this)
    this.menuHeaderStore = new MenuHeaderStore(this)
    this.materialDialogStore = new MaterialDialogStore(this)
    this.endRegistrationStore = new EndRegistrationStore(this)
    this.documentStore = new DocumentStore(this)
    this.homeLandingStore = new HomeLandingStore(this)
    this.userProfileStore = new UserProfileStore(this)
    this.menuProfileStore = new MenuProfileStore(this)
    this.menuBitoriumStore = new MenuBitoriumStore(this)
    this.bitoriumStore = new BitoriumStore(this)
    this.marketplaceArticlePageStore = new MarketplaceArticlePageStore(this)
    this.userCourseStore = new UserCourseStore(this)
    this.constructorMaterialsStore = new ConstructorMaterialsStore(this)
    this.courseEditViewStore = new CourseEditViewStore(this)
    this.articleEditViewStore = new ArticleEditViewStore(this)
    this.uploadFileStore = new UploadFileStore(this)
    this.createCertificateStore = new CreateCertificateStore(this)
    this.testEditViewStore = new TestEditViewStore(this)
    this.videoEditViewStore = new VideoEditViewStore(this)
    this.userGroupsStore = new UserGroupsStore(this)
    this.courseServiceStore = new CourseServiceStore(this)
    this.xAPIService = new XAPIService(this)
    this.reCaptchaStore = new ReCaptchaStore(this)
    this.videoServiceStore = new VideoServiceStore(this)
    this.analyticsStore = new AnalyticsStore(this)
    this.gptStore = new GPTStore(this)

    // this.xAPIService.getStatementsForSaveStatic()
    console.warn(moment.duration("PT1M6.841S").asSeconds())
  }
}

export interface IRootStore extends RootStore {
}
