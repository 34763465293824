import {GPTQuestionsResponseType, ResponseAnswersType} from "../../../../stores/GPTStore";
import {observer} from "mobx-react-lite";
import React, {useCallback} from "react";
import {CBCheckbox} from "../../../../uikit/baseComponent/checkbox";
import {stores} from "../../../../pages/_app";
import {StyleSheet} from "../../../../utils/styles";
import {defaultFontStyles} from "../../../../uikit/fontStyle";

interface IProps {
  gptResponse: GPTQuestionsResponseType
}

export const CBGptQuestionItem = observer(({gptResponse}: IProps) => {

  const confirmSaveQuestionIndex = (value: boolean, index: number) => {
    if (value) {
      stores.gptStore.setConfirmOnSaveQuestionIndex(index)
    } else {
      stores.gptStore.removeConfirmOnSaveQuestionIndex(index)
    }
  }

  const renderAnswers = useCallback((answers: ResponseAnswersType[]) => {
    return (
      <>
        {answers?.map((answer, index) => {
          return (
            <CBCheckbox
              key={`chapterAnswer${index}`}
              onChange={() => {}} disabled={true}
              defaultChecked={answer.correct}
              label={answer.text}
            />
          )
        })}
      </>
    )
  }, [stores.gptStore?.gptChapterQuestions, stores.gptStore?.gptCourseQuestions])

  const renderQuestions = () => {
    return gptResponse.questions.map((item, index) => {
      return (
        <div key={`${index}${item.title}`}
          style={{display: 'flex', flexDirection: 'row', alignItems: 'center', alignContent: 'center', width: '100%'}}>
          <div style={{backgroundColor: '#F5F5F5', borderRadius: '20px', padding: '20px', margin: '10px'}}>
            <div id={`courseQuestionTitle${index}`}
                 style={{...styles.content, fontSize: '20px', marginBottom: '10px'}}
                 dangerouslySetInnerHTML={{__html: item.title || ''}}/>
            <div id={`courseQuestion${index}`} style={{...styles.content, marginBottom: '10px'}}
                 dangerouslySetInnerHTML={{__html: item.description || ''}}/>
            {renderAnswers(item.answers)}
          </div>
          <CBCheckbox onChange={(value) => confirmSaveQuestionIndex(value, index)} defaultChecked={false}/>
        </div>
      )
    })
  }

  return (
    <>
      {renderQuestions()}
    </>
  )
})

const styles = StyleSheet.create({
  content: {
    ...defaultFontStyles.linkSmall,
  },
})