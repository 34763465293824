import React from "react"
import {Button} from "@mui/material";
import {baseStyles} from "../../../utils/baseStyles";
import Link from "next/link";

type IProps = {
  isSelected: boolean,
  //@ts-ignore
  buttonProps: Button,
  nonDisable?: boolean,
  link?: string | null,
}

export const HeaderUnderlineButton = ({isSelected, buttonProps, nonDisable = true, link = null}: IProps) => {

  const renderBaseButton = () => (
    <div style={{...baseStyles.verticalContainer, ...baseStyles.centerOnVertical, position: 'relative'}}>
      <Button
        {...buttonProps}
        disabled={!nonDisable && isSelected}
        sx={{
          ...buttonProps.sx,
          '&:disabled': {
            color: '#383838'
          }
        }}
      />
      {isSelected && <div style={{
        background: 'linear-gradient(90deg, #84EE9B 0%, #32CAB8 100%)',
        height: 2,
        borderRadius: 10,
        width: '70%',
        position: 'absolute',
        marginRight: '30px',
        top: 30
      }}/>}
    </div>
  )

  switch (link === null) {
    case true:
      return renderBaseButton()
    case false:
      return (
        <Link href={link ?? '#'}>
          {renderBaseButton()}
        </Link>
      )
  }
}
