import {observer} from "mobx-react-lite";
import {PageDetails} from "../../../../types/materials/course/PageDetails";
import {CircularProgress, DialogActions, DialogContent, DialogProps, DialogTitle} from "@mui/material";
import React, {useEffect, useRef} from "react";
import {stores} from "../../../../pages/_app";
import {baseStyles} from "../../../../utils/baseStyles";
import VideoPlayerKinescope from "../../../IndependentComponents/VideoPlayerKinescope";
import CBButton from "../../../../uikit/baseComponent/buttons/mainButton";
import {Colors, ComponentSize, ComponentType, LearnModalType} from "../../../../uikit/enums";
import {baseButtonStyles} from "../../../../utils/baseButtonStyles";
import {StyleSheet} from "../../../../utils/styles";
import {defaultFontStyles} from "../../../../uikit/fontStyle";
import {DialogType} from "../../MaterialDialogStore";
import {MaterialsType} from "../LearningMaterial/LearnMaterialDialog";

interface IProps {
	page: PageDetails,
	scroll: DialogProps['scroll']
	type: LearnModalType
}

const VideoLearned = observer(({page, scroll, type}: IProps) => {
	const descriptionElementRef = useRef<HTMLElement>(null);
	let playerRef: React.RefObject<any> = React.createRef()

	useEffect(() => {
		if (page === undefined || !stores.materialDialogStore.isOpen
				|| stores.materialDialogStore.currentDialogType !== DialogType.seeLearnedMaterial || type !== LearnModalType.LEARNED_VIDEO) {
			return
		}

		setTimeout(() => {
			stores.xAPIService.sendVideoOpenForRelearningEvent(page)
		}, 1000)
	}, [stores.materialDialogStore.isOpen, stores.materialDialogStore.currentDialogType, type, page])

	useEffect(() => {
		if (stores.materialDialogStore.isOpen) {
			const {current: descriptionElement} = descriptionElementRef;
			if (descriptionElement !== null) {
				descriptionElement.focus();
			}
		}
	}, [stores.materialDialogStore.isOpen]);

	const handleClose = () => {
		stores.xAPIService.sendVideoCloseOfRelearningEvent(page)
		stores.materialDialogStore.setCurrentState(false)
	}

	const handlePause = () => {
		playerRef?.current?.pause()
		stores.xAPIService.sendVideoPausedEvent(page)
	}

	const handlePlay = () => {
		playerRef?.current?.play()
		stores.xAPIService.sendVideoResumeEvent(page)
	}

	return (
		page.content !== undefined
			? <div style={{...baseStyles.verticalContainer, height: '600px', width: '80vw', maxWidth: '900px'}}>
				<DialogTitle id="scroll-dialog-title" sx={styles.title} children={`${page?.content.title}`}/>
				<DialogContent dividers={scroll === 'paper'} sx={{flex: 1, overflowY: 'hidden'}}>
					<VideoPlayerKinescope
						ref={playerRef}
						videoId={page.content.url ?? ''}
						style={styles.video}
						onPause={handlePause}
						onPlay={handlePlay}
					/>
				</DialogContent>
				<DialogActions sx={styles.buttonsContainer}>
					<CBButton
						disabled={false}
						onClick={handleClose}
						children={'Закрыть'}
						size={ComponentSize.SMALL}
						type={ComponentType.PRIMARY}
						style={baseButtonStyles.buttonDiscard}
					/>
				</DialogActions>
			</div>
			: <div style={styles.progressContainer}>
				<CircularProgress sx={styles.progress}/>
			</div>
	)
})

const styles = StyleSheet.create({
	title: {
		...defaultFontStyles.displaySmallBold,
		margin: '0 auto'
	},
	video: {
		width: '100%',
		height: '100%',
		borderRadius: '10px',
	},
	progressContainer: {
		display: 'flex',
		...baseStyles.centeredView,
		width: '400px',
		height: '500px'
	},
	progress: {
		alignSelf: 'center',
		color: Colors.PRIMARY_COLOR,
	},
	buttonsContainer: {
		justifyContent: 'center'
	}
})

export default VideoLearned
