import {observer} from "mobx-react-lite";
import {baseStyles} from "../../utils/baseStyles";
import {stores} from "../../pages/_app";
import {Box, Button, IconButton} from "@mui/material";
import Image from 'next/image'
import {Routers} from "../../utils/Routers";
import {PageDocumentsNames} from "../../stores/DocumentsStore";
import {DialogType} from "../MaterialDialog/MaterialDialogStore";
import {StyleSheet} from "../../utils/styles";
import Link from "next/link";
import {useEffect, useState} from "react";
import CBLogo from "../../uikit/baseComponent/logo";

export const Footer = observer(() => {

  const [isClient, setIsClient] = useState(false)

  useEffect(() => {
    setIsClient(true)
  }, [])

  const renderLogo = () => (
    <Box marginTop='40px'>
      <CBLogo/>
    </Box>
  )
  const buttons = () => (
    <div style={{...baseStyles.horizontalContainer, marginTop: stores.adaptiveService.isMobile && 5}}>
      <Button
        variant={"text"}
        sx={{...styles.textButton, fontSize: stores.adaptiveService.isMobile ? 7 : stores.adaptiveService.isTablet && 9 }}
        children={
          <Link href={Routers.DOCUMENTS_PAGE_WITH_PARAMETER.replace(":pageType", PageDocumentsNames.userTerms)}>
            <a
              children={'Пользовательское соглашение'}
              style={{...styles.textButton, fontSize: stores.adaptiveService.isMobile ? 7 : stores.adaptiveService.isTablet && 9}}
            />
          </Link>
        }
      />
      <Button
        variant={"text"}
        sx={{...styles.textButton, fontSize: stores.adaptiveService.isMobile ? 7 : stores.adaptiveService.isTablet && 9}}
        children={'Cлужба поддержки'}
        onClick={() => stores.materialDialogStore.setCurrentState(true, DialogType.supportDialog)}
      />
      <Button
        variant={"text"}
        sx={{...styles.textButton, fontSize: stores.adaptiveService.isMobile ? 7 : stores.adaptiveService.isTablet && 9}}
        children={
          <Link
            href={Routers.DOCUMENTS_PAGE_WITH_PARAMETER.replace(":pageType", PageDocumentsNames.privacyPolicy)}>
            <a
              children={'Политика конфидициальности'}
              style={{...styles.textButton, fontSize: stores.adaptiveService.isMobile ? 7 : stores.adaptiveService.isTablet && 9}}
            />
          </Link>
        }
      />
    </div>
  )
  const renderSocialButtons = () => (
    <div style={{...baseStyles.horizontalContainer, marginTop: "30px", marginLeft: stores.adaptiveService.isMobile && 120}}>
      <IconButton  component={"a"} href={'https://vk.com/coursebit'} target={'_blank'}>
        <Image src={'/icons/social/vk_rounded_border.svg'} width={20} height={20}/>
      </IconButton>
      <IconButton disabled={true} component={"a"} href={'https://vk.com/coursebit'} target={'_blank'}>
        <Image src={'/icons/social/tg_rounded_border.svg'} width={20} height={20}/>
      </IconButton>
    </div>
  )
  const textCourseBit = () => (
    <p style={{
      ...styles.text,
      fontSize: stores.adaptiveService.isMobile ? 7 : stores.adaptiveService.isTablet && 9,
      marginLeft: stores.adaptiveService.isMobile && 15,
      marginTop: stores.adaptiveService.isMobile && 5
    }}>©bitmoon.it@gmail.com  |   Учебная площадка Coursebit для авторов и обучающихся, 2022-2023</p>
  )
  const renderDesktopFooter = () => (
    <div style={{...styles.footerBody}}>
      {renderLogo()}
      <div style={{
        ...baseStyles.verticalContainer,
        marginTop: "30px",
        alignSelf: "self-start",
      }}>
        {buttons()}
        {textCourseBit()}
      </div>
      {renderSocialButtons()}
    </div>
  )

  const renderMobileFooter = () => (
    <div style={{...styles.footerBody}}>
      {renderLogo()}
      {renderSocialButtons()}
      <div>
        {buttons()}
        {textCourseBit()}
      </div>
    </div>
  )

  const getRenderItem = () => {
    return stores.adaptiveService.isMobile
      ? renderMobileFooter()
      : renderDesktopFooter()
  }

  return isClient ? getRenderItem() : <></>
})

const styles = StyleSheet.create({
  textButton: {
    color: '#383838',
    fontFamily: 'Roboto',
    fontWeight: '500',
    textDecoration: 'none',
    fontSize: 12
  },
  footerBody: {
    minHeight: 116,
    width: '100%',
    backgroundColor: '#F6F6F6',
    ...baseStyles.horizontalContainerWrap,
    alignItems: "start",
    justifyContent: "space-around"
  },
  text:{
    alignSelf: "center",
    fontFamily: 'Roboto',
    fontSize: 12,
    fontWeight: '400',
    lineHeight: '30px',
    color: '#383838',
    marginBottom: 30
  }
})
