export type CurrentSortType = {
  categoryId: number | string | null,
  sortId: number | string | null,
  searchString: string | number | null,
  page: number | string | null
}

export const initCurrentSort: CurrentSortType = {
  categoryId: null,
  sortId: null,
  searchString: null,
  page: 0
}
