export enum Colors {
  PRIMARY_COLOR = '#26AF7E',
  SECONDARY_COLOR = '#59A3E7',
  ERROR = '#FE594E',
  SUCCESS = '#26AF7E',
  WARNING = '#FDD340',
  PRIMARY_DARK = '#00966D',
  PRIMARY_LIGHT = '#DCF2EA',
  SUCCESS_DARK = '#00966D',
  SUCCESS_LIGHT = '#DCF2EA',
  SECONDARY_DARK = '#3275A5',
  SECONDARY_LIGHT = '#BCE3FF',
  ERROR_DARK = '#C32300',
  ERROR_LIGHT = '#FFE9E6',
  WARNING_DARK = '#B77900',
  WARNING_LIGHT = '#FFF282',
  LABEL_COLOR = '#6E8691',
  SECONDARY_ERROR = '#D8D8D8',
  SECONDARY_SUCCESS = '#32BD9C',
  CONNECT_INFO = '#4E4B66'
}
export enum Gradient {
  GRADIENT_PRIMARY = 'linear-gradient(114.44deg, #47E6AD 16.02%, #57AFFF 100%)',
  GRADIENT_SECONDARY = 'linear-gradient(114.44deg, #1964D4 0%, #15C0DF 42.71%, #0FFFE2 100%)',
  GRADIENT_ACCENT = 'linear-gradient(114.44deg, #FDD340 0%, #FE594E 100%)',
  GRADIENT_COMPLEXITY_LOW = 'linear-gradient(114.44deg, #26AF7E 0%, #FDD340 100%)',
  GRADIENT_COMPLEXITY_HIGH = 'linear-gradient(114.44deg, #26AF7E 0%, #FDD340 42.71%, #FE594E 100%)',
  GRADIENT_DARK = 'linear-gradient(209.37deg, #081415 0%, #103A40 98.58%)'
}
export enum GrayScale {
  TITLE_ACTIVE_COLOR = '#14252B',
  BODY_COLOR = '#4B5F66',
  LABEL_COLOR = '#6E8791',
  PLACEHOLDER_COLOR = '#A0BDBB',
  TITLE_ADDITIONAL_COLOR = '#B3B3B3',
  LINE_COLOR = '#D9E9E4',
  INPUT_BACKGROUND = '#EFF7F5',
  BACKGROUND_COLOR = '#F7FCFB',
  OFF_WHITE_COLOR = '#FCFCFC',
  INPUT_ADORNMENT = '#C4C4C4'
}

export enum ComponentSize {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
  EXTRA_SMALL = 'extraSmall'
}

export enum FontType {
  TITLE = 'title',
  TEXT = 'text'
}

export enum ProgressAnimation {
  DETERMINATE = 'determinate',
  INDETERMINATE = 'indeterminate',
}

export enum FontStyle {
  LARGE = 'large',
  LARGEBOLD = 'largeBold',
  MEDIUM = 'medium',
  MEDIUMBOLD = 'mediumBold',
  SMALL = 'small',
  SMALLBOLD = 'smallBold',
  XSMALL = 'xSmall',
  LINKLARGE = 'linkLarge',
  LINKMEDIUM = 'linkMedium',
  LINKSMALL = 'linkSmall',
  LINKXSMALL = 'linkXSmall'
}

export enum ComponentType {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  SUBTITLE = 'subtitle',
  TEXT = 'text',
  ERROR = 'error'
}

export enum InputsType {
  PASSWORD = 'password',
  DATE = 'date',
  TEXT = 'text',
  NUMBER = 'number'
}

export enum CategoriesType {
  DEFAULT = 'default',
  SHORT = 'short',
  MINIMAL = 'minimal',
}

export enum DragAndDropLevel {
  FIRST = 'first',
  SECOND = 'second'
}

export enum DragAndDropSectionType {
  CHAPTER = 'chapter',
  ARTICLE = 'article',
  TEST = 'test',
  VIDEO = 'video',
  QUESTION = 'question'
}
export enum ViewsAndCommentsType {
  VIEWS_AND_COMMENTS = 'Views and comments',
  VIEWS_AND_COMMENTS_RATING = 'Views, comments and rating',
  SHORT = 'Short',
  SHORT_WITH_EXTEND_RATING = 'Short with extend rating',
  DEFAULT_WITH_EXTEND_RATING = 'Default with extend rating',
  ONLY_RATING = 'Only rating'
}

export enum LearnModalType {
  LEARN_ARTICLE = 'learnArticle',
  LEARN_TEST = 'learnTest',
  LEARN_VIDEO = 'learnVideo',
  LEARNED_ARTICLE = 'learnedArticle',
  LEARNED_TEST = 'learnedTest',
  LEARNED_VIDEO = 'learnedVideo'
}

export enum AnalyticModalType {
  ALL_ANALYTICS = 'allAnalytics',
  TEST_ANALYTICS = 'testAnalytics',
  PROBLEMS_MATERIALS = 'problemsMaterials'
}
