import {UserMinimalDataModel} from "../../user/UserMinimalDataModel";
import {CategoryModel} from "../../dictionaries/categories/CategoryModel";

export type ArticleObjectByIdUser = {
  countArticles: number,
  countPages: number,
  articles: [
    {
      id: number,
      title: string,
      description: string | null,
      content: string,
      createDate: string,
      author: UserMinimalDataModel,
      categories: CategoryModel[],
      tags: string,
      rating: {
        id: number,
        average: number,
        totalCount: number
      },
      views: number,
      commentsCount: number
    }]
}

export const initArticleData: ArticleObjectByIdUser = {
  countArticles: 0,
  countPages: 0,
  articles: [
    {
      id: 0,
      title: '',
      description: null,
      content: '',
      createDate: '',
      author: {
        id: 0,
        login: '',
        profilePhoto: ''
      },
      categories: [],
      tags: '',
      rating: {
        id: 0,
        average: 0,
        totalCount: 0
      },
      views: 0,
      commentsCount: 0
    }]
}
