import {observer} from "mobx-react-lite";
import {StyleSheet} from "../../../utils/styles";
import {stores} from "../../../pages/_app";
import {LoadingButton} from "@mui/lab";
import {Avatar, Button} from "@mui/material";
import {useEffect} from "react";
import {baseStyles} from "../../../utils/baseStyles";
import {FileExtensionsTypes} from "../../../stores/UploadFileStore";

const UploadUserProfilePhoto = observer(() => {
  useEffect(() => {
    stores.uploadFileStore.setFile(undefined, FileExtensionsTypes.uploadImageDefault)
  }, [stores.materialDialogStore.isOpen])

  return(
    <div style={{width: '350px'}}>
      <div style={{margin: '30px'}}>
        <p style={styles.title}>Изменение аватара</p>
        <p style={styles.description}>Загрузите изображение. Размер загружаемого изображения не должен превышать 2Мб</p>
        <p style={styles.extensions}>Допустимые форматы: .jpeg, .jpg, .jpe, .png</p>
        <div style={{...baseStyles.horizontalContainer, marginTop: '20px', marginBottom: '20px', alignItems: 'end', justifyContent: 'center'}}>
          <Avatar src={!!stores.uploadFileStore.selectedFile ? URL.createObjectURL(stores.uploadFileStore.selectedFile) : (stores.userStore.userData.profilePhoto || undefined)} sx={{
            width: 130,
            height: 130,
          }}
          />
          <Avatar src={!!stores.uploadFileStore.selectedFile ? URL.createObjectURL(stores.uploadFileStore.selectedFile) : (stores.userStore.userData.profilePhoto || undefined)} sx={{
            width: 40,
            height: 40,
          }}
          />
        </div>
        <Button
          variant={"outlined"}
          style={{width: '100%'}}
          size={"small"}
          component="label"
          disabled={stores.uploadFileStore.isUploadFileProcess || stores.uploadFileStore.isDeleteFileProcess}
          sx={{color: '#32BD9C', borderColor: '#32BD9C', '&:hover': { color: '#308670', borderColor: '#308670' }}}
        >
          Выбрать фото
          <input
            type="file"
            accept={'image/jpeg'}
            onChange={(event) => {
              stores.uploadFileStore.setFile(event.target.files !== null ? event.target.files[0] : undefined, FileExtensionsTypes.uploadImageDefault)
            }}
            hidden
          />
        </Button>
        <div style={{...baseStyles.horizontalContainer, marginTop: '10px'}}>
          <LoadingButton
            variant={"contained"}
            children={"Загрузить"}
            loading={stores.uploadFileStore.isUploadFileProcess}
            loadingIndicator={`${stores.uploadFileStore.uploadFileProgress}%`}
            disabled={!stores?.uploadFileStore.selectedFile || stores.uploadFileStore.isDeleteFileProcess}
            style={{flex: 2, marginRight: '20px'}}
            size={"small"}
            onClick={() => {
              stores.uploadFileStore.uploadProfilePhoto().then(() => {
                stores.materialDialogStore.setCurrentState(false)
              })
            }}
            sx={{bgcolor: '#32BD9C', '&:hover': { bgcolor: '#32BD9C' }}}
          />
          <Button
            variant={"outlined"}
            children={"Отменить"}
            disabled={stores.uploadFileStore.isUploadFileProcess || stores.uploadFileStore.isDeleteFileProcess}
            onClick={() => stores.materialDialogStore.setCurrentState(false)}
            style={{flex: 1}}
            size={"small"}
            sx={{color: '#FE594E', borderColor: '#FE594E', '&:hover': { borderColor: '#ff9a93' }}}/>
        </div>
      </div>

      <div style={{...styles.buttonContainer}}>
        <LoadingButton
          variant={"contained"}
          children={"Удалить фото"}
          loading={stores.uploadFileStore.isDeleteFileProcess}
          loadingIndicator={`Удаление...`}
          disabled={!stores.userStore.userData.profilePhoto || stores.uploadFileStore.isUploadFileProcess}
          style={{flex: 1, margin: '20px 30px'}}
          size={"small"}
          onClick={() => {
            stores.uploadFileStore.deleteProfilePhoto().then(() => {
              stores.materialDialogStore.setCurrentState(false)
            })
          }}
          sx={{bgcolor: '#FE594E', '&:hover': { bgcolor: '#FE594E' }, '&:disabled': { bgcolor: '#773c36' }}}
        />
      </div>
    </div>
  )
})

export default UploadUserProfilePhoto

const styles = StyleSheet.create({
  title: {
    fontFamily: 'Montserrat',
    fontWeight: '700',
    fontSize: '24px',
  },
  description: {
    fontFamily: 'Montserrat',
    fontWeight: '400',
    fontSize: '14px',
    marginTop: '10px'
  },
  articleTitle: {
    width: '100%',
    marginTop: '30px',
    marginBottom: '20px'
  },
  buttonContainer: {
    marginTop: '20px',
    width: '100%',
    height: 'auto',
    display: 'flex',
    backgroundColor: '#1a1a1a',
  },
  extensions: {
    fontFamily: 'Montserrat',
    fontWeight: '400',
    fontSize: '14px',
    marginTop: '8px',
    color: '#FE594E'
  },
})
