import networkService from "../services/NetworkService";
import {makeAutoObservable, runInAction} from "mobx";
import {ArticleObject} from "../types/materials/article/ArticleObject";
import {IRootStore} from "./global/RootStore";
import {FileExtensionsTypes} from "./UploadFileStore";

export enum PageArticleEditNames {
  'main' = 'main',
  'editor' = 'editor'
}

export const PageTitles = {
  'main': 'Основные настройки',
  'editor': 'Редактор статьи'
}

export class ArticleEditViewStore {
  private rootStore: IRootStore

  constructor(rootStore: IRootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  currentPage: PageArticleEditNames = PageArticleEditNames.main
  currentArticle: ArticleObject | null = null
  currentArticleCash: ArticleObject | null = null
  isArticleLoadSaveProcess: boolean = false

  currentCategories: string | null = null
  currentCategoriesCash: string | null = null

  currentTags: string | null = null
  currentTagsCash: string | null = null
  newCoverFile: string | null = null

  isPdfFile: boolean = false
  pdfArticle: any | undefined = undefined

  resetCurrentArticle = () => {
    this.currentArticle = null
    this.currentCategories = null
    this.currentTags = null

    this.currentArticleCash = null
    this.currentCategoriesCash = null
    this.currentTagsCash = null
  }

  setCurrentPage = (page: PageArticleEditNames) => {
    this.currentPage = page
  }

  setIsPdfFile = (value: boolean) => {
    this.isPdfFile = value
  }

  loadArticleData = async (articleId: number) => {
    try {
      runInAction(() => this.isArticleLoadSaveProcess = true)
      const {data} = await networkService.post(`constructor/course/page/get/article`, {
        articleId: articleId
      })
      runInAction(() => {
        this.currentArticle = {...data,
          title: data.title ? data.title : '',
          description: data.description ? data.description : '',
          duration: data.duration ? data.duration : ''
        }
        this.currentArticleCash = {...data,
          title: data.title ? data.title : '',
          description: data.description ? data.description : '',
          duration: data.duration ? data.duration : ''
        }

        this.currentCategories = this.currentArticle?.categories?.map(item => item.id).join('|') || null
        this.currentCategoriesCash = this.currentCategories

        this.currentTags = this.currentArticle?.tags || ''
        this.currentTagsCash = this.currentTags

        this.newCoverFile = null

        this.isArticleLoadSaveProcess = false
      })
      return Promise.resolve()
    } catch (e) {
      // @ts-ignore
      return Promise.reject(e.response.data)
    }
  }

  saveCurrentArticle = async () => {
    try {
      runInAction(() => this.isArticleLoadSaveProcess = true)

      if (!!this.newCoverFile) {
        switch (this.newCoverFile) {
          case 'delete': {
            if (!!this.currentArticle?.coverImage)
              await this.rootStore.uploadFileStore.deleteFileInStorage(this.currentArticle?.coverImage,
                () => {
                  this.changeArticleData('coverImage', null)
                })
            break
          }
          default: {
            let fileObject: File = await fetch(this.newCoverFile).then(r => r.blob()) as File;
            await this.rootStore.uploadFileStore.uploadFile(this.currentArticle?.coverImage,
              (newFileUrl) => this.changeArticleData('coverImage', newFileUrl), fileObject
            )
          }
        }
      }
      console.warn('this.currentArticle.content', this.currentArticle.content, this.isPdfFile)
      if (!!this.currentArticle?.content) {
        switch (this.currentArticle.content) {
          case 'delete': {
            console.log('delete case', !!this.currentArticleCash?.content, this.currentArticleCash.content)
            if (!!this.currentArticleCash?.content) {
              await this.rootStore.uploadFileStore.deleteFileInStorage(this.currentArticleCash.content.replace('file:', '') || '',
                () => {
                  console.warn('change!!!')
                  this.changeArticleContent(null)
                  this.checkFileOnContent()
                })
            }
            break
          }
          default: {
            if (this.isPdfFile) {
              await this.rootStore.uploadFileStore.uploadFile(this.checkFileOnContent() || null,
                (newFileUrl) => this.changeArticleContent(newFileUrl),
              ).then(
                onSuccess => {
                  this.setIsPdfFile(true)
                  this.rootStore.uploadFileStore.setFile(undefined, FileExtensionsTypes.uploadDocumentDefault)
                }
              )
            }
          }
        }
      }

      console.warn('send!!!', this.currentArticle?.content)

      const {data} = await networkService.post('/constructor/course/page/editArticle', {
        articleId: this.currentArticle?.id,
        title: this.currentArticle?.title,
        description: this.currentArticle?.description,
        privacyId: this.currentArticle?.privacy.id,
        content: this.currentArticle?.content,
        duration: this.currentArticle?.duration || 0,
        tags: this.currentTags,
        categoryIds: this.currentCategories,
        coverImage: this.currentArticle?.coverImage
      })
      await this.loadArticleData(this.currentArticle?.id || 0)
      return Promise.resolve()
    }
    catch (e) {
      // @ts-ignore
      return Promise.reject(e)
    }
  }

  setTime = (value: number) => {
    if (this.currentArticle !== null)
      this.currentArticle.duration = value
  }

  changeArticleData = (key: 'title' | 'description' | 'privacyId' | 'content' | 'coverImage' | 'coverNewImage', value: number | string | null | object) => {
    console.log(`changeArticleData: key - ${key}, value - ${value} - ${typeof value}`)
    if (this.currentArticle !== null) {
      // @ts-ignore
      if (key === 'privacyId' && value !== null && typeof value === "object") {
        // @ts-ignore
        this.currentArticle.privacy = value
      } else if (key === 'coverNewImage') {
        // @ts-ignore
        this.newCoverFile = value === null ? value : `${value}`
      } else this.currentArticle[key] = !value ? value : `${value}`
    }
  }

  setCurrentTags = (value: string[]) => {
    return this.currentTags = value.join('|')
  }

  setCurrentCategories = (value: number[] | string[]) => {
    this.currentCategories = value.join('|')
  }

  get isValidCategories() {
    return this.currentCategories !== ''
  }

  checkFileOnContent = () => {
    if (!this.currentArticle?.content) {
      this.setIsPdfFile(false)
      return
    }

    if ((this.currentArticle?.content?.indexOf('file:') ?? -1) >= 0 || (this.currentArticle.content?.indexOf('blob:') ?? -1) >= 0) {
      this.setIsPdfFile(true)
      return this.currentArticle.content.replace('file:', '')
    } else return ''
  }

  get isArticleChanged() {
    return JSON.stringify(this.currentArticleCash) !== JSON.stringify(this.currentArticle) && (this.currentArticle?.title.length || 0) < 501
      || this.currentCategories !== this.currentCategoriesCash && this.isValidCategories
      || this.currentTags !== this.currentTagsCash
      || this.newCoverFile !== null
      || JSON.stringify(this.currentArticle?.content) !== JSON.stringify(this.currentArticleCash?.content)
  }

  changeArticleContent = (content: string) => {
    if (this.currentArticle?.content !== undefined) {
      this.isPdfFile && content !== 'delete' && content !== ''
        ? this.currentArticle.content = 'file:' + content
        : this.currentArticle.content = content
    }
  }

  restoreArticle = async () => {
    try {
      await this.loadArticleData(this.currentArticle?.id || 0)
    } catch (e) {
      return Promise.reject(e)
    }
  }
}

export interface IArticleEditViewStore extends ArticleEditViewStore {}
