import {observer} from "mobx-react-lite";
import {
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle
} from "@mui/material";
import {stores} from "../../../../pages/_app";
import CBButton from "../../../../uikit/baseComponent/buttons/mainButton";
import {Colors, ComponentSize, ComponentType, GrayScale} from "../../../../uikit/enums";
import {baseButtonStyles} from "../../../../utils/baseButtonStyles";
import React, {useCallback, useEffect, useRef} from "react";
import {StyleSheet} from "../../../../utils/styles";
import {defaultFontStyles} from "../../../../uikit/fontStyle";
import {baseStyles} from "../../../../utils/baseStyles";
import {GPTRequestType, ResponseAnswersType} from "../../../../stores/GPTStore";
import {CBCheckbox} from "../../../../uikit/baseComponent/checkbox";
import {CBGptQuestionItem} from "../questions/CBGPTQuestionItem";

interface IProps {
  mainTitle: string,
  scroll: DialogProps['scroll'],
  materialTitle: string
}
const CBGptChapterQuestions = observer(({mainTitle, materialTitle, scroll}: IProps) => {
  const descriptionElementRef = useRef<HTMLElement>(null);
  useEffect(() => {
    if (stores.materialDialogStore.isOpen) {
      const {current: descriptionElement} = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [stores.materialDialogStore.isOpen]);

  const handleClose = () => {
    stores.gptStore.clearGptResponses();
    stores.materialDialogStore.setCurrentState(false)
  }

  const renderQuestions = useCallback(() => {
    return (
      <div>
        {stores.gptStore.gptChapterQuestions.map((item, index) => {
          return <CBGptQuestionItem key={index} gptResponse={item}/>
        })}
      </div>
    )
  }, [stores.gptStore?.gptChapterQuestions])

  return (
    <>
      <DialogTitle id="scroll-dialog-title" sx={styles.title} children={`${mainTitle} "${materialTitle}"`}/>
      {!stores.gptStore.isRequestInProcess && !!stores.gptStore.gptChapterQuestions ? (
        <>
          <DialogContent dividers={scroll === 'paper'}>
            <DialogContentText
              id="scroll-dialog-description"
              ref={descriptionElementRef}
              tabIndex={-1}
              sx={{width: '900px', height: 'auto'}}
            >
              {renderQuestions()}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <CBButton
              disabled={false}
              onClick={handleClose}
              children={'Закрыть'}
              size={ComponentSize.SMALL}
              type={ComponentType.PRIMARY}
              style={baseButtonStyles.buttonDiscard}
            />
            <CBButton
              onClick={() => stores.gptStore.saveGPTQuestions(GPTRequestType.GENERATE_CHAPTER_QUESTIONS)}
              disabled={stores.gptStore.isAddQuestionToTestInProcess}
              children={'Добавить в тест'}
              loading={stores.gptStore.isAddQuestionToTestInProcess}
              style={{color: GrayScale.OFF_WHITE_COLOR}}
              size={ComponentSize.SMALL}
              color={GrayScale.OFF_WHITE_COLOR}
              type={ComponentType.PRIMARY}
            />
          </DialogActions>
        </>
      ) : (
        <div style={styles.progressContainer}>
          <CircularProgress sx={styles.progress}/>
        </div>
      )
      }
    </>
  )
})

const styles = StyleSheet.create({
  title: {
    ...defaultFontStyles.displaySmallBold,
  },
  content: {
    ...defaultFontStyles.linkSmall,
  },
  progressContainer: {
    display: 'flex',
    ...baseStyles.centeredView,
    width: '1048px',
    height: '616px'
  },
  progress: {
    alignSelf: 'center',
    color: Colors.PRIMARY_COLOR,
  },
  checkbox: {
    '&.MuiCheckbox-root': {
      padding: '3px 10px'
    },
    '&.Mui-checked': {
      color: '#32BD9C'
    }
  }
})

export default CBGptChapterQuestions