import {observer} from "mobx-react-lite";
import {StyleSheet} from "../../../utils/styles";
import {baseStyles, DefaultCourseImage} from "../../../utils/baseStyles";
import {stores} from "../../../pages/_app";
import {LoadingButton} from "@mui/lab";
import {Button} from "@mui/material";
import {useEffect} from "react";
import {FileExtensionsTypes} from "../../../stores/UploadFileStore";

const UploadCourseIcon = observer(() => {
  useEffect(() => {
    if (stores.materialDialogStore.isOpen)
      stores.uploadFileStore.setFile(undefined, FileExtensionsTypes.uploadImageDefault)
  }, [stores.materialDialogStore.isOpen])

  return (
    <div style={{width: '600px'}}>
      <div style={{margin: '30px'}}>
        <p style={styles.title}>Изменение иконки курса</p>
        <p style={styles.description}>Загрузите изображение. Рекомендуемый размер изображения 1000x500px, размер изображения не более 2Мб</p>
        <p style={styles.extensions}>Допустимые форматы: .jpeg, .jpg, .jpe, .png</p>
        <div style={{}}>
          <div style={{...styles.imageContainer}}>
            <img src={
              !!stores.uploadFileStore.selectedFile
                ? URL.createObjectURL(stores.uploadFileStore.selectedFile)
                : (stores.courseEditViewStore.currentCourse?.urlIcon || DefaultCourseImage)
            }
                 style={{...styles.image}}
            />
          </div>
        </div>
        <Button
          variant={"outlined"}
          style={{width: '100%'}}
          size={"small"}
          component="label"
          disabled={stores.uploadFileStore.isUploadFileProcess || stores.uploadFileStore.isDeleteFileProcess}
          sx={{color: '#32BD9C', borderColor: '#32BD9C', '&:hover': { color: '#308670', borderColor: '#308670' }}}
        >
          Выбрать фото
          <input
            type="file"
            accept={'image/jpeg,image/png'}
            onChange={(event) => {
              stores?.uploadFileStore.setFile(event.target.files !== null ? event.target.files[0] : undefined, FileExtensionsTypes.uploadImageDefault)
            }}
            hidden
          />
        </Button>
        <div style={{...baseStyles.horizontalContainer, marginTop: '10px'}}>
          <LoadingButton
            variant={"contained"}
            children={"Сохранить"}
            loading={stores.uploadFileStore.isUploadFileProcess}
            loadingIndicator={`${stores.uploadFileStore.uploadFileProgress}%`}
            disabled={!stores.uploadFileStore.selectedFile || stores?.uploadFileStore.isDeleteFileProcess}
            style={{flex: 2, marginRight: '20px'}}
            size={"small"}
            onClick={() => {
              if (!!stores.uploadFileStore.selectedFile)
                stores.courseEditViewStore.changeCourseData("iconNewImage", URL.createObjectURL(stores.uploadFileStore.selectedFile))
              stores.materialDialogStore.setCurrentState(false)
            }}
            sx={{bgcolor: '#32BD9C', '&:hover': { bgcolor: '#32BD9C' }}}
          />
          <Button
            variant={"outlined"}
            children={"Отменить"}
            disabled={stores.uploadFileStore.isUploadFileProcess || stores.uploadFileStore.isDeleteFileProcess}
            onClick={() => stores.materialDialogStore.setCurrentState(false)}
            style={{flex: 1}}
            size={"small"}
            sx={{color: '#FE594E', borderColor: '#FE594E', '&:hover': { borderColor: '#ff9a93' }}}/>

        </div>
      </div>
      <div style={{...styles.buttonContainer}}>
        <LoadingButton
          variant={"contained"}
          children={"Удалить фото"}
          loading={stores.uploadFileStore.isDeleteFileProcess}
          loadingIndicator={`Удаление...`}
          disabled={!stores.courseEditViewStore.currentCourse?.urlIcon|| stores.uploadFileStore.isUploadFileProcess}
          style={{flex: 1, margin: '20px 30px'}}
          size={"small"}
          onClick={() =>{
            stores.courseEditViewStore.changeCourseData("iconNewImage",'delete')
            stores.materialDialogStore.setCurrentState(false)
          }}
          sx={{bgcolor: '#FE594E', '&:hover': { bgcolor: '#FE594E' }, '&:disabled': { bgcolor: '#773c36' }}}
        />
      </div>
    </div>
  )
})

export default UploadCourseIcon

const styles = StyleSheet.create({
  title: {
    fontFamily: 'Montserrat',
    fontWeight: '700',
    fontSize: '24px',
  },
  description: {
    fontFamily: 'Montserrat',
    fontWeight: '400',
    fontSize: '14px',
    marginTop: '10px'
  },
  buttonContainer: {
    marginTop: '20px',
    width: '100%',
    height: 'auto',
    display: 'flex',
    backgroundColor: '#1a1a1a'
  },
  container: {
    ...baseStyles.horizontalContainer,
    marginTop: '20px',
    marginBottom: '20px',
    alignItems: 'end',
    justifyContent: 'center'
  },
  imageContainer: {
    width: '100%',
    position: 'relative',
    zIndex: 1,
    borderRadius: '10px',
    display: 'flex',
    aspectRatio: '2 / 1'
  },
  image: {
    height: '100%',
    width: '100%',
    objectFit: 'cover',
    display: 'block',
    zIndex: 1,
    position: 'absolute',
    borderRadius: '10px',
  },
  extensions: {
    fontFamily: 'Montserrat',
    fontWeight: '400',
    fontSize: '14px',
    marginTop: '8px',
    color: '#FE594E'
  }
})
