import {makeAutoObservable, runInAction} from "mobx";
import {IRootStore} from "./global/RootStore";
import networkService from "../services/NetworkService";
import {isNullOrEmpty} from "../utils/StringUtils";
import {CompleteAuthModal, initialCompleteAuthModal} from "../types/auth/CompleteAuthModal";
import {CompleteAuthValidateModal, initialCompleteAuthValidateModal} from "../types/auth/CompleteAuthValidateModal";
import {stores} from "../pages/_app";
import {Routers} from "../utils/Routers";
import {PageProfileNames} from "./MenuProfileStore";
import {DialogType} from "../components/MaterialDialog/MaterialDialogStore";

export class EndRegistrationStore {
  private rootStore: IRootStore

  constructor(rootStore: IRootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  isCompleteAuthLoadingProcess: boolean = false
  currentStep: number | undefined = undefined
  completeAuthData: CompleteAuthModal = initialCompleteAuthModal
  completeAuthValidateData: CompleteAuthValidateModal = initialCompleteAuthValidateModal
  loginIsUsed: boolean = false

  resetValidate = () => {
    this.completeAuthData = initialCompleteAuthModal
    this.setTermsOfUseValue(false)
  }

  setCurrentStep = (value: number) => {
    this.currentStep = value
  }

  setCompleteAuthData = async (key: 'login' | 'email' | 'password' | 'passwordRepeat' | 'firstName' | 'secondName' | 'thirdName' | 'birthDate' | 'country' | 'city' | 'interests', value: string | null | number[]) => {
      if (key === "interests") {
        this.completeAuthData.interests = (value as number[]).join('|')
      } else {
        // @ts-ignore
        this.completeAuthData[key] = !value ? value : `${value}`
      }
      if (key === 'login'){
        await this.checkLoginIsAlreadyUsed(this.completeAuthData.login || '')
      }
    this.validateCompleteAuthData()
  }

  checkLoginIsAlreadyUsed = async (login: string) => {
    try {
      const requestData = {
        login: login
      }
      const {data} = await networkService.post('auth/checkLoginIsAlreadyUsed', {
        login: login
      })
      runInAction(() => {
        this.loginIsUsed = data.isUsed
      })
      return Promise.resolve()
    }
    catch (e) {
      return Promise.reject(e)
    }
  }

  validateCompleteAuthData = () => {
    this.validateLogin()
    this.validatePassword()
    this.validateEmail()
    this.validateFirstName()
    this.validateSecondName()
    this.validateThirdName()
    this.validateBirthDate()
    this.validateCountry()
    this.validateCity()
    this.validateInterests()
  }

  validateLogin = () => {
    if (!this.completeAuthData?.login) {
      this.completeAuthValidateData.isLoginValid = false
      return
    }

    this.completeAuthValidateData.isLoginValid =
      this.completeAuthData?.login?.length > 4
      && this.completeAuthData?.login?.length < 21
      && /[a-zA-Z]+/gmus.test(this.completeAuthData?.login)
  }

  validatePassword = () => {
    if (isNullOrEmpty(this.completeAuthData.password)) {
      this.completeAuthValidateData.passwordRegister =  false
      this.completeAuthValidateData.passwordSymbols = false
      this.completeAuthValidateData.passwordNumber = false
      this.completeAuthValidateData.passwordEqual = false
    } else {
      // @ts-ignore
      this.completeAuthValidateData.passwordRegister =  /[A-Z]+/gmus.test(this.completeAuthData.password) && /[a-z]+/.test(this.completeAuthData.password)
      // @ts-ignore
      this.completeAuthValidateData.passwordSymbols = /[!@#$%^&*?"()_={}<>:.~]+/gmus.test(this.completeAuthData.password)
      // @ts-ignore
      this.completeAuthValidateData.passwordNumber = /\d/gmus.test(this.completeAuthData.password)
      this.completeAuthValidateData.passwordEqual = this.completeAuthData.password === this.completeAuthData.passwordRepeat && this.completeAuthData.password?.length !== 0
    }
  }

  getValidatePassword = () => {
    return this.completeAuthValidateData.passwordRegister
      && this.completeAuthValidateData.passwordEqual
      && this.completeAuthValidateData.passwordNumber
      && this.completeAuthValidateData.passwordSymbols
  }

  getValidateEndRegistrationFirstStep = () => {
    return this.completeAuthValidateData.passwordRegister
      && this.completeAuthValidateData.passwordEqual
      && this.completeAuthValidateData.passwordNumber
      && this.completeAuthValidateData.passwordSymbols
      && this.completeAuthValidateData.isLoginValid
      && (this.rootStore.userStore.isUserCreatedFromAuthor ? this.completeAuthValidateData.isEmailValid : true)
  }

  getValidateEndRegistrationSecondStep = () => {
    return this.completeAuthValidateData.isFirstNameValid
    && this.completeAuthValidateData.isSecondNameValid
    && this.completeAuthValidateData.isThirdNameValid
    && this.completeAuthValidateData.isBirthDateValid
    && this.completeAuthValidateData.isCountryValid
    && this.completeAuthValidateData.isCityValid
    && this.completeAuthValidateData.isTermsAccept
  }

  setTermsOfUseValue = (value: boolean) => {
    this.completeAuthValidateData.isTermsAccept = value
  }

  validateEmail = () => {
    this.completeAuthValidateData.isEmailValid = isNullOrEmpty(this.completeAuthData.email)
      ? false
      : /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/gmus.test(this.completeAuthData.email!)
      && this.completeAuthData.email.length < 256
  }

  validateFirstName = () => {
    if (isNullOrEmpty(this.completeAuthData.firstName)) {
      this.completeAuthValidateData.isFirstNameValid = false
    } else {
      this.completeAuthValidateData.isFirstNameValid = this.completeAuthData.firstName.length < 51
        && /^\p{sc=Cyrillic}+([-\s]?\p{sc=Cyrillic})*$/gu.test(this.completeAuthData.firstName)
    } 
  }

  validateSecondName = () => {
    if (isNullOrEmpty(this.completeAuthData.secondName)) {
      this.completeAuthValidateData.isSecondNameValid = false
    } else {
      this.completeAuthValidateData.isSecondNameValid = this.completeAuthData.secondName.length < 51
        && /^\p{sc=Cyrillic}+([-\s]?\p{sc=Cyrillic})*$/gu.test(this.completeAuthData.secondName)
    }
  }

  validateThirdName = () => {
    if (isNullOrEmpty(this.completeAuthData.thirdName)) {
      this.completeAuthValidateData.isThirdNameValid = true
    } else {
      this.completeAuthValidateData.isThirdNameValid = this.completeAuthData.thirdName.length < 51
        && /^\p{sc=Cyrillic}+([-\s]?\p{sc=Cyrillic})*$/gu.test(this.completeAuthData.thirdName)
    }
  }

  validateCountry = () => {
    if (isNullOrEmpty(this.completeAuthData.country)) {
      this.completeAuthValidateData.isCountryValid = false
    } else {
      this.completeAuthValidateData.isCountryValid = this.completeAuthData.country.length < 51
        && /^(?!\p{Ll})\p{sc=Cyrillic}[\p{sc=Cyrillic}]*([-\s]?\p{sc=Cyrillic})*$/gu.test(this.completeAuthData.country)
    }
  }

  validateCity = () => {
    if (isNullOrEmpty(this.completeAuthData.city)) {
      this.completeAuthValidateData.isCityValid = false
    } else {
      this.completeAuthValidateData.isCityValid = this.completeAuthData.city.length < 51
        && /^(?!\p{Ll})\p{sc=Cyrillic}[\p{sc=Cyrillic}]*([-\s]?\p{sc=Cyrillic})*$/gu.test(this.completeAuthData.city)
    }
  }

  validateBirthDate = () => {
    const birthDateTime = Date.parse(this.completeAuthData.birthDate)
    if (isNullOrEmpty(this.completeAuthData.birthDate)) {
      this.completeAuthValidateData.isBirthDateValid = false
    } else {
      this.completeAuthValidateData.isBirthDateValid = birthDateTime >= Date.parse("1900-01-01")
        && birthDateTime < Date.now()
    }
  }

  validateInterests = () => {
    this.completeAuthValidateData.isInterestsValid = this.completeAuthData.interests.length > 0
  }

  completeStudentAuth = async () => {
    const responseData = {
      login: this.completeAuthData.login === '' ? null : this.completeAuthData.login,
      password: this.completeAuthData.password === '' ? null : this.completeAuthData.password,
      passwordRepeat: this.completeAuthData.passwordRepeat === '' ? null : this.completeAuthData.passwordRepeat,
      email: this.completeAuthData.email === '' ? null : this.completeAuthData.email,
      firstName: this.completeAuthData.firstName === '' ? null : this.completeAuthData.firstName,
      secondName: this.completeAuthData.secondName === '' ? null : this.completeAuthData.secondName,
      thirdName: this.completeAuthData.thirdName === '' ? null : this.completeAuthData.thirdName,
      birthDate: this.completeAuthData.birthDate === '' ? null : this.completeAuthData.birthDate,
      country: this.completeAuthData.country === '' ? null : this.completeAuthData.country,
      city: this.completeAuthData.city === '' ? null : this.completeAuthData.city,
      interests: this.completeAuthData.interests === '' ? null : typeof this.completeAuthData.interests === "object" ? this.completeAuthData.interests?.map(item => item.id).join('|') : this.completeAuthData.interests
    }
    try {
      runInAction(() => this.isCompleteAuthLoadingProcess = true)
      
      // if (!this.rootStore.userStore.isUserCreatedFromAuthor)
      //   return Promise.reject("Вы не можете изменить свою почту на данном этапе")

      const {data} = await networkService.post(`user/completeStudentAuth`, responseData)
      await this.rootStore.userStore.loadUserProfile()
      runInAction(() => {
        this.isCompleteAuthLoadingProcess = false
        localStorage.setItem('isNewUser', 'true')
      })
      return Promise.resolve()
    }
    catch (e) {
      // @ts-ignore
      return Promise.reject(`Ошибка регистрации ${e.code}, попробуйте позднее`)
    }
    finally {
      runInAction(() => this.isCompleteAuthLoadingProcess = false)
    }
  }

  checkUserIsEmpty = (routerProcessCallback: () => void) => {
    if (!this.rootStore.authStore.isUserAuth || !this.rootStore.userStore.userData.isEmptyUser)
      return

    this.setCurrentStep(0)
    routerProcessCallback()
    this.rootStore.materialDialogStore.setCurrentState(true, DialogType.endRegistration)
  }
}

export interface IEndRegistrationStore extends EndRegistrationStore {}
