import {makeAutoObservable, runInAction} from "mobx"
import networkService from "../services/NetworkService";
import {UserInGroup} from "../types/groups/UserInGroup";
import {DefaultAndBindingGroups, initDefaultAndBindingGroups, UserGroup} from "../types/groups/UserGroup";
import {IRootStore} from "./global/RootStore";

export const CourseStatus = {
  give_course: 'Не начат',
  buy_course: 'Не начат',
  remove_given_course: 'Не начат',
  start_course: 'В процессе',
  finish_course: 'Завершен',
  request_certificate: 'Завершен',
  build_certificate: 'Завершен',
  give_certificate: 'Завершен',
  delete_empty_student_with_course: 'Завершен',
  delete_course: 'Завершен'
}

export class UserGroupsStore {
  private rootStore: IRootStore

  constructor(rootStore: IRootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  allUserGroupsWithoutIncludeUser: DefaultAndBindingGroups = initDefaultAndBindingGroups
  currentUserGroup: UserGroup | undefined = undefined
  newGroupTitle: string = ''
  currentUserInGroup: UserInGroup | undefined = undefined
  selectedCourseForGroup: number | undefined = undefined
  reason: string | undefined = undefined
  currentUserInGroupLogin: string | undefined = undefined
  generatedUsers: number = 0
  allUserGroups: UserGroup[] | undefined = undefined

  invalidCurrentUserInGroupLogin: string | undefined = undefined

  isGroupsLoading: boolean = false
  isGroupProcess: boolean = false
  isCurrentGroupLoading: boolean = false
  isSetCourseProcess: boolean = false
  isRemoveCourseProcess: boolean = false

  resetGroups = () => {
    this.allUserGroupsWithoutIncludeUser = initDefaultAndBindingGroups
    this.currentUserGroup = undefined
    this.newGroupTitle = ''
    this.currentUserInGroup = undefined
    this.selectedCourseForGroup = undefined
    this.reason = undefined
    this.allUserGroups = undefined
  }

  certificateNumber: string  = ''

  clearCurrentGroup = () => {
    this.currentUserGroup = undefined
    this.currentUserInGroup = undefined
    this.currentUserInGroupLogin = undefined
  }

  setCertificateNumber = (value) => {
    this.certificateNumber = value
  }

  setCourseCertificateRequest = (request: boolean) => {
    if (!!this.currentUserInGroup?.courseCertificateRequest)
      this.currentUserInGroup.courseCertificateRequest = request
  }

  setAllUserGroups = (value: UserGroup[]) => {
    this.allUserGroups = value
  }
  setCertificateToUser = async () => {
    try {
      const {data} = await networkService.post('authorGroups/setCertificateIdToUser',{
        groupId: this.currentUserGroup?.id,
        userId: this.currentUserInGroup?.userId,
        certificateNumber: this.certificateNumber
      })
      return Promise.resolve()
    }
    catch (e) {
      // @ts-ignore
      console.log('Send certificate number was failed. Error: ', e.response.data)
      // @ts-ignore
      return Promise.reject(e.response.data)

    }

  }

  get userGroupsIsEmpty() {
    return this.allUserGroupsWithoutIncludeUser.customGroups === undefined || this.allUserGroupsWithoutIncludeUser.customGroups.length === 0
      && this.allUserGroupsWithoutIncludeUser.bindingGroups === undefined || this.allUserGroupsWithoutIncludeUser.bindingGroups.length === 0
  }

  get isBindingGroupsIsEmpty() {
    return this.allUserGroupsWithoutIncludeUser.bindingGroups === undefined || this.allUserGroupsWithoutIncludeUser.bindingGroups.length === 0
  }

  get isCustomGroupsIsEmpty() {
    return this.allUserGroupsWithoutIncludeUser.customGroups === undefined || this.allUserGroupsWithoutIncludeUser.customGroups.length === 0
  }

  get currentGroupIsEmptyOrLoading() {
    return this.currentUserGroup === undefined || this.isCurrentGroupLoading
  }

  setSelectedCourseForGroup = (value: number) => {
    this.selectedCourseForGroup = value
  }

  setReason = (value: string) => {
    this.reason = value
  }

  setCurrentUserInGroup = (id: number | undefined) => {
    this.currentUserInGroup = this.currentUserGroup?.users?.find(item => item.userId === id)
  }

  setGroupTitle = (value: string) => {
    this.newGroupTitle = value
  }

  loadUserGroups = async () => {
    try {
      runInAction(() => this.isGroupsLoading = true)
      const {data}: {data: UserGroup[]} = await networkService.post('authorGroups/getAllAuthorGroups')
      await this.rootStore.userStore.loadUserStorageData(false)
      runInAction(() => {
        this.allUserGroupsWithoutIncludeUser.bindingGroups = data.filter(item => item.isBinding)
        this.allUserGroupsWithoutIncludeUser.customGroups = data.filter(item => !item.isBinding)
        this.allUserGroups = [
          ...this.allUserGroupsWithoutIncludeUser.bindingGroups.filter(item => !!item.title),
          ...this.allUserGroupsWithoutIncludeUser.customGroups.filter(item => !!item.title)
        ]
        this.isGroupsLoading = false
      })
      return Promise.resolve()
    } catch (e) {
      // @ts-ignore
      return Promise.reject(e.response.data)
    }
  }

  loadUserGroupById = async (groupId: number | null | undefined) => {
    try {
      runInAction(() => {
        this.isCurrentGroupLoading = true
        this.currentUserInGroup = undefined
      })

      const {data} = await networkService.post('authorGroups/getAuthorGroupById', {
        groupId: groupId
      })
      runInAction(() => {
        this.currentUserGroup = data
        this.isCurrentGroupLoading = false
      })
      return Promise.resolve()
    } catch (e) {
      // @ts-ignore
      return Promise.reject(e.response.data)
    }
  }

  createUserGroup = async () => {
    try {
      runInAction(() => this.isGroupProcess = true)
      const {data} = await networkService.post('authorGroups/createGroup', {
        title: this.newGroupTitle
      })
      await this.loadUserGroups()
      runInAction(() => {
        this.isGroupProcess = false
      })
      return Promise.resolve()
    } catch (e) {
      this.rootStore.materialDialogStore.setMessageViewState(true,
        "Ошибка создания группы", 2000)
      runInAction(() => {
        this.isGroupProcess = false
      })
      return Promise.reject(e.response.data)
    }
  }

  deleteCurrentUserGroup = async (): Promise<any> => {
    if (this.currentUserGroup.isBinding) {
      this.rootStore.materialDialogStore.setMessageViewState(true,
        "Ошибка удаления, обязательную группу курса удалить невозможно", 2000)
      return Promise.reject("Обязательную группу курса удалить невозможно")
    }

    try {
      runInAction(() => this.isGroupProcess = true)
      const {data} = await networkService.post('authorGroups/deleteGroupById', {
        groupId: this.currentUserGroup?.id,
      })
      await this.loadUserGroups()
      if (data === false) {
        await this.loadUserGroupById(this.currentUserGroup?.id)
      }
      runInAction(() => {
        if (data === false) {
          this.rootStore.materialDialogStore.setMessageViewState(true,
            "Ошибка удаления, мы оставили некоторых пользователей в группе, которые уже проходят установленный для группы курс", 6000)
        } else {
          this.currentUserGroup = undefined
        }
        this.isGroupProcess = false
        return Promise.resolve(data)
      })
    } catch (e) {
      this.rootStore.materialDialogStore.setMessageViewState(true,
        "Ошибка удаления группы", 2000)
      runInAction(() => {
        this.isGroupProcess = false
      })
      return Promise.reject(e)
    }
  }

  changeGeneratedUsersCount = (value: number) => {
    runInAction(() => {
      this.generatedUsers = value;
    })
  }

  generateUserInGroup = async (count: number) => {
    if (this.currentUserGroup.isBinding) {
      this.rootStore.materialDialogStore.setMessageViewState(true,
        "В обязательную группу нельзя добавить пользователей, используйте пользовательские группы", 4000)
      return Promise.reject("В обязательную группу курса нельзя добавить пользователей")
    }

    try {
      runInAction(() => this.isGroupProcess = true)
      const {data} = await networkService.post('authorGroups/createEmptyUsersInGroup', {
        groupId: this.currentUserGroup?.id,
        countNewUser: count
      })
      await this.loadUserGroups()
      await this.loadUserGroupById(this.currentUserGroup?.id)
      runInAction(() => {
        this.isGroupProcess = false
      })
      return Promise.resolve()
    } catch (e) {
      this.rootStore.materialDialogStore.setMessageViewState(true,
        "Ошибка создания пустых пользователей", 2000)
      runInAction(() => {
        this.isGroupProcess = false
      })
      return Promise.reject(e.response.data)
    }
  }

  deleteUnusedUsersInGroup = async () => {
    if (this.currentUserGroup.isBinding) {
      this.rootStore.materialDialogStore.setMessageViewState(true,
        "В обязательной группе нельзя удалять пользователей", 2000)
      return Promise.reject("В обязательной группе нельзя удалять пользователей")
    }

    try {
      runInAction(() => this.isGroupProcess = true)
      const {data} = await networkService.post('authorGroups/deleteUnusedUsersAccountsInGroup', {
        groupId: this.currentUserGroup?.id,
      })
      await this.loadUserGroups()
      await this.loadUserGroupById(this.currentUserGroup?.id)
      runInAction(() => {
        this.isGroupProcess = false
      })
      return Promise.resolve()
    } catch (e) {
      this.rootStore.materialDialogStore.setMessageViewState(true,
        "Ошибка удаления неактивных пустых пользователей", 2000)
      runInAction(() => {
        this.isGroupProcess = false
      })
      return Promise.reject(e.response.data)
    }
  }

  setCourseForGroup = async () => {
    if (this.currentUserGroup.isBinding) {
      this.rootStore.materialDialogStore.setMessageViewState(true,
        "В обязательной группе нельзя установить новый курс", 2000)
      return Promise.reject("В обязательной группе нельзя установить новый курс")
    }

    try {
      runInAction(() => this.isSetCourseProcess = true)
      const {data} = await networkService.post('authorGroups/setCourseForGroup', {
        courseId: this.selectedCourseForGroup,
        groupId: this.currentUserGroup?.id
      })
      await this.loadUserGroups()
      await this.loadUserGroupById(this.currentUserGroup?.id)
      runInAction(() => {
        this.isSetCourseProcess = false
      })
      return Promise.resolve()
    }
    catch (e) {
      this.rootStore.materialDialogStore.setMessageViewState(true,
        "Ошибка установки курса", 2000)
      runInAction(() => {
        this.isSetCourseProcess = false
      })
      return Promise.reject(e.response.data)
    }
  }

  get validReason() {
    return ((String(this.reason)?.trim().length || 0) > 6 && (String(this.reason)?.trim().length || 0) < 250)
  }

  removeCourseFromGroup = async () => {
    if (this.currentUserGroup.isBinding) {
      this.rootStore.materialDialogStore.setMessageViewState(true,
        "В обязательной группе нельзя удалить курс", 2000)
      return Promise.reject("В обязательной группе нельзя удалить курс")
    }

    try {
      runInAction(() => this.isRemoveCourseProcess = true)
      const {data} = await networkService.post('authorGroups/removeCourseForGroup',{
        groupId: this.currentUserGroup?.id,
        reason: this.reason
      })
      await this.loadUserGroups()
      await this.loadUserGroupById(this.currentUserGroup?.id)
      runInAction(() => {
        this.isRemoveCourseProcess = false
      })
      return Promise.resolve()
    }
    catch (e) {
      this.rootStore.materialDialogStore.setMessageViewState(true,
        "Ошибка удаления курса", 2000)
      runInAction(() => {
        this.isRemoveCourseProcess = false
      })
      return Promise.reject(e.response.data)
    }
  }

  changeAddingUserInGroupError = (value: string) => {
    runInAction(() => {
      this.invalidCurrentUserInGroupLogin = value
    })
  }

  changeCurrentUserInGroupLogin = (value: string | number) => {
    runInAction(() => {
      this.currentUserInGroupLogin = String(value)?.trim();
    })
  }

  addUserInGroupByLogin = async (login: string) => {
    if (this.currentUserGroup.isBinding) {
      this.rootStore.materialDialogStore.setMessageViewState(true,
        "В обязательную группу нельзя добавить студента, используйте пользовательские группы", 2000)
      return Promise.reject("В обязательную группу нельзя добавить студента, используйте пользовательские группы")
    }

    try {
      runInAction(() => this.isGroupProcess = true)
      const {data} = await networkService.post('authorGroups/addUserByLoginInGroup', {
        groupId: this.currentUserGroup?.id,
        studentLogin: login
      })
      await this.loadUserGroups()
      await this.loadUserGroupById(this.currentUserGroup?.id)
      runInAction(() => {
        this.isGroupProcess = false
      })
      return Promise.resolve()
    }
    catch (e) {
      this.rootStore.materialDialogStore.setMessageViewState(true,
        "Ошибка добавления пользователя в группу", 2000)
      runInAction(() => {
        this.isGroupProcess = false
      })
      return Promise.reject(e.response.data)
    }
  }

  removeUserFromGroup = async () => {
    if (this.currentUserGroup.isBinding) {
      this.rootStore.materialDialogStore.setMessageViewState(true,
        "В обязательной группе нельзя удалять пользователей", 2000)
      return Promise.reject("В обязательной группе нельзя удалять пользователей")
    }

    try {
      runInAction(() => this.isGroupProcess = true)
      const {data} = await networkService.post('authorGroups/removeUserByLoginFromGroup', {
        groupId: this.currentUserGroup?.id,
        studentLogin: this.currentUserInGroup.login
      })
      await this.loadUserGroups()
      await this.loadUserGroupById(this.currentUserGroup?.id)
      runInAction(() => {
        this.isGroupProcess = false
      })
      return Promise.resolve()
    }
    catch (e) {
      this.rootStore.materialDialogStore.setMessageViewState(true,
        "Ошибка удаление пользователя из группы", 2000)
      runInAction(() => {
        this.isGroupProcess = false
      })
      return Promise.reject(e.response.data)
    }
  }
}

export interface IUserGroupsStore extends UserGroupsStore {}
