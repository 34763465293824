import React, {useState} from 'react';
import {StyleSheet} from "../../../utils/styles";
import {AppBar, Box, ButtonBase, Card, Container, MenuItem, Stack, Toolbar} from "@mui/material";
import {Colors, ComponentSize, ComponentType, GrayScale} from "../../enums";
import CBButton from "../../baseComponent/buttons/mainButton";
import {stores} from "../../../pages/_app";
import {ProfileView} from "../../../components/navigationBar/profileView";
import {TypeOfAuth} from "../../../types/auth/TypeOfAuth";
import {ConstructorAuthModal} from "../../../components/AuthComponents/AuthModals";
import {DialogType} from "../../../components/MaterialDialog/MaterialDialogStore";
import {PageNames} from "../../../stores/menuHeaderStore";
import {Routers} from "../../../utils/Routers";
import {observer} from "mobx-react-lite";
import {PageBitoruimNames} from "../../../stores/bitoriumMenuStore";
import CBTextWithChoose from "../textWithChoose";
import {useRouter} from "next/router";
import CBLogo from "../../baseComponent/logo";
import Link from "next/link";
import {DensityMedium} from "@mui/icons-material";
import {ExpandMenu} from "../../../components/navigationBar/expandMenu";

const CBHeaderMenu = observer(() => {
	const [isOpen, setIsOpen] = useState(false)
	const router = useRouter();

	function translateCurrentPageRUS(): string {
		switch (stores.menuBitoriumStore.currentPage) {
			case PageBitoruimNames.articles:
				return 'Статьи'
			case PageBitoruimNames.news:
				return 'Новости'
			case PageBitoruimNames.webinars:
				return 'Вебинары'
			case PageBitoruimNames.course:
				return 'Курсы'
			case PageBitoruimNames.discussions:
				return 'Обсуждения'
		}
	}

	const translateValueToEN = (value: string): string => {
		switch (value) {
			case 'Статьи':
				return PageBitoruimNames.articles
			case 'Новости':
				return PageBitoruimNames.news
			case 'Вебинары':
				return PageBitoruimNames.webinars
			case 'Курсы':
				return PageBitoruimNames.course
			case 'Обсуждения':
				return PageBitoruimNames.discussions
		}
	}

	const renderMobileMenu = () => (
			<AppBar position="static" sx={styles.main}>
				<Container disableGutters maxWidth={false} sx={{position: 'fixed', zIndex: '1000', background: GrayScale.OFF_WHITE_COLOR, borderBottom: '1px solid #D9E9E4'}}>
					<Toolbar disableGutters sx={{
						...styles.container,
						paddingLeft: stores.adaptiveService.horizontalMarginInPage,
						paddingRight: stores.adaptiveService.horizontalMarginInPage
					}}>
						<CBLogo style={{cursor: 'pointer'}} onclick={() => router.push(Routers.MARKETPLACE_HOME_LANDING)}/>
						<ButtonBase sx={{
							position: 'absolute',
							right: '30px',
							color: GrayScale.TITLE_ACTIVE_COLOR
						}} onClick={() => setIsOpen(true)}>
							<DensityMedium/>
						</ButtonBase>
						<ExpandMenu isOpen={isOpen} onClose={() => setIsOpen(false)}/>
						<ConstructorAuthModal
							isAuthShow={stores.menuHeaderStore.isOpen || false}
							onClose={() => {
								stores.menuHeaderStore.setIsAuthShow(TypeOfAuth.none, false)
								stores.reCaptchaStore.resetVerify()
							}}
							modalTypeInit={stores.menuHeaderStore.currentOpenModal || TypeOfAuth.none}
						/>
					</Toolbar>
				</Container>
			</AppBar>
	)

	const renderFullMenu = () => (
		<AppBar position="static" sx={styles.main}>
			<Container disableGutters maxWidth={false}>
				<Toolbar disableGutters sx={{
					...styles.container,
					paddingLeft: stores.adaptiveService.horizontalMarginInPage,
					paddingRight: stores.adaptiveService.horizontalMarginInPage
				}}>
					<CBLogo style={{cursor: 'pointer'}} onclick={() => router.push(Routers.MARKETPLACE_HOME_LANDING)}/>
					<Box sx={{flexGrow: 1, display: {xs: 'none', md: 'flex'}, justifyContent: 'center'}}>
						<Stack direction='row' spacing={-4}>
							<CBButton
								type={ComponentType.TEXT} size={ComponentSize.SMALL}
								style={{color: stores.menuHeaderStore.currentPage === PageNames.bitorium ? Colors.PRIMARY_COLOR : GrayScale.TITLE_ACTIVE_COLOR}}
								onClick={() => router.push(Routers.MARKETPLACE_BITORIUM)}
								children={`Биторий`}
							/>
							{stores.menuHeaderStore.currentPage === PageNames.bitorium &&
								<CBTextWithChoose background={GrayScale.INPUT_BACKGROUND} value={translateCurrentPageRUS()}
																	size={ComponentSize.EXTRA_SMALL}
																	onChange={(value) => router.push(Routers.MARKETPLACE_BITORIUM_WITH_PARAMETER.replace(':pageType', translateValueToEN(value)))}
																	defaultValue={translateCurrentPageRUS()}>
									<MenuItem value='Статьи'>Статьи</MenuItem>
									<MenuItem value='Новости'>Новости</MenuItem>
									<MenuItem value='Курсы'>Курсы</MenuItem>
									<MenuItem value='Вебинары'>Вебинары</MenuItem>
									<MenuItem value='Обсуждения'>Обсуждения</MenuItem>
								</CBTextWithChoose>
							}
						</Stack>
						<CBButton
							type={ComponentType.TEXT} size={ComponentSize.SMALL}
							style={{color: GrayScale.TITLE_ACTIVE_COLOR}}
							onClick={() => stores.materialDialogStore.setCurrentState(true, DialogType.supportDialog)}
							children={`Поддержка`}
						/>
						<CBButton
							type={ComponentType.TEXT} size={ComponentSize.SMALL}
							style={{color: stores.menuHeaderStore.currentPage === PageNames.about ? Colors.PRIMARY_COLOR : GrayScale.TITLE_ACTIVE_COLOR}}
							onClick={() => router.push(Routers.MARKETPLACE_HOME_LANDING)}
							children={`О проекте`}
						/>
					</Box>
					<Box sx={{flexGrow: 0}}>
						{stores.authStore.isUserAuth
							? <ProfileView isDarkTheme={false}/>
							: <CBButton
								type={ComponentType.PRIMARY}
								size={ComponentSize.SMALL}
								onClick={() => stores.menuHeaderStore.setIsAuthShow(TypeOfAuth.signIn, true)}
								children={`Авторизация`}
							/>
						}
						<ConstructorAuthModal
							isAuthShow={stores.menuHeaderStore.isOpen || false}
							onClose={() => {
								stores.menuHeaderStore.setIsAuthShow(TypeOfAuth.none, false)
								stores.reCaptchaStore.resetVerify()
							}}
							modalTypeInit={stores.menuHeaderStore.currentOpenModal || TypeOfAuth.none}
						/>
					</Box>
				</Toolbar>
			</Container>
		</AppBar>
	)

	return stores.adaptiveService.isMinDesktopMini || stores.adaptiveService.isDesktop
		? renderFullMenu()
		: renderMobileMenu()
})

const styles = StyleSheet.create({
	main: {
		background: GrayScale.OFF_WHITE_COLOR,
		borderBottom: `1px solid ${GrayScale.LINE_COLOR}`,
		boxShadow: 'initial',
		zIndex: '1000',
		height: '73px'
	},
	container: {
		height: '72px',
		justifyContent: 'center'
	},
	logo: {
		cursor: 'pointer',
	},
	imageLogo: {
		width: 140,
		height: 40,
		cursor: "pointer",
		display: "block",
		pointerEvents: 'auto'
	},
})

export default CBHeaderMenu;
