import {QuestionType} from "./QuestionType";
import {AnswerType} from "./AnswerType";

export type TestStructureType = {
  idTest: number,
  testStructure: QuestionType[]
}

export const initialQuestionStructure: AnswerType[] = [
  {
    id: 1,
    questionId: null,
    content: '',
    isCorrect: true,
    orderIndex: 1
  },
  {
    id: 2,
    questionId: null,
    content: '',
    isCorrect: false,
    orderIndex: 2
  }
]