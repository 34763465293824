
/*
Хелпер для бизнес логики стейтменов по видео в курсе
Здесь будут функции по формированию стейтментов и их частей для отправки базовых событий по видео в курсе (запуск, открытие и т.д.)
*/

import XAPI, {Actor, Context, ContextActivity, Statement, StatementObject} from "@xapi/xapi";
import {CourseLiveCycleEvents, XAPICourseLiveCycleObjects} from "../XAPIVerbsObjects";
import {UserCourseDetails} from "../../../types/materials/course/UserCourseDetails";
import {PageDetails} from "../../../types/materials/course/PageDetails";
import {BaseXAPIHelper} from "./BaseXAPIHelper";

export class BaseCourseVideoHelper {

  static async sendStatement(statement: Statement, xAPIInstance: XAPI){
    try {
      const data = xAPIInstance.sendStatement({
        statement: statement
      })
      console.warn(`send video statement ${JSON.stringify(statement)}`)
      return Promise.resolve()
    } catch (e) {
      return Promise.reject(e)
    }
  }

  static currentVideoContextParent(userCourseData: UserCourseDetails): ContextActivity {
    return {
      id: `https://coursebit.ru/course/${userCourseData.id}`,
      definition: {
        type: 'http://adlnet.gov/expapi/activities/course',
        name: {"ru-RU": userCourseData.title ?? ''},
        description: {"ru-RU": userCourseData.description ?? ''},
      },
      objectType: 'Activity',
    }
  }
  static getContextVerb(userCourseData: UserCourseDetails, sessionId: string): Context {
    return {
      registration: userCourseData.idUserCourse,
      contextActivities: {
        parent: [this.currentVideoContextParent(userCourseData)]
      },
      extensions: {
        ...BaseXAPIHelper.getDeviceExtensions(),
        'https://w3id.org/xapi/cmi5/context/extensions/sessionid': sessionId
      }
    }
  }
  static getVideoItemObject(page: PageDetails): StatementObject {
    return {
      id: `https://coursebit.ru/course/pages/video/${page.id}`,
      definition: {
        type: `http://adlnet.gov/expapi/activities/course/pages/video`,
        name: {"ru-RU": `${page.content.title ?? ''}`},
        description: {"ru-RU": `${page.content.description ?? ''}`},
      },
      objectType: 'Activity'
    }
  }
  static async sendVideoStartEvent(
    currentActor: Actor,
    page: PageDetails,
    userCourseData: UserCourseDetails,
    sessionId: string,
    xAPIInstance: XAPI
  ){
    const statement: Statement = {
      actor: currentActor,
      verb: XAPICourseLiveCycleObjects[CourseLiveCycleEvents.VIDEO_START_LEARNING],
      object: this.getVideoItemObject(page),
      context: this.getContextVerb(userCourseData, sessionId),
      timestamp: new Date().toISOString()
    }
    await this.sendStatement(statement, xAPIInstance)
  }

  static async sendVideoEndLearning(
    currentActor: Actor,
    page: PageDetails,
    userCourseData: UserCourseDetails,
    sessionId: string,
    xAPIInstance: XAPI
  ) {
    const statement: Statement = {
      actor: currentActor,
      verb: XAPICourseLiveCycleObjects[CourseLiveCycleEvents.VIDEO_END_LEARNING],
      object: this.getVideoItemObject(page),
      context: this.getContextVerb(userCourseData, sessionId),
      timestamp: new Date().toISOString()
    }
    await this.sendStatement(statement, xAPIInstance)
  }

  static async sendVideoCancelEvent(
    currentActor: Actor,
    page: PageDetails,
    userCourseData: UserCourseDetails,
    sessionId: string,
    xAPIInstance: XAPI
  ) {
    const statement: Statement = {
      actor: currentActor,
      verb: XAPICourseLiveCycleObjects[CourseLiveCycleEvents.VIDEO_CANCEL_LEARNING],
      object: this.getVideoItemObject(page),
      context: this.getContextVerb(userCourseData, sessionId),
      timestamp: new Date().toISOString()
    }
    await this.sendStatement(statement, xAPIInstance)
  }

  static async sendVideoOpenForRelearningEvent(
    currentActor: Actor,
    page: PageDetails,
    userCourseData: UserCourseDetails,
    sessionId: string,
    xAPIInstance: XAPI
  ) {
    const statement: Statement = {
      actor: currentActor,
      verb: XAPICourseLiveCycleObjects[CourseLiveCycleEvents.VIDEO_OPEN_FOR_RELEARNING],
      object: this.getVideoItemObject(page),
      context: this.getContextVerb(userCourseData, sessionId),
      timestamp: new Date().toISOString()
    }
    await this.sendStatement(statement, xAPIInstance)
  }

  static async sendVideoCloseOfRelearningEvent(
    currentActor: Actor,
    page: PageDetails,
    userCourseData: UserCourseDetails,
    sessionId: string,
    xAPIInstance: XAPI
  ) {
    const statement: Statement = {
      actor: currentActor,
      verb: XAPICourseLiveCycleObjects[CourseLiveCycleEvents.VIDEO_CLOSE_OF_RELEARNING],
      object: this.getVideoItemObject(page),
      context: this.getContextVerb(userCourseData, sessionId),
      timestamp: new Date().toISOString()
    }
    await this.sendStatement(statement, xAPIInstance)
  }

  static async sendVideoPausedEvent(
    currentActor: Actor,
    page: PageDetails,
    userCourseData: UserCourseDetails,
    sessionId: string,
    xAPIInstance: XAPI
  ) {
    const statement: Statement = {
      actor: currentActor,
      verb: XAPICourseLiveCycleObjects[CourseLiveCycleEvents.VIDEO_PAUSED],
      object: this.getVideoItemObject(page),
      context: this.getContextVerb(userCourseData, sessionId),
      timestamp: new Date().toISOString()
    }
    await this.sendStatement(statement, xAPIInstance)
  }

  static async sendVideoResumeEvent(
    currentActor: Actor,
    page: PageDetails,
    userCourseData: UserCourseDetails,
    sessionId: string,
    xAPIInstance: XAPI
  ) {
    const statement: Statement = {
      actor: currentActor,
      verb: XAPICourseLiveCycleObjects[CourseLiveCycleEvents.VIDEO_RESUME],
      object: this.getVideoItemObject(page),
      context: this.getContextVerb(userCourseData, sessionId),
      timestamp: new Date().toISOString()
    }
    await this.sendStatement(statement, xAPIInstance)
  }

  static async sendVideoRewindToStartEvent(
    currentActor: Actor,
    page: PageDetails,
    userCourseData: UserCourseDetails,
    sessionId: string,
    xAPIInstance: XAPI
  ) {
    const statement: Statement = {
      actor: currentActor,
      verb: XAPICourseLiveCycleObjects[CourseLiveCycleEvents.VIDEO_REWIND_TO_START],
      object: this.getVideoItemObject(page),
      context: this.getContextVerb(userCourseData, sessionId),
      timestamp: new Date().toISOString()
    }
    await this.sendStatement(statement, xAPIInstance)
  }

  static async sendVideoRewindToEndEvent(
    currentActor: Actor,
    page: PageDetails,
    userCourseData: UserCourseDetails,
    sessionId: string,
    xAPIInstance: XAPI
  ) {
    const statement: Statement = {
      actor: currentActor,
      verb: XAPICourseLiveCycleObjects[CourseLiveCycleEvents.VIDEO_REWIND_TO_END],
      object: this.getVideoItemObject(page),
      context: this.getContextVerb(userCourseData, sessionId),
      timestamp: new Date().toISOString()
    }
    await this.sendStatement(statement, xAPIInstance)
  }

  static async sendVideoRewindToSomePathEvent(
    currentActor: Actor,
    page: PageDetails,
    userCourseData: UserCourseDetails,
    sessionId: string,
    xAPIInstance: XAPI
  ) {
    const statement: Statement = {
      actor: currentActor,
      verb: XAPICourseLiveCycleObjects[CourseLiveCycleEvents.VIDEO_REWIND_TO_SOME_PATH],
      object: this.getVideoItemObject(page),
      context: this.getContextVerb(userCourseData, sessionId),
      timestamp: new Date().toISOString()
    }
    await this.sendStatement(statement, xAPIInstance)
  }
}
