import React, {CSSProperties, useEffect, useState} from "react";
import {Colors, GrayScale} from "../../enums";
import {StyleSheet} from "../../../utils/styles";
import {defaultFontStyles} from "../../fontStyle";
import {IconButton, InputAdornment, TextField} from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import {stores} from "../../../pages/_app";

interface IProps {
  value: string,
  onChange: (value: string) => void,
  label?: string,
  disabled?: boolean,
  isValid?: boolean | 'disabled',
  onFocus?: () => void,
  helperText?: string,
  required?: boolean,
  minRows?: number,
  style?: CSSProperties,
  inputLabelProps?: object,
  maxLength?: number,
  placeholder?: string,
}

const CBTextarea = ({onChange, disabled=false, value, label, minRows=3, helperText, required, inputLabelProps, maxLength, onFocus, isValid, style, placeholder}: IProps) => {
  const [query, setQuery] = useState(value)

  useEffect(() => {
    onChange(query)
  }, [query])

  useEffect(() => {
    console.log(value)
    if (!stores.materialDialogStore.isOpen) {
      setQuery('');
    } else {
      setQuery(value)
    }
  }, [stores.materialDialogStore.isOpen])

  const handleFocus = () => {
    !!onFocus && onFocus()
  }

  const onClearClick = () => {
    setQuery("")
  }

  const handleTheme = () => {
    if (isValid === 'disabled') {
      return 'primary'
    }
    if (query?.length > 0) {
      return isValid ? 'success' : 'error'
    }
    return 'primary'
  }

  const renderCrossIcon = () => (
    <InputAdornment position="end" sx={{
      position: 'absolute',
      top: '26px',
      right: '22px',
      zIndex: '3',
    }}>
      <IconButton onClick={onClearClick} edge="end">
        <ClearIcon
          color={handleTheme()}
          sx={{
            color: GrayScale.TITLE_ACTIVE_COLOR,
            '&.MuiSvgIcon-colorError': {
              color: Colors.ERROR_DARK
            },
            '&.MuiSvgIcon-colorSuccess': {
              color: Colors.SUCCESS
            },
          }}
        />
      </IconButton>
    </InputAdornment>
  )

  const renderResizerIcon = () => (
    <InputAdornment position="end" sx={{
      cursor: 'default',
      position: 'absolute',
      bottom: '20px',
      right: '15px',
    }}>
      <svg width="18" height="18" viewBox="0 0 18 18" fill="#333" xmlns="http://www.w3.org/2000/svg">
        <path d="M9 17L17 9" stroke="#A0BDBB"/>
        <path d="M1 17L17 1" stroke="#A0BDBB"/>
      </svg>
    </InputAdornment>
  )

  return (
    <TextField
      sx={styles.default}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => setQuery(event.target.value)}
      disabled={disabled}
      label={label}
      value={query}
      placeholder={placeholder}
      style={{...style}}
      multiline={true}
      minRows={minRows}
      helperText={helperText}
      required={required}
      InputLabelProps={{...inputLabelProps, shrink: false}}
      onFocus={handleFocus}
      InputProps={{
        startAdornment: renderResizerIcon(),
        endAdornment: !disabled && query?.length > 0 && renderCrossIcon(),
      }}
      inputProps={{...(maxLength !== undefined ? {maxLength: maxLength} : {})}}
      color={handleTheme()}
      error={!isValid && query.length > 0}
    />
  )
}

const styles = StyleSheet.create({
  'default': {
    width: '325px',
    boxSizing: 'border-box',
    border: '2px solid transparent',
    outline: 'none',
    borderRadius: '16px',
    ...defaultFontStyles.textSmall,
    color: GrayScale.TITLE_ACTIVE_COLOR,
    resize: 'vertical',
    backgroundColor: GrayScale.INPUT_BACKGROUND,

    '&:has(div.Mui-focused)': {
      border: "2px solid" + GrayScale.TITLE_ACTIVE_COLOR,
      backgroundColor: GrayScale.OFF_WHITE_COLOR
    },

    '&:has(div.Mui-disabled)': {
      opacity: '0.5',
    },

    '&:has(div.MuiInputBase-colorSuccess)': {
      border: "2px solid" + Colors.PRIMARY_COLOR,
      backgroundColor: Colors.PRIMARY_LIGHT
    },

    '&:has(div.Mui-error)': {
      border: "2px solid" + Colors.ERROR,
      backgroundColor: Colors.ERROR_LIGHT
    },

    '&:has(p.MuiFormHelperText-root)': {
      marginBottom: '25px',
    },

    '& fieldset': {
      border: '0',
      ...defaultFontStyles.textSmall,
    },

    '& textarea': {
      resize: 'vertical',
      ...defaultFontStyles.textSmall,
      color: GrayScale.TITLE_ACTIVE_COLOR,
      padding: '22px 6.5px 0 7.5px',
      zIndex: '2',
      minHeight: '40px',

      '&::-webkit-resizer': {
        display: 'none',
      }
    },

    '& label': {
      ...defaultFontStyles.textSmall,
      color: GrayScale.PLACEHOLDER_COLOR,
      top: '3px',
      left: '7px',

      '&.Mui-focused': {
        color: GrayScale.PLACEHOLDER_COLOR,
      },

      '&.MuiFormLabel-filled': {
        ...defaultFontStyles.textXSmall,
        color: GrayScale.LABEL_COLOR,
      },

      '&.MuiFormLabel-colorSuccess': {
        color: Colors.PRIMARY_DARK,
      },

      '&.Mui-error': {
        color: Colors.ERROR_DARK,
      }
    },

    '& p': {
      ...defaultFontStyles.textXSmall,
      margin: '0',
      position: 'absolute',
      bottom: '-33px',
      color: GrayScale.LABEL_COLOR,

      '&.MuiFormHelperText-filled': {
        color: Colors.PRIMARY_DARK,
      },

      '&.Mui-error': {
        color: Colors.ERROR_DARK,
      }
    },
  }
})

export default CBTextarea
