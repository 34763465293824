import {StyleSheet} from "./styles";

export const baseTextStyles = StyleSheet.create({
  montserratS12W400: {
    fontFamily: 'Montserrat',
    fontSize: 12,
    fontWeight: 400
  },
  montserratS12W500: {
    fontFamily: 'Montserrat',
    fontSize: 12,
    fontWeight: 500
  },
  montserratS14W400: {
    fontFamily: 'Montserrat',
    fontSize: 14,
    fontWeight: 400
  },
  montserratS14W500: {
    fontFamily: 'Montserrat',
    fontSize: 14,
    fontWeight: 500
  },
  montserratS14W600: {
    fontFamily: 'Montserrat',
    fontSize: 14,
    fontWeight: 600
  },
  montserratS13W500: {
    fontFamily: 'Montserrat',
    fontSize: 13,
    fontWeight: 500
  },
  montserratS13W600: {
    fontFamily: 'Montserrat',
    fontSize: 13,
    fontWeight: 600
  },
  montserratS13W400: {
    fontFamily: 'Montserrat',
    fontSize: 13,
    fontWeight: 400
  },
  montserratS12W600: {
    fontFamily: 'Montserrat',
    fontSize: 12,
    fontWeight: 600
  },
  montserratS15WBold: {
    fontFamily: 'Montserrat',
    fontSize: 15,
    fontWeight: 'bold'
  },
  montserratS15W400: {
    fontFamily: 'Montserrat',
    fontSize: 15,
    fontWeight: '400'
  },
  montserratS15W500: {
    fontFamily: 'Montserrat',
    fontSize: 15,
    fontWeight: '500'
  },
  montserratS15W600: {
    fontFamily: 'Montserrat',
    fontSize: 15,
    fontWeight: '600'
  },
  montserratS18W600: {
    fontFamily: 'Montserrat',
    fontSize: 18,
    fontWeight: '600'
  },
  montserratS16WBold: {
    fontFamily: 'Montserrat',
    fontSize: 16,
    fontWeight: 'bold'
  },
  montserratS27W700: {
    fontFamily: 'Montserrat',
    fontSize: 27,
    fontWeight: '700'
  },
  montserratAlternatesS12W600: {
    fontFamily: 'Montserrat Alternates',
    fontSize: 12,
    fontWeight: 600
  },
  montserratAlternatesS14W600: {
    fontFamily: 'Montserrat Alternates',
    fontSize: 14,
    fontWeight: 600
  },
  montserratAlternatesS13W600: {
    fontFamily: 'Montserrat Alternates',
    fontSize: 13,
    fontWeight: 600
  },
  montserratAlternatesS13W500: {
    fontFamily: 'Montserrat Alternates',
    fontSize: 13,
    fontWeight: 500
  }
})
