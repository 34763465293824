import {GPTRequestType} from "../../../../stores/GPTStore";
import {observer} from "mobx-react-lite";
import {stores} from "../../../../pages/_app";
import {
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle
} from "@mui/material";
import React, {useEffect, useRef} from "react";
import {StyleSheet} from "../../../../utils/styles";
import {defaultFontStyles} from "../../../../uikit/fontStyle";
import {baseStyles} from "../../../../utils/baseStyles";
import {Colors, ComponentSize, ComponentType} from "../../../../uikit/enums";
import {baseButtonStyles} from "../../../../utils/baseButtonStyles";
import CBButton from "../../../../uikit/baseComponent/buttons/mainButton";

interface IProps {
  mainTitle: string,
  scroll: DialogProps['scroll'],
  articleTitle: string
}

const CBGptSummary = observer(({mainTitle, scroll, articleTitle}: IProps) => {
  const descriptionElementRef = useRef<HTMLElement>(null);
  useEffect(() => {
    if (stores.materialDialogStore.isOpen) {
      const {current: descriptionElement} = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [stores.materialDialogStore.isOpen]);

  const handleClose = () => {
    stores.gptStore.clearGptResponses();
    stores.materialDialogStore.setCurrentState(false)
  }

  return (
    <>
      <DialogTitle id="scroll-dialog-title" sx={styles.title} children={`${mainTitle} "${articleTitle}"`}/>
      {!stores.gptStore.isRequestInProcess && !!stores.gptStore.gptSummary ? (
          <>
            <DialogContent dividers={scroll === 'paper'}>
              <DialogContentText
                id="scroll-dialog-description"
                ref={descriptionElementRef}
                tabIndex={-1}
                sx={{width: '900px', height: 'auto'}}
              >
                <div id={'summaryBody'} style={styles.content}
                     dangerouslySetInnerHTML={{__html: stores.gptStore.gptSummary || ''}}/>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <CBButton
                disabled={false}
                onClick={handleClose}
                children={'Закрыть'}
                size={ComponentSize.SMALL}
                type={ComponentType.PRIMARY}
                style={baseButtonStyles.buttonDiscard}
              />
            </DialogActions>
          </>
      ) : (
        <div style={styles.progressContainer}>
          <CircularProgress sx={styles.progress}/>
        </div>
      )
      }
    </>
  )
})

const styles = StyleSheet.create({
  title: {
    ...defaultFontStyles.displaySmallBold,
  },
  content: {
    ...defaultFontStyles.linkSmall,
  },
  progressContainer: {
    display: 'flex',
    ...baseStyles.centeredView,
    width: '1048px',
    height: '616px'
  },
  progress: {
    alignSelf: 'center',
    color: Colors.PRIMARY_COLOR,
  }
})

export default CBGptSummary