import {Verb} from "@xapi/xapi/dist/types/resources/statement/Verb";

export const XAPIVerbsObjects = {
  launched: {
    id: "http://adlnet.gov/expapi/verbs/launched",
    display: {
      "ru-RU": 'Пользователь открыл курс (начал сессию)'
    }
  },
  initialized: {
    id: "http://adlnet.gov/expapi/verbs/initialized",
    display: {
      "ru-RU": 'Курс инициализирован (данные подготовлены)'
    }
  },
  passed: {
    id: "http://adlnet.gov/expapi/verbs/passed",
    display: {
      "ru-RU": 'Пользователь завершил действие'
    }
  },
  terminated: {
    id: "http://adlnet.gov/expapi/verbs/terminated",
    display: {
      "ru-RU": 'Пользователь закрыл курс (завершил сессию)'
    }
  }
}

export enum CourseLiveCycleEvents {
  // MAIN LIVE CYCLE OF COURSE
  LAUNCH_COURSE = 'LAUNCH_COURSE',
  INITIALIZED_DATA = 'INITIALIZED_DATA',
  USER_STARTED_COURSE = 'USER_STARTED_COURSE',
  USER_COMPLETE_COURSE = 'USER_COMPLETE_COURSE',
  EXIT_FROM_COURSE = 'EXIT_FROM_COURSE',

  // ARTICLE LIVE CYCLE
  ARTICLE_START_LEARNING = 'ARTICLE_START_LEARNING',
  ARTICLE_END_LEARNING = 'ARTICLE_END_LEARNING',
  ARTICLE_CANCEL_LEARNING = 'ARTICLE_CANCEL_LEARNING',
  ARTICLE_OPEN_FOR_RELEARNING = 'ARTICLE_OPEN_FOR_RELEARNING',
  ARTICLE_CLOSE_OF_RELEARNING = 'ARTICLE_CLOSE_OF_RELEARNING',

  // TEST LIVE CYCLE
  TEST_TRY_START_LEARNING = 'TEST_TRY_START_LEARNING',
  TEST_TRY_END_LEARNING = 'TEST_TRY_END_LEARNING',
  TEST_TRY_CANCEL_LEARNING = 'TEST_TRY_CANCEL_LEARNING',
  TEST_TRY_FAILED_LEARNING = 'TEST_TRY_FAILED_LEARNING',
  TEST_OPEN_FOR_RELEARNING = 'TEST_OPEN_FOR_RELEARNING',
  TEST_CLOSE_OF_RELEARNING = 'TEST_CLOSE_OF_RELEARNING',
  TEST_ANSWER_CHOOSE = 'TEST_ANSWER_CHOOSE',
  TEST_ANSWER_ANSWERED = 'TEST_ANSWER_ANSWERED',

  // VIDEO LIVE CYCLE
  VIDEO_START_LEARNING = 'VIDEO_START_LEARNING',
  VIDEO_END_LEARNING = 'VIDEO_END_LEARNING',
  VIDEO_CANCEL_LEARNING = 'VIDEO_CANCEL_LEARNING',
  VIDEO_OPEN_FOR_RELEARNING = 'VIDEO_OPEN_FOR_RELEARNING',
  VIDEO_CLOSE_OF_RELEARNING = 'VIDEO_CLOSE_OF_RELEARNING',
  VIDEO_PAUSED = 'VIDEO_PAUSED',
  VIDEO_RESUME = 'VIDEO_RESUME',
  VIDEO_REWIND_TO_START = 'VIDEO_REWIND_TO_START',
  VIDEO_REWIND_TO_END = 'VIDEO_REWIND_TO_END',
  VIDEO_REWIND_TO_SOME_PATH = 'VIDEO_REWIND_TO_SOME_PATH'
}

type XAPICourseLiveCycleObjects = {
  [key in CourseLiveCycleEvents]: Verb
}

export const XAPICourseLiveCycleObjects: XAPICourseLiveCycleObjects = {
  [CourseLiveCycleEvents.LAUNCH_COURSE]: {
    id: "http://adlnet.gov/expapi/verbs/launched",
    display: {
      "ru-RU": 'Пользователь открыл курс (начал сессию)'
    }
  },
  [CourseLiveCycleEvents.INITIALIZED_DATA]: {
    id: "http://adlnet.gov/expapi/verbs/loaded",
    display: {
      "ru-RU": 'Курс инициализирован (данные подготовлены)'
    }
  },
  [CourseLiveCycleEvents.USER_STARTED_COURSE]: {
    id: "http://adlnet.gov/expapi/verbs/initialized",
    display: {
      "ru-RU": 'Пользователь начал изучение курса'
    }
  },
  [CourseLiveCycleEvents.USER_COMPLETE_COURSE]: {
    id: "http://adlnet.gov/expapi/verbs/completed",
    display: {
      "ru-RU": 'Пользователь завершил изучение курса'
    }
  },
  [CourseLiveCycleEvents.EXIT_FROM_COURSE]: {
    id: "http://adlnet.gov/expapi/verbs/terminated",
    display: {
      "ru-RU": 'Пользователь закрыл курс (завершил сессию)'
    }
  },

  // ARTICLE
  [CourseLiveCycleEvents.ARTICLE_START_LEARNING]: {
    id: "http://adlnet.gov/expapi/verbs/initialized",
    display: {
      "ru-RU": 'Пользователь начал изучение статьи'
    }
  },
  [CourseLiveCycleEvents.ARTICLE_END_LEARNING]: {
    id: "http://adlnet.gov/expapi/verbs/completed",
    display: {
      "ru-RU": 'Пользователь завершил изучение статьи'
    }
  },
  [CourseLiveCycleEvents.ARTICLE_CANCEL_LEARNING]: {
    id: "http://adlnet.gov/expapi/verbs/terminated",
    display: {
      "ru-RU": 'Пользователь закрыл статью не завершив изучение'
    }
  },
  [CourseLiveCycleEvents.ARTICLE_OPEN_FOR_RELEARNING]: {
    id: "http://adlnet.gov/expapi/verbs/resumed",
    display: {
      "ru-RU": 'Пользователь вернулся к прочтению уже изученной ранее статьи'
    }
  },
  [CourseLiveCycleEvents.ARTICLE_CLOSE_OF_RELEARNING]: {
    id: "http://adlnet.gov/expapi/verbs/exited",
    display: {
      "ru-RU": 'Пользователь закрыл уже изученную ранее статью'
    }
  },

  // TEST
  [CourseLiveCycleEvents.TEST_TRY_START_LEARNING]: {
    id: "http://adlnet.gov/expapi/verbs/initialized",
    display: {
      "ru-RU": 'Пользователь начинает попытку прохождения теста'
    }
  },
  [CourseLiveCycleEvents.TEST_TRY_END_LEARNING]: {
    id: "http://adlnet.gov/expapi/verbs/completed",
    display: {
      "ru-RU": 'Пользователь завершает прохождения теста успешно'
    }
  },
  [CourseLiveCycleEvents.TEST_TRY_CANCEL_LEARNING]: {
    id: "http://adlnet.gov/expapi/verbs/terminated",
    display: {
      "ru-RU": 'Пользователь закрыл тест не завершив изучение'
    }
  },
  [CourseLiveCycleEvents.TEST_TRY_FAILED_LEARNING]: {
    id: "http://adlnet.gov/expapi/verbs/failed",
    display: {
      "ru-RU": 'Пользователь проваливает попытку прохождения теста'
    }
  },
  [CourseLiveCycleEvents.TEST_OPEN_FOR_RELEARNING]: {
    id: "http://adlnet.gov/expapi/verbs/resumed",
    display: {
      "ru-RU": 'Пользователь открыл уже пройденный ранее тест'
    }
  },
  [CourseLiveCycleEvents.TEST_CLOSE_OF_RELEARNING]: {
    id: "http://adlnet.gov/expapi/verbs/exited",
    display: {
      "ru-RU": 'Пользователь закрыл уже пройденный ранее тест'
    }
  },
  [CourseLiveCycleEvents.TEST_ANSWER_CHOOSE]: {
    id: "http://adlnet.gov/expapi/verbs/attempted",
    display: {
      "ru-RU": 'Пользователь кликнул на вариант ответа (без подтверждения ответа)'
    }
  },
  [CourseLiveCycleEvents.TEST_ANSWER_ANSWERED]: {
    id: "http://adlnet.gov/expapi/verbs/answered",
    display: {
      "ru-RU": 'Пользователь ответил на вопрос'
    }
  },

  // VIDEO
  [CourseLiveCycleEvents.VIDEO_START_LEARNING]: {
    id: "http://adlnet.gov/expapi/verbs/initialized",
    display: {
      "ru-RU": 'Пользователь начал изучение видео'
    }
  },
  [CourseLiveCycleEvents.VIDEO_END_LEARNING]: {
    id: "http://adlnet.gov/expapi/verbs/completed",
    display: {
      "ru-RU": 'Пользователь завершил изучение видео'
    }
  },
  [CourseLiveCycleEvents.VIDEO_CANCEL_LEARNING]: {
    id: "http://adlnet.gov/expapi/verbs/terminated",
    display: {
      "ru-RU": 'Пользователь закрыл видео не завершив изучение'
    }
  },
  [CourseLiveCycleEvents.VIDEO_OPEN_FOR_RELEARNING]: {
    id: "http://adlnet.gov/expapi/verbs/resumed",
    display: {
      "ru-RU": 'Пользователь вернулся к просмотру уже изученного ранее видео'
    }
  },
  [CourseLiveCycleEvents.VIDEO_CLOSE_OF_RELEARNING]: {
    id: "http://adlnet.gov/expapi/verbs/exited",
    display: {
      "ru-RU": 'Пользователь закрыл уже изученное ранее видео'
    }
  },
  [CourseLiveCycleEvents.VIDEO_PAUSED]: {
    id: "http://adlnet.gov/expapi/verbs/paused",
    display: {
      "ru-RU": 'Пользователь поставил видео на паузу'
    }
  },
  [CourseLiveCycleEvents.VIDEO_RESUME]: {
    id: "http://adlnet.gov/expapi/verbs/played",
    display: {
      "ru-RU": 'Пользователь снял видео с паузы'
    }
  },
  [CourseLiveCycleEvents.VIDEO_REWIND_TO_START]: {
    id: "http://adlnet.gov/expapi/verbs/restarted",
    display: {
      "ru-RU": 'Пользователь пролистал видео к началу'
    }
  },
  [CourseLiveCycleEvents.VIDEO_REWIND_TO_END]: {
    id: "http://adlnet.gov/expapi/verbs/ended",
    display: {
      "ru-RU": 'Пользователь пролистал видео в конец'
    }
  },
  [CourseLiveCycleEvents.VIDEO_REWIND_TO_SOME_PATH]: {
    id: "http://adlnet.gov/expapi/verbs/interacted",
    display: {
      "ru-RU": 'Пользователь пролистал видео в произвольный момент'
    }
  },
}