export const isNullOrEmpty = (value: string | null | undefined) => {
  return value === null || value === undefined || value.trim() === ''
}

export const isOneNullOrEmptyInArray = (values: (string | null | undefined)[]) => {
  return values.reduce((sum: boolean, current: string | null | undefined) => sum || isNullOrEmpty(current), false)
}

export const isAllNullOrEmptyInArray = (values: (string | null | undefined)[]) => {
  return values.reduce((sum: boolean, current: string | null | undefined) => sum && isNullOrEmpty(current), true)
}