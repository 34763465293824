import React, {CSSProperties} from 'react'
import {MenuItem, Select, Skeleton} from "@mui/material";
import {Colors, ComponentSize, GrayScale} from "../../enums";
import {StyleSheet} from "../../../utils/styles";
import {defaultFontStyles} from '../../fontStyle';
import ForwardDownSvg from '../../../public/icons/forward_down.svg';

interface IProps {
  children?: JSX.Element | JSX.Element[],
  value?: string,
  onChange: (value: any) => void,
  defaultValue?: any,
  placeholder?: string,
  size?: ComponentSize,
  disabled?: boolean,
  style?: CSSProperties,
  placeholderDisabled?: boolean,
  endAdornment?: string | JSX.Element,
  color?: Colors | GrayScale,
  background?: Colors | GrayScale,
  strokeColor?: Colors | GrayScale
}

export const CBDropdown = ({children, onChange, defaultValue, disabled = false, size=ComponentSize.MEDIUM, placeholder, value, style, placeholderDisabled=true, endAdornment, color = GrayScale.BODY_COLOR, background = GrayScale.OFF_WHITE_COLOR, strokeColor}: IProps) => {

  return children ?
    <Select
      value={value}
      defaultValue={defaultValue || ''}
      displayEmpty={true}
      onChange={(e) => onChange(e.target.value)}
      onClose={() => {
        // Асинхронно убирает фокус с дропдауна.
        setTimeout(() => {
          (document.activeElement as HTMLElement).blur()
        }, 0);
      }}
      disabled={disabled}
      IconComponent={!!endAdornment ? endAdornment : ForwardDownSvg}
      MenuProps={{disableScrollLock: true, sx: styles.menu}}
      renderValue={(selected) => <em>{selected}</em>}
      style={style}
      sx={[styles.default, styles[size], { background: `${background} !important`, '&.MuiInputBase-root > .MuiSelect-select > em': {...defaultFontStyles.textSmall, color}, '& .MuiSelect-iconOutlined>path': {stroke: strokeColor} }]}
    >
      <MenuItem sx={{display: placeholder ? 'flex' : 'none', width: '100%'}} disabled={placeholderDisabled}
                value={placeholder || ''}>{placeholder || ''}</MenuItem>
      {children}
    </Select>
    : <Skeleton sx={{borderRadius: '15px'}} variant="rectangular" width={124} height={34}/>
}

const styles = StyleSheet.create({
  default: {
    '&.MuiInputBase-root': {
      backgroundColor: GrayScale.OFF_WHITE_COLOR,
      borderRadius: '16px'
    },
    '& fieldset': {
      borderRadius: '16px',
      borderColor: GrayScale.LINE_COLOR
    },
    '&:has(svg.MuiSelect-iconOpen)': {
      outline: "none",
    },
    '&:has(svg.MuiSelect-iconOpen) fieldset': {
      borderColor: `${GrayScale.TITLE_ACTIVE_COLOR} !important`,
    },
    '&.Mui-focused': {
      outline: '8px solid' + Colors.PRIMARY_LIGHT,
      borderRadius: '16px'
    },
    '&.Mui-focused fieldset': {
      borderColor: `${Colors.PRIMARY_COLOR} !important`,
    },
    '& .MuiSelect-icon': {
      right: '24px',
      top: 'auto'
    },
    '&.Mui-focused .MuiSelect-icon': {
      transform: 'rotate(270deg)'
    },
    '&.Mui-focused .MuiSelect-icon>path': {
      stroke: GrayScale.TITLE_ACTIVE_COLOR
    }

  },

  large: {
    '&.MuiInputBase-root .MuiInputBase-input': {
      padding: '18px 50px 18px 20px!important'
    }
  },

  medium: {
    '&.MuiInputBase-root .MuiInputBase-input': {
      padding: '13px 50px 13px 20px!important'
    }
  },

  small: {
    '&.MuiInputBase-root .MuiInputBase-input': {
      padding: '5px 60px 5px 30px!important'
    }
  },

  extraSmall: {
    '&.MuiInputBase-root': {
      border: 'initial',
      backgroundColor: GrayScale.INPUT_BACKGROUND,
      borderRadius: '15px',
      height: '34px',
    },
    '&.MuiInputBase-root .MuiSelect-select': {
      paddingTop: '0',
      paddingBottom: '0',
      paddingRight: '62px !important'
    },
    '& fieldset': {
      border: 'none'
    },
    '&:has(svg.MuiSelect-iconOpen) fieldset': {
      borderColor: GrayScale.TITLE_ACTIVE_COLOR
    },
    '&.Mui-focused fieldset': {
      border: `2px solid ${Colors.PRIMARY_COLOR} !important`,
    },

  },

  menu: {
    '& .MuiPaper-root': {
      marginTop: '5px',
      maxHeight: '400px',
      backgroundColor: GrayScale.INPUT_BACKGROUND,
      color: GrayScale.TITLE_ACTIVE_COLOR,
      borderRadius: '16px',
      ...defaultFontStyles.textSmall,
      '& li.MuiButtonBase-root': {
        padding: '0 24px',
        minHeight: '39px',
      }
    }
  }
})
