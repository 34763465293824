import {makeAutoObservable, runInAction} from "mobx";
import networkService, {networkService1} from "../services/NetworkService";
import {IRootStore} from "./global/RootStore";
import {UserCourse} from "../types/materials/course/UserCourse";
import {UserCourseDetails} from "../types/materials/course/UserCourseDetails";
import {Template} from "@pdfme/common";
import {PageDetails} from "../types/materials/course/PageDetails";

export const CoursePages = {
	materials: 'materials',
	certificate: 'certificate',
	discussion: 'discussion'
}

export class UserCourseStore {
  userCourses: UserCourse[] = []
  isCoursesLoading: boolean = false
  currentUserCourse: UserCourseDetails | null = null
  isCurrentCourseLoading: boolean = true
  currentMenuPage: string = CoursePages.materials
  //TODO возможно нарушены импорты
  templateCertificate: Template | null = null
  publicTemplate: Template | null = null
  private rootStore: IRootStore

	constructor(rootStore: IRootStore) {
		makeAutoObservable(this)
		this.rootStore = rootStore
	}

	setCurrentPage = (page: string) => {
		this.currentMenuPage = page
	}

	loadTemplateCertificate = async (urlTemplate: string) => {
		try {
			const {data} = await networkService1.get(urlTemplate)
			runInAction(() => {
				this.templateCertificate = data
			})
			return Promise.resolve()
		} catch (e) {
			// @ts-ignore
			console.log('Loading certificate template failed. Error: ', (e.response.data))
			return Promise.reject(e)
		}
	}

	loadUserCourses = async () => {
		try {
			runInAction(() => this.isCoursesLoading = true)
			const {data} = await networkService.post('userCourses/getUserCourses')
			runInAction(() => {
				this.userCourses = data
				this.isCoursesLoading = false
			})
			return Promise.resolve()
		} catch (e) {
			// @ts-ignore
			return Promise.reject(e.response.data)
		}
	}
// TODO - создать необходимые запросы для сортировки
	getSortedCourses = async (type: 'category' | 'search' | 'sort' | 'page', value: number | string | null) => {
		try {
			runInAction(() => this.isCoursesLoading = true);
			const {data} = await networkService.post('userCourses/getUserCourses');
			runInAction(() => {
				let sortedData;
				switch (type) {
					case "search":
						sortedData = data.filter(course => course.courseData.title.toLowerCase().includes(String(value).trim()));
						break;
					case "category":
						sortedData = value === '' ? data :  data.filter(course => course.courseData.categories.includes(Number(value)));
						break;
					case "sort":
						if (value === 1) {
							sortedData = this.userCourses.sort();
						} else if (value === 2) {
							sortedData = this.userCourses.sort().reverse();
						} else if (value === 3) {
							sortedData = this.userCourses.sort((a, b) => new Date(a.dateOfReceipt).getTime() - new Date(b.dateOfReceipt).getTime());
						} else if (value === 4) {
							sortedData = this.userCourses.sort((a, b) => new Date(b.dateOfReceipt).getTime() - new Date(a.dateOfReceipt).getTime());
						}
						break;
					default:
						sortedData = data;
						break;
				}
				this.userCourses = sortedData;
				this.isCoursesLoading = false;
			})
			return Promise.resolve();
		} catch (e) {
			return Promise.reject(e.response.data)
		}
	}

	loadUserCourseById = async (copyCourseId: string | null | undefined, isEnableInfinityLoading: boolean = false) => {
		try {
			runInAction(() => this.isCurrentCourseLoading = true)
			const {data} = await networkService.post('userCourses/getCourseDetails', {
				copyCourseId: copyCourseId
			})
			runInAction(() => {
				this.currentUserCourse = data
				if (!this.currentUserCourse?.certificateTemplate)
					return
				this.loadTemplateCertificate(this.currentUserCourse?.certificateTemplate)
				this.isCurrentCourseLoading = isEnableInfinityLoading
			})
			return Promise.resolve()
		} catch (e) {
			// @ts-ignore
			return Promise.reject(e.response.data)
		}
	}

	disablePageLoading = () => {
		this.isCurrentCourseLoading = false
	}

	clearCurrentUserCourse = () => {
		this.currentUserCourse = null
	}

	setCertificateItem = (template: Template, courseItem: UserCourse) => {
		const sampleData = template.sampledata?.[0]
		this.publicTemplate = {
			"schemas": template.schemas,
			"basePdf": template.basePdf,
			sampledata: [{
				"Номер сертификата": !!courseItem?.certificateNumber ? courseItem?.certificateNumber : '',
				"Регистрационный номер": sampleData?.["Регистрационный номер"] ?? '',
				"Город": sampleData?.["Город"] ?? '',
				"Дата выдачи": !!courseItem?.certificateFateIssue ? courseItem?.certificateFateIssue : '',
				"Фамилия": this.rootStore.userStore.userData.secondName ?? '',
				"Имя": this.rootStore.userStore.userData.firstName ?? '',
				"Отчество": this.rootStore.userStore.userData.thirdName ?? '',
				"Автор курса": sampleData?.["Автор курса"] ?? '',
				"Название курса": !!courseItem?.courseData.title ? courseItem?.courseData.title : '',
				"Объем": sampleData?.["Объем"] ?? '',
				"Руководитель": sampleData?.["Руководитель"] ?? '',
				"Секретарь": sampleData?.["Секретарь"] ?? ''
			}],
			columns: template.columns
		}
	}

	setCourseStateCode = (stateCode: string) => {
		this.currentUserCourse.courseStateCode = stateCode
	}

	setCurrentCertificate = (template: Template) => {
		const sampleData = template.sampledata?.[0]
		this.publicTemplate = {
			"schemas": template.schemas,
			"basePdf": template.basePdf,
			sampledata: [{
				"Номер сертификата": this.currentUserCourse?.certificateNumber ?? '',
				"Регистрационный номер": sampleData?.["Регистрационный номер"] ?? '',
				"Город": sampleData?.["Город"] ?? '',
				"Дата выдачи": this.currentUserCourse?.certificateFateIssue ?? '',
				"Фамилия": this.rootStore.userStore.userData.secondName ?? '',
				"Имя": this.rootStore.userStore.userData.firstName ?? '',
				"Отчество": this.rootStore.userStore.userData.thirdName ?? '',
				"Автор курса": sampleData?.["Автор курса"] ?? '',
				"Название курса": this.currentUserCourse?.title ?? '',
				"Объем": sampleData?.["Объем"] ?? '',
				"Руководитель": sampleData?.["Руководитель"] ?? '',
				"Секретарь": sampleData?.["Секретарь"] ?? ''
			}],
			columns: template.columns
		}
	}
}

export interface IUserCourseStore extends UserCourseStore {
}

export const getDuration = (page: PageDetails) => {
	if (page.typeContent.code === 'video') {
		if (!page.content.duration || page.content.duration === 0)
			return '0 ч 0мин 0 сек'

		const hours = Math.floor((page.content.duration || 0) / 3600);
		const minutes = Math.floor(((page.content.duration || 0) - (hours * 3600)) / 60)
		const seconds = (page.content.duration || 0) - (hours * 3600) - (minutes * 60);
		return `${hours} ч ${minutes} мин ${seconds} сек`
	} else {
		var hours = Math.floor((page.content.duration || 0) / 60);
		var minutes = (page.content.duration || 0) % 60;
		return `${hours} ч ${minutes} мин`
	}
}
