import {Box} from '@mui/material';
import React, {CSSProperties} from 'react';
import {CBDropdown} from '../../baseComponent/dropdown';
import {Colors, ComponentSize, FontStyle, GrayScale} from '../../enums';
import CBText from '../../baseComponent/text';

interface IProps {
  text?: string,
  children?: JSX.Element | JSX.Element[],
  value?: string,
  onChange: (value: any) => void,
  defaultValue?: any,
  placeholder?: string,
  size?: ComponentSize,
  disabled?: boolean
  style?: CSSProperties,
  background?: Colors | GrayScale
}

const CBTextWithChoose = ({text, style, children, onChange, defaultValue, disabled, size=ComponentSize.SMALL, placeholder, value, background }: IProps) => {
  return (
    <Box style={style} sx={{
      display: 'flex',
      alignItems: 'center'
    }}>
      <CBText color={Colors.PRIMARY_COLOR} fontStyle={FontStyle.SMALL} style={{
        marginRight: '10px',
      }}>
        {text}
      </CBText>
      <CBDropdown
        value={value}
        onChange={onChange}
        size={size}
        defaultValue={defaultValue}
        disabled={disabled}
        background={background}
        placeholder={placeholder}>
          {children}
      </CBDropdown>
    </Box>
  )
}

export default CBTextWithChoose
