import {observer} from "mobx-react-lite";
import {StyleSheet} from "../../../../utils/styles";
import {GptAnalysisType} from "../../../../stores/GPTStore";
import {AuthorAvatar} from "../AuthorAvatar";
import StarIcon from "@mui/icons-material/Star";
import {Colors, GrayScale} from "../../../../uikit/enums";
import React from "react";
import {Rating, Stack} from "@mui/material";
import {stores} from "../../../../pages/_app";
import StarBorderIcon from "@mui/icons-material/StarBorder";

interface IProps {
  analyticsData: GptAnalysisType
}

const CommentAnalytics = observer(({analyticsData}: IProps) => {
  const getFeelingType = () => {
    switch (analyticsData.feeling_type) {
      case "bad": {
        return <p style={{color: Colors.ERROR}}>Плохо</p>
      }
      case "neutral": {
        return <p style={{color: Colors.WARNING}}>Нейтрально</p>
      }
      case "good": {
        return <p style={{color: Colors.SUCCESS}}>Хорошо</p>
      }
    }
  }

  return (
    <div style={styles.container}>
      <div style={styles.contentContainer}>
        <div>
          <AuthorAvatar author={{
            id: analyticsData.user_id,
            login: analyticsData.login,
            profilePhoto: analyticsData.profile_photo ?? ''
          }} fontStyle={styles.author} avatarStyle={{cursor: 'default'}}/>
          <p style={styles.comment}>{analyticsData.text}</p>
        </div>
      </div>
      <div style={styles.analyticsContainer}>
        <div style={styles.ratingContainer}>
          <div style={styles.rating}>
            <p>Оценка пользователя</p>
            <Stack direction="row" spacing={0.5} sx={{alignItems: 'center'}}>
              <Rating name="customized-10" value={analyticsData.user_index ?? 0} precision={1} readOnly={true}
                      sx={{color: Colors.SUCCESS, columnGap: '3px', fontSize: '20px'}}
                      emptyIcon={<StarBorderIcon sx={{color: Colors.SUCCESS, fontSize: 'inherit'}}/>}
                      onChange={() => {}} max={10}/>
            </Stack>
          </div>
          <div style={{...styles.rating}}>
            <p>Оценка ИИ</p>
            <Stack direction="row" spacing={0.5} sx={{alignItems: 'center'}}>
              <Rating name="customized-10" value={analyticsData.satisfaction_index ?? 0} precision={1} readOnly={true}
                      sx={{color: Colors.SUCCESS, columnGap: '3px', fontSize: '20px'}}
                      emptyIcon={<StarBorderIcon sx={{color: Colors.SUCCESS, fontSize: 'inherit'}}/>}
                      onChange={() => {
                      }} max={10}/>
            </Stack>
          </div>
        </div>
        <div style={styles.analysisTextContainer}>
          <p style={styles.analysisTitle}>Описание от ИИ</p>
          <p>{analyticsData.analysis_text_general}</p>
          <p style={styles.analysisTitle}>Настроение комментария</p>
          {getFeelingType()}
          <p style={styles.analysisTitle}>Пожелание пользователя</p>
          {analyticsData.analysis_for_change?.map((item, index) => {
            return (
              <div key={`${index}${index}`} style={styles.listItem}>
                <div style={styles.marker}/>
                <p style={styles.label}>{item}</p>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
})

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flex: 1,
    marginTop: 30,
    border: '1px solid ' + GrayScale.LINE_COLOR,
    borderRadius: 20,
    flexDirection: 'column',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'space-between',
    border: '1px solid ' + GrayScale.LINE_COLOR,
    borderRadius: 20,
    padding: 20
  },
  analyticsContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    padding: 20
  },
  ratingContainer: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    marginTop: 10,
    justifyContent: 'space-between'
  },
  rating: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  analysisTextContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    marginTop: 10
  },
  marker: {
    background: '#000',
    width: 10,
    height: 10,
    borderRadius: 50,
    marginTop: 5
  },
  label: {
    marginLeft: 10,
    marginTop: 10
  },
  listItem: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    alignContent: 'center'
  },
  analysisTitle: {
    marginTop: 10,
    fontSize: 16,
    fontWeight: '600'
  },
  author: {
    color: '#000'
  },
  comment: {
    flex: 1,
    fontFamily: 'Roboto',
    fontWeight: 'normal',
    flexWrap: 'wrap',
    marginTop: 10,
    lineHeight: 1.5
  },
  ratingText: {
    fontFamily: 'Roboto',
    fontWeight: 'normal',
    fontSize: '25px',
    marginLeft: 3,
    lineHeight: 1.5,
    color: GrayScale.BACKGROUND_COLOR
  }
})

export default CommentAnalytics