import axios from "axios";
import {getAccessToken, getRefreshToken, setAccessToken, setRefreshToken} from "./localStorage/LocalStorageScope";
import {clearStorage} from "./localStorage/LocalStorage";
import {sha512} from "js-sha512";
import {AuthResponseModel} from "../types/auth/TokenDataModel";
import {isClient} from "../utils/isServerCheck";

const networkService = axios.create({
  // baseURL: `http://141.8.199.10:8080/`,
  //baseURL: `https://coursebit-ser.ru/`,
  // http://192.168.0.25:3000/gpt/
  baseURL: `https://coursebit-ser.ru/`,
  responseType: 'json',
  responseEncoding: 'utf8',
})

export const networkService1 = axios.create({})

networkService.interceptors.request.use((config) => {
  config.params = {...config.params}
  config.data = {
    ...config.data,
    ...(isClient() && getAccessToken() ? {'token': getAccessToken()} : {})
  }
  config.headers = {
    'Content-Type': 'application/json;charset=UTF-8',
    'Authorization': isClient() && getAccessToken() ? `Bearer ${getAccessToken()}` : '',
    'Sign': sha512(JSON.stringify(config.data) + "aawf46af4a546aw4f4afw&%*)nkajhjwfhK#*(#Tbbks65s4"),
    ...config.headers
  }
    console.log(config)
  //config.responseEncoding = 'binary'
  return config
})

const interceptor = networkService.interceptors.response.use(
  (response) => {
      console.log(response)
    return response
  },
  async error => {
    //TODO проверить на рефреш
    if (isClient() && getAccessToken() && error.response.status === 401) {
      axios.interceptors.response.eject(interceptor)

      try {
        const {data}: AuthResponseModel = await networkService.post("auth/token/refresh", {
          refreshToken: getRefreshToken(),
        })
        setAccessToken(data.token)
        setRefreshToken(data.refreshToken)
        error.response.config.headers['Authorization'] = data.token ? `Bearer ${data.token}` : ''
        return Promise.resolve(axios(error.config))
      } catch (e) {
        clearStorage()
        // @ts-ignore
        window.location = '/'
        // @ts-ignore
        return Promise.reject(error)
      }
    }
    return Promise.reject(error)
  })

export default networkService

