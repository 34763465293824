import {makeAutoObservable} from "mobx";
import {IRootStore} from "./global/RootStore";

export enum PageBitoruimNames {
  'articles' = 'articles',
  'course' = 'course',
  'webinars' = 'webinars',
  'news' = 'news',
  'discussions' = 'discussions'
}

export class MenuBitoriumStore {
  private rootStore: IRootStore

  constructor(rootStore: IRootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  currentPage: PageBitoruimNames = PageBitoruimNames.articles

  setCurrentPage = (page: PageBitoruimNames) => {
    this.currentPage = page
  }

}

export interface IMenuBitoriumStore extends MenuBitoriumStore {}
