import {CircularProgress, DialogActions, DialogContent, DialogProps, DialogTitle,} from "@mui/material";
import {PageDetails} from "../../../../types/materials/course/PageDetails";
import {observer} from "mobx-react-lite";
import {StyleSheet} from "../../../../utils/styles";
import {baseStyles} from "../../../../utils/baseStyles";
import {stores} from "../../../../pages/_app";
import React, {useEffect, useRef, useState} from "react";
import VideoPlayerKinescope from "../../../IndependentComponents/VideoPlayerKinescope";
import {defaultFontStyles} from "../../../../uikit/fontStyle";
import {Colors, ComponentSize, ComponentType, GrayScale, LearnModalType} from "../../../../uikit/enums";
import CBButton from "../../../../uikit/baseComponent/buttons/mainButton";
import {baseButtonStyles} from "../../../../utils/baseButtonStyles";
import {DialogType} from "../../MaterialDialogStore";

interface IProps {
	page: PageDetails,
	scroll: DialogProps['scroll'],
	type: LearnModalType
}

const LearningVideo = observer(({ page, scroll, type}: IProps) => {
	const [isVideoEnd, setIsVideoEnd] = useState(false)
	let playerRef: React.RefObject<any> = React.createRef()
	const descriptionElementRef = useRef<HTMLElement>(null);
	const [isLoading, setIsLoading] = useState(false)

	useEffect(() => {
		if (page === undefined || !stores.materialDialogStore.isOpen
				|| stores.materialDialogStore.currentDialogType !== DialogType.learningMaterial || type !== LearnModalType.LEARN_VIDEO) {
			return
		}
		stores.xAPIService.sendVideoStartEvent(page)

	}, [stores.materialDialogStore.isOpen, type, page, stores.materialDialogStore.currentDialogType])

	useEffect(() => {
		if (stores.materialDialogStore.isOpen) {
			const {current: descriptionElement} = descriptionElementRef;
			if (descriptionElement !== null) {
				descriptionElement.focus();
			}
		}
	}, [stores.materialDialogStore.isOpen]);

	const handleClick = () => {
		if (page !== undefined) {
			setIsLoading(true)
			stores.xAPIService.sendVideoEndEvent(page)
			stores.xAPIService.sendCompleteCourseItemEvent(page, stores.courseServiceStore.timeStartLearningPage)
			stores.materialDialogStore.setCurrentState(false)
			setIsLoading(false)
			stores.courseServiceStore.goToNextPage()
		}
	}

	const handleClose = () => {
		stores.xAPIService.sendVideoCancelEvent(page)
		stores.materialDialogStore.setCurrentState(false)
	}

	const handlePause = () => {
		playerRef?.current?.pause()
		stores.xAPIService.sendVideoPausedEvent(page)
	}

	const handlePlay = () => {
		playerRef?.current?.play()
		stores.xAPIService.sendVideoResumeEvent(page)
	}

	// TODO Переделать позже. Сейчас отрабатывает просто при проигрывании ролика
	// const handleTimeUpdate = () => {
	// 	// const videoDuration = playerRef?.current?.getDuration()
	// 	// const tenPercentDuration = videoDuration * 0.1
	// 	// const currentTime = playerRef?.current?.getCurrentTime()
	// 	//
	// 	// if (currentTime <= tenPercentDuration) {
	// 	// 	stores.xAPIService.sendVideoRewindToStartEvent(page)
	// 	// } else if (currentTime >= videoDuration - tenPercentDuration && currentTime <= videoDuration) {
	// 	// 	stores.xAPIService.sendVideoRewindToEndEvent(page)
	// 	// } else {
	// 	// 	stores.xAPIService.sendVideoRewindToSomePathEvent(page)
	// 	// }
	// }

	return (
		page.content !== undefined
			? <div style={{...baseStyles.verticalContainer, height: '600px', width: '80vw', maxWidth: '900px'}}>
				<DialogTitle id="scroll-dialog-title" sx={styles.title} children={`${page?.content.title}`}/>
				<DialogContent dividers={scroll === 'paper'} sx={{flex: 1, overflowY: 'hidden'}}>
					<VideoPlayerKinescope
						ref={playerRef}
						videoId={page.content.url ?? ''}
						style={styles.video}
						onEnded={() => setIsVideoEnd(true)}
						onPause={handlePause}
						onPlay={handlePlay}
						// onTimeUpdate={handleTimeUpdate}
					/>
				</DialogContent>
				<DialogActions sx={styles.buttonsContainer}>
					<CBButton
						disabled={false}
						onClick={handleClose}
						children={'Закрыть'}
						size={ComponentSize.SMALL}
						type={ComponentType.PRIMARY}
						style={baseButtonStyles.buttonDiscard}
					/>
					<CBButton
						disabled={!isVideoEnd && !isLoading}
						style={{color: GrayScale.OFF_WHITE_COLOR}}
						loading={isLoading}
						size={ComponentSize.SMALL}
						color={GrayScale.OFF_WHITE_COLOR}
						type={ComponentType.PRIMARY}
						children={'Завершить изучение'}
						onClick={handleClick}
					/>
				</DialogActions>
			</div>
			: <div style={styles.progressContainer}>
				<CircularProgress sx={styles.progress}/>
			</div>
	)
})

const styles = StyleSheet.create({
	title: {
		...defaultFontStyles.displaySmallBold,
		margin: '0 auto'
	},
	video: {
		width: '100%',
		height: '100%',
		borderRadius: '10px',
	},
	progressContainer: {
		display: 'flex',
		...baseStyles.centeredView,
		width: '400px',
		height: '500px'
	},
	progress: {
		alignSelf: 'center',
		color: Colors.PRIMARY_COLOR,
	},
	buttonsContainer: {
		justifyContent: 'center'
	}
})

export default LearningVideo
