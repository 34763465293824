import {AnswerType} from "../../../../types/materials/test/AnswerType";
import {observer} from "mobx-react-lite";
import {StyleSheet} from "../../../../utils/styles";
import React, {useState} from "react";
import {stores} from "../../../../pages/_app";
import {Box, IconButton, Stack} from "@mui/material";
import {Image} from "../../../baseComponents/Image";
import CBInput from "../../../../uikit/baseComponent/input/input";
import {Colors, ComponentSize} from "../../../../uikit/enums";
import {CBCheckbox} from "../../../../uikit/baseComponent/checkbox";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";

interface IProps {
  answer: AnswerType
}

const AnswerItem = observer(({answer}: IProps) => {
  const [agree, setAgree] = useState(answer.isCorrect || false)

  const handleChangeCorrect = (value: boolean) => {
    setAgree(value)
    stores.testEditViewStore.setAnswerData('isCorrect', value, answer.id)
    stores.testEditViewStore.setIsCorrectChange(value)
  }

  return(
    <Stack direction='row' style={{...styles.container, width: stores.adaptiveService.isMinDesktopMini ? '594px' : '100%'}}>
      <Image src={'/icons/dragAndDrop.svg'} width={20} height={20} style={styles.dragAndDropIcon}/>
      <CBInput required={true} size={ComponentSize.SMALL} onChange={(value: string) => stores?.testEditViewStore.setAnswerData('content', value, answer.id)}
               isValid={answer.content.length > 0} label={'Ответ'}
               style={{width: '100%'}} defaultValue={answer.content || ''}/>
      <Box sx={{'& label': {margin: '0'} }}><CBCheckbox defaultChecked={agree} onChange={(value) => handleChangeCorrect(value)}/></Box>
      <IconButton
        style={styles.removeIcon}
        onClick={() => stores?.testEditViewStore.deleteAnswer(answer.id || 0)}
      >
        <RemoveCircleOutlineOutlinedIcon sx={{color: Colors.ERROR}}/>
      </IconButton>
    </Stack>
  )
})

export default AnswerItem

const styles = StyleSheet.create({
  container: {
    marginTop: 10,
    marginBottom: 10,
    backgroundColor: '#FFF',
    alignItems: 'flex-start'
  },
  dragAndDropIcon: {
    marginRight: 10,
    width: 20,
    height: 20,
    padding: '11px 0'
  },
  removeIcon: {
    height: 30,
    width: 30,
    marginLeft: 5,
    padding: '21px'
  }
})