import {makeAutoObservable, runInAction} from "mobx";
import networkService from "../services/NetworkService";
import {initialQuestionStructure, TestStructureType} from "../types/materials/test/TestStructureType";
import {AnswerType} from "../types/materials/test/AnswerType";
import {initialNewQuestion, QuestionType} from "../types/materials/test/QuestionType";
import {initialStructureChange, StructureChangeType} from "../types/materials/test/StructureChangeType";
import {QuestionChangeType} from "../types/materials/test/QuestionChangeType";
import {TestObject} from "../types/materials/test/TestObject";
import {IRootStore} from "./global/RootStore";

export enum PageTestEditName {
  'main' = 'main',
  'article' = 'article',
  'test' = 'test'
}

export enum TypeOfQuestionModal {
  'create' = 'create',
  'change' = 'change'
}

export class TestEditViewStore {
  private rootStore: IRootStore

  constructor(rootStore: IRootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  isTestLoadSaveProcess: boolean = false
  isQuestionCreateProcess: boolean = false
  isStructureChangeProcess: boolean = false
  currentPage: PageTestEditName = PageTestEditName.main
  currentTest: TestObject | undefined = undefined
  currentTestCash: TestObject | undefined = undefined
  selectedQuestionId: number | undefined = undefined
  currentTestStructure: TestStructureType | undefined = undefined
  currentTestStructureCash: TestStructureType | undefined = undefined
  structureChange: StructureChangeType | undefined = initialStructureChange
  questionChange: QuestionChangeType[] | undefined = undefined
  currentQuestionData: QuestionType | undefined = undefined
  currentQuestionDataCache: QuestionType | undefined = undefined
  currentQuestionStructure: AnswerType[] | undefined = initialQuestionStructure
  isCorrectChange: boolean = false
  deleteQuestionId: number | undefined = undefined
  deleteQuestionTitle: string | undefined = undefined
  newQuestion: QuestionType | undefined = initialNewQuestion
  newQuestionStructure: AnswerType[] | undefined = undefined
  isStructureChanged: boolean = false
  isQuestionStructureChanged: boolean = false
  currentDeleteTestTitle: string | undefined = undefined
  currentDeleteTestId: number | undefined = undefined
  currentOpenModal: TypeOfQuestionModal | undefined = undefined
  isAnswerContentNotEmpty: boolean = false
  isAnswerCorrect: boolean = false

  setCurrentOpenModal = (value: TypeOfQuestionModal | undefined) => {
    this.currentOpenModal = value
  }

  setCurrentDeleteTestData = (id: number, title: string) => {
    this.currentDeleteTestId = id
    this.currentDeleteTestTitle = title
  }

  setCurrentPage = (page: PageTestEditName) => {
    this.currentPage = page
  }

  setIsCorrectChange = (value: boolean) => {
    this.isCorrectChange = value
  }

  setCurrentTestStructure = (structure: TestStructureType) => {
    this.currentTestStructure = structure;
  }

  loadCurrentTest = async (testId: number) => {
    try {
      runInAction(() => this.isTestLoadSaveProcess = true)
      const {data} = await networkService.post('/constructor/test/get', {
        testId: testId
      })
      runInAction(() => {
        this.currentTest = {...data,
          title: data.title ? data.title : '',
          description: data.description ? data.description : '',
          duration: data.duration ? data.duration : 0
        }
        this.currentTestCash = {...data,
          title: data.title ? data.title : '',
          description: data.description ? data.description : '',
          duration: data.duration ? data.duration : 0
        }

        this.isTestLoadSaveProcess = false
      })

      return Promise.resolve()
    }
    catch (e) {
      // @ts-ignore
      return Promise.reject(e.response.data)
    }
  }

  changeTestData = (key: 'title' | 'description' , value: string) => {
    if (this.currentTest !== undefined) {
      this.currentTest[key] = value
    }
  }

  setTime = (value: number) => {
    if (this.currentTest !== undefined) {
      this.currentTest.duration = +value
    }
  }

  saveNewStructure = async () => {
    try {
      runInAction(() => this.isTestLoadSaveProcess = true)
      const {data} = await networkService.post(`constructor/test/changeTestStructure`, this.structureChange)
      // console.error(`data.error ${JSON.stringify(data)}`)
      runInAction(() => {
        this.structureChange = initialStructureChange
        this.questionChange = undefined
      })
      await this.getTestStructure(this.currentTest?.id)
      return Promise.resolve()
    }
    catch (e) {
      console.error(`structureChange ${JSON.stringify(this.structureChange)}`)
      console.error(`e.response.data ${e.response.data}`)
      //@ts-ignore
      return Promise.reject(e.response.data)
    }
  }

  saveTest = async () => {
    try {
      runInAction(() => this.isTestLoadSaveProcess = true)
      const {data} = await networkService.post('/constructor/test/change', {
        testId: this.currentTest?.id,
        title: this.currentTest?.title,
        description: this.currentTest?.description,
        duration: this.currentTest?.duration
      })
      runInAction(() => {
        this.loadCurrentTest(this.currentTest?.id || 0)
        this.isTestLoadSaveProcess = false
        this.isStructureChanged = false
      })
      return Promise.resolve()
    }
    catch (e) {
      // @ts-ignore
      return Promise.reject(e.response.data)
    }
  }

  get isTestChanged() {
    return this.validateTestTitle && this.validateTestDescription && this.validateTestDuration
      && (this.checkTestDataChanged || this.checkTestStructureDataChanged)
  }

  getTestStructure = async (testId: number, isLoading: boolean | undefined = true) => {
    try {
      runInAction(() => this.isTestLoadSaveProcess = isLoading)
      const {data} = await networkService.post('/constructor/test/getTestStructure', {
        testId: testId
      })
      runInAction(() => {
        const orderedTestStructure: Awaited<TestStructureType> = {
          idTest: data.testId || 0,
          testStructure: data.testStructure.sort((a, b) => a.orderIndex - b.orderIndex).map((structure, index) => ({...structure, orderIndex: index + 1})) || []
        }
        this.setCurrentStructure(orderedTestStructure)
        this.isStructureChanged = false
        console.error(`constructor/test/getTestStructure ${JSON.stringify(data)}`)
      })
      return Promise.resolve()
    }
    catch (e) {
      // @ts-ignore
      return Promise.reject(e.response.data)
    }
    finally {
      this.isTestLoadSaveProcess = false
    }
  }

  changeStructure = (newStructure: QuestionType[], value: boolean) => {
    this.isStructureChangeProcess = true;
    this.questionChange = undefined;
    const orderedStructure = newStructure.map((item, index) => {
      this.questionChange = [...this.questionChange || [], {
        questionId: item.questionId,
        orderIndex: index + 1
      }]

      return {
        ...item,
        orderIndex: index + 1
      };
    })

    this.setCurrentTestStructure({
      ...this.currentTestStructure,
      testStructure: orderedStructure
    })


    this.structureChange = {
      testId: this.currentTestStructure?.idTest || 0,
      orderIndex: 1,
      testStructure: this.questionChange || []
    }

    this.isStructureChangeProcess = false;
  }

  get validateQuestionTitle(){
    return (this.currentQuestionData?.title.length || 0) > 5
  }

  get validateQuestionDescription(){
    return  (this.currentQuestionData?.description?.length || 0) <= 500
  }

  get checkTestDataChanged() {
    return JSON.stringify(this.currentTestCash) !== JSON.stringify(this.currentTest)
  }

  get checkTestStructureDataChanged() {
    return JSON.stringify(this.currentTestStructureCash) !== JSON.stringify(this.currentTestStructure)
  }

  get validateQuestionChangeData(){
    return (this.currentQuestionData?.title || '') !== (this.currentQuestionDataCache?.title || '')
      || (this.currentQuestionData?.description || '') !== (this.currentQuestionDataCache?.description || '')
  }

  get validateQuestionChangeAnswer(){
    return JSON.stringify(this.currentQuestionStructure) !== JSON.stringify(this.currentQuestionDataCache?.answers)
  }

  get validateQuestionChangeAnswerLength(){
    return (this.currentQuestionData?.answers?.length || 0) !== (this.currentQuestionDataCache?.answers?.length || 0)
  }

  get validateQuestionAnswerCount(){
    return (this.currentQuestionData?.answers?.length || 0) >= 2
  }

  get validateTestTitle() {
    return (this.currentTest?.title.length || 0) <= 20 && (this.currentTest?.title.length || 0) >= 5;
  }

  get validateTestDescription() {
    return (this.currentTest?.description.length || 0) <= 500
  }

  get validateQuestionChange(){
    return (this.validateQuestionTitle && this.validateQuestionDescription && this.validateQuestionAnswerCount && this.validateCorrectAnswers && this.validateAnswersLength)
      && (this.validateQuestionChangeData || this.validateQuestionChangeAnswer || this.validateQuestionChangeAnswerLength)
  }

  get validateAnswersLength() {
    return this.currentQuestionStructure.every(question => (question.content?.length || 0) > 0);
  }

  get validateCorrectAnswers() {
    return this.currentQuestionStructure.find(answer => answer.isCorrect === true);
  }

  get validateAnswerTitle(){
    let checkAnswer: AnswerType[] | undefined = this.currentQuestionStructure?.filter(answer => answer.content.length > 0)
    return checkAnswer?.length === this.currentQuestionStructure?.length
  }

  get validateAnswerCorrect(){
    let checkCorrectAnswer: AnswerType[] | undefined = this.currentQuestionStructure?.filter(answer => answer.isCorrect)
    return (checkCorrectAnswer?.length || 0) > 0
  }

  get validateAnswerCount(){
    return (this.currentQuestionStructure?.length || 0) > 1
  }

  get validateCreateQuestionTitle(){
    return (this.newQuestion?.title.length || 0) > 5
  }

  get validateCreateQuestionDescription(){
    return (this.newQuestion?.description.length || 0) <= 500
  }

  get validateButton(){
    return this.validateQuestionTitle && this.validateQuestionDescription
  }

  get validateTestDuration() {
    return (this.currentTest?.duration || 0) > 0
  }

  get validateCreateButton(){
    return this.validateCreateQuestionTitle && this.validateCreateQuestionDescription
      && this.validateAnswerTitle && this.validateAnswerCorrect
      && this.validateAnswerCount
  }

  sortStructure = (structure: QuestionType[] | AnswerType[]) => {
    structure.sort((a, b) => (a.orderIndex > b.orderIndex) ? 1 : -1)
  }

  setCurrentStructure = (value: TestStructureType) => {
    this.currentTestStructure = value
    this.sortStructure(this.currentTestStructure?.testStructure)
    this.currentTestStructureCash = this.currentTestStructure
  }

  setNewQuestionData = (key: 'title' | 'description', value: string) => {
    // @ts-ignore
    this.newQuestion[key] = value
  }

  changeQuestionData = (key: 'title' | 'description' | 'answers', value: string | AnswerType[]) => {
    if (this.currentQuestionData === undefined)
      return

    // @ts-ignore
    this.currentQuestionData[key] = value
  }

  setAnswerData = (key: 'content' | 'isCorrect', value: string | boolean, index) => {
    this.currentQuestionStructure?.map((item) => {
      if (item.id === index) {
        // @ts-ignore
        item[key] = value
      }
    })
  }

  changeQuestionStructure = (type: 'create' | 'change' ,newStructure: AnswerType[]) => {
    this.isQuestionCreateProcess = true
    this.isQuestionStructureChanged = true
    if (type === 'create') {
      this.newQuestionStructure = []
      newStructure.forEach((item, index) => {
        this.newQuestionStructure = [...this.newQuestionStructure || [], {
          id: null,
          questionId: null,
          content: item.content,
          isCorrect: item.isCorrect,
          orderIndex: index + 1
        }]
      })
    } else {
      this.newQuestionStructure = []
      newStructure.forEach((item, index) => {
        this.newQuestionStructure = [...this.newQuestionStructure || [], {
          id: item.id,
          questionId: item.questionId,
          content: item.content,
          isCorrect: item.isCorrect,
          orderIndex: index + 1
        }]
      })
    }
    this.currentQuestionStructure = this.newQuestionStructure;
    this.isQuestionCreateProcess = false
  }

  setQuestionStructure = (type: 'create' | 'change', value: AnswerType | number ) => {
    console.error(`setQuestionStructure`)
    if (type === 'create' && value !== undefined && typeof value === "number") {
      console.error(`in first if`)
      const newOrderIndex = (this.currentQuestionStructure?.length || 0) + 1
      this.currentQuestionStructure = [...this.currentQuestionStructure || [], {
        id: value,
        questionId: null,
        orderIndex: newOrderIndex,
        content: '',
        isCorrect: false
      }]
      this.changeQuestionData('answers', this.currentQuestionStructure)
    } else if (value !== undefined && typeof value === 'number') {
      console.error(`in else if`)
      this.getQuestionData(value)
    }
  }

  setSelectedQuestionId = (id: number) => {
    this.selectedQuestionId = id
    this.getQuestionData(id).then(
      onSuccess => {

      },
      onError => {

      }
    )
  }

  getQuestionData = async (questionId: number) => {
    try {
      runInAction(() => {
        this.isQuestionCreateProcess = true
      })
      const {data} = await networkService.post('constructor/test/getQuestion',{
        questionId: questionId
      })
      console.error(`constructor/test/getQuestion ${JSON.stringify(data)}`)
      runInAction(() => {
        this.currentQuestionData = data
        this.currentQuestionDataCache = data
        this.currentQuestionStructure = [...this.currentQuestionData?.answers || []]
        this.sortStructure(this.currentQuestionStructure)
        this.isQuestionCreateProcess = false
      })
      return Promise.resolve()
    }
    catch (e) {
      // @ts-ignore
      return Promise.reject(e.response.data)
    }
  }

  createQuestions = async () => {
    try {
      console.warn(`start createQuestions`)
      runInAction(() => {
        if (this.newQuestionStructure !== undefined) {
          this.newQuestionStructure.map((item) => {
            item.id = null
          })
        }else {
          this.currentQuestionStructure?.map((item) => {
            item.id = null
          })
        }
        this.isQuestionCreateProcess = true
      })
      const {data} = await  networkService.post('constructor/test/createQuestion', {
        title: this.newQuestion?.title,
        description: this.newQuestion?.description,
        testId: this.currentTest?.id,
        answers: this.newQuestionStructure !== undefined ? this.newQuestionStructure : this.currentQuestionStructure
      })
      runInAction(() => {
        this.currentQuestionStructure = initialQuestionStructure
        this.isQuestionCreateProcess = false
      })
      console.warn(`end createQuestions ${JSON.stringify(data)}`)
      return Promise.resolve()
    }
    catch (e) {
      // @ts-ignore
      return Promise.reject(e.response.data)
    }
  }

  changeQuestion = async () => {
    try {
      runInAction(() => this.isQuestionCreateProcess)
      const {data} = await networkService.post('constructor/test/changeQuestion', {
        questionId: this.currentQuestionData?.questionId,
        title: this.currentQuestionData?.title,
        description: this.currentQuestionData?.description,
        testId: this.currentQuestionData?.idTest,
        answers: this.currentQuestionStructure || []
      })
      console.error(`constructor/test/changeQuestion ${JSON.parse(JSON.stringify(data))}`)
      runInAction(() => {
        this.currentQuestionStructure = initialQuestionStructure
        this.currentQuestionData = undefined
        this.isQuestionCreateProcess = false
      })
      return Promise.resolve()
    }
    catch (e) {
      //@ts-ignore
      return Promise.reject(e.response.data)
    }
  }

  setDeleteQuestion = (id: number, title: string) => {
    this.deleteQuestionId = id
    this.deleteQuestionTitle = title
  }

  deleteAnswer = (id: number) => {
    this.currentQuestionStructure = this.currentQuestionStructure?.filter(item => item.id !== id)
    this.changeQuestionData('answers', this.currentQuestionStructure || [])
  }

  deleteQuestion = async (id: number) => {
    try {
      runInAction(() => {
        this.isQuestionCreateProcess = true
      })
      const {data} = await networkService.post('constructor/test/deleteQuestion',{
        questionId: id
      })
      runInAction(() => {
        this.setDeleteQuestion(0,'')
        this.isQuestionCreateProcess = false
      })
      return Promise.resolve()
    }
    catch (e) {
      // @ts-ignore
      return Promise.reject(e.response.data)
    }
  }

  deleteTest = async (id: number) => {
    try {
      runInAction(() => this.isTestLoadSaveProcess = true)
      const {data} = await networkService.post('constructor/test/delete', {
        testId: id
      })
      runInAction(() => {
        this.isTestLoadSaveProcess = false
      })
      return Promise.resolve()
    }
    catch (e) {
      // @ts-ignore
      return Promise.reject(e.response.data)
    }
  }

  clearQuestionData = () => {
    this.currentQuestionStructure = initialQuestionStructure
    this.newQuestion = initialNewQuestion
    this.setIsCorrectChange(false)
    this.setCurrentOpenModal(undefined)
  }

}
export interface ITestEditViewStore extends TestEditViewStore {}
