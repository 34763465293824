
/*
Хелпер для бизнес логики стейтменов по статьям в курсе
Здесь будут функции по формированию стейтментов и их частей для отправки базовых событий по статьям в курсе (запуск, открытие и т.д.)
*/

import XAPI, {Actor, Context, ContextActivity, Statement, StatementObject} from "@xapi/xapi";
import {CourseLiveCycleEvents, XAPICourseLiveCycleObjects} from "../XAPIVerbsObjects";
import {BaseXAPIHelper} from "./BaseXAPIHelper";
import {UserCourseDetails} from "../../../types/materials/course/UserCourseDetails";
import moment from "moment/moment";
import {PageDetails} from "../../../types/materials/course/PageDetails";

export class BaseCourseArticleHelper {

  static async sendStatement(statement: Statement, xAPIInstance: XAPI) {
    try {
      const data = await xAPIInstance?.sendStatement({
        statement: statement
      })
      console.warn(`sendArticleStatement ${JSON.stringify(statement)}`)
      return Promise.resolve()
    } catch (e) {
      console.log(JSON.stringify(e))
      return Promise.reject(e)
    }
  }

  static getArticleObject(page: PageDetails): StatementObject {
    return {
      id: `https://coursebit.ru/course/pages/article/${page.id}`,
      definition: {
        type: `http://adlnet.gov/expapi/activities/course/pages/article`,
        name: {"ru-RU": `${page.content.title ?? ''}`},
        description: {"ru-RU": `${page.content.description ?? ''}`},
      },
      objectType: 'Activity'
    }
  }

  static getCurrentContext(currentUserCourse: UserCourseDetails, currentContextParent: ContextActivity, sessionId: string): Context {
    return {
      registration: currentUserCourse?.idUserCourse,
      contextActivities: {
        parent: [currentContextParent]
      },
      extensions: {
        ...BaseXAPIHelper.getDeviceExtensions(),
        'https://w3id.org/xapi/cmi5/context/extensions/sessionid': sessionId
      }
    }
  }

  static async sendArticleStartLearningEvent(
    currentUser?: Actor,
    currentUserCourse?: UserCourseDetails,
    page?: PageDetails,
    xAPIInstance?: XAPI,
    sessionId?: string,
    currentContextParent?: ContextActivity
  ) {
    const statement: Statement = {
      actor: currentUser,
      verb: XAPICourseLiveCycleObjects[CourseLiveCycleEvents.ARTICLE_START_LEARNING],
      object: this.getArticleObject(page),
      context: this.getCurrentContext(currentUserCourse, currentContextParent, sessionId),
      timestamp: new Date().toISOString()
    }
    await this.sendStatement(statement, xAPIInstance)
  }

  static async sendArticleCompleteLearningEvent(
    currentUser?: Actor,
    currentUserCourse?: UserCourseDetails,
    page?: PageDetails,
    xAPIInstance?: XAPI,
    sessionId?: string,
    currentContextParent?: ContextActivity,
    timeStartLearningMaterialInMilliseconds?: number
  ) {
    const statement: Statement = {
      actor: currentUser,
      verb: XAPICourseLiveCycleObjects[CourseLiveCycleEvents.ARTICLE_END_LEARNING],
      object: this.getArticleObject(page),
      result: {
        duration: `${moment.duration(Date.now() - timeStartLearningMaterialInMilliseconds, "ms").toISOString()}`,
      },
      context: this.getCurrentContext(currentUserCourse, currentContextParent, sessionId),
      timestamp: new Date().toISOString()
    }
    await this.sendStatement(statement, xAPIInstance)
  }

  static async sendArticleCancelLearning(
    currentUser: Actor,
    currentUserCourse: UserCourseDetails,
    page: PageDetails,
    xAPIInstance: XAPI,
    sessionId: string,
    currentContextParent: ContextActivity,
    timeStartLearningMaterialInMilliseconds: number
  ) {
    const statement: Statement = {
      actor: currentUser,
      verb: XAPICourseLiveCycleObjects[CourseLiveCycleEvents.ARTICLE_CANCEL_LEARNING],
      object: this.getArticleObject(page),
      result: {
        duration: `${moment.duration(Date.now() - timeStartLearningMaterialInMilliseconds, "ms").toISOString()}`,
      },
      context: this.getCurrentContext(currentUserCourse, currentContextParent, sessionId),
      timestamp: new Date().toISOString()
    }
    await this.sendStatement(statement, xAPIInstance)
  }

  static async sendArticleOpenForRelearning(
    currentUser?: Actor,
    currentUserCourse?: UserCourseDetails,
    page?: PageDetails,
    xAPIInstance?: XAPI,
    sessionId?: string,
    currentContextParent?: ContextActivity
  ) {
    const statement: Statement = {
      actor: currentUser,
      verb: XAPICourseLiveCycleObjects[CourseLiveCycleEvents.ARTICLE_OPEN_FOR_RELEARNING],
      object: this.getArticleObject(page),
      context: this.getCurrentContext(currentUserCourse, currentContextParent, sessionId),
      timestamp: new Date().toISOString()
    }
    await this.sendStatement(statement, xAPIInstance)
  }

  static async sendArticleCloseOfRelearning(
    currentUser: Actor,
    currentUserCourse: UserCourseDetails,
    page: PageDetails,
    xAPIInstance: XAPI,
    sessionId: string,
    currentContextParent: ContextActivity,
    timeStartLearningMaterialInMilliseconds: number
  ) {
    const statement: Statement = {
      actor: currentUser,
      verb: XAPICourseLiveCycleObjects[CourseLiveCycleEvents.ARTICLE_CLOSE_OF_RELEARNING],
      object: this.getArticleObject(page),
      result: {
        duration: `${moment.duration(Date.now() - timeStartLearningMaterialInMilliseconds, "ms").toISOString()}`,
      },
      context: this.getCurrentContext(currentUserCourse, currentContextParent, sessionId),
      timestamp: new Date().toISOString()
    }
    await this.sendStatement(statement, xAPIInstance)
  }
}
