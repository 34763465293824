import {IRootStore} from "./global/RootStore";
import {makeAutoObservable, runInAction} from "mobx";
import {BaseAnalyticsResponseType} from "../types/analyrics/BaseAnalyticsResponseType";
import networkService from "../services/NetworkService";
import {AnalyticModalType} from "../uikit/enums";
import {QuestionType} from "../types/materials/test/QuestionType";
import {MaterialsType} from "../components/MaterialDialog/learnCourse/LearningMaterial/LearnMaterialDialog";

export type MostHardestQuestionsType = {
  questionId: string,
  value: number,
  title: string
}
export type MaterialsIDForRelearningType = {
  materialId: string,
  title: string
}

export type ChaptersType = {
  id: number,
  title: string
}

export type UserArticleStatType = {
  chapter_id: string,
  material_id: string,
  material_type: string
  title: string,
  passage_time: number,
  view_count_before_learning_complete: number,
  count_canceled: number,
  view_count_after_learning_complete: number,
  summ_time_to_view_after_learning_complete: number
}
export type UserTestStatType = {
  chapter_id: string,
  material_type: string
  material_id: string,
  title: string,
  passage_time: number,
  view_count_before_learning_complete: number,
  count_canceled: number,
  view_count_after_learning_complete: number,
  summ_time_to_view_after_learning_complete: number,
  is_return_to_other_materials_on_test_learning_process: boolean,
  is_return_to_other_materials_after_test_reject: boolean,
  material_ids_for_relearning_opened: MaterialsIDForRelearningType[]
  reject_count: number,
  most_difficult_question_IDs: MostHardestQuestionsType[]
  complete_result: number,
  min_result: number,
  average_result: number
}

export type UserVideoStatType = {
  chapter_id: string,
  material_id: string,
  material_type: string
  title: string,
  passage_time: number,
  view_count_before_learning_complete: number,
  count_canceled: number,
  pause_count: number,
  resume_count: number,
  view_count_after_learning_complete: number,
  summ_time_to_view_after_learning_complete: number
}

export type AnalyticsAnswerType = {
  questionId: number,
  questionTitle: string,
  answerId: number,
  answerTitle: string
}

export type AnalyticsAnswerInTestType = {
  attemptNumber: number,
  answers: AnalyticsAnswerType[],
  allQuestions: QuestionType[]
  result: string,
  date: string
}

export type AnalyticsTestStat = {
  testTitle: string,
  answerAttempt: AnalyticsAnswerInTestType[]
}

export type ResponseProblemsMaterial = {
  materialsType: MaterialsType,
  materialId: string,
  materialTitle: string
}

export type ProblemsMaterialInCourseType = {
  userCopyCourse: string,
  courseTitle: string,
  userId: number
  problemMaterials: ResponseProblemsMaterial[]
}

export class AnalyticsStore {
  private rootStore: IRootStore

  constructor(rootStore: IRootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  baseAnalyticsData?: BaseAnalyticsResponseType = undefined
  lastUpdateTime?: number
  isDataLoaded: boolean = true
  isOpenModal: boolean = false
  analyticDataByUser: {
    courseStat: {
      user_copy_course_id: string,
      student_id: number,
      age: number,
      title: string,
      chapters: ChaptersType[],
      device_type: string,
      browser: string,
      course_completion_time: number
    },
    materialsStat: (UserArticleStatType | UserTestStatType | UserVideoStatType)[]
  } | undefined = undefined
  analyticsAnswerData: AnalyticsTestStat[] | undefined = undefined
  analyticsProblemsMaterials: ProblemsMaterialInCourseType | undefined = undefined
  currentAttempt: AnalyticsAnswerInTestType | undefined = undefined
  modalType: AnalyticModalType = AnalyticModalType.ALL_ANALYTICS
  setIsOpenModal = (value: boolean) => {
    this.isOpenModal = value
  }

  setCurrentAttempt = (value: AnalyticsAnswerInTestType) => {
    this.currentAttempt = value
  }

  setModalType = (value: AnalyticModalType) => {
    this.modalType = value
  }

  clearAnalyticData = () => {
    this.analyticsAnswerData = undefined
    this.analyticDataByUser = undefined
    this.analyticsProblemsMaterials = undefined
  }

  getAnalyticByUserCopyCourse = async (userCopyCourse: string) => {
    try {
      // const {data} = await networkService.post(`analitycs/getReportByCourseLearning`, {
      //   userCopyCourse: `${userCopyCourse}`
      // })
      runInAction(() => {
        this.analyticDataByUser = undefined
      })
    } catch (e) {
      console.log(e)
    }
  }

  getUserAnswersByCourseCopyId = async (userCopyCourse: string) => {
    try {
      // const {data} = await networkService.post(`analitycs/getUserAnswerByCourseCopyId`, {
      //   userCopyCourse: `${userCopyCourse}`
      // })
      runInAction(() => {
        this.analyticsAnswerData = undefined
      })
    } catch (e) {
      console.log(e)
    }
  }

  getProblemsMaterialDataByCopyCourse = async (userCopyCourse: string) => {
    try {
      // const {data} = await networkService.post(`analitycs/getProblemsMaterialDataByCopyCourse`, {
      //   userCopyCourse: `${userCopyCourse}`
      // })
      // console.error(`data ${JSON.stringify(data.body)}`)
      runInAction(() => {
        this.analyticsProblemsMaterials = undefined
      })
    } catch (e) {
      console.log(e)
    }
  }

  async getBaseAnalyticsData() {
    // if (this.lastUpdateTime && Date.now() - this.lastUpdateTime < 1000 * 5 * 60)
    //   return

    this.isDataLoaded = true
    try {
      // const {data} = await networkService.post('/analitycs/get')
      // this.lastUpdateTime = Date.now()
      this.baseAnalyticsData = undefined
    } catch (e) {
      console.log(e)
    }
    this.isDataLoaded = false
  }

}

export interface IAnalyticsStore extends AnalyticsStore {}
