import React, {CSSProperties, useEffect, useState} from 'react'
import {Checkbox, FormControlLabel, FormGroup} from "@mui/material";
import {Colors, GrayScale} from "../../enums";
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import CheckIcon from '@mui/icons-material/Check';

interface IProps {
  onChange: (value: boolean) => void,
  defaultChecked?: boolean,
  label?: JSX.Element | string,
  disabled?: boolean,
  style?: CSSProperties,
  iconStyle?: CSSProperties,
}

export const CBCheckbox = ({onChange, defaultChecked = false, label, disabled = false, style = {}, iconStyle = {}}: IProps) => {
  const [isCheck, setIsCheck] = useState(defaultChecked)

  useEffect(() => {
    onChange(isCheck)
  }, [isCheck])

  const renderCheckBox = () => (
    <Checkbox
      size={"medium"}
      defaultChecked={defaultChecked}
      icon={<CircleOutlinedIcon sx={style}/>}
      checkedIcon={
        <div style={{borderRadius: '50px', backgroundColor: Colors.PRIMARY_COLOR, width: '24px', height: '24px', display: 'flex', alignItems: 'center', alignContent: 'center', justifyContent: 'center', ...style,}}>
          <CheckIcon sx={{color: GrayScale.BACKGROUND_COLOR, width: '12px', height: '12px', ...iconStyle,}}/>
        </div>
      }
      onChange={event => setIsCheck(event.target.checked)}
      sx={{
        '&.MuiCheckbox-root': {
          color: GrayScale.LINE_COLOR,
          borderRadius: '50px',
          '&:hover': {
            color: GrayScale.PLACEHOLDER_COLOR
          },
          '&.Mui-disabled': {
            opacity: 0.5
          },
          '&.Mui-checked': {
            color: Colors.PRIMARY_COLOR,
            '&:hover': {
              color: Colors.PRIMARY_DARK
            },
            '&.Mui-disabled': {
              opacity: 0.5
            }
          }
        }
      }}
    />
  )

  return (
    <FormGroup>
      <FormControlLabel
        disabled={disabled}
        control={renderCheckBox()}
        label={label}
      />
    </FormGroup>
  )
}

