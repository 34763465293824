import {styled, Tooltip} from "@mui/material";
import {tooltipClasses, TooltipProps} from "@mui/material/Tooltip";
import React from "react";
import {Colors, GrayScale} from "../../enums";
import {defaultFontStyles} from "../../fontStyle";

interface IProps {
  children: JSX.Element,
  title: string,
}

export const CBTooltip = ({children, title}: IProps) => {

  const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: GrayScale.BODY_COLOR,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: GrayScale.BODY_COLOR,
      ...defaultFontStyles.textSmall,
      color: GrayScale.OFF_WHITE_COLOR,
      padding: '16px 24px'
    },
  }));

  return (
    <CustomTooltip
      title={title}
      children={children}
    />
  )
}
