import {makeAutoObservable, runInAction} from "mobx";
import networkService from "../services/NetworkService";
import {IRootStore} from "./global/RootStore";
import * as tus from "tus-js-client"
import {UploadLink} from "../types/materials/video/UploadLink";
import {UploadStateProcess} from "../types/materials/video/UploadStateProcess";

export class VideoServiceStore {
  private rootStore: IRootStore

  constructor(rootStore: IRootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  selectedFile: File | undefined = undefined
  uploadLinkData: UploadLink | undefined = undefined
  uploadFileStateProcess: UploadStateProcess | undefined

  isVideoUploading: boolean = false
  isVideoDeleting: boolean = false

  selectFile = (file: File | undefined) => {
    this.selectedFile = file
  }

  private getUploadFileLink = async () => {
    try {
      const {data} = await networkService.post('constructor/video/getUploadingLink', {
        fileSize: this.selectedFile?.size || 0,
        title: this.rootStore.videoEditViewStore.currentVideo?.title
      })
      runInAction(() => this.uploadLinkData = data)
    } catch (e) {
    }
  }

  uploadFile = async () => {
    if (!this.selectedFile)
      return

    let vid = document.createElement('video');
    vid.src = URL.createObjectURL(this.selectedFile)
    vid.preload = 'metadata';

    vid.onloadedmetadata = async () => {
      if (vid.duration < 0.001)
        return

      if (vid.duration > ((this.rootStore.userStore.userStorageData?.userSubscribe?.videoStorageSize ?? 0) * 3600) - (this.rootStore.userStore.userStorageData?.userStorage?.videoStorageSize ?? 0)) {
        this.rootStore.materialDialogStore.setMessageViewState(true, "Длинна загружаемого видео превышает доступное количество на данном аккаунте", 4000)
        return
      }

      try {
        if (!this.selectedFile || !this.rootStore.videoEditViewStore.currentVideo?.title)
          return

        runInAction(() => this.isVideoUploading = true)

        await this.getUploadFileLink()
        let upload = new tus.Upload(this.selectedFile, {
          uploadUrl: this.uploadLinkData?.endpoint,
          retryDelays: [0, 3000, 5000, 10000, 20000],
          onError: function(error) {
          },
          uploadSize: this.selectedFile.size,
          onProgress: (bytesUploaded, bytesTotal) => {
            runInAction(() => {
              this.uploadFileStateProcess = {
                bytesUploaded: bytesUploaded,
                bytesTotal: bytesTotal
              }
            })
            let percentage = (bytesUploaded / bytesTotal * 100).toFixed(2)
          },
          onSuccess: async () => {
            await this.acceptVideoUploading(vid.duration)
          }
        })

        await upload.start()
      } catch (e) {
      }
    }
  }

  acceptVideoUploading = async (videoDuration: number) => {
    try {
      const {data} = await networkService.post('constructor/video/acceptVideoUploading', {
        videoId: this.uploadLinkData?.id,
        videoPageId: this.rootStore.videoEditViewStore.currentVideo?.id,
        videoDuration: videoDuration
      })
      await this.rootStore.videoEditViewStore.loadCurrentVideo(this.rootStore.videoEditViewStore.currentVideo?.id)
      await this.rootStore.userStore.loadUserStorageData(false)
      runInAction(() => {
        this.resetData()
      })
      runInAction(() => this.isVideoUploading = false)
    } catch (e) {
    }
  }

  deleteVideoFile = async () => {
    try {
      runInAction(() => this.isVideoDeleting = true)
      const {data} = await networkService.post('constructor/video/deleteVideoFile', {
        videoId: this.rootStore.videoEditViewStore.currentVideo?.url,
        videoPageId: this.rootStore.videoEditViewStore.currentVideo?.id
      })
      await this.rootStore.videoEditViewStore.loadCurrentVideo(this.rootStore.videoEditViewStore.currentVideo?.id)
      await this.rootStore.userStore.loadUserStorageData(false)
      runInAction(() => this.isVideoDeleting = false)
      return Promise.resolve()
    } catch (e) {
      return Promise.reject(e)
    }
  }

  get uploadProgress() {
    if (!this.uploadFileStateProcess)
      return 0

    return +(this.uploadFileStateProcess?.bytesUploaded / this.uploadFileStateProcess?.bytesTotal * 100).toFixed(2)
  }

  get videoDurationToString() {
    if (!this.rootStore.videoEditViewStore.currentVideo?.duration || this.rootStore.videoEditViewStore.currentVideo?.duration === 0)
      return '0 секунд'

    const hours = Math.floor((this.rootStore.videoEditViewStore.currentVideo?.duration || 0) / 3600);
    const minutes = Math.floor(((this.rootStore.videoEditViewStore.currentVideo?.duration || 0) - (hours * 3600)) / 60)
    const seconds = (this.rootStore.videoEditViewStore.currentVideo?.duration || 0) - (hours * 3600) - (minutes * 60);
    return `${hours} ч ${minutes} мин ${seconds} сек`
  }

  resetData = () => {
    this.selectedFile = undefined
    this.uploadLinkData = undefined
    this.uploadFileStateProcess = undefined
  }
}

export interface IVideoServiceStore extends VideoServiceStore {}
