import {AnswerType} from "./AnswerType";

export type QuestionType = {
  questionId: number,
  title: string,
  description: string,
  idTest: number,
  orderIndex: number,
  answers: AnswerType[] | null
}

export const initialNewQuestion: QuestionType = {
  questionId: 0,
  title: '',
  description: '',
  idTest: 0,
  orderIndex: 0,
  answers: [
    {
      id: null,
      questionId: null,
      content: '',
      isCorrect: false,
      orderIndex: 0
    }
  ]
}