import {StyleSheet} from "./styles";
import {Colors, GrayScale} from "../uikit/enums";

export const baseButtonStyles = StyleSheet.create({
	buttonDiscard: {
		background: Colors.ERROR,
		'&:hover': {
			background: Colors.ERROR_DARK
		},
		'&:disabled': {
			background: Colors.ERROR,
			opacity: 0.5
		}
	}
})