import {observer} from "mobx-react-lite";
import {StyleSheet} from "../../../utils/styles";
import {stores} from "../../../pages/_app";
import {Box, DialogActions, DialogContent, MenuItem, Skeleton, Stack} from "@mui/material";
import {DialogType} from "../MaterialDialogStore";
import React, {useEffect, useMemo, useState} from "react";
import {useUnload} from "../../../utils/useUnload";
import CBInput from "../../../uikit/baseComponent/input/input";
import {CBDropdown} from "../../../uikit/baseComponent/dropdown";
import {Colors, ComponentSize, InputsType} from "../../../uikit/enums";
import CBTextarea from "../../../uikit/baseComponent/textarea";
import CBButton from "../../../uikit/baseComponent/buttons/mainButton";
import CountryJSON from '../../../utils/documentsData/countries.json';
import CitiesJSON from '../../../utils/documentsData/cities.json'

interface ICountry {
  id: number,
  native: string
}

interface ICity {
  id: number,
  name: string,
  country_id: number
}

const MainUserSetting = observer(() => {
  const [countryId, setCountryId] = useState<number>(null);
  const countries: ICountry[] = useMemo(() => CountryJSON, []);
  const cities: ICity[] = useMemo(() => CitiesJSON as Array<ICity>, []);

  useEffect(() => {
    const countryIdFound: number = CountryJSON.find(country => country.native === stores.userStore.changeMainInfoBuffer.country).id
    setCountryId(countryIdFound);
  }, [stores.userStore.changeMainInfoBuffer.country])

  useEffect(() => {
    if (stores.materialDialogStore.isOpen && stores.materialDialogStore.currentDialogType === DialogType.userSettings)
      stores.userStore.loadUserProfile()
  }, [stores.materialDialogStore.isOpen])

  useUnload(stores.userStore.isMainDataChange)

  const handleClose = () => {
    if (stores.userStore.isMainDataChange) {
      if (!window.confirm("Возможно, внесенные изменения не сохранятся.")) return
      stores.materialDialogStore.setCurrentState(false);
      stores.userStore.cancelMainInfoBufferChanges();
      stores.userStore.loadUserProfile()
    } else {
      stores.materialDialogStore.setCurrentState(false);
    }
  }

  // @ts-ignore
  const getLocalTime : string[] = Intl.supportedValuesOf('timeZone');

  const translateSexRus = (value: string): string => {
    switch (value) {
      case 'male':
        return 'Мужской';
      case 'female':
        return 'Женский';
      default:
        return 'Пол';
    }
  }

  const renderUserData = () => (
    <Stack columnGap='50px' direction={!stores.adaptiveService.isMinDesktopMini ? 'column' : 'row'} spacing={2} width='100%'>
      <Stack spacing={2} flex={1}>
        <CBInput required={true} size={ComponentSize.LARGE} defaultValue={stores.userStore.changeMainInfoBuffer.firstName || ''}
                 onChange={(value) => stores.userStore.changeMainInfo('firstName', value)} label='Имя'
                 isValid={!stores.userStore.isFirstNameValidInBuffer ? false : 'disabled'} maxLength={50}/>
        <CBInput required={true} size={ComponentSize.LARGE} defaultValue={stores.userStore.changeMainInfoBuffer.secondName || ''}
                 onChange={(value) => stores.userStore.changeMainInfo('secondName', value)} label='Фамилия'
                 isValid={stores.userStore.isSecondNameValidInBuffer ? 'disabled' : false} maxLength={50}/>
        <CBInput size={ComponentSize.LARGE} defaultValue={stores.userStore.changeMainInfoBuffer.thirdName || ''}
                 onChange={(value) => stores.userStore.changeMainInfo('thirdName', value)} label='Отчество'
                 isValid={stores.userStore.isThirdNameValidInBuffer ? 'disabled' : false} maxLength={50}/>
        <CBInput required={true} size={ComponentSize.LARGE} type={InputsType.DATE}
                 defaultValue={stores.userStore.changeMainInfoBuffer.birthDate || ''}
                 onChange={(value) => stores.userStore.changeMainInfo('birthDate', value)} label='Дата рождения' isValid={stores.userStore.isBirthDateValidInBuffer ? 'disabled' : false}/>
        <CBDropdown size={ComponentSize.LARGE} onChange={(value) => stores.userStore.changeMainInfo('sex', value)}
                    value={translateSexRus(stores.userStore.changeMainInfoBuffer.sex)} placeholder='Пол' style={{width: '100%'}}>
          <MenuItem value='male' disabled={stores.userStore.changeMainInfoBuffer.sex === 'male'}>Мужской</MenuItem>
          <MenuItem value='female' disabled={stores.userStore.changeMainInfoBuffer.sex === 'female'}>Женский</MenuItem>
        </CBDropdown>
        <CBInput required={true} size={ComponentSize.LARGE}
                 defaultValue={stores.userStore.changeMainInfoBuffer.email || ''}
                 onChange={(value) => stores.userStore.changeMainInfo('email', value)}
                 label='Почта' isValid={stores.userStore.isEmailValidInBuffer ? 'disabled' : false}/>
      </Stack>

      <Stack spacing={2} flex={1}>
        <CBDropdown size={ComponentSize.LARGE} onChange={(value) => stores.userStore.changeMainInfo('country', value)}
                    value={stores.userStore.changeMainInfoBuffer.country || 'Страна'} placeholder='Страна'>
          {
            countries.map(country => <MenuItem key={country.id} value={country.native}>{country.native}</MenuItem>)
          }
        </CBDropdown>

        <CBDropdown size={ComponentSize.LARGE} onChange={(value) => stores.userStore.changeMainInfo('city', value)} value={stores.userStore.changeMainInfoBuffer.city || 'Город'} placeholder='Город'>
          {
            cities.map(city => city.country_id === countryId && <MenuItem key={city.id} value={city.name}>{city.name}</MenuItem>)
          }
        </CBDropdown>

        <CBDropdown size={ComponentSize.LARGE}
                    onChange={(value) => stores.userStore.changeMainInfo('timeZone', value)}
                    value={stores.userStore.changeMainInfoBuffer.timeZone || 'Часовой пояс'} placeholder='Часовой пояс'>
          { getLocalTime.map(item => (
            <MenuItem value={item}>{item}</MenuItem>
          )) }
        </CBDropdown>

        <Box sx={styles.textareaContainer}>
        <CBTextarea value={stores.userStore.changeMainInfoBuffer.aboutMe}
                    onChange={(value) => stores.userStore.changeMainInfo('aboutMe', value)} label={'О себе'}
                    isValid={stores.userStore.isAboutMeLenghtValidInBuffer}
                    maxLength={200}
                    style={{width: '100%'}}/>
        </Box>
      </Stack>
    </Stack>
  )

  const renderSkeleton = () => {
    return !stores.adaptiveService.isMinDesktopMini
      ? <Stack direction='column' columnGap='28px' rowGap='20px' sx={{marginTop: '60px !important'}}>
        {[...new Array(2)].map((_, index) => <Skeleton key={index} variant='rectangular' sx={styles.skeletonButtons}/>)}
      </Stack>
      : <Stack direction='row' columnGap='28px' justifyContent='flex-end' sx={styles.skeletonButtonsContainer}>
        {[...new Array(2)].map((_, index) => <Skeleton key={index} variant='rectangular' sx={styles.skeletonButtons}/>)}
      </Stack>
  }

  return(
    <>
      <DialogContent sx={styles.skeletonDataContainer}>
        {
          stores.userStore.isUserDataLoading
            ? (<Skeleton sx={styles.skeletonData} variant={"rectangular"}/>)
            : renderUserData()
        }
      </DialogContent>


      {
        stores.userStore.isUserDataLoading
          ? renderSkeleton()
          : (
            <DialogActions sx={{...styles.actions, ...(!stores.adaptiveService.isMinDesktopMini && {flexWrap: 'wrap', rowGap: '20px', width: '100%', padding: '0'})}}>
              <CBButton
                style={{...styles.button, ...(!stores.adaptiveService.isMinDesktopMini && {width: '100%', margin: '0 !important'})}}
                loading={stores.userStore.isMainDataSave}
                disabled={stores.userStore.isUserDataLoading || !stores.userStore.validateChange || !stores?.userStore.isMainDataChange}
                children='Сохранить'
                onClick={() => {
                stores.userStore.saveMainData().then(
                  onSuccess => {

                  },
                  onError => {

                  }
                )
              }}
              />
              <CBButton
                style={{...styles.button, ...(!stores.adaptiveService.isMinDesktopMini && {width: '100%', margin: '0 !important'})}}
                disabled={stores.userStore.isMainDataSave}
                children='Отменить'
                onClick={handleClose}
                background={Colors.ERROR}
                backgroundHover={Colors.ERROR_DARK}
              />
            </DialogActions>
          )
      }
    </>
  )
})

const styles = StyleSheet.create({
  textareaContainer: {
    '& .MuiOutlinedInput-root > textarea:first-of-type': {
        minHeight: '154px !important'
      }
  },
  skeletonDataContainer: {
    width: '100%',
    padding: '0'
  },
  skeletonData: {
    width: '100%',
    height: '441px',
    flex: 1,
    borderRadius: '10px'
  },
  skeletonButtonsContainer: {
    marginTop: '60px !important',
    padding: '8px'
  },
  skeletonButtons: {
    width: '100%',
    height: '50px',
    borderRadius: '20px'
  },
  actions: {
    columnGap: '20px',
  },
  button: {
    width: '209px'
  }
})

export default MainUserSetting
