import React, {useState} from "react";
import {Box, Divider, MenuItem, Stack} from "@mui/material";
import {observer} from "mobx-react-lite";
import {stores} from "../../../pages/_app";
import MainUserSetting from "./MainUserSetting";
import PrivacyUserSetting from "./PrivacyUserSetting";
import SecurityUserSetting from "./SecurityUserSetting";
import InterestsUserSettings from "./InterestsUserSetting";
import {FontStyle, FontType, GrayScale} from "../../../uikit/enums";
import {StyleSheet} from "../../../utils/styles";
import {defaultFontStyles} from "../../../uikit/fontStyle";
import {CBDropdown} from "../../../uikit/baseComponent/dropdown";
import AdditionalUserSettings from "./AdditionalUserSettings";
import CBText from "../../../uikit/baseComponent/text";

enum SettingTypePage {
  mainSettings = 'Основные настройки',
  interests = 'Интересы',
  privacy = 'Приватность',
  security = 'Безопаность',
  additional = 'Дополнительные сведения'
}

const UserSettings = observer(() => {
  const [value, setValue] = useState(SettingTypePage.mainSettings);

  const handleChange = (newValue: SettingTypePage) => {
    if (stores.userStore.isMainDataChange || stores.userStore.isUserPrivacySettingsChanged || stores.userStore.isChangePasswordDataChanged) {
      if (!window.confirm("Возможно, внесенные изменения не сохранятся.")) return
      stores.userStore.cancelMainInfoBufferChanges()
      stores.userStore.cancelUserPrivacySettingsBufferChanges()
      stores.userStore.cancelChangePasswordData()
    }
    if (newValue === SettingTypePage.mainSettings) {
      stores.userStore.loadUserProfile().then(
        onSuccess => {

        },
        onError => {

        }
      )
    }
    if (newValue === SettingTypePage.privacy) {
      stores.userStore.loadUserPrivacySettings().then(
        onSuccess => {

        },
        onError => {

        }
      )
    }
    if (newValue === SettingTypePage.additional) {
      stores.userStore.loadUserAdditionalData().then(
        onSuccess => {

        },
        onError => {

        }
      )
    }
    setValue(newValue);
  };

  const renderSettingsPanel = (value: SettingTypePage): JSX.Element => {
    switch (value) {
      case SettingTypePage.mainSettings:
        return <MainUserSetting />;
      case SettingTypePage.interests:
        return <InterestsUserSettings />;
      case SettingTypePage.privacy:
        return <PrivacyUserSetting />;
      case SettingTypePage.security:
        return <SecurityUserSetting />;
      case SettingTypePage.additional:
        return <AdditionalUserSettings/>;
      default:
        return <MainUserSetting />;
    }
  }

  return (
    <Stack>
      <CBText type={FontType.TITLE} fontStyle={FontStyle.SMALLBOLD} style={{...(!stores.adaptiveService.isMinDesktopMini && {fontSize: '26px'})}}>Настройки аккаунта</CBText>

      <Box sx={{...styles.menu, height: !stores.adaptiveService.isMinDesktopMini ? 'auto' : '671px'}}>
        <Stack spacing={3}>
        <CBDropdown onChange={handleChange} value={value} style={{width: '100%'}}>
          <MenuItem value={SettingTypePage.mainSettings} disabled={value === SettingTypePage.mainSettings}>{SettingTypePage.mainSettings}</MenuItem>
          <MenuItem value={SettingTypePage.interests} disabled={value === SettingTypePage.interests}>{SettingTypePage.interests}</MenuItem>
          <MenuItem value={SettingTypePage.privacy} disabled={value === SettingTypePage.privacy}>{SettingTypePage.privacy}</MenuItem>
          <MenuItem value={SettingTypePage.security} disabled={value === SettingTypePage.security}>{SettingTypePage.security}</MenuItem>
          <MenuItem value={SettingTypePage.additional} disabled={true}>{SettingTypePage.additional}</MenuItem>
        </CBDropdown>

          <Divider sx={{borderColor: GrayScale.LINE_COLOR}} />

          { renderSettingsPanel(value) }

        </Stack>
      </Box>
    </Stack>
  )
})

const styles = StyleSheet.create({
  menu: {
    marginTop: '20px',
    width: '100%',
    '& > div': {
      height: '100%'
    }
  },
  tabs: {
    '& .MuiTabs-indicator': {
      backgroundColor: '#32BD9C'
    }
  },
  tab: {
    ...defaultFontStyles.textSmall,
    '&.Mui-selected': {
      color: '#32BD9C'
    }
  },
  innerContainer: {
    flex: 1
  }
})

export default UserSettings
