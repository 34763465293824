import React from 'react';
import CBText from "../../baseComponent/text";
import {ComponentSize, ComponentType, FontStyle, FontType, GrayScale, InputsType} from "../../enums";
import CBInput from "../../baseComponent/input/input";
import {baseStyles} from "../../../utils/baseStyles";
import CBButton from "../../baseComponent/buttons/mainButton";
import {StyleSheet} from "../../../utils/styles";
import {Autocomplete, AutocompleteRenderInputParams, Stack} from "@mui/material";
import {observer} from "mobx-react-lite";
import {baseButtonStyles} from "../../../utils/baseButtonStyles";
import {stores} from "../../../pages/_app";

interface IProps {
	title: string,
	description?: string,
	placeholder: string,
	discardButton: string,
	discardButtonDisabled: boolean,
	acceptButtonDisabled: boolean,
	acceptButton: string,
	acceptButtonLoading: boolean,
	onAccess: () => void,
	onDiscard: () => void,
	validationInput?: boolean,
	onChangeInput: (value: string | number | string[] | object | object[] | (unknown | unknown[]) | null, event?: string) => void,
	helperTextInput?: string,
	onClearClickInput?: () => void,
	type?: 'default' | 'autocomplete' | 'number',
	callback?: () => void,
	optionsAutocomplete?: readonly (string | Record<string, any>)[],
	defaultValue?: string | number,
	callbackWithDeps?: () => void,
	deps?: React.DependencyList | boolean
}

const CBModalWithInput = observer(({
																		 title,
																		 description,
																		 placeholder,
																		 discardButton,
																		 acceptButton,
																		 onAccess,
																		 onDiscard,
																		 validationInput,
																		 onChangeInput,
																		 helperTextInput,
																		 onClearClickInput,
																		 discardButtonDisabled,
																		 acceptButtonDisabled,
																		 acceptButtonLoading,
																		 type = 'default',
																		 callback,
																		 optionsAutocomplete = [],
																		 defaultValue,
																		 callbackWithDeps,
																		 deps
																	 }: IProps) => {
	const [valueAutocomplete, setValueAutocomplete] = React.useState<string>('');
	const onChangeAutocomplete = (_, value) => {
		setValueAutocomplete(value);
	};

	const onInputChangeAutocomplete = (_,value, reason) => {
		if (reason === 'clear') {
			onClearClickInput();
		} else {
			onChangeInput(value);
		}
	}
	const clearSelectedAutocomplete = () => {
		setValueAutocomplete('');
	};

	React.useEffect(() => {
		if (!stores.materialDialogStore.isOpen) {
			clearSelectedAutocomplete();
		}
	}, [stores.materialDialogStore.isOpen])

	React.useEffect(() => {
		if (!!stores.materialDialogStore.isOpen) {
			!!callback && callback()
		}
	}, [stores.materialDialogStore.isOpen])

	React.useEffect(() => {
		!!callbackWithDeps && callbackWithDeps()
	}, [deps])

	const renderInput = (params?: AutocompleteRenderInputParams) => (
		<CBInput
			params={!!params && params}
			defaultValue={defaultValue}
			onChange={onChangeInput}
			label={placeholder}
			type={type === 'number' ? InputsType.NUMBER : InputsType.TEXT}
			size={ComponentSize.LARGE}
			isValid={validationInput}
			required={true}
			helperText={helperTextInput}
			onClearClick={onClearClickInput}
			style={styles.input}
		/>
	)

	const renderAutocomplete = () => (
		<Autocomplete
			onChange={onChangeAutocomplete}
			onInputChange={onInputChangeAutocomplete}
			freeSolo={true}
			disabled={false}
			limitTags={1}
			value={valueAutocomplete}
			filterSelectedOptions
			size={'small'}
			sx={{marginTop: '20px'}}
			id={'course'}
			getOptionLabel={(option) => typeof option === "object" ? option.title || '' : option}
			options={optionsAutocomplete}
			renderInput={(params) => renderInput(params)}
		/>
	)

	return (
		<Stack style={styles.container}>
			<CBText
				children={title}
				type={FontType.TITLE}
				fontStyle={FontStyle.SMALLBOLD}
				color={GrayScale.TITLE_ACTIVE_COLOR}
				style={styles.title}
			/>
			<CBText
				children={description}
				type={FontType.TEXT}
				fontStyle={FontStyle.MEDIUM}
				color={GrayScale.BODY_COLOR}
			/>
			{
				type === 'autocomplete'
					? renderAutocomplete()
					: renderInput()
			}
			<div style={{...baseStyles.horizontalContainer, ...styles.buttons}}>
				<CBButton
					disabled={discardButtonDisabled}
					onClick={onDiscard}
					children={discardButton}
					size={ComponentSize.SMALL}
					type={ComponentType.PRIMARY}
					style={{...styles.cancelButton, ...baseButtonStyles.buttonDiscard}}
					width={'50%'}
				/>
				<CBButton
					disabled={acceptButtonDisabled}
					loading={acceptButtonLoading}
					size={ComponentSize.SMALL}
					type={ComponentType.PRIMARY}
					children={acceptButton}
					width={'50%'}
					onClick={onAccess}
				/>
			</div>
		</Stack>
	);
});

const styles = StyleSheet.create({
	container: {
		width: '100%'
	},
	title: {
		marginBottom: '45px'
	},
	input: {
		width: '100%',
		marginTop: '15px'
	},
	buttons: {
		marginTop: '30px',
		width: '100%'
	},
	cancelButton: {
		marginRight: '10px',
	}
})

export default CBModalWithInput;