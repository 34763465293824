export const Routers = {
  MARKETPLACE_HOME_LANDING: '/about',

  MARKETPLACE_BITORIUM: '/',
  MARKETPLACE_BITORIUM_WITH_PARAMETER: '/bitorium/:pageType',
  MARKETPLACE_BITORIUM_WITH_PARAMETER_ARTICLE: '/bitorium/:pageType/:articleId',

  PERSONAL_PROFILE_WITH_PARAMETER: '/profile/:pageType',
  PERSONAL_PROFILE_WITH_PARAMETER_MATERIAL: '/profile/:pageType/:materialId',
  PERSONAL_PROFILE_WITH_PARAMETER_CHILD_MATERIAL: '/profile/:pageType/:materialId/:materialType/:childMaterialId',
  // PERSONAL_PROFILE_WITH_PARAMETER_CHILD_MATERIAL: '/profile/:pageType?materialId=:materialId&materialType=:materialType?childMaterialId=:childMaterialId',

  MARKETPLACE_USER_PROFILE_WITH_PARAMETER: '/user/:userId/:pageType',
  MARKETPLACE_USER_PROFILE: '/user/:userId/',

  MARKETPLACE_COURSE_PAGE_WITH_PARAMETER: '/course/:copyCourseId?pageType=:pageType',
  MARKETPLACE_COURSE_PAGE_WITHOUT_PAGE: '/course/:copyCourseId',
  MARKETPLACE_COURSE_PAGE: '/course/',

  DOCUMENTS_PAGE_WITH_PARAMETER: '/documents/:pageType'
}
 
