import React from "react"
import {styles} from "./styles";
import {Colors, GrayScale} from "../../../uikit/enums";

interface IProps {
  status: StatusTagType,
  text: string,
  style?: React.CSSProperties
}

export enum StatusTagType {
  OPEN = "OPEN",
  INPROGRESS = "INPROGRESS",
  CLOSE = "CLOSE",
  NOT_LEARNED = "NOT_LEARNED",
  LEARNED = "LEARNED"
}

const StatusTagTypeColors = {
  "OPEN": {
    backgroundColor: '#C9F2FF',
    color: '#2A9CDC'
  },
  "INPROGRESS": {
    backgroundColor: '#FFF8BD',
    color: '#C3AF67'
  },
  "CLOSE": {
    backgroundColor: '#CEFFBD',
    color: '#56BB46'
  },
  "NOT_LEARNED": {
    backgroundColor: '#D9D9D9',
    color: '#5E5E5E'
  },
  "LEARNED": {
    backgroundColor: Colors.PRIMARY_COLOR,
    color: GrayScale.INPUT_BACKGROUND
  }
}

export const StatusTag = ({status, text, style}: IProps) => {

  return(
    <div style={{...StatusTagTypeColors[status], ...styles.container, ...style}}>
      <p style={styles.text}>{text}</p>
    </div>
  )
}
