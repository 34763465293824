import {makeAutoObservable} from "mobx";
import {IRootStore} from "../../stores/global/RootStore";

export enum DialogType {
  endRegistration = 'endRegistration',
  createCourse = 'createCourse',
  deleteCourse = 'deleteCourse',
  createArticleWithChoose = 'createArticleWithChoose',
  createArticleWithoutChoose = 'createArticleWithoutChoose',
  deleteArticle = 'deleteArticle',
  deleteTest = 'deleteTest',
  restoreArticleEditData = 'restoreArticleEditData',
  restoreTestEditData = 'restoreTestEditData',
  restoreCourseEditData = 'restoreCourseEditData',
  createTest = 'createTest',
  createChapter = 'createChapter',
  deleteChapter = 'deleteChapter',
  createQuestion = 'createQuestion',
  deleteQuestion = 'deleteQuestion',
  uploadUserProfilePhoto = 'uploadUserProfilePhoto',
  uploadArticleCover = 'uploadArticleCover',
  uploadCourseCover = 'uploadCourseCover',
  uploadCourseIcon = 'uploadCourseIcon',
  uploadUserProfileCover = 'uploadUserProfileCover',
  uploadPdfFile = 'uploadPdfFile',
  confirmExit = 'confirmExit',
  userSettings = 'userSettings',
  createGroup = 'createGroup',
  generateUserInGroup = 'generateUserInGroup',
  deleteUnusedUsersInGroup = 'deleteUnusedUsersInGroup',
  setNewCourseForGroup = 'setNewCourseForGroup',
  deleteCourseInGroup = 'deleteCourseInGroup',
  deleteGroup = 'deleteGroup',
  supportDialog = 'supportDialog',
  learningMaterial = 'learningMaterial',
  createVideo = 'createVideo',
  deleteVideo = 'deleteVideo',
  seeLearnedMaterial = 'seeLearnedMaterial',
  addUserByLoginInGroup = 'addUserByLoginInGroup',
  chooseCategories = 'chooseCategories',
  chooseCategoriesCourse = 'chooseCategoriesCourse',
  openCertificateEditor = 'openCertificateEditor',
  gptSummary = 'gptSummary',
  gptUpgrades = 'gptUpgrades',
  gptArticleComplexityReason = 'gptArticleComplexityReason',
  gptCourseQuestions = 'gptCourseQuestions',
  gptChapterQuestions = 'gptChapterQuestions',
  discussionAnalytics = 'discussionAnalytics'
}


export class MaterialDialogStore {
  private rootStore: IRootStore

  constructor(rootStore: IRootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  isOpen: boolean = false
  isMessageViewOpen: boolean = false
  messageDuration: number = 2000
  messageView: string = ''
  currentDialogType: DialogType | undefined = undefined

  title?: string = undefined
  id?: number = undefined
  callback?: () => void = undefined

  setCurrentState = (value: boolean, dialogType: DialogType | undefined = undefined, title?: string, id?: number, callback?: () => void) => {
    this.currentDialogType = dialogType
    this.isOpen = value
    this.title = title
    this.id = id
    this.callback = callback
  }

  setMessageViewState = (value: boolean, message: string = '', messageDuration: number = 2000) => {
    this.isMessageViewOpen = value
    this.messageDuration = messageDuration
    this.messageView = value ? message : ''
  }

}

export interface IMaterialDialogStore extends MaterialDialogStore {}
