import {makeAutoObservable, runInAction} from "mobx";
import networkService from "../services/NetworkService";
import {VideoObject} from "../types/materials/video/VideoObject";
import {IRootStore} from "./global/RootStore";

export enum PageVideoEditName {
  'main' = 'main',
}

export const PageVideoEditTitles = {
  'main': 'Основные настройки',
}

export class VideoEditViewStore {
  private rootStore: IRootStore

  constructor(rootStore: IRootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  currentPage: PageVideoEditName = PageVideoEditName.main
  currentVideo: VideoObject | undefined = undefined
  currentVideoCash: VideoObject | undefined = undefined
  currentDeleteVideoId: number | undefined = undefined
  currentDeleteVideoTitle: string | undefined = undefined

  isVideoLoadSaveProcess: boolean = false

  setCurrentPage = (page: PageVideoEditName) => {
    this.currentPage = page
  }

  setCurrentDeleteVideoData = (id: number, title: string) => {
    this.currentDeleteVideoId = id
    this.currentDeleteVideoTitle = title
  }

  loadCurrentVideo = async (videoId: number | undefined | null) => {
    try {
      runInAction(() => this.isVideoLoadSaveProcess = true)
      const {data} = await networkService.post('/constructor/video/getVideoData', {
        videoId: videoId
      })
      runInAction(() => {
        this.currentVideo = data
        this.currentVideoCash = data
      })

      runInAction(() => this.isVideoLoadSaveProcess = false)
      return Promise.resolve()
    }
    catch (e) {
      // @ts-ignore
      return Promise.reject(e.response.data)
    }
  }

  saveVideo = async () => {
    try {
      runInAction(() => this.isVideoLoadSaveProcess = true)
      const {data} = await networkService.post('/constructor/video/changeVideo', {
        videoId: this.currentVideo?.id,
        title: this.currentVideo?.title,
        description: this.currentVideo?.description,
        duration: this.currentVideo?.duration,
        url: this.currentVideo?.url
      })
      runInAction(() => {
        this.loadCurrentVideo(this.currentVideo?.id || 0)
        this.isVideoLoadSaveProcess = false
      })
      return Promise.resolve()
    }
    catch (e) {
      // @ts-ignore
      return Promise.reject(e.response.data)
    }
  }

  changeVideoData = (key: 'title' | 'description' | 'url' , value: string) => {
    if (this.currentVideo !== undefined) {
      this.currentVideo[key] = value
    }
  }

  setTime = (value: number) => {
    if (this.currentVideo !== undefined) {
      this.currentVideo.duration = value
    }
  }

  get isVideoChanged() {
    return JSON.stringify(this.currentVideoCash) !== JSON.stringify(this.currentVideo)
  }

}
export interface IVideoEditViewStore extends VideoEditViewStore {}