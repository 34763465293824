import type {AppProps} from 'next/app'
import MainLayout from "../components/layouts/MainLayout";
import {IMainStore} from '../stores/global/MainStore';
import {IRootStore, RootStore} from "../stores/global/RootStore";
import {IUserStore} from "../stores/global/UserStore";
import {IAuthStore} from "../stores/global/AuthStore";
import {IAdaptiveService} from "../services/AdaptiveService";
import {IMenuHeaderStore} from "../stores/menuHeaderStore";
import {IMaterialDialogStore,} from "../components/MaterialDialog/MaterialDialogStore";
import {IEndRegistrationStore} from "../stores/EndRegistrationStore";
import "../utils/css/zIndexStyles.css"
import "../utils/css/index.css"
import "../utils/css/testLine.css"
import '../utils/css/articleStyle.css'
import '../utils/css/customNProgress.css'
import '../utils/css/editorStyles.css'
import 'react-multi-carousel/lib/styles.css'
import {IDocumentStore} from "../stores/DocumentsStore";
import {IHomeLandingStore} from "../stores/HomeLandingStore";
import {IUserProfileStore} from "../stores/UserProfileStore";
import {IMenuProfileStore} from "../stores/MenuProfileStore";
import {IMenuBitoriumStore} from "../stores/bitoriumMenuStore";
import {IBitoriumStore} from "../stores/BitoriumStore";
import {IMarketplaceArticlePageStore} from "../stores/MarketplaceArticlePageStore";
import {Router} from "next/router";
import NProgress from 'nprogress';
import {IUserCourseStore} from "../stores/UserCourseStore";
import {IConstructorMaterialsStore} from "../stores/ConstructorMaterialsStore";
import {ICourseEditViewStore} from "../stores/CourseEditViewStore";
import {IUploadFileStore} from "../stores/UploadFileStore";
import {ICreateCertificateStore} from "../stores/createCertificateStore";
import {ITestEditViewStore} from "../stores/TestEditViewStore";
import {IVideoEditViewStore} from "../stores/VideoEditViewStore";
import {IArticleEditViewStore} from "../stores/ArticleEditViewStore";
import {IUserGroupsStore} from "../stores/UserGroupStore";
import {ICourseServiceStore} from "../stores/CourseServiceStore";
import {IXAPIService} from "../services/xapi/XAPIService";
import {IReCaptchaStore} from "../stores/reCaptchaStore";
import {IVideoServiceStore} from "../stores/videoServiceStore";
import LogRocket from "logrocket";
import {IAnalyticsStore} from "../stores/AnalyticsStore";
import {IGPTStore} from "../stores/GPTStore";

const rootStore = new RootStore()
export type IStores = {
  rootStore: IRootStore,
  mainStore: IMainStore,
  authStore: IAuthStore,
  userStore: IUserStore,
  adaptiveService: IAdaptiveService,
  menuHeaderStore: IMenuHeaderStore,
  materialDialogStore: IMaterialDialogStore,
  endRegistrationStore: IEndRegistrationStore,
  documentStore: IDocumentStore,
  homeLandingStore: IHomeLandingStore,
  userProfileStore: IUserProfileStore,
  menuProfileStore: IMenuProfileStore,
  menuBitoriumStore: IMenuBitoriumStore,
  bitoriumStore: IBitoriumStore,
  marketplaceArticlePageStore: IMarketplaceArticlePageStore,
  userCourseStore: IUserCourseStore,
  constructorMaterialsStore: IConstructorMaterialsStore
  courseEditViewStore: ICourseEditViewStore
  articleEditViewStore: IArticleEditViewStore
  uploadFileStore: IUploadFileStore
  createCertificateStore: ICreateCertificateStore
  testEditViewStore: ITestEditViewStore
  videoEditViewStore: IVideoEditViewStore
  userGroupsStore: IUserGroupsStore
  courseServiceStore: ICourseServiceStore
  xAPIService: IXAPIService,
  reCaptchaStore: IReCaptchaStore,
  videoServiceStore: IVideoServiceStore,
  analyticsStore: IAnalyticsStore,
  gptStore: IGPTStore,
}

export const stores: IStores = {
  rootStore,
  mainStore: rootStore.mainStore,
  authStore: rootStore.authStore,
  userStore: rootStore.userStore,
  adaptiveService: rootStore.adaptiveService,
  menuHeaderStore: rootStore.menuHeaderStore,
  materialDialogStore: rootStore.materialDialogStore,
  endRegistrationStore: rootStore.endRegistrationStore,
  documentStore: rootStore.documentStore,
  homeLandingStore: rootStore.homeLandingStore,
  userProfileStore: rootStore.userProfileStore,
  menuProfileStore: rootStore.menuProfileStore,
  menuBitoriumStore: rootStore.menuBitoriumStore,
  bitoriumStore: rootStore.bitoriumStore,
  marketplaceArticlePageStore: rootStore.marketplaceArticlePageStore,
  userCourseStore: rootStore.userCourseStore,
  constructorMaterialsStore: rootStore.constructorMaterialsStore,
  courseEditViewStore: rootStore.courseEditViewStore,
  articleEditViewStore: rootStore.articleEditViewStore,
  uploadFileStore: rootStore.uploadFileStore,
  createCertificateStore: rootStore.createCertificateStore,
  testEditViewStore: rootStore.testEditViewStore,
  videoEditViewStore: rootStore.videoEditViewStore,
  userGroupsStore: rootStore.userGroupsStore,
  courseServiceStore: rootStore.courseServiceStore,
  xAPIService: rootStore.xAPIService,
  reCaptchaStore: rootStore.reCaptchaStore,
  videoServiceStore: rootStore.videoServiceStore,
  analyticsStore: rootStore.analyticsStore,
  gptStore: rootStore.gptStore
}

Router.events.on('routeChangeStart', () => NProgress.start());
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());

LogRocket.init('qw7div/coursebit');

export default function App({Component, pageProps}: AppProps) {
  return (
    <MainLayout children={<Component {...pageProps} />}/>
  )
}
