import {observer} from "mobx-react-lite";
import {PageDetails} from "../../../../types/materials/course/PageDetails";
import {QuestionType} from "../../../../types/materials/test/QuestionType";
import React, {useEffect} from "react";
import {stores} from "../../../../pages/_app";
import {Box, CircularProgress, FormControl, FormGroup, RadioGroup, Stack} from "@mui/material";
import {baseStyles} from "../../../../utils/baseStyles";
import {StyleSheet} from "../../../../utils/styles";
import {defaultFontStyles} from "../../../../uikit/fontStyle";
import CBText from "../../../../uikit/baseComponent/text";
import {Colors, FontStyle, GrayScale} from "../../../../uikit/enums";

interface IProps {
	question: QuestionType,
	page: PageDetails,
}

const TestAnswerItem = observer(({question, page}: IProps) => {
	let statementAnswer
	let statementObject

	useEffect(() => {
		if (stores.courseServiceStore.questionStatement !== undefined) {
			statementAnswer = stores.courseServiceStore.questionStatement.map(statement => {
				if (statement?.result !== undefined) {
					return (statement.result)
				}
				statementObject = stores.courseServiceStore.questionStatement.map(statement => {
					if (statement?.object !== undefined) {
						return statement.object
					}
				})
			})
		}
	}, [])

	const makeUniq = (array) => [...new Set(array)]

	const correctResponsePattern = stores.courseServiceStore.questionStatement.map(statement => {
		// @ts-ignore
		return statement?.result?.response
	})

	// @ts-ignore
	const newArray: string[] = makeUniq(correctResponsePattern)

	const renderAnswerWithCheckbox = (question: QuestionType) => {
		let value: number | undefined = undefined
		let newValue = newArray.filter(item => item?.indexOf('[,]') > -1)
		let arrayWithoutSeparator: string[] = []
		newValue.forEach((item, index, array) => {
			arrayWithoutSeparator = item.split("[,]")
		})
		return (
			<FormGroup sx={styles.checkboxGroup}>
				{question.answers?.map(answer => {
					if (!!answer.id) {

						let checkArray = arrayWithoutSeparator.find(item => item === answer.id?.toString())
						if (checkArray !== undefined) {
							value = +(checkArray)
						}
					}
					return (
						!!value && answer.id === value
							? <CBText fontStyle={FontStyle.MEDIUM} color={Colors.PRIMARY_COLOR}
												children={`Ваш ответ: ${answer.content}`}/>
							: <CBText fontStyle={FontStyle.MEDIUM} color={GrayScale.LABEL_COLOR} children={answer.content}/>
					)
				})
				}
			</FormGroup>
		)
	}

	const renderAnswerWithRadioButton = (question: QuestionType) => {
		let value: number | undefined = undefined
		return (
			<FormControl>
				<RadioGroup sx={styles.radioGroup}>
					{question.answers?.map(answer => {
							if (!!answer.id) {
								let checkArray = newArray.find(item => item === answer.id?.toString())
								if (checkArray !== undefined) {
									value = +(checkArray)
								}
							}
							return (
								!!value && answer.id === value
									? <CBText fontStyle={FontStyle.MEDIUM} color={Colors.PRIMARY_COLOR}
														children={`Ваш ответ: ${answer.content}`}/>
									: <CBText fontStyle={FontStyle.MEDIUM} color={GrayScale.LABEL_COLOR} children={answer.content}/>
							)
						}
					)
					}
				</RadioGroup>
			</FormControl>
		)
	}

	return (
		(stores?.courseServiceStore.correctAnswers.length || 0) > 0
			? <Box marginBottom='20px' borderRadius='10px' padding='20px 30px' sx={{backgroundColor: '#F5F5F5'}}>
				<Stack rowGap='10px' marginBottom='20px'>
					<CBText
						children={`Вопрос ${question.orderIndex}`}
						fontStyle={FontStyle.MEDIUM}
					/>
					<CBText
						children={`${question.title}`}
						fontStyle={FontStyle.LINKMEDIUM}
					/>
					<CBText
						children={`${question.description}`}
						fontStyle={FontStyle.MEDIUM}
					/>
				</Stack>
				<Stack>
					{stores?.courseServiceStore.correctAnswers?.filter((item) => item.questionId === question.questionId).filter(item => item.correct.length === 1).length !== 1
						? renderAnswerWithRadioButton(question)
						: renderAnswerWithCheckbox(question)
					}
				</Stack>
			</Box>
			: <div style={styles.progressContainer}>
				<CircularProgress sx={styles.progress}/>
			</div>
	)
})

const styles = StyleSheet.create({
	radioGroup: {
		'&>label>span.MuiTypography-root': {
			...defaultFontStyles.textMedium
		}
	},
	checkboxGroup: {
		'&>label>span.MuiTypography-root': {
			...defaultFontStyles.textMedium
		}
	},
	progressContainer: {
		display: 'flex',
		...baseStyles.centeredView,
		width: '400px',
		height: '500px'
	},
	progress: {
		alignSelf: 'center',
		color: Colors.PRIMARY_COLOR,
	}
})

export default TestAnswerItem
