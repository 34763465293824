import {makeAutoObservable, runInAction} from "mobx";
import {IRootStore} from "./global/RootStore";
import networkService from "../services/NetworkService";
import {initSortedArticle, SortedArticle} from "../types/materials/article/SortedArticle";
import {CurrentSortType, initCurrentSort} from "../types/materials/CurrentSortType";

export class BitoriumStore {
  private rootStore: IRootStore

  constructor(rootStore: IRootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  sortedArticle: SortedArticle = initSortedArticle
  currentSortParams: CurrentSortType = initCurrentSort
  isLoaded: boolean = false
  isLoadingData: boolean = false

  setCurrentSortParams = (key: 'category' | 'sort' | 'search' | 'page', value: number | string | null) => {
    if (value === '') {
      this.currentSortParams = {
        categoryId: key === 'category' && value || null,
        sortId: key === 'sort' && value || null,
        searchString: key === 'search' && value || null,
        page: key === 'page' && value || null
      }
    } else {
      this.currentSortParams = {
        categoryId: key === 'category' && value || this.currentSortParams.categoryId,
        sortId: key === 'sort' && value || this.currentSortParams.sortId,
        searchString: key === 'search' && value || this.currentSortParams.searchString,
        page: key === 'page' && value || this.currentSortParams.page
      }
    }
  }

  setIsLoadingData = (value: boolean) => {
    this.isLoadingData = value
  }

  setIsLoaded = (value: boolean) => {
    this.isLoaded = value
  }

  getData = async () => {
    try {
      runInAction(() => this.setIsLoadingData(true))
      await this.rootStore.homeLandingStore.loadPopularArticles()
      await this.rootStore.mainStore.loadSorts()
      await this.rootStore.mainStore.loadCategory()
      await this.getSortedArticle('sort', 3)
      return Promise.resolve()
    } catch (e) {
      // @ts-ignore
      return Promise.reject(e.response.data)
    } finally {
      this.setIsLoadingData(false)
    }
  }

  getSortedArticle = async (type: 'category' | 'search' | 'sort' | 'page', value: number | string | null) => {
    try {
      this.setIsLoaded(true)
      runInAction(() => {
        this.setCurrentSortParams(type, value)
      })
      const {data} = await networkService.post('/public/articles/getArticlesByFilter', {
        categoryId: type === 'category' && value || this.currentSortParams.categoryId,
        searchTag: null,
        searchString: (type === 'search' && value?.toString()?.trim()) || null,
        page: type === 'page' && value || 0,
        step: 10,
        sortingId: type === 'sort' && value || this.currentSortParams.sortId
      })
      runInAction(() => {
        this.sortedArticle = data
        this.setIsLoaded(false)
      })
      return Promise.resolve()
    } catch (e) {
      console.log(JSON.stringify(e))
      // @ts-ignore
      return Promise.reject(e)
    }
  }
}

export interface IBitoriumStore extends BitoriumStore {
}
