import React from "react";
import {observer} from "mobx-react-lite";
import {styles} from "./styles";
import {SignInAuthBlock} from "./signIn";
import {TypeOfAuth} from "../../../types/auth/TypeOfAuth";
import ReactModal from "react-modal";
import {baseStyles} from "../../../utils/baseStyles";
import {stores} from "../../../pages/_app";
import {SignUpByEmail} from "./signUpByEmail";

interface IProps {
  isAuthShow: boolean,
  onClose: () => void,
  modalTypeInit: TypeOfAuth
}

// const socials: ISocialButtonProps[] = [
//   {
//     backgroundColor: '#EC7E66',
//     backgroundColorHover: '#f66345',
//     socialIcon: Google,
//     socialTitle: 'Google'
//   },
//   {
//     backgroundColor: '#5591EC',
//     backgroundColorHover: '#2c66bf',
//     socialIcon: VK,
//     socialTitle: 'VK'
//   }
// ]

export const ConstructorAuthModal = observer(({isAuthShow, onClose, modalTypeInit}: IProps) => {
  const renderSignInModal = () => (
    <ReactModal
      closeTimeoutMS={500}
      isOpen={isAuthShow}
      onAfterClose={() => {
        stores.authStore.resetValidate()
      }}
      onRequestClose={onClose}
      style={{overlay: styles.overlay, content: styles.content}}
    >
      <SignInAuthBlock
        socials={[]}
        onSignUpClick={() => stores.menuHeaderStore.setIsAuthShow(TypeOfAuth.signUpByEmail, true)}
      />
    </ReactModal>
  )

  const renderSignUpByEmailModal = () => (
          <ReactModal
              isOpen={isAuthShow}
              onAfterClose={() => {
                  stores.authStore.resetValidate()
              }}
              onRequestClose={onClose}
              style={{overlay: styles.overlay, content: styles.content}}
              >
              <SignUpByEmail onSignInClick={() => stores.menuHeaderStore.setIsAuthShow(TypeOfAuth.signIn, true)}/>
          </ReactModal>
  )

  switch (modalTypeInit) {
    case TypeOfAuth.signIn:
      return renderSignInModal()
    case TypeOfAuth.signUpByEmail:
      return renderSignUpByEmailModal()
    case TypeOfAuth.none:
      return <></>
  }
})
