import {observer} from "mobx-react-lite";
import {Button, Checkbox} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import {ChoosingInterests} from "../../../IndependentComponents/ChoosingInterests";
import {StyleSheet} from "../../../../utils/styles";
import {stores} from "../../../../pages/_app";
import {baseStyles} from "../../../../utils/baseStyles";

const ChooseArticleCategories = observer(() => {
  return (
    <div style={{margin: '20px'}}>
      <ChoosingInterests
        onSave={ids => stores.articleEditViewStore.setCurrentCategories(ids)}
        defaultValues={(stores.articleEditViewStore?.currentCategories?.split('|') || []).filter(item => item !== '').map(item => +item)}
        style={{
          height: '350px'
        }}
      />
      <div style={{...baseStyles.horizontalContainer, marginTop: '20px'}}>
        <Button
          variant={"outlined"}
          children={"Закрыть"}
          onClick={() => stores.materialDialogStore.setCurrentState(false)}
          style={{flex: 1}}
          size={"small"}
          sx={{color: '#FE594E', borderColor: '#FE594E', '&:hover': { borderColor: '#ff9a93' }}}/>
      </div>
    </div>
  )
})

export default ChooseArticleCategories

const styles = StyleSheet.create({
  title: {
    fontFamily: 'Montserrat',
    fontWeight: '700',
    fontSize: '24px',
  },
  description: {
    fontFamily: 'Montserrat',
    fontWeight: '400',
    fontSize: '14px',
    marginTop: '10px'
  },
  articleTitle: {
    width: '100%',
    marginTop: '30px',
    marginBottom: '20px'
  }
})