import React from "react";
import {Avatar} from "@mui/material";
import {UserMinimalDataModel} from "../../../../types/user/UserMinimalDataModel";
import {useRouter} from "next/router";
import {Routers} from "../../../../utils/Routers";
import {StyleSheet} from "../../../../utils/styles";
import {baseStyles} from "../../../../utils/baseStyles";

interface IProps {
  author: UserMinimalDataModel | undefined,
  style?: React.CSSProperties,
  fontStyle?: React.CSSProperties
  avatarStyle?: React.CSSProperties
}

export const AuthorAvatar = ({author, style, fontStyle, avatarStyle}: IProps) => {
  if (author === undefined)
    return <></>

  const router = useRouter()

  return (
    <div style={{...baseStyles.horizontalContainer, alignContent: 'center', alignItems: "center", ...style}}
         onClick={() => router.push(Routers.MARKETPLACE_USER_PROFILE
           .replace(':userId', author.id.toString()))}>
      <Avatar sx={{height: 40, width: 40, cursor: 'pointer', ...(avatarStyle ? avatarStyle : {})}} alt={author?.login} src={author?.profilePhoto}/>
      <p style={{...styles.name, ...(fontStyle ? fontStyle : {})}}>{author?.login}</p>
      {/*<p style={{...styles.rating, marginLeft: "auto",}}>4.9</p>*/}
      {/*<Rating name="half-rating" readOnly={true} defaultValue={0.5} precision={0.5}*/}
      {/*        max={1} sx={{color: '#32BD9C'}} />*/}
    </div>
  )
}

const styles = StyleSheet.create({
  name: {
    marginLeft: 15,
    fontFamily: 'Roboto',
    fontWeight: '500',
    fontSize: '20px',
    color: '#ffffff',
    cursor: 'pointer'
  },
  rating: {
    color: '#32BD9C',
    fontFamily: 'Roboto',
    fontWeight: '400',
    fontSize: '20px'
  }
})
