import React from 'react';
import CreateCertificate from "../../../components/IndependentComponents/CreateCertificate";
import {Box} from "@mui/material";
import {StyleSheet} from "../../../utils/styles";
import CBButton from "../../baseComponent/buttons/mainButton";
import {stores} from "../../../pages/_app";
import {ComponentType} from "../../enums";

const CbCertificateEditor = () => {
	return <Box sx={styles.box}>
		<CreateCertificate/>
		<CBButton type={ComponentType.ERROR} onClick={() => stores.materialDialogStore.setCurrentState(false)}>Закрыть</CBButton>
	</Box>
};

const styles = StyleSheet.create({
		box: {
			display: 'flex',
			flexDirection: 'column',
			rowGap: '20px',
			'& > div > div:last-of-type > div': {
				width: '100% !important',
			},

			'& > div > div:last-of-type > div > div > div:first-of-type': {
				width: '100% !important'
			},

			'& > div > div:last-of-type > div > div > div:last-of-type > div:last-of-type': {
				width: '100% !important'
			}
		}
})

export default CbCertificateEditor;