import {makeAutoObservable} from "mobx";
import {IRootStore} from "./global/RootStore";

export enum PageProfileNames {
  //Для личного профиля обучающегося
  'courses' = 'courses',
  'certificates' = 'certificates',

  //Для личного профиля автора
  'authorCourses' = 'authorCourses',
  'authorArticles' = 'authorArticles',
  'authorWebinars' = 'authorWebinars',
  'authorStat' = 'authorAnalytics',
  'authorStudent' = 'authorStudent',
  'authorSubscribe' = 'authorSubscribe',

  //Для конструктора материалов
  'course' = 'course',
  'article' = 'article',
  'test' = 'test',
  'video' = 'video',

  //Для чужого профиля
  'userArticles' = 'userArticles',
}

export class MenuProfileStore {
  private rootStore: IRootStore

  constructor(rootStore: IRootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  currentPage: PageProfileNames = PageProfileNames.courses

  setCurrentPage = (page: PageProfileNames) => {
    this.currentPage = page
  }

}

export interface IMenuProfileStore extends MenuProfileStore {}
