import {default as BaseImage} from 'next/future/image'
import {CSSProperties} from "react";

interface IProps {
  style?: CSSProperties,
  src: string,
  width: number,
  height: number,
  alt?: string
}

export const Image = ({width, height, src, style, alt = ''}: IProps) => {

  return(
    <div
      style={style}>
      <BaseImage
        src={src}
        style={{width: 'auto', height: '100%'}}
        width={width}
        height={height}
        alt={alt}
      />
    </div>
  )
}
