import React from 'react';
import {DialogActions, DialogContent, MenuItem, Stack} from "@mui/material";
import {StyleSheet} from "../../../utils/styles";
import CBButton from "../../../uikit/baseComponent/buttons/mainButton";
import {stores} from "../../../pages/_app";
import {Colors, ComponentSize, FontStyle, GrayScale} from "../../../uikit/enums";
import {CBDropdown} from "../../../uikit/baseComponent/dropdown";
import CBText from "../../../uikit/baseComponent/text";
import {useUnload} from "../../../utils/useUnload";
import {observer} from "mobx-react-lite";

const AdditionalUserSettings = observer(() => {
	useUnload(stores.userStore.isAdditionalDataChanged)

	const handleClose = () => {
		if (stores.userStore.isAdditionalDataChanged) {
			if (!window.confirm("Возможно, внесенные изменения не сохранятся.")) return
			stores.materialDialogStore.setCurrentState(false);
			stores.userStore.cancelAdditionalDataBufferChanges();
			stores.userStore.loadUserProfile()
		} else {
			stores.materialDialogStore.setCurrentState(false);
		}
	}

	const renderContent = () => (
		<DialogContent sx={styles.content}>
			<Stack direction={!stores.adaptiveService.isMinDesktopMini ? 'column' : 'row'} sx={{columnGap: '50px', rowGap: '20px'}}>
				<Stack sx={styles.innerBlock}>
					<CBDropdown size={ComponentSize.LARGE} onChange={(value) => stores.userStore.changeAdditionalData('education', value)} value={stores.userStore.userAdditionalDataBuffer.education || 'Образование'}
											placeholder='Образование' placeholderDisabled={false}>
						<MenuItem value="Нет образования">Нет образования</MenuItem>
						<MenuItem value="Начальное образование">Начальное образование</MenuItem>
						<MenuItem value="Основное образование">Основное образование</MenuItem>
						<MenuItem value="Среднее образование">Среднее образование</MenuItem>
						<MenuItem value="Среднее образование (техническое)">Среднее образование (техническое)</MenuItem>
						<MenuItem value="Среднее образование (гуманитарное)">Среднее образование (гуманитарное)</MenuItem>
						<MenuItem value="Профессионально-техническое образование">Профессионально-техническое образование</MenuItem>
						<MenuItem value="Высшее образование">Высшее образование</MenuItem>
						<MenuItem value="Высшее образование (бакалавр)">Высшее образование (бакалавр)</MenuItem>
						<MenuItem value="Высшее образование (магистр)">Высшее образование (магистр)</MenuItem>
						<MenuItem value="Высшее образование (специалист)">Высшее образование (специалист)</MenuItem>
						<MenuItem value="Аспирантура">Аспирантура</MenuItem>
						<MenuItem value="Докторантура">Докторантура</MenuItem>
					</CBDropdown>

					<CBDropdown size={ComponentSize.LARGE} onChange={(value) => stores.userStore.changeAdditionalData('income', value)} value={stores.userStore.userAdditionalDataBuffer.income || 'Доход'}
											placeholder='Доход' placeholderDisabled={false}>
						<MenuItem value='Не имею дохода'>Не имею дохода</MenuItem>
						<MenuItem value='Выше 50000Р'>Выше 50000Р</MenuItem>
					</CBDropdown>

					<CBDropdown size={ComponentSize.LARGE} onChange={(value) => stores.userStore.changeAdditionalData('laborStatus', value)}
											value={stores.userStore.userAdditionalDataBuffer.laborStatus || 'Статус на рынке труда'} placeholder='Статус на рынке труда' placeholderDisabled={false}>
						<MenuItem value='Безработный'>Безработный</MenuItem>
						<MenuItem value='Работаю по найму'>Работаю по найму</MenuItem>
					</CBDropdown>

					<CBDropdown size={ComponentSize.LARGE} onChange={(value) => stores.userStore.changeAdditionalData('laborArea', value)} value={stores.userStore.userAdditionalDataBuffer.laborArea || 'Сфера труда'}
											placeholder='Сфера труда' placeholderDisabled={false}>
						<MenuItem value='IT'>IT</MenuItem>
						<MenuItem value='Маркетинг'>Маркетинг</MenuItem>
						<MenuItem value='Образование'>Образование</MenuItem>
						<MenuItem value='Медицина'>Медицина</MenuItem>
						<MenuItem value='Банковская сфера'>Банковская сфера</MenuItem>
						<MenuItem value='Производственная сфера'>Производственная сфера</MenuItem>
						<MenuItem value='Маркетинг'>Правовая сфера</MenuItem>
					</CBDropdown>

					<CBDropdown size={ComponentSize.LARGE} onChange={(value) => stores.userStore.changeAdditionalData('familyStatus', value)} value={stores.userStore.userAdditionalDataBuffer.familyStatus || 'Семейное положение'}
											placeholder='Семейное положение' placeholderDisabled={false}>
						<MenuItem value='Женат/замужем'>Женат/замужем</MenuItem>
						<MenuItem value='В отношениях'>В отношениях</MenuItem>
						<MenuItem value='Разведен(-а)'>Разведен(-а)</MenuItem>
						<MenuItem value='Вдовец/вдова'>Вдовец/вдова</MenuItem>
						<MenuItem value='Никогда не состоял(-ась) в браке'>Никогда не состоял(-ась) в браке</MenuItem>
					</CBDropdown>

					<CBDropdown size={ComponentSize.LARGE} onChange={(value) => stores.userStore.changeAdditionalData('childrenAmount', value)} value={stores.userStore.userAdditionalDataBuffer.childrenAmount || 'Количество детей'}
											placeholder='Количество детей' placeholderDisabled={false}
					>
						<MenuItem value='Нет детей'>Нет детей</MenuItem>
						<MenuItem value='Один ребенок'>Один ребенок</MenuItem>
						<MenuItem value='Двое детей'>Двое детей</MenuItem>
						<MenuItem value='Трое детей'>Трое детей</MenuItem>
						<MenuItem value='Больше трех детей'>Больше трех детей</MenuItem>
					</CBDropdown>

				</Stack>

				<Stack sx={styles.innerBlock}>
					<CBDropdown size={ComponentSize.LARGE} onChange={(value) => stores.userStore.changeAdditionalData('expectations', value)} value={stores.userStore.userAdditionalDataBuffer.expectations || 'Ожидания'}
											placeholder='Ожидания' placeholderDisabled={false}>
						<MenuItem value='Ожидания 1'>Ожидания 1</MenuItem>
						<MenuItem value='Ожидания 2'>Ожидания 2</MenuItem>
					</CBDropdown>

					<Stack direction='row' sx={styles.connectContainer}>
						<CBText color={Colors.CONNECT_INFO} fontStyle={FontStyle.SMALL} children='Профиль в VK'/>
						<CBButton size={ComponentSize.SMALL} style={{maxWidth: '145px'}} onClick={() => stores.userStore.changeAdditionalData('vkProfileConnected', !stores.userStore.userAdditionalDataBuffer.vkProfileConnected)}
											children={stores.userStore.userAdditionalDataBuffer.vkProfileConnected ? 'Отключить' : 'Подключить'}
											background={stores.userStore.userAdditionalDataBuffer.vkProfileConnected ? Colors.ERROR : Colors.PRIMARY_COLOR}
											backgroundHover={stores.userStore.userAdditionalDataBuffer.vkProfileConnected ? Colors.ERROR_DARK : Colors.PRIMARY_DARK}
						/>
					</Stack>

					<Stack direction='row' sx={styles.connectContainer}>
						<CBText color={Colors.CONNECT_INFO} fontStyle={FontStyle.SMALL} children='Профиль в Telegram'/>
						<CBButton size={ComponentSize.SMALL} style={{maxWidth: '145px'}} onClick={() => stores.userStore.changeAdditionalData('telegramProfileConnected', !stores.userStore.userAdditionalDataBuffer.telegramProfileConnected)}
											children={stores.userStore.userAdditionalDataBuffer.telegramProfileConnected ? 'Отключить' : 'Подключить'}
											background={stores.userStore.userAdditionalDataBuffer.telegramProfileConnected ? Colors.ERROR : Colors.PRIMARY_COLOR}
											backgroundHover={stores.userStore.userAdditionalDataBuffer.telegramProfileConnected ? Colors.ERROR_DARK : Colors.PRIMARY_DARK}
						/>
					</Stack>

					<CBText fontStyle={FontStyle.XSMALL} color={GrayScale.PLACEHOLDER_COLOR}>Мы сохраняем только ваш логин или
						ссылку на профиль из ваших социальных сетей</CBText>

				</Stack>
			</Stack>
		</DialogContent>
	)

	const renderActions = () => (
		<DialogActions sx={{...styles.actions, ...(!stores.adaptiveService.isMinDesktopMini && {flexWrap: 'wrap', rowGap: '20px', width: '100%', padding: '0', marginTop: '60px'})}}>
			<CBButton
				style={{...styles.button, ...(!stores.adaptiveService.isMinDesktopMini && {width: '100%', margin: '0 !important'})}}
				loading={stores.userStore.isUserAdditionalDataProcess}
				disabled={!stores.userStore.isAdditionalDataChanged}
				children='Сохранить'
				onClick={() => {
					stores.userStore.saveUserAdditionalData().then(
						onSuccess => {

						},
						onError => {

						}
					)
				}}
			/>
			<CBButton
				style={{...styles.button, ...(!stores.adaptiveService.isMinDesktopMini && {width: '100%', margin: '0 !important'})}}
				disabled={stores.userStore.isUserAdditionalDataProcess}
				children='Отменить'
				onClick={handleClose}
				background={Colors.ERROR}
				backgroundHover={Colors.ERROR_DARK}
			/>
		</DialogActions>
	)

	return (
		<Stack height='100%'>
			{renderContent()}
			{renderActions()}
		</Stack>
	);
});

const styles = StyleSheet.create({
	content: {
		width: '100%',
		padding: '0'
	},
	innerBlock: {
		rowGap: '20px',
		width: '100%'
	},
	connectContainer: {
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	actions: {
		columnGap: '20px',
	},
	button: {
		width: '209px'
	}
})

export default AdditionalUserSettings;