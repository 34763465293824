import {observer} from "mobx-react-lite";
import {PageDetails} from "../../../../types/materials/course/PageDetails";
import {QuestionType} from "../../../../types/materials/test/QuestionType";
import {XAPIRadioButtonAnswers} from "../../../../types/xapi/XAPIRadioButtonAnswers";
import {XAPICheckBoxAnswers} from "../../../../types/xapi/XAPICheckBoxAnswers";
import React, {useEffect, useState} from "react";
import {stores} from "../../../../pages/_app";
import {Box, Checkbox, FormControl, FormControlLabel, FormGroup, Radio, RadioGroup, Stack} from "@mui/material";
import {AnswerType} from "../../../../types/materials/test/AnswerType";
import CBText from "../../../../uikit/baseComponent/text";
import {FontStyle} from "../../../../uikit/enums";
import {StyleSheet} from "../../../../utils/styles";
import {defaultFontStyles} from "../../../../uikit/fontStyle";

interface IProps {
	question: QuestionType,
	page: PageDetails
}

const TestQuestionItem = observer(({page, question}: IProps) => {
	const [userAnswersRadioButton, setUserAnswersRadioButton] = useState<XAPIRadioButtonAnswers[] | undefined>([])
	const [userAnswersCheckBox, setUserAnswersCheckBox] = useState<XAPICheckBoxAnswers[] | undefined>([])
	const [currentQuestionId, setCurrentQuestionId] = useState(0)
	const [typeAnswer, setTypeAnswer] = useState('checkbox')
	stores.courseServiceStore.generateTimeStartLearningPage()
	stores.courseServiceStore.setCurrentTestId(page.content.id || 0)

	useEffect(() => {
		//TODO разобраться с прохождением теста (избавиться от строки 32)
		// @ts-ignore
		if (userAnswersRadioButton !== [] || userAnswersCheckBox !== []) {
			let checkBox = userAnswersCheckBox
			let radioButton = userAnswersRadioButton
			stores.courseServiceStore.setCurrentUserAnswer(page, checkBox || [], radioButton || [], stores.courseServiceStore.correctAnswers, currentQuestionId, question, typeAnswer)
			stores.courseServiceStore.setUserAnswer(page, checkBox || [], radioButton || [], stores.courseServiceStore.correctAnswers, currentQuestionId, question, typeAnswer)
		}
	}, [userAnswersRadioButton, userAnswersCheckBox])


	useEffect(() => {
		if (!stores.materialDialogStore.isOpen) {
			stores.courseServiceStore.clearAnswerData()
			stores.courseServiceStore.setCorrectAnswers([])
		}
	}, [stores.materialDialogStore.isOpen])

	const renderAnswerWithRadioButton = (question: QuestionType) => {
		return (
			<FormControl>
				<RadioGroup sx={styles.radioGroup}>
					{question.answers?.map(answer => {
							return (<FormControlLabel label={answer.content}
																				control={<Radio
																					onChange={(event) => {
																						stores?.courseServiceStore.setCurrentAnswer(answer)
																						handleChooseAnswerRadioButton(event.target.checked)
																					}}
																					checkedIcon={<div style={styles.radioIcon}/>}
																					value={answer.content}
																					size={"small"}
																					sx={styles.radio}/>}
								/>
							)
						}
					)
					}
				</RadioGroup>
			</FormControl>
		)
	}

	const handleChooseAnswerCheckBox = (value: boolean) => {
		if (!!stores.courseServiceStore.currentAnswer) {
			let chooseAnswer: AnswerType = stores.courseServiceStore.currentAnswer
			setCurrentQuestionId(chooseAnswer?.questionId || 0)
			setTypeAnswer('checkbox')

			if (value) {
				setUserAnswersCheckBox([...userAnswersCheckBox || [], {
					questionId: chooseAnswer.questionId || 0,
					userAnswersId: chooseAnswer.id || 0,
					answerTitle: chooseAnswer.content
				}])
			} else {
				setUserAnswersCheckBox(userAnswersCheckBox.filter(item => item.userAnswersId !== chooseAnswer.id))
			}
		}
	}

	const handleChooseAnswerRadioButton = (value: boolean) => {
		if (!!stores.courseServiceStore.currentAnswer) {
			let chooseAnswer: AnswerType = stores.courseServiceStore.currentAnswer
			setCurrentQuestionId(chooseAnswer?.questionId || 0)
			setTypeAnswer('radiobutton')

			const arrayWithoutAnswer = (userAnswersRadioButton?.filter(answer => answer.questionId !== chooseAnswer.questionId))
			setUserAnswersRadioButton([...(arrayWithoutAnswer?.length === 0 || arrayWithoutAnswer === undefined ? [] : arrayWithoutAnswer || []), {
				questionId: chooseAnswer.questionId || 0,
				userAnswersId: chooseAnswer.id || 0,
				answerTitle: chooseAnswer.content
			}])
		}
	}

	const renderAnswerWithCheckbox = (question: QuestionType) => {
		return (
			<FormGroup sx={styles.checkboxGroup}>
				{question.answers?.map(answer => {
					return (<FormControlLabel
						label={answer.content}
						disabled={false}
						control={<Checkbox
							defaultChecked={false}
							onChange={(event) => {
								stores?.courseServiceStore.setCurrentAnswer(answer)
								handleChooseAnswerCheckBox(event.target.checked)
							}}
							sx={styles.checkbox}
						/>}
					/>)
				})
				}
			</FormGroup>
		)
	}

	return (
		<Box marginBottom='20px' borderRadius='10px' padding='20px 30px' sx={{backgroundColor: '#F5F5F5'}}>
			<Stack rowGap='10px' marginBottom='20px'>
				<CBText
					children={`Вопрос ${question.orderIndex}`}
					fontStyle={FontStyle.MEDIUM}
				/>
				<CBText
					children={`${question.title}`}
					fontStyle={FontStyle.LINKMEDIUM}
				/>
				<CBText
					children={`${question.description}`}
					fontStyle={FontStyle.MEDIUM}
				/>
			</Stack>
			<Stack>
				{stores.courseServiceStore.correctAnswers?.filter(item => item.questionId === question.questionId).filter(item => item.correct.length === 1).length === 1
					? renderAnswerWithRadioButton(question)
					: renderAnswerWithCheckbox(question)
				}
			</Stack>
		</Box>
	)
})

const styles = StyleSheet.create({
	radioGroup: {
		'&>label>span.MuiTypography-root': {
			...defaultFontStyles.textMedium
		}
	},
	radio: {
		'&.MuiRadio-root': {
			paddingTop: '1px',
			paddingBottom: '1px',
		},
		color: '#494949',
		'&.Mui-checked': {
			color: '#32BD9C'
		}
	},
	radioIcon: {
		backgroundColor: '#32CAB8',
		width: '16px',
		height: '16px',
		borderRadius: 50,
		marginLeft: '2px',
		marginRight: '2px'
	},
	checkboxGroup: {
		'&>label>span.MuiTypography-root': {
			...defaultFontStyles.textMedium
		}
	},
	checkbox: {
		'&.MuiCheckbox-root': {
			padding: '3px 10px'
		},
		'&.Mui-checked': {
			color: '#32BD9C'
		}
	}
})

export default TestQuestionItem
