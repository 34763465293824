import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import {observer} from "mobx-react-lite";
import {stores} from "../../../pages/_app";

const ReCaptcha = observer(() => {

  return (
    <>
      <ReCAPTCHA
        onChange={stores.reCaptchaStore.onVerify}
        sitekey={stores.reCaptchaStore.sitekey}
        size={"small"}
      />
    </>
  )
})

export default ReCaptcha
