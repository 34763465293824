import {GrayScale} from "./enums";
import {StyleSheet} from "../utils/styles";
export const defaultFontStyles = StyleSheet.create({
  displayLarge: {
    fontFamily: 'FuturaPT-Book',
    fontStyle: 'normal',
    fontSize: '64px',
    fontWeight: '400',
    color: GrayScale.TITLE_ACTIVE_COLOR,
    lineHeight: '80px',
    letterSpacing: '1px'
  },
  displayLargeBold: {
    fontFamily: 'FuturaPT-Bold',
    fontStyle: 'normal',
    fontSize: '64px',
    fontWeight: '700',
    color: GrayScale.TITLE_ACTIVE_COLOR,
    lineHeight: '66px',
    letterSpacing: '1px'
  },
  displayMedium: {
    fontFamily: 'FuturaPT-Book',
    fontStyle: 'normal',
    fontSize: '48px',
    fontWeight: '400',
    color: GrayScale.TITLE_ACTIVE_COLOR,
    lineHeight: '50px',
    letterSpacing: '1px'
  },
  displayMediumBold: {
    fontFamily: 'FuturaPT-Bold',
    fontStyle: 'normal',
    fontSize: '48px',
    fontWeight: '700',
    color: GrayScale.TITLE_ACTIVE_COLOR,
    lineHeight: '50px',
    letterSpacing: '1px'
  },
  displaySmall: {
    fontFamily: 'FuturaPT-Book',
    fontStyle: 'normal',
    fontSize: '32px',
    fontWeight: '400',
    color: GrayScale.TITLE_ACTIVE_COLOR,
    lineHeight: '34px',
    letterSpacing: '1px'
  },
  displaySmallBold: {
    fontFamily: 'FuturaPT-Bold',
    fontStyle: 'normal',
    fontSize: '32px',
    fontWeight: '700',
    color: GrayScale.TITLE_ACTIVE_COLOR,
    lineHeight: '34px',
    letterSpacing: '1px'
  },
  textLarge: {
    fontFamily: 'FuturaPT-Medium',
    fontStyle: 'normal',
    fontSize: '24px',
    fontWeight: '400',
    color: GrayScale.BODY_COLOR,
    lineHeight: '38px',
    letterSpacing: '0.75px'
  },
  linkLarge: {
    fontFamily: 'FuturaPT_Heavy',
    fontStyle: 'normal',
    fontSize: '24px',
    fontWeight: '600',
    color: GrayScale.TITLE_ACTIVE_COLOR,
    lineHeight: '38px',
    letterSpacing: '0.75px'
  },
  textMedium: {
    fontFamily: 'FuturaPT-Book',
    fontStyle: 'normal',
    fontSize: '18px',
    fontWeight: '400',
    color: GrayScale.BODY_COLOR,
    lineHeight: '26px',
    letterSpacing: '0.75px'
  },
  linkMedium: {
    fontFamily: 'FuturaPT-Heavy',
    fontStyle: 'normal',
    fontSize: '18px',
    fontWeight: '600',
    color: GrayScale.TITLE_ACTIVE_COLOR,
    lineHeight: '26px',
    letterSpacing: '0.75px'
  },
  textSmall: {
    fontFamily: 'FuturaPT-Book',
    fontStyle: 'normal',
    fontSize: '16px',
    fontWeight: '400',
    color: GrayScale.BODY_COLOR,
    lineHeight: '24px',
    letterSpacing: '0.75px'
  },
  linkSmall: {
    fontFamily: 'FuturaPT-Demi',
    fontStyle: 'normal',
    fontSize: '16px',
    fontWeight: '500',
    color: GrayScale.TITLE_ACTIVE_COLOR,
    lineHeight: '24px',
    letterSpacing: '0.75px'
  },
  textXSmall: {
    fontFamily: 'FuturaPT-Book',
    fontStyle: 'normal',
    fontSize: '14px',
    fontWeight: '500',
    color: GrayScale.BODY_COLOR,
    lineHeight: '20px',
    letterSpacing: '0.25px'
  },
  linkXSmall: {
    fontFamily: 'FuturaPT-Medium',
    fontStyle: 'normal',
    fontSize: '14px',
    fontWeight: '600',
    color: GrayScale.TITLE_ACTIVE_COLOR,
    lineHeight: '20px',
    letterSpacing: '0.25px'
  }
})
