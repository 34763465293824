import {makeAutoObservable, runInAction} from "mobx";
import networkService, {networkService1} from "../services/NetworkService";
import {sha512} from "js-sha512";
import {IRootStore} from "./global/RootStore";

export enum FileExtensionsTypes {
  uploadImageDefault = 'uploadImageDefault',
  extensionUploadImage = 'extensionUploadImage',
  uploadVideoDefault = 'uploadVideoDefault',
  uploadDocumentDefault = 'uploadDocumentDefault'
}

export const FileExtensions: {[index in FileExtensionsTypes]: string[]} = {
  uploadImageDefault: [
    'image/jpeg',
    'image/png',
  ],
  extensionUploadImage: [
    'image/jpeg',
    'image/png',
    'image/gif'
  ],
  uploadVideoDefault: [
    'video/mp4'
  ],
  uploadDocumentDefault: [
    'application/pdf'
  ]
}

export class UploadFileStore {
  private rootStore: IRootStore

  constructor(rootStore: IRootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  selectedFile: File | undefined = undefined
  isUploadFileProcess: boolean = false
  isDeleteFileProcess: boolean = false
  uploadFileProgress: number = 0
  fileBackground: File | undefined = undefined
  defaultMaxSize: number = 2097152

  setFile = (file: File | undefined, fileExtension: keyof typeof FileExtensionsTypes, maxSize: number = this.defaultMaxSize) => {
    if (file === undefined ) {
      this.selectedFile = file
    }
    else if (file.size <= maxSize) {
      if (FileExtensions[fileExtension].find(el => el === file.type)) {
        this.selectedFile = file
      } else {
        this.rootStore.materialDialogStore.setMessageViewState(true, 'Недопустимый формат файла')
      }
    }
    else {
      this.rootStore.materialDialogStore.setMessageViewState(true, 'Размер загружаемого файла слишком велик')
    }
  }

  deleteProfilePhoto = async () => {
    try {
      runInAction(() => {
        this.isDeleteFileProcess = true
      })
      await networkService.post('media/deleteProfilePhoto')
      await this.rootStore.userStore.loadUserProfile()
      runInAction(() => {
        this.isDeleteFileProcess = false
      })
      return Promise.resolve()
    } catch (e) {
      this.rootStore.materialDialogStore.setMessageViewState(true, 'Ошибка удаления файла, попробуйте позже')
      runInAction(() => {
        this.isDeleteFileProcess = false
      })
      // @ts-ignore
      return Promise.reject(e.response.data)
    }
  }

  uploadProfilePhoto = async () => {
    try {
      runInAction(() => {
        this.isUploadFileProcess = true
      })
      const convertFile = this.filePreload()
      if (convertFile !== undefined) {

        const urlData = await networkService.post('media/getUploadProfilePhotoLink', {
          fileName: convertFile.name
        })

        const storageData = await networkService1.put(urlData.data.uploadLink, convertFile, {
          onUploadProgress: (progressEvent) => {
            runInAction(() => {
              this.uploadFileProgress = Math.floor(progressEvent.loaded / progressEvent.total * 100)
            })
          },
          headers: {
            'Content-Type': convertFile.type,
          }
        })

        const {data} = await networkService.post('media/acceptProfilePhotoUploading', {
          isFileUpload: true,
          fileName: convertFile.name
        })
        await this.rootStore.userStore.loadUserProfile()
      }
      runInAction(() => {
        this.isUploadFileProcess = false
      })
      return Promise.resolve()
    } catch (e) {
      this.rootStore.materialDialogStore.setMessageViewState(true, 'Ошибка загрузки файла, попробуйте позже')
      runInAction(() => {
        this.isUploadFileProcess = false
      })
      // @ts-ignore
      return Promise.reject(e.response.data)
    }
  }

  deleteProfileCover = async () => {
    try {
      runInAction(() => {
        this.isDeleteFileProcess = true
      })
      await networkService.post('media/deleteProfileCover')
      await this.rootStore.userStore.loadUserProfile()
      runInAction(() => {
        this.isDeleteFileProcess = false
      })
      return Promise.resolve()
    } catch (e) {
      this.rootStore.materialDialogStore.setMessageViewState(true, 'Ошибка удаления файла, попробуйте позже')
      runInAction(() => {
        this.isDeleteFileProcess = false
      })
      // @ts-ignore
      return Promise.reject(e.response.data)
    }
  }

  uploadProfileCover = async () => {
    try {
      runInAction(() => {
        this.isUploadFileProcess = true
      })
      const convertFile = this.filePreload()
      if (convertFile !== undefined) {

        const urlData = await networkService.post('media/getUploadProfileCoverLink', {
          fileName: convertFile.name
        })

        const storageData = await networkService1.put(urlData.data.uploadLink, convertFile, {
          onUploadProgress: (progressEvent) => {
            runInAction(() => {
              this.uploadFileProgress = Math.floor(progressEvent.loaded / progressEvent.total * 100)
            })
          },
          headers: {
            'Content-Type': convertFile.type,
          }
        })

        const {data} = await networkService.post('media/acceptProfileCoverUploading', {
          isFileUpload: true,
          fileName: convertFile.name
        })
        await this.rootStore.userStore.loadUserProfile()
      }
      runInAction(() => {
        this.isUploadFileProcess = false
      })
      return Promise.resolve()
    } catch (e) {
      this.rootStore.materialDialogStore.setMessageViewState(true, 'Ошибка загрузки файла, попробуйте позже')
      runInAction(() => {
        this.isUploadFileProcess = false
      })
      // @ts-ignore
      return Promise.reject(e.response.data)
    }
  }

  uploadFile = async (oldFileLink: string | null = null, callback: ((newFileUrl) => void) | null = null, file: File | undefined = undefined) => {
    try {
      runInAction(() => {
        this.isUploadFileProcess = true
      })
      const convertFile = this.filePreload(file)
      if (convertFile !== undefined) {

        const urlData = await networkService.post('media/generateLinkForUploadFile', {
          fileName: convertFile.name
        })

        runInAction(() => {
          this.rootStore.materialDialogStore.setMessageViewState(true, 'Начало загрузки файла')
        })

        const storageData = await networkService1.put(urlData.data.uploadLink, convertFile, {
          onUploadProgress: (progressEvent) => {
            runInAction(() => {
              this.uploadFileProgress = Math.floor(progressEvent.loaded / progressEvent.total * 100)
            })
          },
          headers: {
            'Content-Type': convertFile.type,
          }
        })

        runInAction(() => {
          this.rootStore.materialDialogStore.setMessageViewState(true, 'Файл успешно загружен')
        })

        const {data} = await networkService.post('media/acceptUploadFile', {
          oldFileLink: oldFileLink !== null && oldFileLink?.indexOf('blob:') > -1 ? null : oldFileLink,
          fileSize: convertFile.size,
          fileName: convertFile.name
        })

        await this.rootStore.userStore.loadUserStorageData()

        runInAction(() => {
          if (callback) {
            callback(data.fileUrl)
          }
        })
      }
      runInAction(() => {
        this.isUploadFileProcess = false
      })
      return Promise.resolve()
    } catch (e) {
      this.rootStore.materialDialogStore.setMessageViewState(true, 'Ошибка загрузки файла, попробуйте позже')
      runInAction(() => {
        this.isUploadFileProcess = false
      })
      // @ts-ignore
      return Promise.reject(e.response.data)
    }
  }

  deleteFileInStorage = async (fileUrl: string, callback?: () => void) => {
    try {
      runInAction(() => {
        this.isDeleteFileProcess = true
      })
      await networkService.post('media/deleteFile', {
        fileName: fileUrl
      })
      await this.rootStore.userStore.loadUserStorageData()
      runInAction(() => {
        if (!!callback) {
          callback()
        }
        this.isDeleteFileProcess = false
      })
      return Promise.resolve()
    } catch (e) {
      this.rootStore.materialDialogStore.setMessageViewState(true, 'Ошибка удаления файла, попробуйте позже')
      runInAction(() => {
        this.isDeleteFileProcess = false
      })
      // @ts-ignore
      return Promise.reject(e.response.data)
    }
  }
  setFileBackground = async (file: File) => {
    try {
      const result = await this.getBase64(file)
      console.log(JSON.stringify(result))
    } catch (e) {
      console.log(e)
    }
  }

  testUploadVideo = async (file: File) => {
    try {
      // const tData = await networkService1.post('https://uploader.kinescope.io/v2/init', {
      //   filesize: file.size,
      //   type: 'video',
      //   title: 'Test video',
      //   parent_id: 'eyJwYXJlbnRfaWQiOiJkYWIxOGIzMC1iZmYxLTQ5MWMtYjAwZS00OGE1OGE3MzZhN2IifQ'
      // })
      const data = await networkService1.post('https://uploader.kinescope.io/v2/video', file, {
        headers: {
          'Accept': '*/*',
          'Content-Type': 'video/mp4',
          'Access-Control-Allow-Origin': '*',
          "Authorization": 'Bearer ab715047-c74b-40e3-92ee-54295daeb096',
          'X-Video-Title': 'Test video',
          'X-Video-Description': 'test desc',
          'X-File-Name': 'video.mp4',
          'X-Parent-ID': '39628da0-0e65-42ca-9aae-ce8153ebcffa',
          'X-Video-Preview': '{"start": 2, "length": 4, "quality": "720p"}'
        }
      })
    } catch (e) {
    }
  }

  getBase64 = async (file: File) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  })

  private filePreload = (file: File | undefined = this.selectedFile) => {
    this.uploadFileProgress = 0
    if (file !== undefined) {
      const blob = file.slice(0, file.size, file.type)
      return new File([blob], sha512(`${this.rootStore.userStore.userData.id}_${file.name}_${Date.now()}`), {type: file.type})
    }
    return undefined
  }
}



export interface IUploadFileStore extends UploadFileStore {
}
