import React, {CSSProperties, useEffect, useState} from 'react'
import {FormControlLabel, FormGroup, Switch, SxProps} from "@mui/material";

import {Colors, GrayScale} from "../../enums";

interface IProps {
  onChange: (value: boolean) => void,
  style?: CSSProperties | SxProps,
  checked?: boolean,
  label?: JSX.Element | string,
  disabled?: boolean,
}

export const CBToggle = ({onChange, label, disabled = false, checked = false, style}: IProps) => {
  const [isCheck, setIsCheck] = useState(checked)

  useEffect(() => {
    onChange(isCheck)
  }, [isCheck])

  const renderToggle = () => (
    <Switch
      size={"medium"}
      checked={isCheck}
      onChange={event => setIsCheck(event.target.checked)}
      sx={{
        '&.MuiSwitch-root': {
          padding: '0',
          width: '64px',
          height: '36px',
          borderRadius: '40px',
          boxSizing: 'border-box',

          '& .MuiSwitch-switchBase': {
            padding: '0',
            color: GrayScale.BACKGROUND_COLOR,
            '&.Mui-checked': {
              transform: 'translateX(28px)',
              '& + span.MuiSwitch-track': {
                backgroundColor: Colors.PRIMARY_COLOR,
              }
            },
          },

          '& .MuiSwitch-thumb': {
            padding: '0',
            margin: '2px',
            width: '32px',
            height: '32px',
            boxShadow: 'none',
          },

          '& .MuiSwitch-track': {
            width: '64px',
            height: '36px',
            backgroundColor: GrayScale.LINE_COLOR,
            opacity: 1,
          },

          '&:hover:enabled': {
            '& span.MuiSwitch-track': {
              backgroundColor: GrayScale.PLACEHOLDER_COLOR
            },
            '& .MuiSwitch-switchBase.Mui-checked + span.MuiSwitch-track': {
              backgroundColor: Colors.PRIMARY_DARK
            },
          },

          '&:has(.Mui-focusVisible)': {
            outline: '8px solid ' + GrayScale.INPUT_BACKGROUND,
          },
          '&:has(.Mui-checked.Mui-focusVisible)': {
            outline: '8px solid ' + Colors.PRIMARY_LIGHT,
          },

          '&:has(.Mui-disabled)': {
            opacity: 0.5,
          }
        }
      }}
    />
  )

  return (
    <FormGroup sx={style}>
      <FormControlLabel
        disabled={disabled}
        control={renderToggle()}
        label={label}
      />
    </FormGroup>
  )
}
