import { CategoryModel } from "../dictionaries/categories/CategoryModel"

export type CompleteAuthModal = {
  login: string | null,
  email: string | null,
  password: string | null,
  passwordRepeat: string | null,
  firstName: string,
  secondName: string,
  thirdName: string | null,
  birthDate: string,
  country: string,
  city: string,
  interests: CategoryModel[] | string | null,
}

export const initialCompleteAuthModal: CompleteAuthModal = {
  login: null,
  email: null,
  password: null,
  passwordRepeat: null,
  firstName: '',
  secondName: '',
  thirdName: '',
  birthDate: '',
  country: '',
  city: '',
  interests: '',
}
