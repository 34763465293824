import {observer} from "mobx-react-lite";
import {StyleSheet} from "../../../../utils/styles";
import {baseTextStyles} from "../../../../utils/baseTextStyles";
import {baseStyles} from "../../../../utils/baseStyles";
import {stores} from "../../../../pages/_app";
import {Alert, AlertTitle, Checkbox} from "@mui/material";
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import {SimpleInput} from "../../../baseComponents/inputs/simpleInput";

const PrivacyText = 'Данная информация по умолчанию не доступна другим пользователям'
const PublicText = 'Отображение данной информации вы сможете настроить в личном кабинете'

const SecondStep = observer(() => (
  <div>
    <div style={{...baseStyles.horizontalContainer, marginTop: 30}}>
      <SimpleInput title={'Имя'} placeholder={'Введите имя'} size={'small'}
                   onTextChange={(text) => stores.endRegistrationStore.setCompleteAuthData('firstName', text)}
                   isValid={!!stores.endRegistrationStore.completeAuthValidateData.isFirstNameValid}
                   isValidText={'Введите ваше имя'}
                   required={true} style={{marginRight: 20}}
                   helperText={PrivacyText}
                   inputText={stores.endRegistrationStore.completeAuthData.firstName || ''} stylesForToolTip={{top: 5, right: 25 }}
      />
      <SimpleInput title={'Дата рождения'} size={"small"}
                   onTextChange={(text) => stores.endRegistrationStore.setCompleteAuthData('birthDate', text)}
                   isValid={!!stores.endRegistrationStore.completeAuthValidateData.isBirthDateValid}
                   inputLabelProps={{ shrink: true }}
                   isValidText={''} helperText={PublicText} style={{flex: 1}} type={'date'}
                   inputText={stores.endRegistrationStore.completeAuthData.birthDate || ''} stylesForToolTip={{top: 5, right: 30 }}
      />
    </div>
    <div style={{...baseStyles.horizontalContainer, marginTop: 20}}>
      <SimpleInput title={'Фамилия'} placeholder={'Введите фамилию'} size={'small'}
                   onTextChange={(text) => stores.endRegistrationStore.setCompleteAuthData('secondName', text)}
                   isValid={!!stores.endRegistrationStore.completeAuthValidateData.isSecondNameValid}
                   isValidText={'Введите вашу фамилию'}
                   required={true} style={{marginRight: 20}}
                   helperText={PrivacyText}
                   inputText={stores.endRegistrationStore.completeAuthData.secondName || ''} stylesForToolTip={{top: 5, right: 25 }}
      />
      <SimpleInput title={'Страна'} placeholder={'Введите страну'} size={'small'}
                   onTextChange={(text) => stores.endRegistrationStore.setCompleteAuthData('country', text)}
                   isValid={!!stores.endRegistrationStore.completeAuthValidateData.isCountryValid}
                   isValidText={''} required={true} helperText={PublicText}
                   inputText={stores.endRegistrationStore.completeAuthData.country || ''} stylesForToolTip={{top: 5, right: 5 }}
      />
    </div>
    <div style={{...baseStyles.horizontalContainer, marginTop: 20, marginBottom: 30}}>
      <SimpleInput title={'Отчество'} placeholder={'Введите отчество'} size={'small'}
                   onTextChange={(text) => stores.endRegistrationStore.setCompleteAuthData('thirdName', text)}
                   isValid={!!stores.endRegistrationStore.completeAuthValidateData.isThirdNameValid}
                   isValidText={''}
                   required={false} style={{marginRight: 20}}
                   helperText={PrivacyText}
                   inputText={stores.endRegistrationStore.completeAuthData.thirdName || ''} stylesForToolTip={{top: 5, right: 25 }}
      />
      <SimpleInput title={'Город'} placeholder={'Введите город'} size={'small'}
                   onTextChange={(text) => stores.endRegistrationStore.setCompleteAuthData('city', text)}
                   isValid={!!stores.endRegistrationStore.completeAuthValidateData.isCityValid}
                   isValidText={''} required={true} helperText={PublicText}
                   inputText={stores.endRegistrationStore.completeAuthData.city || ''} stylesForToolTip={{top: 5, right: 5 }}
      />
    </div>
    <Alert severity="warning" icon={<ErrorOutlineOutlinedIcon sx={{color:"black"}}/>} sx={{color: "black", bgcolor: '#FFDE68'}}>
      <AlertTitle>Внимание!</AlertTitle>
      <p style={{...styles.alertDescription}}>Внимание! Для окончания некоторых сертифицированных курсов и получение
        ревалетного сертификата, в будущем потребуется другая персональная информация, например такие как СНИЛС и
        документы, подтверждающие образование. Курсы, требующие для окончания дополнительные персональные данные,
        будут содержать соответствующие данные на странице курса.</p>
    </Alert>
    <div style={{...styles.checkContainer}}>
      <Checkbox sx={{'&.Mui-checked': {
          color: '#32BD9C'
        }}} checked={stores.endRegistrationStore.completeAuthValidateData.isTermsAccept} onChange={(event) => stores.endRegistrationStore.setTermsOfUseValue(event.target.checked)}/>
      <p style={{...styles.checkTitle}}>Подтверждаю, что ознакомлен с возможным запросом дополнительных данных и коректностью и правдивостью введённых данных</p>
    </div>
  </div>
))

export default SecondStep

const styles = StyleSheet.create({
  title: {
    fontFamily: 'Montserrat',
    fontWeight: '700',
    fontSize: '24px',
    width: '60%'
  },
  description: {
    fontFamily: 'Montserrat',
    fontWeight: '400',
    fontSize: '14px',
    marginTop: '10px',
  },
  alertContainer: {
    paddingTop: 14,
    paddingBottom: 14,
    paddingLeft: 16,
    paddingRight: 16,
    backgroundColor: '#FFDE68',
    borderRadius: 4,
    ...baseStyles.horizontalContainer
  },
  alertTitle: {
    fontFamily: 'Roboto',
    fontWeight: '500',
    fontSize: '16px',
    marginLeft: 13
  },
  alertDescription: {
    fontFamily: 'Roboto',
    fontWeight: '400',
    fontSize: '14px',
    marginTop: 5,
    flexWrap: "wrap",
  },
  checkContainer: {
    ...baseStyles.horizontalContainer,
    marginLeft: '-10px',
    marginTop: '10px',
    marginBottom: '30px'
  },
  checkTitle: {
    ...baseTextStyles.montserratS13W400,
    lineHeight: 1.2,
    marginTop: 8,
    flexWrap: 'wrap'
  }
})