import {makeAutoObservable, runInAction} from "mobx";
import {IRootStore} from "./global/RootStore";
import networkService from "../services/NetworkService";
import {PublicArticleObject} from "../types/materials/article/PublicArticleObject";

export class HomeLandingStore {
  private rootStore: IRootStore

  constructor(rootStore: IRootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }
  popularArticles: PublicArticleObject[] = []
  isLoaded: boolean = false

  setIsLoaded = (value: boolean) => {
    this.isLoaded = value
  }

  loadPopularArticles = async () => {
    try {
      const {data} = await networkService.post('public/articles/getPopularArticles')
      runInAction(() => this.popularArticles = data)
      this.setIsLoaded(true)
      return Promise.resolve()
    } catch (e) {
      this.setIsLoaded(false)
      // @ts-ignore
      return Promise.reject(e.response.data)
    }
  }
}

export interface IHomeLandingStore extends HomeLandingStore {}
