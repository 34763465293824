import {observer} from "mobx-react-lite";
import {StyleSheet} from "../../../../utils/styles";
import {useEffect, useState} from "react";
import {stores} from "../../../../pages/_app";
import {LoadingButton} from "@mui/lab";
import {Box, Button, Step, StepLabel, Stepper} from "@mui/material";
import {baseStyles} from "../../../../utils/baseStyles";
import FirstStep from "./FirstStep";
import SecondStep from "./SecondStep";
import ThirdStep from "./ThirdStep";

const steps = ['Заполните данные для авторизации', 'Заполните основные данные', 'Выберите ваши интересы'];

const EndStudentRegistrationDialogItem = observer(() => {
  const [activeStep, setActiveStep] = useState<number>(null);

  useEffect(() => {
    if (!stores.materialDialogStore.isOpen)
      stores.endRegistrationStore.resetValidate()

  }, [stores.materialDialogStore.isOpen])

  useEffect(() => {
    setActiveStep(stores.endRegistrationStore.currentStep)
  }, [stores.endRegistrationStore.currentStep])

  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      stores.endRegistrationStore.completeStudentAuth().then(
        onSuccess => {
          stores.materialDialogStore.setMessageViewState(true, 'Регистрация успешно окончена')
          stores.materialDialogStore.setCurrentState(false)
        },
        onError => {
          stores.materialDialogStore.setMessageViewState(true, onError)
        }
      )
    } else
      setActiveStep((prevActiveStep) => (prevActiveStep || 0) + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => (prevActiveStep || 0) - 1);
  };

  const getStepView = () => {
    switch (activeStep) {
      case 0:
        return <FirstStep/>
      case 1:
        return <SecondStep/>
      case 2:
        return <ThirdStep/>
      default:
        return <></>
    }
  }

  const isNotDisabled = () => {
    if (activeStep === steps.length - 1)
      return stores.endRegistrationStore.completeAuthValidateData.isInterestsValid
    if (activeStep === steps.length - 2)
      return stores.endRegistrationStore.getValidateEndRegistrationSecondStep()
    if (activeStep === steps.length - 3)
      return stores.endRegistrationStore.getValidateEndRegistrationFirstStep()
  }

  return (
    <div style={{margin: '30px', width: '800px'}}>
      <p style={styles.title}>Осталось всего пару моментов перед использованием сервиса</p>
      <p style={styles.description}>Завершите регистрацию для доступа к сервису.</p>
      <Box sx={{width: '100%', marginTop: '30px'}}>
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps: { completed?: boolean, } = {};
            const labelProps: {
              optional?: React.ReactNode;
            } = {};

            return (
              <Step key={label} {...stepProps}
                    sx={{
                      '.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active':
                        {
                          color: '#32BD9C'
                        },
                      '.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed': {
                        color: '#32BD9C'
                      },
                    }}
              >
                <StepLabel {...labelProps} >{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        {getStepView()}
      </Box>
      <div style={{width: '100%', ...baseStyles.horizontalContainer, marginTop: '20px'}}>
        <Button
          variant={"outlined"}
          children={"Назад"}
          disabled={activeStep === 0}
          onClick={handleBack}
          style={{flex: 1}}
          size={"small"}
          sx={{marginRight: '15px', color: '#32BD9C', borderColor: '#32BD9C', '&:hover': {borderColor: '#32BD9C'}}}
        />
        <LoadingButton
          disabled={!isNotDisabled()}
          loading={stores.endRegistrationStore.isCompleteAuthLoadingProcess}
          loadingIndicator={'Создание...'}
          variant={"contained"}
          children={activeStep === steps.length - 1 ? "Завершить регистрацию" : "Далее"}
          onClick={handleNext}
          style={{flex: 2}}
          size={"small"}
          sx={{bgcolor: '#32BD9C', '&:hover': {bgcolor: '#32BD9C'}}}
        />
      </div>
    </div>
  )
})

export default EndStudentRegistrationDialogItem

const styles = StyleSheet.create({
  title: {
    fontFamily: 'Montserrat',
    fontWeight: '700',
    fontSize: '24px',
    width: '100%'
  },
  description: {
    fontFamily: 'Montserrat',
    fontWeight: '400',
    fontSize: '14px',
    marginTop: '10px',
  }
})