export type AuthDataValidateModel = {
  passwordLength: boolean,
  passwordRegister: boolean,
  passwordSymbols: boolean,
  passwordNumber: boolean,
  passwordEqual: boolean,
  passwordLatin: boolean,
  isTermsAccept?: boolean,
  isLoginValid?: boolean,
  isFirstNameValid: boolean,
  isSecondNameValid: boolean,
  isEmailValid?: boolean
}

export const initAuthDataValidateModel: AuthDataValidateModel = {
  passwordLength: false,
  passwordRegister: false,
  passwordSymbols: false,
  passwordNumber: false,
  passwordEqual: false,
  passwordLatin: false,
  isTermsAccept: false,
  isLoginValid: false,
  isFirstNameValid: false,
  isSecondNameValid: false,
  isEmailValid: false
}
