import {makeAutoObservable, runInAction} from "mobx";
import {IRootStore} from "./global/RootStore";
import {PublicArticleObject} from "../types/materials/article/PublicArticleObject";
import networkService, {networkService1} from "../services/NetworkService";

export class MarketplaceArticlePageStore {
  private rootStore: IRootStore

  constructor(rootStore: IRootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  article: PublicArticleObject | undefined = undefined
  articleLoadingProcess: boolean = false
  isRatingSet: boolean = false
  ratingUserValue: number = 0

  clearCurrentArticle = () => {
    this.article = undefined
  }

  clearRatingData = () => {
    this.ratingUserValue = 0
    this.isRatingSet = false
  }

  putRatingValue = async (ratingValue: number | null) => {
    try {
      const url = ratingValue === null
        ? 'public/articles/deleteRatingForArticleByUser'
        : 'public/articles/setRatingForArticleByUser'
      const {data} = await networkService.post(url, {
        [ratingValue === null ? 'articleId' : 'materialId']: this.article?.id,
        ratingValue: ratingValue
      })
      runInAction(() => {
        this.isRatingSet = data.isRatingPut
        this.ratingUserValue = data.ratingValue
      })
      await this.loadArticle(null, false)
      return Promise.resolve()
    } catch (e) {
      // @ts-ignore
      return Promise.reject(e.response.data)
    }
  }

  loadRatingInfo = async () => {
    if (!this.rootStore.authStore.isUserAuth)
      return

    try {
      const {data} = await networkService.post('public/articles/getRatingInfoForCurrentArticleByUser', {
        articleId: this.article?.id
      })
      runInAction(() => {
        this.isRatingSet = data.isRatingPut
        this.ratingUserValue = data.ratingValue
      })
      return Promise.resolve()
    } catch (e) {
      // @ts-ignore
      return Promise.reject(e.response.data)
    }
  }

  get isRatingCanBeSet() {
    return this.rootStore.authStore.isUserAuth && this.article?.author.id !== this.rootStore.userStore.userData.id
  }

  loadArticle = async (articleId: number | null = null, withLoadingPage: boolean = true) => {
    runInAction(() => {
      if (withLoadingPage)
        this.articleLoadingProcess = true
    })
    try {
      //TODO поправить на беке название
      const {data} = await networkService.post('public/articles/getArticleByArtcileId', {articleId: articleId || this.article?.id})

      if (data === undefined) {
        return Promise.reject('data is undefined')
      }

      await this.addNewView(articleId || this.article?.id)
      runInAction(() => {
        this.article = data
      })
      await this.loadRatingInfo()
      runInAction(() => {
        this.articleLoadingProcess = false
      })
      return Promise.resolve(data)
    } catch (e) {
      console.log(e)
      // @ts-ignore
      return Promise.reject(e.response.data)
    }
  }

  addNewView = async (articleId: number | undefined) => {
    if (articleId === undefined)
      return

    try {
      const ipData = await networkService1.get('https://ipinfo.io?token=333517aa2fa0a4')
      if (ipData.data.ip !== undefined && ipData.data.ip !== null) {
        const {data} = await networkService.post('public/articles/addViewByArticleId', {
          ip: ipData.data.ip,
          articleId: articleId
        })
      }
      return Promise.resolve()
    } catch (e) {
      // @ts-ignore
      return Promise.reject(e.response.data)
    }
  }
}

export interface IMarketplaceArticlePageStore extends MarketplaceArticlePageStore {}
