import {observer} from "mobx-react-lite";
import {generate} from "@pdfme/generator";
import {stores} from "../../../pages/_app";
import {useEffect, useRef} from "react";
import {Designer} from "@pdfme/ui";
import {baseStyles} from "../../../utils/baseStyles";
import { Button } from "@mui/material";
import ImageIcon from '@mui/icons-material/Image';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {FileExtensions} from "../../../stores/UploadFileStore";

const CreateCertificate = observer(() => {
  const designerRef = useRef<HTMLDivElement | null>(null);
  const designer = useRef<Designer | null>(null);

  useEffect(() => {
    stores.createCertificateStore.getCertificateTemplate()
    if (designerRef.current && stores.createCertificateStore.template !== undefined) {
      designer.current = new Designer({ domContainer: designerRef.current, template: stores.createCertificateStore.template});
      designer.current.onChangeTemplate((value) => {
        stores.createCertificateStore.setTemplate(value)
        stores.createCertificateStore.saveJsonFile()
      });
    }
    return () => {
      designer.current?.destroy();
    };
  }, [designerRef]);

  const cloneDeep = (value) => JSON.parse(JSON.stringify(value))

  const readFile = (file: File | null, type: 'text' | 'dataURL' | 'arrayBuffer') => {
    return new Promise<string | ArrayBuffer>((r) => {
      const fileReader = new FileReader();
      fileReader.addEventListener('load', (e) => {
        if (e && e.target && e.target.result && file !== null) {
          r(e.target.result);
        }
      });
      if (file !== null) {
        if (type === 'text') {
          fileReader.readAsText(file);
        } else if (type === 'dataURL') {
          fileReader.readAsDataURL(file);
        } else if (type === 'arrayBuffer') {
          fileReader.readAsArrayBuffer(file);
        }
      }
    });
  };


  const changeBasePdf = (file: File) => {
    if (designer.current && FileExtensions.uploadDocumentDefault.find(el => el === file.type)) {
      readFile(file, 'dataURL').then(async (basePdf: string | ArrayBuffer) => {
        designer?.current?.updateTemplate(Object.assign(cloneDeep(stores.createCertificateStore.template), { basePdf }));
      });
    } else {
      stores.materialDialogStore.setMessageViewState(true, 'Недопустимый формат файла. Необходимое расширение .pdf')
    }
  };

  const onGeneratePDF = async () => {
    if (designer.current){
      const template = designer.current.getTemplate()
      const inputs = template.sampledata ?? []
      const pdf = await generate({template, inputs})
      const blob = new Blob([pdf.buffer],{type:"application/pdf"})
      window.open(URL.createObjectURL(blob))
    }
  }

  return (
    <div>
      <div style={{...baseStyles.horizontalContainer, marginTop: '20px'}}>
        <Button
          variant={"outlined"}
          style={{width: '100%'}}
          size={"small"}
          component="label"
          sx={{color: '#32BD9C', borderColor: '#32BD9C', '&:hover': { color: '#308670', borderColor: '#308670' }}}
          startIcon={<ImageIcon sx={{marginTop: "-3px"}}/>}
        >
          Выбрать фон
          <input
            type="file"
            accept={'application/pdf'}
            onChange={(e) => {
              if (e.target && e.target.files) {
                changeBasePdf(e.target.files[0]);
              }}}
            onClick={(e) => {
              e.currentTarget.value = '';
            }}
            hidden
          />
        </Button>
        <Button variant={"outlined"}
                style={{width: '100%'}}
                size={"small"}
                component="label"
                startIcon={<DownloadIcon sx={{marginTop: "-3px"}}/>}
                sx={{color: '#32BD9C', borderColor: '#32BD9C', '&:hover': { color: '#308670', borderColor: '#308670' }}}
                onClick={onGeneratePDF} children={'Скачать шаблон'}/>
        <Button variant={"outlined"}
                style={{width: '100%'}}
                size={"small"}
                component="label"
                startIcon={<DeleteForeverIcon sx={{marginTop: "-3px"}}/>}
                sx={{color: '#FE594E', borderColor: '#FE594E', '&:hover': {color:'#ff9a93', borderColor: '#ff9a93' }}}
                children={'Удалить'} onClick={() => stores?.courseEditViewStore.changeCourseData('newCertificateTemplate', 'delete')}/>
      </div>
      <div style={{marginTop: '10px'}} ref={designerRef}/>
    </div>
  )
})

export default CreateCertificate
