import {ShortCourseObject} from "../materials/course/ShortCourseObject";
import {UserInGroup} from "./UserInGroup";

export type UserGroup = {
  id: number | null,
  title: string | null,
  users: UserInGroup[] | null,
  usersCount: number | null,
  course: ShortCourseObject | null,
  isBinding: boolean | null
}

export type DefaultAndBindingGroups = {
  bindingGroups: UserGroup[] | undefined,
  customGroups: UserGroup[] | undefined
}

export const initDefaultAndBindingGroups: DefaultAndBindingGroups = {
  bindingGroups: undefined,
  customGroups: undefined
}