import {Box, SxProps} from '@mui/material';
import React, {CSSProperties} from 'react';
import {CBBadge} from '../../baseComponent/badge';
import {CBTooltip} from '../../baseComponent/tooltip';
import {CategoriesType} from '../../enums';
import {stores} from "../../../pages/_app";

interface IProps {
  categories: null | Array<string>,
  type?: CategoriesType,
  style?: SxProps,
  onClick?: (index: number) => void,
  canBeClosed?: boolean,
  onClose?: (value: string) => void,
  isSelected?: (index: number) => boolean,
}

const CBCategories = ({categories, type=CategoriesType.DEFAULT, style, onClick, canBeClosed, onClose, isSelected}: IProps) => {
  const normalizeCategory = (category: string): string => {
    return category?.length > 10 ? category?.slice(0, 10) + '...' : category;
  }

  const renderCategories = () => {
    switch (type) {
      case CategoriesType.DEFAULT: {
        return categories.map((category, index) => (
          <CBTooltip title={category} key={category + index}>
            <div style={{width: 'fit-content', marginRight: '10px', marginBottom: '10px', whiteSpace: 'nowrap'}}>
              <CBBadge
                selected={isSelected ? isSelected(index) : true}
                canBeClosed={canBeClosed}
                onClick={onClick ? () => onClick(index) : undefined}
                onClose={() => onClose(category)}
                text={normalizeCategory(category)} />
            </div>
          </CBTooltip>
        ))
      }

      case CategoriesType.SHORT: {
        const visibleCategories = categories
          .filter((value, index) => {return index < 2})
          .map((category, index) => (
            <CBTooltip title={category} key={category + index}>
              <div style={{width: 'fit-content', marginRight: '10px', marginBottom: '10px', whiteSpace: 'nowrap'}}>
                <CBBadge text={normalizeCategory(category)} />
              </div>
            </CBTooltip>
          ))

        const hideCategories = categories.filter((value, index) => {return index >= 2})
        const numbericCategories = (
          <CBTooltip title={hideCategories.join(', ')} key={hideCategories.join(', ')}>
            <div style={{width: 'fit-content', marginRight: '10px', marginBottom: '10px', whiteSpace: 'nowrap'}}>
              <CBBadge text={'+' + hideCategories.length} />
            </div>
          </CBTooltip>
        )

        return [...visibleCategories, hideCategories.length > 0 ? numbericCategories : []]
      }

      case CategoriesType.MINIMAL: {
        const visibleCategories = categories
          .filter((value, index) => {return index < 1})
          .map((category, index) => (
            <CBTooltip title={category} key={category + index}>
              <div style={{width: 'fit-content', marginRight: '10px', marginBottom: '10px', whiteSpace: 'nowrap'}}>
                <CBBadge text={normalizeCategory(category)} />
              </div>
            </CBTooltip>
          ))

        const hideCategories = categories.filter((value, index) => {return index >= 1})
        const numbericCategories = (
          <CBTooltip title={hideCategories.join(', ')} key={hideCategories.join(', ')}>
            <div style={{width: 'fit-content', marginRight: '10px', marginBottom: '10px'}}>
              <CBBadge text={'+' + hideCategories.length} />
            </div>
          </CBTooltip>
        )

        const isMinTabletCategories = categories
          .filter((value, index) => {return index < 1})
          .map((category, index) => (
            <CBTooltip title={category + ', ' + hideCategories.join(', ')} key={category + index}>
              <div style={{width: 'fit-content', marginRight: '10px', whiteSpace: 'nowrap'}}>
                <CBBadge isMinDisplayStyle={{fontSize: !stores.adaptiveService.isMinTablet ? '12px' : 'initial'}} style={{padding: `5px ${!stores.adaptiveService.isMinTablet ? '10px' : 'initial'}`}} text={normalizeCategory(category) + ' +' + hideCategories.length} />
              </div>
            </CBTooltip>
          ))

        if (!stores.adaptiveService.isMinTablet) {
          return [...isMinTabletCategories]
        }

        return [...visibleCategories, numbericCategories]
      }
    }
  }

  return (
    categories &&
    <Box sx={{
      ...style,
      display: 'flex',
    }}>
      {renderCategories()}
    </Box>
  )
}

export default CBCategories
