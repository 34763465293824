import {StyleSheet} from "../../../utils/styles";
import {LabelOutlined, LocalOfferOutlined} from "@mui/icons-material";
import CBText from "../text";
import {Colors, FontStyle, GrayScale} from "../../enums";
import {useMemo} from "react";
import {baseStyles} from "../../../utils/baseStyles";
import {CSSProperties} from "styled-components";
import {ButtonBase} from "@mui/material";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

interface IProps {
  text: string,
  disable?: boolean,
  onClick?: () => void,
  style?: CSSProperties,
  canBeClosed?: boolean,
  onClose?: () => void,
  selected?: boolean,
  isMinDisplayStyle?: CSSProperties,
}

export const CBBadge = ({text, disable = false, onClick, canBeClosed, onClose, style, selected = true, isMinDisplayStyle}: IProps) => {

  const getColor = useMemo(() => {
    return !disable ? Colors.PRIMARY_COLOR : GrayScale.PLACEHOLDER_COLOR
  }, [disable])

  const renderBadge = () => (
    <div style={{...styles.container, ...(disable ? {} : styles.enableContainer), ...(selected ? {} : styles.notSelected) ,...style}}>
      <LocalOfferOutlined sx={{color: selected ? getColor : GrayScale.PLACEHOLDER_COLOR, marginRight: '5px', width: '20px', height: '20px'}}/>
      <CBText style={isMinDisplayStyle} children={text} fontStyle={FontStyle.LINKXSMALL} color={selected ? getColor : GrayScale.PLACEHOLDER_COLOR} />
      {canBeClosed && 
        <ButtonBase onClick={onClose} >
          <CancelOutlinedIcon sx={{marginLeft: '5px', color: GrayScale.PLACEHOLDER_COLOR}}/>
        </ButtonBase>
      }
    </div>
  )

  return !!onClick
    ? (
      <ButtonBase sx={{borderRadius: '30px'}} onClick={onClick}>
        {renderBadge()}
      </ButtonBase>
    )
    : renderBadge()
}

const styles = StyleSheet.create({
  container: {
    ...baseStyles.horizontalContainer,
    ...baseStyles.centerOnVertical,
    justifyContent: 'space-between',
    padding: '5px 15px',
    borderRadius: '30px',
    background: Colors.PRIMARY_LIGHT,
    boxSizing: 'border-box',
    border: `1px solid`,
    borderColor: 'transparent'
  },
  enableContainer: {
    borderColor: Colors.PRIMARY_COLOR
  },
  notSelected: {
    background: Colors.SUCCESS_LIGHT,
    border: `1px solid ${Colors.SUCCESS_LIGHT}`,
  },
})
