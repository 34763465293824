import {makeAutoObservable, set} from "mobx";
import {IRootStore} from "../stores/global/RootStore";
import {useWindow} from "../utils/useWindow";

export enum BreakPoints {
  Desktop = 1440,
  DesktopMini = 1000,
  Tablet = 768,
  Mobile = 360,
}

export class AdaptiveService {
  ui = {
    width: BreakPoints.Desktop
  }
  listeners: (() => void)[] = []

  private rootStore: IRootStore
  constructor(rootStore: IRootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  get horizontalMarginInPage() {
    if (this.isDesktop)
      return '70px'
    if (this.isDesktopMini)
      return '80px'
    if (this.isTablet)
      return '20px'
    return '10px'
  }

  get horizontalMarginInPageNumber() {
    if (this.isDesktop)
      return 70
    if (this.isDesktopMini)
      return 80
    if (this.isTablet)
      return 20
    return 10
  }

  get getDeviceType() {
    if (this.ui.width >= BreakPoints.Desktop) {
      return BreakPoints.Desktop
    } else if (this.ui.width >= BreakPoints.DesktopMini) {
      return BreakPoints.DesktopMini
    } else if (this.ui.width >= BreakPoints.Tablet) {
      return BreakPoints.Tablet
    } else {
      return BreakPoints.Mobile
    }
  }

  get isDesktop() {
    return this.getDeviceType === BreakPoints.Desktop
  }

  get isDesktopMini() {
    return this.getDeviceType === BreakPoints.DesktopMini
  }

  get isTablet() {
    return this.getDeviceType === BreakPoints.Tablet
  }

  get isMobile() {
    return this.getDeviceType === BreakPoints.Mobile
  }

  get isMinDesktop() {
    return this.getDeviceType >= BreakPoints.Desktop
  }

  get isMinDesktopMini() {
    return this.getDeviceType >= BreakPoints.DesktopMini
  }

  get isMinTablet() {
    return this.getDeviceType >= BreakPoints.Tablet
  }

  setListener = (listener: () => void) => {
    if (this.listeners.indexOf(listener) === -1) {
      this.listeners.push(listener)
      window.addEventListener('resize', listener)
    }
  }

  removeListener = (listener: () => void) => {
    if (this.listeners.indexOf(listener) !== -1) {
      this.listeners.splice(this.listeners.indexOf(listener), 1)
      window.removeEventListener('resize', listener)
    }
  }

  removeAllListeners = () => {
    this.listeners.forEach(listener => {
      this.removeListener(listener)
    })
  }

  public subscribeOnResizingView = () => {
    const interval = setInterval(() => {
      if (typeof window !== "undefined")
        this.setListener(this.handleSubscribeWindowWidth)
    }, 100)
  }

  public unsubscribeOnResizingView = () => {
    const interval = setInterval(() => {
      if (typeof window !== "undefined")
        this.removeListener(this.handleSubscribeWindowWidth)
    }, 100)
  }

  handleSubscribeWindowWidth = () => {
    this.ui.width = typeof window !== "undefined" ? window.innerWidth : BreakPoints.Desktop
  }
}

export interface IAdaptiveService extends AdaptiveService {
}
