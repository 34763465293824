export type UserPrivacySettingsModel = {
  privateFio: boolean,
  privateAge: boolean,
  privateEmail: boolean,
  privateCity: boolean
}

export const initUserPrivacySettingsModel: UserPrivacySettingsModel = {
  privateFio: true,
  privateAge: true,
  privateEmail: true,
  privateCity: true
}
