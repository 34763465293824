import {StyleSheet} from "../../../utils/styles";

export const styles = StyleSheet.create({
  container: {
    borderRadius: 5,
    width: 110,
    height: 25,
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center"
  },
  text: {
    fontFamily: 'Montserrat',
    fontWeight: 500,
    fontSize: 11,
    lineHeight: 1,
    marginRight: 10,
    marginLeft: 10
  }
})
