import {observer} from "mobx-react-lite";
import {stores} from "../../../pages/_app";
import {DialogActions, DialogContent, Stack} from "@mui/material";
import {useUnload} from "../../../utils/useUnload";
import CBButton from "../../../uikit/baseComponent/buttons/mainButton";
import {Colors} from "../../../uikit/enums";
import React from "react";
import {StyleSheet} from "../../../utils/styles";
import {CBToggle} from "../../../uikit/baseComponent/toggle";
import {defaultFontStyles} from "../../../uikit/fontStyle";

const PrivacyUserSetting = observer(() => {
  useUnload(stores.userStore.isUserPrivacySettingsChanged)

  const handleClose = () => {
    if (stores.userStore.isUserPrivacySettingsChanged) {
      if (!window.confirm("Возможно, внесенные изменения не сохранятся.")) return
      stores.materialDialogStore.setCurrentState(false);
      stores.userStore.cancelUserPrivacySettingsBufferChanges();
      stores.userStore.loadUserProfile()
    } else {
      stores.materialDialogStore.setCurrentState(false);
    }
  }

  return(
    <Stack height='100%'>
      <DialogContent sx={styles.content}>
        <Stack rowGap='30px'>
          <CBToggle style={styles.toggle} checked={stores.userStore.userPrivacySettingsBuffer.privateFio} onChange={(value) => stores.userStore.setUserPrivacySettings("privateFio", value)} label='Скрывать ФИО от других пользователей'/>
          <CBToggle style={styles.toggle} checked={stores.userStore.userPrivacySettingsBuffer.privateAge} onChange={(value) => stores.userStore.setUserPrivacySettings("privateAge", value)} label='Скрывать возраст от других пользователей'/>
          <CBToggle style={styles.toggle} checked={stores.userStore.userPrivacySettingsBuffer.privateEmail} onChange={(value) => stores.userStore.setUserPrivacySettings("privateEmail", value)} label='Скрывать почту от других пользователей'/>
          <CBToggle style={styles.toggle} checked={stores.userStore.userPrivacySettingsBuffer.privateCity} onChange={(value) => stores.userStore.setUserPrivacySettings("privateCity", value)} label='Скрывать город от других пользователей'/>
        </Stack>
      </DialogContent>

      <div style={{flexGrow: 1}}/>

      <DialogActions sx={{...styles.actions, ...(!stores.adaptiveService.isMinDesktopMini && {flexWrap: 'wrap', rowGap: '20px', width: '100%', padding: '0', marginTop: '60px'})}}>
        <CBButton
          style={{...styles.button, ...(!stores.adaptiveService.isMinDesktopMini && {width: '100%', margin: '0 !important'})}}
          loading={stores.userStore.isUserPrivacySettingsProcess}
          disabled={!stores.userStore.isUserPrivacySettingsChanged}
          children='Сохранить'
          onClick={() => {
            stores.userStore.saveUserPrivacySettings().then(
              onSuccess => {

              },
              onError => {

              }
            )
          }}
        />
        <CBButton
          style={{...styles.button, ...(!stores.adaptiveService.isMinDesktopMini && {width: '100%', margin: '0 !important'})}}
          disabled={stores.userStore.isUserPrivacySettingsProcess}
          children='Отменить'
          onClick={handleClose}
          background={Colors.ERROR}
          backgroundHover={Colors.ERROR_DARK}
        />
      </DialogActions>
    </Stack>
  )
})

const styles = StyleSheet.create({
  toggle: {
    '& label':
      {
        flexDirection: 'row-reverse',
        justifyContent: 'space-between',
        marginLeft: '0'
      },
    '& label > .MuiTypography-root': {
      ...defaultFontStyles.textMedium
    }
  },
  content: {
    width: '100%',
    padding: '0'
  },
  actions: {
    columnGap: '20px',
  },
  button: {
    width: '209px'
  }
})

export default PrivacyUserSetting