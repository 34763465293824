import {observer} from "mobx-react-lite";
import {ButtonBase, Card, Modal} from "@mui/material";
import {useEffect} from "react";
import {stores} from "../../../pages/_app";
import {BaseProfileView} from "../profileView/baseProfileView";
import {baseStyles} from "../../../utils/baseStyles";
import {
  CollectionsBookmarkOutlined,
  CoPresentOutlined,
  ExitToAppOutlined,
  HomeOutlined,
  SettingsOutlined,
  SpatialAudioOffOutlined,
  SupportAgentOutlined,
  SvgIconComponent
} from "@mui/icons-material";
import {useRouter} from "next/router";
import {Routers} from "../../../utils/Routers";
import {PageProfileNames} from "../../../stores/MenuProfileStore";
import {DialogType} from "../../MaterialDialog/MaterialDialogStore";
import {PageBitoruimNames} from "../../../stores/bitoriumMenuStore";
import Link from "next/link";
import {TypeOfAuth} from "../../../types/auth/TypeOfAuth";
import {HeaderUnderlineButton} from "../headerUnderlineButton";
import {StyleSheet} from "../../../utils/styles";
import {PageNames} from "../../../stores/menuHeaderStore";

interface IProps {
  onClose: () => void,
  isOpen: boolean,
}

type MenuButton = {
  icon: SvgIconComponent,
  title: string,
  onClick: () => void
}

type FullMenuButton = {
  icon: SvgIconComponent,
  title: string,
  router?: string,
  onClick?: () => void,
  isSelect?: boolean
}

export const ExpandMenu = observer(({onClose, isOpen}: IProps) => {

    const router = useRouter()

    const menuButtons: MenuButton[] = [
      {
        icon: SettingsOutlined,
        title: 'Настройки аккаунта',
        onClick: () => {
          stores.materialDialogStore.setCurrentState(true, DialogType.userSettings)
          onClose()
        }
      },
      {
        icon: SpatialAudioOffOutlined,
        title: 'Панель автора',
        onClick: () => {
          router.push(Routers.PERSONAL_PROFILE_WITH_PARAMETER.replace(':pageType', PageProfileNames.authorCourses))
          onClose()
        }
      },
      {
        icon: CoPresentOutlined,
        title: 'Профиль студента',
        onClick: () => {
          router.push(Routers.PERSONAL_PROFILE_WITH_PARAMETER.replace(':pageType', PageProfileNames.courses))
          onClose()
        }
      }
    ]

    const fullMenuButtons: FullMenuButton[] = [
      {
        icon: HomeOutlined,
        title: 'О проекте',
        router: Routers.MARKETPLACE_HOME_LANDING,
        isSelect: stores.menuHeaderStore.currentPage === PageNames.about
      },
      {
        icon: SupportAgentOutlined,
        title: 'Поддержка',
        onClick: () => {
          onClose()
          stores.materialDialogStore.setCurrentState(true, DialogType.supportDialog)
        }
      },
      {
        icon: CollectionsBookmarkOutlined,
        title: 'Биторий',
        router: Routers.MARKETPLACE_BITORIUM_WITH_PARAMETER.replace(':pageType', PageBitoruimNames.articles),
        isSelect: stores.menuHeaderStore.currentPage === PageNames.bitorium
      }
    ]

    const handleScroll = () => {
      onClose()
    }

    const MenuItem = (item: {
      title: string,
      onClick: () => void,
      icon: SvgIconComponent,
      isSelect?: boolean
    }) => (
      <ButtonBase sx={{
        ...baseStyles.horizontalContainer,
        ...baseStyles.centeredView,
        justifyContent: 'flex-start',
        width: '100%',
        height: '40px',
        '&:hover': {
          bgcolor: '#EEEEEE'
        }
      }} onClick={item.onClick} key={item.title}>
        <item.icon sx={{
          color: item.isSelect ?? false ? '#32CAB8' : '#494949',
          marginRight: '10px',
          marginLeft: '20px'
        }}/>
        <p style={{
          marginRight: '20px',
          fontFamily: 'Roboto',
          fontWeight: '400',
          fontSize: '16px',
          color: item.isSelect ?? false ? '#32CAB8' : '#494949'
        }}>{item.title}</p>
      </ButtonBase>
    )

    const renderFullMenu = () => (
      <div style={{
        marginBottom: '30px'
      }}>
        <div style={{border: '0.2px solid #eeeeee', marginBottom: '30px'}}/>
        {
          fullMenuButtons.map(item => {
            return !!item.onClick
              ? <MenuItem onClick={item.onClick} icon={item.icon} title={item.title} isSelect={item.isSelect}/>
              : <Link href={item.router}><MenuItem onClick={() => onClose()} icon={item.icon} title={item.title} isSelect={item.isSelect}/></Link>
          })
        }
      </div>
    )

    const renderProfileMenu = () => {
      return stores.authStore.isUserAuth
        ? (
          <>
            <BaseProfileView isDarkTheme={false} style={{
              padding: '10px 20px 20px 20px',
            }}/>
            <div style={{
              flex: 1,
            }}>
              {
                menuButtons.map(item => (
                  <MenuItem onClick={item.onClick} icon={item.icon} title={item.title}/>
                ))
              }
              <ButtonBase sx={{
                ...baseStyles.horizontalContainer,
                ...baseStyles.centeredView,
                justifyContent: 'flex-start',
                width: '100%',
                height: '40px',
                marginTop: '20px',
                marginBottom: '20px',
                '&:hover': {
                  bgcolor: '#EEEEEE'
                }
              }} onClick={() => {
                stores.authStore.logout()
                onClose()
                router.push(Routers.MARKETPLACE_HOME_LANDING)
              }}>
                <ExitToAppOutlined sx={{
                  color: '#353333',
                  marginRight: '10px',
                  marginLeft: '20px'
                }}/>
                <p style={{
                  marginRight: '20px',
                  fontFamily: 'Roboto',
                  fontWeight: '400',
                  fontSize: '16px'
                }}>Выход</p>
              </ButtonBase>
            </div>
          </>
        )
        : (
          <HeaderUnderlineButton
            buttonProps={{
              variant: "contained",
              sx: styles.containedButton,
              children: 'АВТОРИЗАЦИЯ',
              onClick: () => {
                onClose()
                stores.menuHeaderStore.setIsAuthShow(TypeOfAuth.signIn, true)
              }
            }}
            isSelected={false}
          />
        )
    }

    useEffect(() => {
      window.addEventListener('scroll', handleScroll)
      return () => window.removeEventListener('scroll', handleScroll)
    }, [])

    return (
      <Modal open={isOpen} onClose={() => onClose()} componentsProps={{
        backdrop: {
          style: {
            backgroundColor: 'transparent'
          }
        }
      }} disableScrollLock={true}>
        <Card style={{
          backgroundColor: 'white',
          borderRadius: '10px',
          width: '280px',
          position: 'absolute',
          right: !stores.adaptiveService.isMinDesktopMini ? '30px' : stores.adaptiveService.horizontalMarginInPage,
          marginRight: '-5px',
          top: '6px',
          ...baseStyles.verticalContainer
        }}>
          {
            renderProfileMenu()
          }
          {
            !stores.adaptiveService.isMinDesktopMini
              && renderFullMenu()
          }

        </Card>
      </Modal>
    )
  }
)

const styles = StyleSheet.create({
  containedButton: {
    background: 'linear-gradient(90deg, #84EE9B -29.53%, #32CAB8 68.75%)',
    color: '#ffffff',
    '&:disabled': {
      background: 'linear-gradient(90deg, rgba(132, 238, 155, 0.4) -29.53%, rgba(50, 202, 184, 0.4) 68.75%);'
    },
    '&:hover': {
      color: '#285740'
    },
    margin: '20px 0',
    width: '250px'
  },
})