import React from "react";
import {observer} from "mobx-react-lite";
import {AuthDataValidateModel} from "../../../types/auth/AuthDataValidateModel";
import Image from 'next/image'
import CBText from "../../../uikit/baseComponent/text";
import {Colors, FontStyle, GrayScale} from "../../../uikit/enums";
import {Stack} from "@mui/material";

const TrueIcon: string = '/icons/true-false/true.svg';
const FalseIcon: string = '/icons/true-false/false.svg';

interface IProps {
  style?: object,
  observableValue: AuthDataValidateModel | undefined
}

export const ValidationPasswordInfo = observer(({style, observableValue}: IProps) => {

  return (
    <Stack style={style} rowGap='10px'>
      <CBText fontStyle={FontStyle.SMALL} color={GrayScale.TITLE_ADDITIONAL_COLOR} children='Требования к паролю'/>
      <Stack>
        <Stack direction='row' columnGap='10px'>
          <Image src={observableValue?.passwordLength ? TrueIcon : FalseIcon} style={{width: 10}} width={10} height={10}/>
          <CBText fontStyle={FontStyle.XSMALL} color={observableValue?.passwordLength ? Colors.SECONDARY_SUCCESS : Colors.SECONDARY_ERROR}>Пароль должен содержать минимум 6 символов</CBText>
        </Stack>
        <Stack direction='row' columnGap='10px'>
          <Image src={observableValue?.passwordLatin ? TrueIcon : FalseIcon} style={{width: 10}} width={10} height={10}/>
          <CBText fontStyle={FontStyle.XSMALL} color={observableValue?.passwordLatin ? Colors.SECONDARY_SUCCESS : Colors.SECONDARY_ERROR}>Только буквы латинского алфавита</CBText>
        </Stack>
        <Stack direction='row' columnGap='10px'>
          <Image src={observableValue?.passwordRegister ? TrueIcon : FalseIcon} style={{width: 10}} width={10} height={10}/>
          <CBText fontStyle={FontStyle.XSMALL} color={observableValue?.passwordRegister ? Colors.SECONDARY_SUCCESS : Colors.SECONDARY_ERROR}>Использование верхнего и нижнего регистра</CBText>
        </Stack>
        <Stack direction='row' columnGap='10px'>
          <Image src={observableValue?.passwordSymbols ? TrueIcon : FalseIcon} style={{width: 10}} width={10} height={10}/>
          <CBText fontStyle={FontStyle.XSMALL} color={observableValue?.passwordSymbols ? Colors.SECONDARY_SUCCESS : Colors.SECONDARY_ERROR}>Использование символов</CBText>
        </Stack>
        <Stack direction='row' columnGap='10px'>
          <Image src={observableValue?.passwordNumber ? TrueIcon : FalseIcon} style={{width: 10}} width={10} height={10}/>
          <CBText fontStyle={FontStyle.XSMALL} color={observableValue?.passwordNumber ? Colors.SECONDARY_SUCCESS : Colors.SECONDARY_ERROR}>Использование цифр</CBText>
        </Stack>
        <Stack direction='row' columnGap='10px'>
          <Image src={observableValue?.passwordEqual ? TrueIcon : FalseIcon} style={{width: 10, height: 10}} width={10} height={10}/>
          <CBText fontStyle={FontStyle.XSMALL} color={observableValue?.passwordEqual ? Colors.SECONDARY_SUCCESS : Colors.SECONDARY_ERROR}>Введённые пароли совпадают</CBText>
        </Stack>
      </Stack>
    </Stack>
  )
})
