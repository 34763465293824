import {makeAutoObservable, runInAction} from "mobx";
import {initialStructure, StructureType} from "../types/materials/StructureType";
import {PageChangeType} from "../types/materials/PageChangeType";
import {ChapterChangeType} from "../types/materials/ChapterChangeType";
import {TypeOfChapterModal} from "../types/materials/TypeOfChapterModal";
import {PageType} from "../types/materials/PageType";
import {ChaptersType} from "../types/materials/ChaptersType";
import {IRootStore} from "./global/RootStore";
import {CourseObject} from "../types/materials/course/CourseObject";
import networkService from "../services/NetworkService";

export enum PageCourseEditNames {
  'main' = 'main',
  'structure' = 'structure',
  'certificate'= 'certificate'
}

export const PageCourseEditTitles = {
  'main': 'Основные настройки',
  'structure': 'Структура',
  'certificate': 'Сертификат'
}

export class CourseEditViewStore {
  private rootStore: IRootStore

  constructor(rootStore: IRootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  isCourseLoadSaveProcess: boolean = false
  isChapterCreateProcess: boolean = false
  currentPage: PageCourseEditNames = PageCourseEditNames.main
  currentDeleteCourseId: number | undefined = undefined
  currentDeleteCourseTitle: string | undefined = undefined
  //TODO Изменить currentCourse и currentCourseCash (поменять местамми кэш)
  currentCourse: CourseObject | undefined = undefined
  currentCourseCash: CourseObject | undefined = undefined

  currentChapterDescription: string | undefined = undefined
  currentChapterTitle: string | undefined = undefined

  currentStructure: StructureType = initialStructure
  currentStructureCash: StructureType = initialStructure
  structurePageList: PageChangeType[] = []
  structureChapterList: ChapterChangeType[] = []
  isStructureChanged: boolean = false

  currentTags: string | null = null
  currentTagsCash: string | null = null

  currentCategories: string | null = null
  currentCategoriesCash: string | null = null

  isChapterDeleteProcess: boolean | undefined = undefined
  currentChapterId: number | undefined = undefined
  currentPageId: number | undefined = undefined
  currentOpenModal: TypeOfChapterModal | undefined = undefined
  changeChapterId: number | undefined = undefined
  newCoverFile: string | null = null
  newIconFile: string | null = null
  newCertificateTemplate : string | null = null

  setDraft = (value: boolean) => {
    if (this.currentCourseCash?.isDraft !== undefined && this.currentCourseCash?.isDraft !== null)
      this.currentCourseCash.isDraft = value
  }

  setCurrentPageId = (value: number) => {
    this.currentPageId = value
  }

  setCurrentChapterId = (value: number) => {
    this.currentChapterId = value
  }

  setCurrentPage = (page: PageCourseEditNames) => {
    this.currentPage = page
  }

  updatePages = (value: PageType[]) => {
    if (this.currentStructureCash.pages === undefined)
      return
    this.currentStructureCash.pages = value
  }

  updateChapters = (value: ChaptersType[]) => {
    if (this.currentStructureCash.chapters === undefined)
      return
    this.currentStructureCash.chapters = value
  }

  setIsCourseLoadSaveProcess = (value: boolean) => {
    this.isCourseLoadSaveProcess = value;
  }

  setCurrentChapterDescription = (value: string) => {
    this.currentChapterDescription = value;
  }

  setCurrentChapterTitle = (value: string) => {
    this.currentChapterTitle = value;
  }

  loadCurrentCourse = async (courseId: number | undefined | null) => {
    try{
      runInAction(() => this.isCourseLoadSaveProcess = true)
      const {data} = await networkService.get(`constructor/materials/course.getCourseByIdAndAuthorId/${this.rootStore.userStore.userData.id}&${courseId}`)
      runInAction(() => {
        this.currentCourse = data
        this.currentCourseCash = data

        this.currentTags = this.currentCourse?.tags || ''
        this.currentTagsCash = this.currentTags

        this.currentCategories = this.currentCourse?.categories?.map(item => item.id).join('|') || null
        this.currentCategoriesCash = this.currentCategories

        this.newCoverFile = null
        this.newIconFile = null
        this.newCertificateTemplate = null

        this.getCurrentStructure(courseId)
        this.isCourseLoadSaveProcess = false
      })

      await this.rootStore.createCertificateStore.getCertificateTemplate()

      return Promise.resolve()
    }
    catch (e) {
      // @ts-ignore
      return Promise.reject(e.response.data)
    }
  }

  get isValidCategories() {
    return this.currentCategories !== ''
  }

  changeCourseData = (key: 'title' | 'description' | 'privacyId' | 'skills' | 'urlCover' | 'coverNewImage' | 'urlIcon' | 'iconNewImage' | 'isDraft' | 'certificateTemplate' | 'newCertificateTemplate', value: number | string | null | boolean) => {
    if (this.currentCourseCash !== undefined) {
      if (key === 'privacyId' && value !== null) {
        this.currentCourseCash.privacyId = +value
      } else if (key === 'coverNewImage') {
        this.newCoverFile = value === null ? null : `${value}`
      } else if (key === 'iconNewImage') {
        this.newIconFile = value === null ? null : `${value}`
      } else if (key === 'newCertificateTemplate'){
        this.newCertificateTemplate = value === null ? null : `${value}`
      } else {
        // @ts-ignore
        this.currentCourseCash[key] = value === null ? null : `${value}`
      }
    }
  }

  saveCurrentCourse = async () => {
    try {
      runInAction(() => this.isCourseLoadSaveProcess = true)

      if (!!this.newCoverFile) {
				let fileObject: File = await fetch(this.newCoverFile).then(r => r.blob()) as File;
        switch (this.newCoverFile) {
          case 'delete': {
            if (!!this.currentCourseCash?.urlCover)
              await this.rootStore.uploadFileStore.deleteFileInStorage(this.currentCourseCash.urlCover,
                  () => {
                    this.changeCourseData('urlCover', null)
                  })
            break
          }
          default: {
            await this.rootStore.uploadFileStore.uploadFile(this.currentCourseCash?.urlCover,
                (newFileUrl) => this.changeCourseData("urlCover", newFileUrl), fileObject
            )
          }
        }
      }
      if (!!this.newIconFile) {
				let fileObject: File = await fetch(this.newIconFile).then(r => r.blob()) as File;
        switch (this.newIconFile) {
          case 'delete': {
            if (!!this.currentCourseCash?.urlIcon)
              await this.rootStore.uploadFileStore.deleteFileInStorage(this.currentCourseCash.urlIcon,
                () => {
                  this.changeCourseData('urlIcon', null)
                })
            break
          }
          default: {
            await this.rootStore.uploadFileStore.uploadFile(this.currentCourseCash?.urlIcon,
              (newFileUrl) => this.changeCourseData("urlIcon", newFileUrl), fileObject
            )
          }
        }
      }
      if (!!this.newCertificateTemplate){
        switch (this.newCertificateTemplate) {
          case 'delete' : {
            if (!!this.currentCourseCash?.certificateTemplate)
              await this.rootStore.uploadFileStore.deleteFileInStorage(this.currentCourseCash?.certificateTemplate, () => {
                this.changeCourseData("certificateTemplate", null)
              })
            break
          }
          default:{
            await this.rootStore.uploadFileStore.uploadFile(this.currentCourseCash?.certificateTemplate,
              (newFileUrl) => this.changeCourseData("certificateTemplate", newFileUrl), this.rootStore.createCertificateStore.templateFile)
          }
        }
      }
      const {data} = await networkService.post('/constructor/materials/course.changeMainDetailsCourseById', {
        id: this.currentCourseCash?.id,
        title: this.currentCourseCash?.title,
        description: this.currentCourseCash?.description,
        privacyId: this.currentCourseCash?.privacyId,
        tags: this.currentTags,
        urlIcon: this.currentCourseCash?.urlIcon,
        urlCover: this.currentCourseCash?.urlCover,
        skills: this.currentCourseCash?.skills,
        isDraft: this.currentCourseCash?.isDraft,
        categoryIds: this.currentCategories,
        certificateTemplate: this.currentCourseCash?.certificateTemplate
      })
     await this.saveStructure();
     await this.loadCurrentCourse(this.currentCourseCash?.id)
      runInAction(() => {
        this.newCoverFile = null
        this.newIconFile = null
        this.isCourseLoadSaveProcess = false

      })
      return Promise.resolve()
    }
    catch (e) {
      // @ts-ignore
      return Promise.reject(e.response.data)
    }
  }

  setCurrentTags = (value: string[]) => {
    return this.currentTags = value.join('|')
  }

  setCurrentCategories = (value: number[] | string[]) => {
    this.currentCategories = value.join('|')
  }

  getCurrentStructure = async(courseId: number, isLoadingShow: boolean = true) => {
    try {
      runInAction(() => this.isCourseLoadSaveProcess = isLoadingShow)
      const {data} = await networkService.get(`constructor/course/page/getCourseStructureByUserId=${this.rootStore.userStore.userData.id}AndCourseId=${courseId}`)
      runInAction(() => {
        this.setCurrentStructure(data)
      })
      return Promise.resolve()
    }
    catch (e) {
      // @ts-ignore
      return Promise.reject(e.response.data)
    }
    finally {
      runInAction(() => this.isCourseLoadSaveProcess = false)
    }
  }

  changeStructureChapterList = (newChapter: ChaptersType[]) => {
    this.structureChapterList = [];
    newChapter.map(item => {
      this.structureChapterList.push({
        chapterId: item.id,
        orderIndex: item.orderIndex
      })
    })
  }

  changeStructurePageList = (newStructure: PageType[]) => {
    this.structurePageList = [];
    newStructure.map(item => {
      this.structurePageList.push({
        pageId: item.id,
        orderIndex: item.orderIndex,
        chapterId: item.chapterId
      })
    })
  }

  saveStructure = async () => {
    try {
      runInAction(() => {
        this.isCourseLoadSaveProcess = true;
        this.changeStructurePageList(this.currentStructureCash.pages);
        this.changeStructureChapterList(this.currentStructureCash.chapters);
      })
      const requestData = {
        courseId: this.currentStructureCash.courseId,
        userId: this.currentStructureCash.userId,
        pages: this.structurePageList,
        chapters: this.structureChapterList
      }
      const {data} = await networkService.post('constructor/course/page/changeStructure', requestData)
      runInAction(() => {
        this.getCurrentStructure(this.currentStructureCash.courseId)
        this.isStructureChanged = false
        this.isCourseLoadSaveProcess = false;
      })
      return Promise.resolve()
    }
    catch (e) {
      // @ts-ignore
      return Promise.reject(e.response.data)
    }
  }

  sortStructure = (structure: StructureType) => {
    structure.pages.sort((a, b) => (a.orderIndex > b.orderIndex) ? 1 : -1)
    structure.chapters.sort((a, b) => ((a.orderIndex || 0) > (b.orderIndex || 0)) ? 1 : -1)
  }

  setCurrentStructure = (value) => {
    this.currentStructure = value
    this.sortStructure(this.currentStructure)
    this.currentStructureCash = value
    this.sortStructure(this.currentStructureCash)
  }

  deletePageById = async (id: number) => {
    try {
      runInAction(() => {this.isCourseLoadSaveProcess = true})
      const {data} = await networkService.delete(`/constructor/course/page/deletePageById=${id}`)
      await this.loadCurrentCourse(this.currentCourseCash?.id);
      runInAction(() => {
        this.isCourseLoadSaveProcess = false
        this.currentPageId = undefined;
      })
      return Promise.resolve()
    }
    catch (e) {
      // @ts-ignore
      return Promise.reject(e.response.data)
    }
  }

  setCurrentOpenModal = (value: TypeOfChapterModal | undefined) => {
    this.currentOpenModal = value
  }

  setChangeChapterId = (value: number) => {
    this.changeChapterId = value
  }

  changeChapter = async (title: string, description: string) => {
    try {
      runInAction(() => {
        this.isChapterCreateProcess = true
      })
      const requestData = {
        chapterId: this.changeChapterId,
        title: title,
        description: description ?? null
      }
      const {data} = await networkService.post('/constructor/course/page/changeChapter', requestData)
      await this.loadCurrentCourse(this.currentCourseCash?.id)
      runInAction(() => {this.isChapterCreateProcess = false})
      return Promise.resolve()
    }
    catch (e) {
      return Promise.reject(e.response.data)
    }
  }
//TODO Реализовать изменение и создание разделов и статей для хранения в кэше и отправки всех данных ВМЕСТЕ по кнопке сохранить, после создания такого метода на сервере
  setChapterData = (key: 'title' | 'description', value: string | undefined) => {
    switch (key) {
      case "title": {
        runInAction(() => this.currentChapterTitle = value)
        break
      }
      case "description": {
        runInAction(() => this.currentChapterDescription = value)
        break
      }
    }
    // this.isChapterCreateProcess = true
    // if (type === 'change') {
    //   this.currentStructureCash.chapters = this.currentStructureCash.chapters.reduce((previousValue: ChaptersType[], currentValue: ChaptersType, currentIndex, array) => {
    //     if (currentValue.id === this.changeChapterId) {
    //       previousValue.push({
    //         ...currentValue,
    //         title: title,
    //         description: description
    //       })
    //     } else {
    //       previousValue.push(currentValue);
    //     }
    //
    //     return previousValue;
    //   }, [])
    // } else {
    //   this.currentStructureCash.chapters.push({
    //     id: this.currentStructureCash.chapters.at(-1).id + 1,
    //     orderIndex: this.currentStructureCash.chapters.at(-1).orderIndex + 1,
    //     title: title,
    //     courseId: this.currentStructureCash.courseId,
    //     description: description
    //   })
    // }
    // this.isChapterCreateProcess = false
  }

  createChapter = async (title: string, description: string) => {
    try {
      runInAction(() => this.isChapterCreateProcess = true)

      const {data} = await networkService.post('constructor/course/page/createChapter', {
        userId: this.rootStore.userStore.userData.id,
        title: title,
        courseId: this.currentCourse?.id,
        description: description ?? null
      })
      await this.loadCurrentCourse(this.currentCourseCash?.id)
      runInAction(() => {
        this.isChapterCreateProcess = false
      })
      return Promise.resolve()
    }
    catch (e) {
      // @ts-ignore
      return Promise.reject(e.response.data)
    }
  }

  deleteChapterById = async (id: number, isDeleteInputPages: boolean) => {
    try {
      runInAction(() => this.isChapterDeleteProcess = true)
      const {data} = await networkService.delete(`constructor/course/page/deleteChapterById=${id}&isDeleteInputPages=${isDeleteInputPages}`)
      await this.loadCurrentCourse(this.currentCourseCash?.id)
      runInAction(() => {
        this.currentChapterId = 0
        this.isChapterDeleteProcess = false
      })
      return Promise.resolve()
    }
    catch (e) {
      // @ts-ignore
      return Promise.reject(e.response.data)
    }
  }

  setCurrentDeleteCourseId = (value: number) => {
    this.currentDeleteCourseId = value
  }

  setCurrentDeleteCourseTitle = (value: string) => {
    this.currentDeleteCourseTitle = value
  }

  deleteCurrentCourse = async () => {
    try {
      runInAction(() => this.isCourseLoadSaveProcess = true)
      const {data} = await networkService.post('constructor/materials/course.deleteCourses', {
        userId: this.rootStore.userStore.userData.id,
        coursesId: [this.currentDeleteCourseId]
      })
      runInAction(() => {
        this.isCourseLoadSaveProcess = false
      })
      return Promise.resolve()
    }
    catch (e) {
      runInAction(() => {
        this.isCourseLoadSaveProcess = false
      })
      // @ts-ignore
      return Promise.reject(e.response.data)
    }
  }

  get isCourseChanged() {
    return JSON.stringify(this.currentCourseCash) !== JSON.stringify(this.currentCourse) && (this.currentCourseCash?.title?.length || 0) < 501
        || JSON.stringify(this.currentStructure) !== JSON.stringify(this.currentStructureCash)
        || this.currentTags !== this.currentTagsCash
        || this.currentCategories !== this.currentCategoriesCash && this.isValidCategories
        || this.newCoverFile !== null
        || this.newIconFile !== null
        || this.newCertificateTemplate !== null
  }
}
export interface ICourseEditViewStore extends CourseEditViewStore {}
