import {observer} from "mobx-react-lite";
import {StyleSheet} from "../../../utils/styles";
import {stores} from "../../../pages/_app";
import {Button} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import { MailService } from "../../../services/MailService";
import {baseStyles} from "../../../utils/baseStyles";
import { SimpleInput } from "../../baseComponents/inputs/simpleInput";
import {useState} from "react";

const SupportDialog = observer(() => {
  const [email, setEmail] = useState('')
  const [subject, setSubject] = useState('')
  const [text, setText] = useState('')
  const [isSendProcess, setSendProcess] = useState(false)

  const isValidEmail = () => {
    return /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/gmus.test(email!)
      && email.length > 0
      && email.length < 256
  }

  const isValid = () => {
    return isValidEmail()
      && subject.length > 0
      && text.length > 0
  }

  return (
    <div style={{width: '500px', margin: '30px'}}>
      <p style={{...styles.title}}>Поддержка</p>
      <p style={{...styles.description}}>Расскажите нам о какой-то проблеме или поделитесь впечатлениями о сервисе
        CourseBit</p>
      <div style={{...styles.border}}>
        <SimpleInput title={'Укажите вашу почту, чтобы мы могли с вами связаться'} onTextChange={setEmail}
                     size={"small"}
                     isValid={isValidEmail()} isValidText={'Не должно быть пустым'} inputText={email || ''}
                     style={{...styles.text}} maxLength={100} stylesForToolTip={{top: "15px", right: 0}}
                     required={true}/>
        <SimpleInput title={'Укажите тему сообщения'} onTextChange={setSubject}
                     size={"small"}
                     isValid={subject.length > 0 && subject.length <= 100} isValidText={'Не должно быть пустым и превышать 100 символов'}
                     inputText={subject || ''}
                     style={{...styles.text}} maxLength={100} stylesForToolTip={{top: "15px", right: 0}}
                     required={true}/>
        <SimpleInput title={'Напишите текст сообщения'} onTextChange={setText}
                     size={"small"}
                     isValid={text.length > 0} isValidText={'Не должно быть пустым'}
                     inputText={text || ''}
                     style={{...styles.text, height: "fit-content"}} multiline={true}
                     stylesForToolTip={{top: "15px", right: 0}} maxLength={3000} required={true}/>
      </div>
      <div style={{...baseStyles.horizontalContainer}}>
        <LoadingButton
          size="small"
          onClick={() => {
            setSendProcess(true)
            MailService.mailSend(
              email,
              subject,
              text,
              () => {
                stores.materialDialogStore.setMessageViewState(true, "Обращение отправлено")
                setSendProcess(false)
                stores.materialDialogStore.setCurrentState(false)
              },
              () => {
                stores.materialDialogStore.setMessageViewState(true, "Не удалось отправить обращение")
                setSendProcess(false)
                stores.materialDialogStore.setCurrentState(false)
              }
            )
          }}
          disabled={!isValid()}
          loading={isSendProcess}
          loadingIndicator={'Отправка...'}
          variant={"contained"}
          children={'Отправить'}
          style={{...styles.controlButton}}
          sx={{bgcolor: '#32BD9C', '&:hover': {bgcolor: '#32BD9C'}}}
        />
        <Button
          variant={"outlined"}
          children={"Отменить"}
          onClick={() => stores.materialDialogStore.setCurrentState(false)}
          style={{
            ...styles.controlButton,
            marginLeft: '5px'
          }}
          size={"small"}
          sx={{color: '#FE594E', borderColor: '#FE594E', '&:hover': {borderColor: '#ff9a93'}}}/>
      </div>
    </div>
  )
})

export default SupportDialog

const styles = StyleSheet.create({
  border: {
    marginTop: '10px',
    padding: '5px'
  },
  title: {
    fontFamily: 'Montserrat',
    fontWeight: '700',
    fontSize: '24px',
  },
  description: {
    fontFamily: 'Montserrat',
    fontWeight: '400',
    fontSize: '14px',
    marginTop: '10px'
  },
  text: {
    marginTop: '10px',
    width: '100%'
  },
  controlButton: {
    flex: 1,
    marginTop: '10px'
  }
})