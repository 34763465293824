export const arraysEqual = (a: number[], b: number[]) => {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  const sortedA = a.sort()
  const sortedB = b.sort()
 
  for (let i = 0; i < a.length; ++i) {
    if (sortedA[i] !== sortedB[i]) return false;
  }
  return true;
}
