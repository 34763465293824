import {CategoryModel} from "../dictionaries/categories/CategoryModel";

export type UserDataModel = {
  id: number,
  login: string | null,
  email: string | null,
  isSubscribe: boolean,
  firstName: string | null,
  secondName: string | null,
  thirdName: string | null,
  profilePhoto: string | null,
  timeZone: string | null,
  isUseConstructor: boolean,
  isUseMarketplace: boolean,
  birthDate: string | null,
  country: string | null,
  city: string | null,
  aboutMe: string | null,
  interests: CategoryModel[] | string | null,
  coverImage: string | null,
  isEmptyUser: boolean | null,
  sex: string | null
}

export const initUserDataModel: UserDataModel = {
  id: 0,
  login: null,
  email: null,
  isSubscribe: false,
  firstName: null,
  secondName: null,
  thirdName: null,
  profilePhoto: null,
  timeZone: null,
  isUseConstructor: false,
  isUseMarketplace: false,
  birthDate: null,
  country: null,
  city: null,
  aboutMe: null,
  interests: [],
  coverImage: null,
  isEmptyUser: null,
  sex: null
}
