export type ChangePasswordModel = {
  oldPassword: string | undefined,
  password: string | undefined,
  passwordRepeat: string | undefined,
}

export const initChangePasswordModel: ChangePasswordModel = {
  oldPassword: '',
  passwordRepeat: '',
  password: ''
}
