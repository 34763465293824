import React, {CSSProperties, useCallback} from 'react'
import {defaultFontStyles} from "../../fontStyle";
import {Colors, FontStyle, FontType, GrayScale} from "../../enums";
import {CBTooltip} from "../tooltip";

interface IProps {
  children: string | JSX.Element,
  style?: CSSProperties,
  color?: GrayScale | Colors,
  fontStyle?: FontStyle,
  type?: FontType,
  tooltipText?: string,
}

const CBText = ({children, style, color, type = FontType.TEXT, fontStyle = FontStyle.MEDIUM, tooltipText}: IProps) => {
  const getStyle = useCallback(() => {
    let finalStyle: CSSProperties = {}
    if (type === FontType.TITLE) {
      switch (fontStyle) {
        case FontStyle.LARGE: {
          finalStyle = {...defaultFontStyles.displayLarge}
          break
        }
        case FontStyle.LARGEBOLD: {
          finalStyle = {...defaultFontStyles.displayLargeBold}
          break
        }
        case FontStyle.MEDIUM: {
          finalStyle = {...defaultFontStyles.displayMedium}
          break
        }
        case FontStyle.MEDIUMBOLD: {
          finalStyle = {...defaultFontStyles.displayMediumBold}
          break
        }
        case FontStyle.SMALL: {
          finalStyle = {...defaultFontStyles.displaySmall}
          break
        }
        case FontStyle.SMALLBOLD: {
          finalStyle = {...defaultFontStyles.displaySmallBold}
          break
        }
      }
    } else {
      switch (fontStyle) {
        case FontStyle.LARGE: {
          finalStyle = {...defaultFontStyles.textLarge}
          break
        }
        case FontStyle.LINKLARGE: {
          finalStyle = {...defaultFontStyles.linkLarge}
          break
        }
        case FontStyle.MEDIUM: {
          finalStyle = {...defaultFontStyles.textMedium}
          break
        }
        case FontStyle.LINKMEDIUM: {
          finalStyle = {...defaultFontStyles.linkMedium}
          break
        }
        case FontStyle.SMALL: {
          finalStyle = {...defaultFontStyles.textSmall}
          break
        }
        case FontStyle.LINKSMALL: {
          finalStyle = {...defaultFontStyles.linkSmall}
          break
        }
        case FontStyle.XSMALL: {
          finalStyle = {...defaultFontStyles.textXSmall}
          break
        }
        case FontStyle.LINKXSMALL: {
          finalStyle = {...defaultFontStyles.linkXSmall}
          break
        }
      }
    }

    finalStyle = {...finalStyle, ...style}
    if (color) {
      finalStyle.color = color
    }
    return finalStyle
  }, [type, color, fontStyle, style])

  const renderText = () => (
    <p style={getStyle()} children={children}/>
  )

  return !!tooltipText
    ? (<CBTooltip title={tooltipText} children={renderText()} />)
    : renderText()
}

export default CBText
