import {StyleSheet} from "../../../../utils/styles";
import {baseStyles} from "../../../../utils/baseStyles";
import {authBaseHorizontalMargin} from "../styles";

export const styles = StyleSheet.create({
  signInLogoContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "end",
    alignContent: "end",
    ...baseStyles.horizontalMargins30
  },
  signInLogo: {
    width: 150,
    height: "auto"
  },
  signInLogoText: {
    textAlign: "end",
    fontFamily: 'Montserrat Alternates',
    fontWeight: "bold",
    lineHeight: 0.75,
    color: '#D8D8D8',
    fontSize: 12,
    marginLeft: 5
  },
  signInWelcomeTitle: {
    fontFamily: 'Roboto',
    fontWeight: "bold",
    color: '#3D3D3D',
    fontSize: 16,
    ...authBaseHorizontalMargin,
    marginTop: 30
  },
  signInWelcomeText: {
    fontFamily: 'Montserrat',
    color: '#3D3D3D',
    fontSize: 13,
    ...authBaseHorizontalMargin,
    marginTop: 5
  },
  signInSocialsContainer: {
    ...baseStyles.horizontalContainerWrap,
    ...authBaseHorizontalMargin,
    alignContent: "space-around",
    justifyContent: "space-between",
    marginTop: 20
  },
  signInAuthButton: {
    background: 'linear-gradient(90deg, #B1D881 0%, #93D688 100%)',
    display: "block",
    marginLeft: 30,
    marginRight: 30,
    marginTop: 20,
    textAlign: "center",
    padding: 12,
    fontSize: 13,
    fontFamily: 'Montserrat',
    color: 'rgb(255,255,255)',
    borderRadius: 10,
    fontWeight: 500,
    cursor: "pointer"
  },
  signInPasswordSettings: {
    ...baseStyles.horizontalContainerWrap,
    ...authBaseHorizontalMargin,
    alignContent: "center",
    alignItems: "center",
    marginTop: '10px',
    justifyContent: "flex-end",
    marginBottom: '10px'
  },
})
