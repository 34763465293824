import {IRootStore} from "./global/RootStore";
import {makeAutoObservable, runInAction} from "mobx";
import networkService, { networkService1 } from "../services/NetworkService";
import {initialNewQuestion} from "../types/materials/test/QuestionType";
import {stores} from "../pages/_app";
import {AnswerType} from "../types/materials/test/AnswerType";

export enum GPTRequestType {
  SUMMARY = 'summary',
  GENERATE_EXAMPLES = 'generate_examples',
  UPGRADE = 'upgrade',
  GENERATE_COURSE_QUESTIONS = 'generate_course_questions',
  GENERATE_CHAPTER_QUESTIONS = 'generate_chapter_questions',
  DISCUSSION = 'discussion',
  COMPLEXITY_REASON = 'complexity_reason'
}

export type GPTRequestProps = {
  materialId?: number
  content?: string
}

export type ComplexityReasonType = {
  complexity: number,
  reason: string
}

export type ResponseAnswersType = {
  text: string,
  correct: boolean
}

export type ResponseQuestionsType = {
  title: string,
  description: string,
  answers: ResponseAnswersType[]
}

export type GPTQuestionsResponseType = {
  questions: ResponseQuestionsType[]
}

export type UserFeedbackType = {
  id: number,
  login: string,
  profile_photo: string
}

export type GptAnalysisType = {
  id: number,
  user_id: number,
  profile_photo: string,
  login: string,
  course_id: number,
  text: string,
  analysis_text_general: string,
  analysis_for_change: string[],
  satisfaction_index: number,
  user_index: number,
  feeling_type: 'bad' | 'neutral' | 'good',
}


export type FeedbackType = {
  id: number,
  user: UserFeedbackType,
  course_id: number,
  text: string,
  score: number
}

export type FeedbackResponseType = {
  feedbacks: FeedbackType[],
  count: number,
  page: number,
  max_page: number
}

export type CreateFeedbackRequestType = {
  user_id: number,
  course_id: number,
  text: string,
  score: number
}

export class GPTStore {
  private rootStore: IRootStore
  private readonly url: string

  constructor(rootStore: IRootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
    this.url = 'http://192.168.0.16:3001/gpt/'
  }

  isRequestInProcess: boolean = false
  gptSummary: string | undefined = undefined
  gptUpgrades: string | undefined = undefined
  gptArticleComplexity: ComplexityReasonType | undefined = undefined
  gptCourseQuestions: GPTQuestionsResponseType[] | undefined = undefined
  gptChapterQuestions: GPTQuestionsResponseType[] | undefined = undefined
  confirmOnSaveQuestionIndex: number[] = []
  isAddQuestionToTestInProcess = false
  userFeedbackStatus = false
  currentUserFeedbackText: string | undefined = undefined
  currentUserFeedbackRating: number | null = null
  courseFeedbacks: FeedbackResponseType | undefined = undefined
  isCreateFeedbackInProcess = false
  analysisFeedbacks: GptAnalysisType[] | undefined = undefined
  isGetAnalysisFeedbacksInProcess = false

  setIsGetAnalysisFeedbacksInProcess = (value: boolean) => {
    this.isGetAnalysisFeedbacksInProcess = value
  }

  setAnalysisFeedbacks = (data: GptAnalysisType[] | undefined) => {
    this.analysisFeedbacks = data
  }

  setCourseFeedbacks = (data: FeedbackResponseType | undefined) => {
    this.courseFeedbacks = data
  }

  setCurrentUserFeedbackText = (value: string | undefined) => {
    this.currentUserFeedbackText = value
  }

  setCurrentUserFeedbackRating = (value: number | null) => {
    this.currentUserFeedbackRating = value
  }

  setUserFeedbackStatus = (value: boolean) => {
    this.userFeedbackStatus = value
  }

  setIsAddQuestionToTestInProcess = (value: boolean) => {
    this.isAddQuestionToTestInProcess = value
  }

  setIsRequestInProcess = (value: boolean) => {
    this.isRequestInProcess = value
  }

  clearGptResponses = () => {
    this.gptSummary = undefined
    this.gptUpgrades = undefined
    this.gptArticleComplexity = undefined
    this.gptCourseQuestions = undefined
    this.gptChapterQuestions = undefined
    this.confirmOnSaveQuestionIndex = []
  }

  clearFeedbackData = () => {
    this.courseFeedbacks = undefined
    this.userFeedbackStatus = false
    this.currentUserFeedbackRating = null
    this.currentUserFeedbackText = undefined
  }

  clearAnalysisData = () => {
    this.analysisFeedbacks = undefined
    this.isGetAnalysisFeedbacksInProcess = false
  }

  removeConfirmOnSaveQuestionIndex = (index: number) => {
    const indexToRemove = this.confirmOnSaveQuestionIndex.indexOf(index)
    if (indexToRemove !== -1) {
      this.confirmOnSaveQuestionIndex.splice(indexToRemove, 1)
    }
  }

  setConfirmOnSaveQuestionIndex = (index: number) => {
    this.confirmOnSaveQuestionIndex = [...this.confirmOnSaveQuestionIndex, index]
    console.warn(`this.confirmOnSaveQuestionIndex`, JSON.stringify(this.confirmOnSaveQuestionIndex))
  }
  sendGPTRequest = async (requestType: GPTRequestType, requestProps: GPTRequestProps) => {
    switch (requestType) {
      case GPTRequestType.SUMMARY: {
        return this.getGPTSummary(requestProps.materialId)
      }
      case GPTRequestType.UPGRADE: {
        return this.getGPTUpgrade(requestProps.content)
      }
      case GPTRequestType.GENERATE_EXAMPLES: {
        return this.getGPTGenerateExamples()
      }
      case GPTRequestType.GENERATE_COURSE_QUESTIONS: {
        return this.getGPTGenerateCourseQuestions(requestProps.materialId)
      }
      case GPTRequestType.GENERATE_CHAPTER_QUESTIONS: {
        return this.getGPTGenerateChapterQuestions(requestProps.materialId)
      }
      case GPTRequestType.DISCUSSION: {
        return this.getGPTDiscussion()
      }
      case GPTRequestType.COMPLEXITY_REASON: {
        return this.getGPTComplexityReason(requestProps.content)
      }
    }
  }

  saveGPTQuestions = async (type: GPTRequestType) => {
    switch (type) {
      case GPTRequestType.GENERATE_COURSE_QUESTIONS: {
        return this.saveGPTCourseQuestions()
      }
      case GPTRequestType.GENERATE_CHAPTER_QUESTIONS: {
        return this.saveGPTChapterQuestions()
      }
    }
  }

  getUserFeedbackStatus = async (userId: number, courseId: number) => {
    try {
      const {data} = await networkService1.post(`${this.url}feedback-analysis/getUserFeedbackStatus`, {
        user_id: userId,
        course_id: courseId
      })

      console.warn(`data ${JSON.stringify(data)}`)
      runInAction(() => {
        this.userFeedbackStatus = data.status
      })
      return Promise.resolve()
    } catch (e) {
      console.log(`error ${JSON.stringify(e)}`)
      this.rootStore.materialDialogStore.setMessageViewState(true, 'Ошибка получения статуса пользовательского фидбека')
      return Promise.reject(e)
    }
  }

  getFeedbacks = async (courseId: number, page: number) => {
    try {
      const {data} = await networkService1.post(`${this.url}feedback-analysis/getFeedbacks`, {
        course_id: courseId,
        page: page
      })
      runInAction(() => {
        this.courseFeedbacks = data
      })
      return Promise.resolve()
    } catch (e) {
      console.log(`error ${JSON.stringify(e)}`)
      this.rootStore.materialDialogStore.setMessageViewState(true, 'Ошибка получения фидбека')
      return Promise.reject(e)
    }
  }

  getAnalysisForFeedback = async (courseId: number) => {
    try {
      this.setIsGetAnalysisFeedbacksInProcess(true)
      const {data} = await networkService1.post(`${this.url}feedback-analysis/getAnalysisForFeedback`, {
        courseId: courseId
      })

      console.log(`data ${JSON.stringify(data)}`)
      runInAction(() => {
        this.analysisFeedbacks = data
      })
      return Promise.resolve()
    } catch (e) {
      console.log(`error ${JSON.stringify(e)}`)
      this.rootStore.materialDialogStore.setMessageViewState(true, 'Ошибка получения фидбека')
      return Promise.reject(e)
    } finally {
      this.setIsGetAnalysisFeedbacksInProcess(false)
    }
  }

  createFeedback = async (feedback: CreateFeedbackRequestType) => {
    try {
      runInAction(() => {
        this.isCreateFeedbackInProcess = true
      })
      const {data} = await networkService1.post(`${this.url}feedback-analysis/addNewFeedback`, feedback)

      await this.getUserFeedbackStatus(feedback.user_id, feedback.course_id)
      await this.getFeedbacks(feedback.course_id, 1)
      runInAction(() => {
        this.currentUserFeedbackText = undefined
        this.currentUserFeedbackRating = null
      })
      return Promise.resolve()
    } catch (e) {
      console.log(`error ${JSON.stringify(e)}`)
      this.rootStore.materialDialogStore.setMessageViewState(true, 'Ошибка создания фидбека')
      return Promise.reject(e)
    } finally {
      runInAction(() => {
        this.isCreateFeedbackInProcess = false
      })
    }
  }
  private getGPTSummary = async (articleId?: number) => {
    try {
      this.setIsRequestInProcess(true)
      const {data} = await networkService1.post(`${this.url}summary`, {
        articleId: articleId
      }, {
        headers: {
          'Content-Type': 'application/json',
        }
      })
      console.log(`data ${JSON.stringify(data)}`)

      runInAction(() => {
        this.gptSummary = data
      })
      return Promise.resolve()
    } catch (e) {
      console.log(`error ${JSON.stringify(e)}`)
      this.rootStore.materialDialogStore.setMessageViewState(true, 'Ошибка получения краткого содержания статьи')
      return Promise.reject(e)
    } finally {
      this.setIsRequestInProcess(false);
    }
  }

  private getGPTGenerateCourseQuestions = async (materialId: number) => {
    try {
      this.setIsRequestInProcess(true)
      const {data} = await networkService1.post(`${this.url}questions/course`, {
        courseId: materialId
      })

      const convertResponse = this.convertData(data)
      runInAction(() => {
        this.gptCourseQuestions = convertResponse
      })
      return Promise.resolve()
    } catch (e) {
      this.rootStore.materialDialogStore.setMessageViewState(true, 'Ошибка генерации контрольных вопросов по курсу')
      return Promise.reject(e)
    } finally {
      this.setIsRequestInProcess(false)
    }
  }

  private getGPTGenerateChapterQuestions = async (materialId: number) => {
    try {
      this.setIsRequestInProcess(true)
      const {data} = await networkService1.post(`${this.url}questions/chapter`, {
        chapterId: materialId
      })

      const convertResponse = this.convertData(data)
      runInAction(() => {
        this.gptChapterQuestions = convertResponse
      })
      return Promise.resolve()
    } catch (e) {
      this.rootStore.materialDialogStore.setMessageViewState(true, 'Ошибка генерации контрольных вопросов по разделу')
      return Promise.reject(e)
    } finally {
      this.setIsRequestInProcess(false)
    }
  }
  private getGPTUpgrade = async (content: string) => {
    try {
      this.setIsRequestInProcess(true)
      const {data} = await networkService1.post(`${this.url}improve-article`, {
        content: content
      })
      runInAction(() => {
        this.gptUpgrades = data.join('\n');
        console.error(`gptUpgrades ${JSON.stringify(this.gptUpgrades)}`)
      })
      return Promise.resolve()
    } catch (e) {
      this.rootStore.materialDialogStore.setMessageViewState(true, 'Ошибка получения улучшений для статьи')
      return Promise.reject(e)
    } finally {
      this.setIsRequestInProcess(false)
    }
  }

  private getGPTComplexityReason = async (content: string) => {
    try {
      this.setIsRequestInProcess(true)
      const {data} = await networkService1.post(`${this.url}complexity`, {
        content: content
      })
      runInAction(() => {
        this.gptArticleComplexity = {
          complexity: data.complexity,
          reason: data.reason
        }
      })
      return Promise.resolve()
    } catch (e) {
      this.rootStore.materialDialogStore.setMessageViewState(true, 'Ошибка оценивания сложности материала')
    } finally {
      this.setIsRequestInProcess(false)
    }
  }

  private getGPTGenerateExamples = async () => {
    try {
      this.setIsRequestInProcess(true)
      const {data} = await networkService.post(`${this.url}generate_examples`)

      return Promise.resolve()
    } catch (e) {
      this.rootStore.materialDialogStore.setMessageViewState(true, 'Ошибка генерации примеров')
      return Promise.reject(e)
    } finally {
      this.setIsRequestInProcess(false)
    }
  }

  private getGPTDiscussion = async () => {
    try {
      this.setIsRequestInProcess(true)
      const {data} = await networkService.post(`${this.url}discussion`)

      return Promise.resolve()
    } catch (e) {
      this.rootStore.materialDialogStore.setMessageViewState(true, 'Ошибка получения обсуждений')
      return Promise.reject(e)
    } finally {
      this.setIsRequestInProcess(false)
    }
  }

  private saveGPTCourseQuestions = async () => {
    this.setIsAddQuestionToTestInProcess(true)
    for (const questions of this.gptCourseQuestions) {
      await this.setNewQuestion(questions)
    }
    this.clearGptResponses();
    this.rootStore.materialDialogStore.setCurrentState(false)
    this.setIsAddQuestionToTestInProcess(false)
  }

  private saveGPTChapterQuestions = async () => {
    this.setIsAddQuestionToTestInProcess(true)
    for (const questions of this.gptChapterQuestions) {
      await this.setNewQuestion(questions)
    }
    this.clearGptResponses();
    this.rootStore.materialDialogStore.setCurrentState(false)
    this.setIsAddQuestionToTestInProcess(false)
  }

  private setNewQuestion = async (questions: GPTQuestionsResponseType) => {
    console.warn(`setNewQuestion`)
    questions.questions.map(async (question, index) => {
      if (this.confirmOnSaveQuestionIndex.indexOf(index) === -1) {
        console.warn(`return without save`)
        return
      }

      runInAction(() => {
        this.rootStore.testEditViewStore.newQuestion = {
          title: question.title,
          description: question.description,
          questionId: 0,
          idTest: 0,
          orderIndex: 0,
          answers: []
        }
        question.answers.forEach((item, index) => this.setAnswer(item, index))
      })
      await this.createQuestion()
      runInAction(() => {
        this.rootStore.testEditViewStore.newQuestion = initialNewQuestion
      })
    })
  }

  private convertData = (data: any[]) => {
    return data.map(item => JSON.parse(item))
  }

  private setAnswer = (item: ResponseAnswersType, index: number) => {
    this.rootStore.testEditViewStore.newQuestionStructure = []
    console.warn(`setAnwers`)
    const newAnswer: AnswerType  = {
      id: null,
      questionId: null,
      content: item.text,
      isCorrect: item.correct,
      orderIndex: index + 1,
    }
    this.rootStore.testEditViewStore.newQuestionStructure = [...this.rootStore.testEditViewStore.newQuestionStructure, newAnswer]
  }

  private createQuestion = async () => {
    await this.rootStore.testEditViewStore.createQuestions()
    this.rootStore.testEditViewStore.currentTest?.id !== undefined && await this.rootStore.testEditViewStore.getTestStructure(stores?.testEditViewStore.currentTest?.id, false)
  }
}

export interface IGPTStore extends GPTStore {}