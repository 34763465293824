import {observer} from "mobx-react-lite";
import React, {CSSProperties, useCallback, useEffect, useMemo, useState} from "react";
import {stores} from "../../../pages/_app";
import {Box, Dialog, DialogProps, Grow, IconButton, Slide, SxProps} from "@mui/material";
import {StyleSheet} from "../../../utils/styles";
import {CloseOutlined} from "@mui/icons-material";
import {TransitionProps} from "@mui/material/transitions";
import {baseStyles} from "../../../utils/baseStyles";

export enum CBModalType {
	CENTER = 'center',
	BOTTOM = 'bottom',
}

interface IProps {
	isShow: boolean,
	type?: CBModalType,
	children: JSX.Element,
	onClose?: () => void,
	style?: CSSProperties
	disableClosing?: boolean,
	hideClose?: boolean,
	bottomStyles?: CSSProperties | SxProps,
	additionalStyle?: CSSProperties | SxProps
}

const TransitionGrow = React.forwardRef(function Transition(
	props: TransitionProps & {
		children: React.ReactElement<any, any>;
	},
	ref: React.Ref<unknown>,
) {
	return <Grow ref={ref} {...props}/>;
});

const TransitionSlide = React.forwardRef(function Transition(
	props: TransitionProps & {
		children: React.ReactElement<any, any>;
	},
	ref: React.Ref<unknown>,
) {
	return <Slide direction={"up"} ref={ref} {...props}/>;
});

export const CBModal = observer(({isShow, type = CBModalType.CENTER, children, onClose, bottomStyles = {}, style, disableClosing = false, hideClose = false, additionalStyle = {}}: IProps) => {
	const [_isShow, _setIsShow] = useState(isShow)

	useEffect(() => {
		_setIsShow(isShow)
	}, [isShow])

	useEffect(() => {
		!isShow && !!onClose
		&& onClose()
	}, [_isShow])

	const isMobile = useMemo(() => !stores.adaptiveService.isMinDesktopMini, [stores.adaptiveService.isMinDesktopMini])

	const renderCenterVersion = () => {
		return (
			<Dialog
				open={_isShow}
				sx={styles.dialogPaper}
				keepMounted
				disableScrollLock
				disableEscapeKeyDown
				transitionDuration={500}
				TransitionComponent={TransitionGrow}
				fullWidth={isMobile}
				maxWidth={false}
			>
				<Box sx={{...styles.dialogCenterContainer, padding: '50px'}}>
					{!hideClose && <IconButton
						sx={styles.closeBtn}
						onClick={() => {
							if (!disableClosing) {
								_setIsShow(false)
								stores.materialDialogStore.setCurrentState(false)
							} else {
								onClose && onClose()
							}
						}}
					>
						<CloseOutlined/>
					</IconButton>}
					{children}
				</Box>
			</Dialog>
		)
	}

	const renderBottomVersion = () => {
		return (
			<Dialog
				open={_isShow}
				disableScrollLock
				TransitionComponent={TransitionSlide}
				onClose={() => { return }}
				sx={{...styles.dialogBottom, ...additionalStyle}}
			>
				<Box sx={{
					...styles.dialogBottomRoot,
					right: isMobile ? 0 : stores.adaptiveService.horizontalMarginInPage,
					left: isMobile ? 0 : stores.adaptiveService.horizontalMarginInPage,
					...bottomStyles
				}}>
				<Box sx={{...styles.dialogCenterContainer, '&>div.MuiDialogContent-root': {padding: isMobile ? 0 : 'initial'}}}>
					<IconButton
						sx={styles.closeBtn}
						onClick={() => {
							!!onClose && onClose();
						}}
					>
						<CloseOutlined/>
					</IconButton>
					{children}
				</Box>
				</Box>
			</Dialog>
		)
	}

	const renderModal = useCallback(() => {
		switch (type) {
			case CBModalType.BOTTOM:
				return renderBottomVersion()
			case CBModalType.CENTER:
				return isMobile ? renderBottomVersion() : renderCenterVersion()
			default:
				renderCenterVersion()
		}
	}, [isMobile, type, children, _isShow])

	return renderModal()
})

const styles = StyleSheet.create({
	dialogBottom: {
		'& .MuiDialog-container': {
			alignItems: 'flex-end'
		},
		'& .MuiPaper-root': {
			margin: '0',
			borderRadius: '30px 30px 0 0',
			maxWidth: '866px',
			width: '100%'
		}
	},
	dialogPaper: {
		'.MuiDialog-paper': {
			borderRadius: '30px',
			minWidth: '538px',
			maxWidth: '1100px'
		}
	},
	dialogBottomRoot: {
		maxWidth: '866px',
		width: '100%',
		margin: '0 auto',

		'& > div': {
			padding: '30px',
		},

		'& > div > div': {
			width: '100%'
		},

		'& > div > h2': {
			fontSize: '24px !important'
		},

		'& #scroll-dialog-title>div>p': {
			fontSize: '14px !important'
		},

		'& #scroll-dialog-description p': {
			fontSize: '14px !important'
		},

		'& #scroll-dialog-description span': {
			fontSize: '14px !important'
		},

		'& p.MuiTypography-root': {
			width: 'auto',
			margin: 0
		}
	},
	dialogCenterContainer: {
		borderRadius: '30px',
		position: 'relative',
		...baseStyles.verticalContainer,
		...baseStyles.centerOnVertical,
	},
	closeBtn: {
		position: 'absolute',
		right: '20px',
		top: '20px',
		padding: '5px'
	}
})
