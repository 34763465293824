import dynamic from 'next/dynamic'
import {CSSProperties} from "react";
const KinescopePlayer = dynamic(import('@kinescope/react-kinescope-player'), { ssr: false });

interface IProps {
  ref?: React.RefObject<any>
  videoId: string,
  style?: CSSProperties,
  onEnded?: () => void
  onPause?: () => void,
  onPlay?: () => void,
  onTimeUpdate?: () => void
}

const Player = ({ref, videoId, style, onEnded, onPlay, onPause, onTimeUpdate}: IProps) => {
  return (
    <KinescopePlayer
      ref={ref}
      videoId={videoId}
      style={style}
      onEnded={onEnded}
      onPause={onPause}
      onPlay={onPlay}
      onTimeUpdate={onTimeUpdate}
    />
  )
}

export default Player;
