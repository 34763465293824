import {observer} from "mobx-react-lite";
import {Avatar, Tooltip} from "@mui/material";
import {stores} from "../../../../pages/_app";
import {NotificationsActiveOutlined} from "@mui/icons-material";
import {StyleSheet} from "../../../../utils/styles";
import {baseStyles} from "../../../../utils/baseStyles";
import {CSSProperties} from "react";

interface IProps {
  isDarkTheme: boolean,
  style?: CSSProperties
}

export const BaseProfileView = observer(({isDarkTheme, style}: IProps) => {

  return(
    <div style={{...baseStyles.horizontalContainer, ...baseStyles.centeredView, ...style}}>
      <Avatar src={stores.userStore.userData.profilePhoto}/>
      <div style={styles.dataContainer}>
        <p style={{...styles.login, color: isDarkTheme ? '#d0d0d0' : '#3b3b3b'}}>{stores.userStore.userData.login}</p>
        <p style={{...styles.email, color: isDarkTheme ? '#a4a4a4' : '#252525'}}>{stores.userStore.userData.email}</p>
      </div>
      <div style={{
        flexGrow: 1
      }}/>
      <Tooltip title={'Функция уведолмений находится в разработке'}>
        <NotificationsActiveOutlined sx={{
          color: isDarkTheme ? '#d0d0d0' : '#252525',
          '&:hover': {
            color: '#32CAB8',
            cursor: 'pointer'
          }
        }}/>
      </Tooltip>
    </div>
  )
})

const styles = StyleSheet.create({
  dataContainer: {
    ...baseStyles.verticalContainer,
    margin: '0 20px 0 10px',
    alignItems: 'flex-start',
  },
  login: {
    fontFamily: 'Roboto',
    fontWeight: '500',
    fontSize: '16px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: '150px',
    textAlign: 'left',
    letterSpacing: '0.5px'
  },
  email: {
    fontFamily: 'Roboto',
    fontWeight: '400',
    fontSize: '12px',
    marginTop: '2px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: '150px',
    textAlign: 'left',
    letterSpacing: '0.5px'
  }
})