export type UserAdditionalDataModel = {
	education: string | null,
	income: string | null,
	laborStatus: string | null,
	laborArea: string | null,
	familyStatus: string | null,
	childrenAmount: string | null,
	expectations: string | null,
	vkProfileConnected: boolean,
	telegramProfileConnected: boolean
}

export const initUserAdditionalDataModel: UserAdditionalDataModel = {
	education: null,
	income: null,
	laborStatus: null,
	laborArea: null,
	familyStatus: null,
	childrenAmount: null,
	expectations: null,
	vkProfileConnected: false,
	telegramProfileConnected: false
}