import {Box, MenuItem, SxProps} from '@mui/material';
import React, {CSSProperties, useState} from 'react';
import CBButton from '../../baseComponent/buttons/mainButton';
import {Colors, ComponentSize, FontStyle, FontType, GrayScale} from '../../enums';
import CBText from '../../baseComponent/text';
import {CBDropdown} from '../../baseComponent/dropdown';
import CBCategories from '../categories';
import {StyleSheet} from "../../../utils/styles";
import {stores} from '../../../pages/_app';
import {CategoryModel} from "../../../types/dictionaries/categories/CategoryModel";

interface IProps {
  style?: CSSProperties | SxProps
  maxCategoriesLenght?: number,
  onSave: (value: number[]) => void,
  parentCategory?: string,
  selectedCurrentCategories?: CategoryModel[]
}

const CBCategorySelector = ({style, maxCategoriesLenght = 4, onSave, parentCategory = '', selectedCurrentCategories = []}: IProps) => {
  const [selectedParentCategory, setSelectedParentCategory] = useState(parentCategory)
  const [selectedCategories, setSelectedCategories] = useState(selectedCurrentCategories)

  React.useEffect(() => {
    setSelectedCategories(selectedCurrentCategories);
  }, [selectedCurrentCategories])

  React.useEffect(() => {
    setSelectedParentCategory(parentCategory);
  }, [parentCategory])
  const getParentsCategories = () => {
    const tempArray = []
    stores.mainStore.category.map((item) => {
      tempArray.push(item.parentCategories)
    })
    const uniqueParentCategories = new Set(tempArray)
    const parentCategories = Array.from(uniqueParentCategories)

    return parentCategories
  }

  const getCategories = () => {
    const categories = stores.mainStore.category.filter((value) => {
      return value.parentCategories === selectedParentCategory
    })

    return categories
  }

  const handleClick = (index) => {
    if (selectedCategories.find(c => c?.id === getCategories()[index].id)) {
      setSelectedCategories((prev) => [...prev].filter(c => c?.id !== getCategories()[index].id))
    }
    else if (selectedCategories.length > maxCategoriesLenght - 1) {
      return
    }
    else {
      setSelectedCategories(prev => [...prev, ...stores.mainStore.category.filter(c => c?.id === getCategories()[index].id)])
    }    
  }

  const handleClose = (value) => {
    setSelectedCategories((prev) => [...prev].filter(c => c?.title !== value))
  }

  const isSelected = (index: any) => {
    return !!selectedCategories.find(c => c?.id === getCategories()[index].id)
  }

  return (
    <Box sx={{...styles.container, ...style}}>
      <Box sx={styles.content}>
        <Box>
          <Box>
            <CBText
              style={{marginBottom: '10px'}}
              color={GrayScale.TITLE_ACTIVE_COLOR}
              type={FontType.TITLE}
              fontStyle={FontStyle.SMALLBOLD}>
                Выберите категории
            </CBText>
            <CBText
              color={GrayScale.BODY_COLOR}
              type={FontType.TEXT}
              fontStyle={FontStyle.MEDIUM}>
                Вы можете выбрать от 1 до 4 категории
            </CBText>
          </Box>
          <Box sx={{margin: '20px 0'}}>
            <CBDropdown size={ComponentSize.MEDIUM} value={selectedParentCategory} onChange={(value) => {setSelectedParentCategory(value)}}>
              {getParentsCategories().map((item, index) => (
                <MenuItem id={`${index}`} key={`${index}`} value={item}>{item}</MenuItem>
              ))}
            </CBDropdown>
          </Box>
          <CBCategories isSelected={isSelected} categories={getCategories().map(c => c?.title)} onClick={handleClick} />
        </Box>
        <Box>
          <CBText
            color={GrayScale.LABEL_COLOR}
            type={FontType.TEXT}
            fontStyle={FontStyle.MEDIUM}>
              Выбранные категории
          </CBText>
          <CBCategories
            style={{margin: '10px 0'}}
            categories={selectedCategories.map(c => c?.title)}
            canBeClosed={true}
            onClose={handleClose}
          />
          <CBButton
            size={ComponentSize.SMALL}
            width={'100%'}
            onClick={()=>{onSave(selectedCategories.map((c) => c?.id))}}>
              Сохранить
          </CBButton>
        </Box>
      </Box>
    </Box>
  )
}

export default CBCategorySelector

const styles = StyleSheet.create({
  container: {
    display: 'block',
    width: '549px',
    height: '662px',
  },

  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
})
