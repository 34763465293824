import {PageType} from "./PageType";
import {ChaptersType} from "./ChaptersType";

export type StructureType = {
  courseId: number,
  userId: number,
  pages: PageType[],
  chapters: ChaptersType[]
}

export const initialStructure: StructureType = {
  courseId: 0,
  userId: 0,
  pages: [],
  chapters: []
}