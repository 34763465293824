/*
Хелпер для бизнес логики стейтменов по тестам в курсе
Здесь будут функции по формированию стейтментов и их частей для отправки базовых событий по тестам в курсе (запуск, открытие и т.д.)
*/

import XAPI, {Actor, Context, ContextActivity, InteractionComponent, Statement, StatementObject} from "@xapi/xapi";
import {PageDetails} from "../../../types/materials/course/PageDetails";
import {UserCourseDetails} from "../../../types/materials/course/UserCourseDetails";
import {CourseLiveCycleEvents, XAPICourseLiveCycleObjects} from "../XAPIVerbsObjects";
import {stores} from "../../../pages/_app";
import {QuestionType} from "../../../types/materials/test/QuestionType";
import {XAPIUserAnswers} from "../../../types/xapi/XAPIUserAnswers";
import moment from "moment/moment";
import {AnswerType} from "../../../types/materials/test/AnswerType";
import {XAPICorrectAnswers} from "../../../types/xapi/XAPICorrectAnswers";
import {ContentType} from "../../../types/materials/ContentType";
import {XAPIRadioButtonAnswers} from "../../../types/xapi/XAPIRadioButtonAnswers";
import {XAPICheckBoxAnswers} from "../../../types/xapi/XAPICheckBoxAnswers";
import {runInAction} from "mobx";
import AnswerItem from "../../../components/IndependentComponents/DragAndDrops/TestDND/AnswerItem";
import {BaseXAPIHelper} from "./BaseXAPIHelper";

export class BaseCourseTestHelper {

  static async sendStatement(statement: Statement, xAPIInstance: XAPI){
    try {
      const data = xAPIInstance.sendStatement({
        statement: statement
      })
      console.warn(`sendTestStatement ${JSON.stringify(statement)}`)
      return Promise.resolve()
    } catch (e) {
      return Promise.reject(e)
    }
  }
  static getTestItemObject(page: PageDetails): StatementObject {
    return {
      id: `https://coursebit.ru/course/pages/test/${page.id}`,
      definition: {
        type: `http://adlnet.gov/expapi/activities/course/pages/test`,
        name: {"ru-RU": `${page.content.title ?? ''}`},
        description: {"ru-RU": `${page.content.description ?? ''}`},
      },
      objectType: 'Activity'
    }
  }

  static currentTestContextParent(userCourseData: UserCourseDetails): ContextActivity {
    return {
      id: `https://coursebit.ru/course/${userCourseData.id}`,
      definition: {
        type: 'http://adlnet.gov/expapi/activities/course',
        name: {"ru-RU": userCourseData.title ?? ''},
        description: {"ru-RU": userCourseData.description ?? ''},
      },
      objectType: 'Activity',
    }
  }

  static currentQuestionContextParent(page: PageDetails): ContextActivity {
    return {
      id: `https://coursebit.ru/course/pages/test/${page.id}`,
      definition: {
        type: 'http://adlnet.gov/expapi/activities/course/pages/test',
        name: {"ru-RU": page.content.title ?? ''},
        description: {"ru-RU": page.content.description ?? ''},
      },
      objectType: 'Activity',
    }
  }

  static getVerbContext(userCourseData: UserCourseDetails, sessionId: string): Context {
    return {
      registration: userCourseData.idUserCourse,

      contextActivities: {
        parent: [this.currentTestContextParent(userCourseData)]
      },
      extensions: {
        ...BaseXAPIHelper.getDeviceExtensions(),
        'https://w3id.org/xapi/cmi5/context/extensions/sessionid': sessionId
      }
    }
  }

  static getQuestionsAllItemsObject(testData: ContentType, userAnswers: XAPIUserAnswers[], sendCorrectAnswers: boolean = false): StatementObject {
    let choices: InteractionComponent[] = []
    let correctAnswers: AnswerType[] = []

    userAnswers.map(item => {
      item.userAnswers.map((el, index) => {
        choices = [
          ...choices,
          {
            id: `https://coursebit.ru/course/pages/test/question/${item.questionId}/answer/${el.userAnswersId}`,
            description: {
              "ru-RU": `${item.questionId}: ответ пользователя ${el.userAnswersId}`
            }
          }
        ]
      })
      correctAnswers = [
        ...correctAnswers,
        ...item.correctAnswers.correct
      ]
    })

    return {
      id: `https://coursebit.ru/course/pages/test/${testData.id}`,
      definition: {
        type: "http://adlnet.gov/expapi/activities/cmi.interaction",
        name: {"ru-RU": `Вопрос: ${testData.title ?? ""}`},
        description: {"ru-RU": `${testData.description ?? ""}`},
        interactionType: 'choice',
        choices: choices,
        ...(sendCorrectAnswers ? {correctResponsesPattern: [`${correctAnswers.map(item => item.id).join('[,]')}`]} : {})
      },
      objectType: 'Activity'
    }
  }

  static getQuestionItemObject(question: QuestionType, userAnswers: XAPIUserAnswers, sendCorrectAnswers: boolean = false): StatementObject {
    let choices: InteractionComponent[] = []

    userAnswers.userAnswers.map((item, index) => {
      choices = [
        ...choices,
        {
          id: `https://coursebit.ru/course/pages/test/question/answer/${item.userAnswersId}`,
          description: {
            "ru-RU": `${index + 1} ответ пользователя`
          }
        }
      ]
    })


    return {
      id: `https://coursebit.ru/course/pages/test/question/${question.questionId}`,
      definition: {
        type: "http://adlnet.gov/expapi/activities/cmi.interaction",
        name: {"ru-RU": `Вопрос: ${question.title ?? ""}`},
        description: {"ru-RU": `${question.description ?? ""}`},
        // interactionType: 'choice',
        // choices: choices,
        ...(sendCorrectAnswers ? {correctResponsesPattern: [`${userAnswers.correctAnswers.correct.map(item => item.id).join('[,]')}`]} : {})
      },
      objectType: 'Activity'
    }
  }

  static getUserAnswerResponse(page: PageDetails, userAnswers: XAPIUserAnswers[]): string[] {
    const userAnswer: XAPIRadioButtonAnswers[] | XAPICheckBoxAnswers[] = userAnswers.reduce((arr, answer) => {
      return [
        ...arr,
        ...answer.userAnswers
      ]
    }, [])

    return page.content.questions.reduce((arr, question) => {
      return [
        ...arr,
        ...userAnswer
          .filter(item => item.questionId === question.questionId)
          .map(item => `${question.questionId}:${item.userAnswersId}`)
      ]
    }, [])
  }

  static getCurrentQuestionCorrectAnswers(correctAnswers: XAPICorrectAnswers[], questionId: number): string[] {
    return [
      ...correctAnswers
          .filter(item => item.questionId === questionId)
          .reduce((answers, answer) => {
            return [
              ...answers,
              ...answer.correct.map(el => `${questionId}: ${el.id}`)
            ]
          }, [])
    ]
  }

  static getCorrectAnswersResponse(page: PageDetails, correctAnswers: XAPICorrectAnswers[]): string[] {
    return page.content.questions.reduce((arr, question) => {
      return [
        ...arr,
        ...this.getCurrentQuestionCorrectAnswers(correctAnswers, question.questionId)
      ]
    }, [])
  }

  static getIsCurrentAnswerCorrect(correctAnswers: AnswerType[], userAnswerId: number): string {
    return `${correctAnswers.find(item => item.id === userAnswerId).id}`
  }

  static getCurrentSuccessAnswer(correctAnswers: AnswerType[], userAnswerId: number): number {
    return correctAnswers.findIndex(item => item.id === userAnswerId)
  }

  static getCorrectAnswer(userAnswers): AnswerType[] {
    let correctAnswer: AnswerType[] = []
    userAnswers.map(item => {
      correctAnswer = [...correctAnswer, ...item.correctAnswers.correct]
    })

    return correctAnswer
  }

  static getUserAnswersIds(userAnswers): {id: number, title: string, questionId: number}[] {
    let userAnswer: {id: number, title: string, questionId: number}[] = []
    userAnswers.map(item => {
      item.userAnswers.map(element => {
        userAnswer = [...userAnswer, {id: element.userAnswersId, title: element.answerTitle, questionId: element.questionId}]
      })
    })

    return userAnswer
  }

  static getCorrectUserAnswerCount(correctAnswer: AnswerType[], userAnswer: {id: number, title: string}[]): number {
    return correctAnswer.map(correctAns => {
      return correctAns.id
    }).filter(item => {
      return userAnswer.find(ans => ans.id === item)
    }).length
  }

  static getQuestions(page: PageDetails): {questionId: number, title: string, answers: AnswerType[]}[] {
    return page.content.questions.reduce((prev, value) => {
      return [
        ...prev,
        {
          questionId: value.questionId,
          title: value.title,
          answers: value.answers
        }
      ]
    },[])
  }

  static async sendTestTryStartLearning(
    currentActor: Actor,
    page: PageDetails,
    userCourseData: UserCourseDetails,
    sessionId: string,
    xAPIInstance: XAPI
  ) {
    stores.xAPIService.testAttempt.set(`${page.content.id}`, !!stores.xAPIService.testAttempt.get(`${page.content.id}`) ? stores.xAPIService.testAttempt.get(`${page.content.id}`) + 1 : 1)
    const statement: Statement = {
      actor: currentActor,
      verb: XAPICourseLiveCycleObjects[CourseLiveCycleEvents.TEST_TRY_START_LEARNING],
      object: this.getTestItemObject(page),
      context: this.getVerbContext(userCourseData, sessionId),
      timestamp: new Date().toISOString()
    }
    await this.sendStatement(statement, xAPIInstance)
  }

  static async sendTestTryEndLearning(
    success: boolean,
    currentActor: Actor,
    page: PageDetails,
    userCourseData: UserCourseDetails,
    sessionId: string,
    userAnswers: XAPIUserAnswers[],
    xAPIInstance: XAPI
  ) {

    const verb = success
        ? XAPICourseLiveCycleObjects[CourseLiveCycleEvents.TEST_TRY_END_LEARNING]
        : XAPICourseLiveCycleObjects[CourseLiveCycleEvents.TEST_TRY_FAILED_LEARNING]

    const userAnswerResponse: string[] = this.getUserAnswerResponse(page, userAnswers)
    const userAnswer: {id: number, title: string, questionId: number}[] = this.getUserAnswersIds(userAnswers)
    const correctAnswer: AnswerType[] = this.getCorrectAnswer(userAnswers)
    const correctUserAnswerCount: number = this.getCorrectUserAnswerCount(correctAnswer, userAnswer)

    const statement: Statement = {
      actor: currentActor,
      verb: verb,
      object: this.getTestItemObject(page),
      result: {
        success: success,
        response: `${userAnswerResponse.join(`[,]`)} `,
        score: {
          min: 0,
          max: correctAnswer.length,
          raw: correctUserAnswerCount,
          scaled: correctUserAnswerCount/correctAnswer.length
        },
      },
      context: this.getVerbContext(userCourseData, sessionId),
      timestamp: new Date().toISOString()
    }

    await this.sendStatement(statement, xAPIInstance)
  }

  static async sendTestTryCancelLearning(
    currentActor: Actor,
    page: PageDetails,
    correctAnswers: XAPICorrectAnswers[],
    userCourseData: UserCourseDetails,
    sessionId: string,
    xAPIInstance: XAPI
  ) {
    const correctAnswerResponse: string [] = this.getCorrectAnswersResponse(page, correctAnswers)

    const statement: Statement = {
      actor: currentActor,
      verb: XAPICourseLiveCycleObjects[CourseLiveCycleEvents.TEST_TRY_CANCEL_LEARNING],
      object: this.getTestItemObject(page),
      result: {
        success: false,
        response: `${correctAnswerResponse.join(`[,]`)}`
      },
      context: this.getVerbContext(userCourseData, sessionId),
      timestamp: new Date().toISOString()
    }

    await this.sendStatement(statement, xAPIInstance)
  }

  static async sendTestOpenForRelearning(
    currentActor: Actor,
    page: PageDetails,
    userCourseData: UserCourseDetails,
    sessionId: string,
    xAPIInstance: XAPI
  ) {
    const statement: Statement = {
      actor: currentActor,
      verb: XAPICourseLiveCycleObjects[CourseLiveCycleEvents.TEST_OPEN_FOR_RELEARNING],
      object: this.getTestItemObject(page),
      context: this.getVerbContext(userCourseData, sessionId),
      timestamp: new Date().toISOString()
    }
    await this.sendStatement(statement, xAPIInstance)
  }

  static async sendTestCloseOfRelearning(
    currentActor: Actor,
    page: PageDetails,
    userCourseData: UserCourseDetails,
    sessionId: string,
    xAPIInstance: XAPI
  ) {
    const statement: Statement = {
      actor: currentActor,
      verb: XAPICourseLiveCycleObjects[CourseLiveCycleEvents.TEST_CLOSE_OF_RELEARNING],
      object: this.getTestItemObject(page),
      context: this.getVerbContext(userCourseData, sessionId),
      timestamp: new Date().toISOString()
    }

    await this.sendStatement(statement, xAPIInstance)
  }

  static async sendTestAnswerChoose(
    currentActor: Actor,
    question: QuestionType,
    userAnswers: XAPIUserAnswers,
    userAnswerId: number,
    userCourseData: UserCourseDetails,
    sessionId: string,
    correctAnswers: AnswerType[],
    xAPIInstance: XAPI
  ) {
    const statement: Statement = {
      actor: currentActor,
      verb: XAPICourseLiveCycleObjects[CourseLiveCycleEvents.TEST_ANSWER_CHOOSE],
      object: this.getQuestionItemObject(question, userAnswers),
      result: {
        response: this.getIsCurrentAnswerCorrect(correctAnswers, userAnswerId),
        extensions: {
          'https://coursebit.ru/course/pages/test/question/answer/': this.getCurrentSuccessAnswer(correctAnswers, userAnswerId) > -1 ? 'a18bb47a-2b07-4f24-a87b-10dd8738fde4' : '7b778219-f46f-4d03-9c19-67b7ea039623'
        }
      },
      context: this.getVerbContext(userCourseData, sessionId),
      timestamp: new Date().toISOString()
    }

    await this.sendStatement(statement, xAPIInstance)
  }

  static async sendTestAnswerAnswered(
    currentActor: Actor,
    userAnswers: XAPIUserAnswers[],
    timeStartLearningMaterialInMilliseconds: number,
    idUserCourse: string,
    page: PageDetails,
    sessionId: string,
    xAPIInstance: XAPI
  ) {
    const userAnswer: {id: number, title: string, questionId: number}[] = this.getUserAnswersIds(userAnswers)
    const questions: {questionId: number, title: string, answers: AnswerType[]}[] = this.getQuestions(page)
    const correctAnswer: AnswerType[] = this.getCorrectAnswer(userAnswers)
    const correctUserAnswerCount: number = this.getCorrectUserAnswerCount(correctAnswer, userAnswer)
    const statement: Statement = {
      actor: currentActor,
      verb: XAPICourseLiveCycleObjects[CourseLiveCycleEvents.TEST_ANSWER_ANSWERED],
      object: this.getQuestionsAllItemsObject(page.content, userAnswers, true),
      result: {
        success: correctUserAnswerCount/correctAnswer.length >= 0.75,
        duration: `${moment.duration(Date.now() - timeStartLearningMaterialInMilliseconds, 'ms').toISOString()}`,
        response: JSON.stringify(userAnswer),
        score: {
          min: 0,
          max: correctAnswer.length,
          raw: correctUserAnswerCount,
          scaled: correctUserAnswerCount/correctAnswer.length
        },
      },
      context: {
        registration: idUserCourse,
        contextActivities: {
          parent: [this.currentQuestionContextParent(page)]
        },
        extensions: {
          ...BaseXAPIHelper.getDeviceExtensions(),
          'https://w3id.org/xapi/cmi5/context/extensions/sessionid': sessionId,
          'https://coursebit.ru/questions': questions
        }
      },
      timestamp: new Date().toISOString()
    }
    runInAction(() => {
      stores.xAPIService.successTest.set(`${page.content.id}`, statement.result.success)
      stores.xAPIService.testResult.set(`${page.content.id}`, statement.result.score.scaled )
    })

    await this.sendStatement(statement, xAPIInstance)
  }
}
