import React, {ReactElement, useEffect} from "react";
import {observer} from "mobx-react-lite";
import {stores} from "../../pages/_app";
import Head from "next/head";
import {Footer} from "../footer";
import {Loader} from "../baseComponents/loader";
import {useRouter} from "next/router";
import {Portal, Snackbar} from "@mui/material";
import {PageProfileNames} from "../../stores/MenuProfileStore";
import {Routers} from "../../utils/Routers";
import {DialogType} from "../MaterialDialog/MaterialDialogStore";
import {MaterialDialog} from "../MaterialDialog";
import CBHeaderMenu from "../../uikit/components/headerMenu";
import {CBModalType} from "../../uikit/baseComponent/modal";
import CBModalSubscribe from '../../uikit/components/modals/CBModalSubscribe';
import {GrayScale} from "../../uikit/enums";
import {CBLoader} from "../../uikit/components/loader";

interface IProps {
  children: ReactElement
}

const MainLayout = ({ children }: IProps) => {
  const router = useRouter()

  useEffect(() => {
    stores.adaptiveService.subscribeOnResizingView()
    stores.adaptiveService.handleSubscribeWindowWidth()

    return(() => {
      stores.adaptiveService.removeAllListeners()
    })
  }, [])

  useEffect(() => {
    if (router.pathname !== '/profile/courses')
      stores.endRegistrationStore.checkUserIsEmpty(() => {
        router.push(Routers.PERSONAL_PROFILE_WITH_PARAMETER.replace(":pageType", PageProfileNames.courses))
      })
  }, [stores.authStore.isUserAuth, stores.userStore.userData])

  const renderLayout = () => (
    <>
      <Head>
        <title>COURSEBIT</title>
        <meta property="og:url" content="https://coursebit.ru/"/>
        <meta property="og:type" content="website"/>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Head>
      {router.pathname.indexOf('og-image') === -1 && <CBHeaderMenu/>}
      <main style={{
        flexGrow: 1,
        backgroundColor: GrayScale.BACKGROUND_COLOR
      }}>{children}</main>
      {router.pathname.indexOf('og-image') === -1 && <Footer />}
    </>
  )

  const renderModals = () => {
    return Object.keys(DialogType).map(key => {
      switch (key) {
        case DialogType.userSettings:
          return <MaterialDialog dialogContent={DialogType[key]} dialogType={CBModalType.BOTTOM} bottomStyles={{'& > div': {padding: '17px 30px 30px 30px'} }} />;
        case DialogType.learningMaterial:
          return <MaterialDialog dialogContent={DialogType[key]} bottomStyles={{height: '100%', overflowY: 'scroll'}} />
        default:
          return <MaterialDialog dialogContent={DialogType[key]} callback={stores.materialDialogStore.callback} title={stores.materialDialogStore.title} id={stores.materialDialogStore.id} />
      }
      }
    )
  }

  const renderSubscribeModal = () => {
    return !stores.authStore.isUserAuth && (
      <CBModalSubscribe />
    )
  }

  const globalLoading = () => {
    return <CBLoader />
  }

  return (
    <div style={{height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
      {globalLoading()}
      {renderLayout()}
      {renderModals()}
      {renderSubscribeModal()}
      {
        stores.mainStore.isGlobalLoading && router.pathname.indexOf('og-image') === -1 && router.pathname.indexOf('bitorium/articles/') === -1
          && <Loader />
      }
      <Portal>
        <Snackbar
          open={stores.materialDialogStore.isMessageViewOpen}
          autoHideDuration={stores.materialDialogStore.messageDuration || 2000}
          onClose={() => stores.materialDialogStore.setMessageViewState(false)}
          message={stores.materialDialogStore.messageView}
          style={{zIndex: 10}}
        />
      </Portal>
    </div>
  )
}

export default observer(MainLayout)
