import {CategoryModel} from "../dictionaries/categories/CategoryModel";

export type UserPublicModel = {
  id: number,
  login: string,
  profilePhoto: string | null,
  age: number | null,
  country: string | null,
  city: string | null,
  aboutMe: string | null,
  interests: CategoryModel[],
  isAuthor: boolean,
  isStudent: boolean,
  coverImage: string | null,
  fio: string | null,
  email: string | null
}

export const initUserPublicModel: UserPublicModel = {
  id : 0,
  login: "",
  profilePhoto: null,
  age: null,
  country: null,
  city: null,
  aboutMe: null,
  interests: [],
  isAuthor: false,
  isStudent: false,
  coverImage: null,
  fio: null,
  email: null
}
