import React, {useState} from 'react';
import CBText from "../../baseComponent/text";
import {Colors, ComponentSize, ComponentType, FontStyle, FontType, GrayScale} from "../../enums";
import {CBCheckbox} from "../../baseComponent/checkbox";
import {baseStyles} from "../../../utils/baseStyles";
import CBButton from "../../baseComponent/buttons/mainButton";
import {Stack} from "@mui/material";
import {StyleSheet} from "../../../utils/styles";
import {observer} from "mobx-react-lite";
import {baseButtonStyles} from "../../../utils/baseButtonStyles";
import {stores} from "../../../pages/_app";

interface IProps {
	title: string,
	description: string,
	additionalDescription?: string,
	agreement?: string,
	discardButton: string,
	discardButtonDisabled?: boolean,
	acceptButton: string,
	acceptButtonLoading: boolean,
	onAccess: () => void,
	onDiscard: () => void,
	type?: 'checkbox' | 'confirmation'
}

const CBModalWithCheckbox = observer(({
																				title,
																				description,
																				additionalDescription,
																				agreement,
																				discardButton,
																				discardButtonDisabled = false,
																				acceptButton,
																				acceptButtonLoading,
																				onAccess,
																				onDiscard,
																				type = 'checkbox'
																			}: IProps) => {
	const [isAgree, setAgree] = useState(false)
	return (
		<Stack style={styles.container}>
			<CBText
				children={title}
				color={GrayScale.TITLE_ACTIVE_COLOR}
				type={FontType.TITLE}
				fontStyle={FontStyle.SMALLBOLD}
				style={{...styles.title, ...(!stores.adaptiveService.isMinDesktopMini && {fontSize: '26px'})}}
			/>
			<CBText
				children={description}
				type={FontType.TEXT}
				fontStyle={!stores.adaptiveService.isMinDesktopMini ? FontStyle.XSMALL : FontStyle.MEDIUM}
				color={GrayScale.BODY_COLOR}
			/>
			{
				additionalDescription &&
				<CBText children={additionalDescription} type={FontType.TEXT} fontStyle={!stores.adaptiveService.isMinDesktopMini ? FontStyle.XSMALL : FontStyle.MEDIUM}
								color={Colors.PRIMARY_COLOR} style={styles.additionalDescription}/>
			}
			{
				type === 'checkbox' &&
				<div style={styles.checkbox}>
					<CBCheckbox
						onChange={(event) => setAgree(event)}
						label={
							<CBText
								children={agreement}
								type={FontType.TEXT}
								fontStyle={!stores.adaptiveService.isMinDesktopMini ? FontStyle.XSMALL : FontStyle.SMALL}
								color={GrayScale.BODY_COLOR}
							/>
						}
						defaultChecked={false}
					/>
				</div>
			}
			<div style={{...baseStyles.horizontalContainer, marginTop: '30px'}}>
				<CBButton
					disabled={discardButtonDisabled}
					onClick={onDiscard}
					children={discardButton}
					size={ComponentSize.SMALL}
					type={ComponentType.ERROR}
					style={{...styles.cancelButton, ...baseButtonStyles.buttonDiscard}}
					width={'50%'}
				/>
				<CBButton
					disabled={type === 'confirmation' ? false : !isAgree}
					loading={acceptButtonLoading}
					size={ComponentSize.SMALL}
					type={ComponentType.PRIMARY}
					children={acceptButton}
					width={'50%'}
					onClick={onAccess}
				/>
			</div>
		</Stack>
	);
});

const styles = StyleSheet.create({
	container: {
		maxWidth: '538px',
		 width: '100%'
	},
	title: {
		marginBottom: '20px'
	},
	checkbox: {
		marginTop: '10px'
	},
	additionalDescription: {
		marginTop: '20px'
	},
	cancelButton: {
		marginRight: '10px',
	}
})

export default CBModalWithCheckbox;