import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd'
import {observer} from "mobx-react-lite";
import {useEffect, useState} from "react";
import {stores} from "../../../../pages/_app";
import {AnswerType} from "../../../../types/materials/test/AnswerType";
import {TypeOfQuestionModal} from "../../../../stores/TestEditViewStore";
import AnswerItem from "./AnswerItem";

const QuestionDragAndDrop = observer(() => {
  const [questions, updateQuestions] = useState(stores.testEditViewStore.currentQuestionStructure)

  useEffect(() => {
    updateQuestions(stores.testEditViewStore.currentQuestionStructure)
  }, [stores.testEditViewStore.currentQuestionStructure])

  const handleOnDragEnd = (result) => {
    if (!result.destination) return
    const items: AnswerType[] = Array.from(questions || [])
    const [reorderedItem] = items.splice(result.source.index, 1)
    items.splice(result.destination.index, 0, reorderedItem)
    if (stores.testEditViewStore.currentOpenModal === TypeOfQuestionModal.create) {
      stores.testEditViewStore.changeQuestionStructure('create',items)
    } else {
      stores.testEditViewStore.changeQuestionStructure('change', items)
    }
    updateQuestions(items)
  }
  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <Droppable droppableId='questionList'>
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {questions?.map((item, index) => {
              return(
                <Draggable key={item.id} draggableId={item.id?.toString()} index={index}>
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                      <AnswerItem answer={item} />
                    </div>
                  )}
                </Draggable>
              )
            })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
})

export default QuestionDragAndDrop