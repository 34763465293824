import emailjs from 'emailjs-com';

export class MailService {

  private static credetionals = {
    serviceId: 'service_coursebit',
    templateId: 'template_support',
    publicKey: 'MLJlStOKdu2Xb8A5O'
  }

  public static mailSend = (email: string, subject: string, text: string, successCallback: () => void, errorCallback: () => void) => {
    emailjs.send(MailService.credetionals.serviceId, MailService.credetionals.templateId,
      {
        email: email,
        subject: subject,
        text: text
      }, MailService.credetionals.publicKey)
      .then((result) => {
        successCallback()
      }, (error) => {
        errorCallback()
      })
      .catch(e => errorCallback());
  }
}