export type CompleteAuthValidateModal = {
  isLoginValid: boolean,
  isEmailValid: boolean,
  passwordRegister: boolean,
  passwordSymbols: boolean,
  passwordNumber: boolean,
  passwordEqual: boolean,
  isFirstNameValid: boolean,
  isSecondNameValid: boolean,
  isThirdNameValid: boolean,
  isBirthDateValid: boolean,
  isCountryValid: boolean,
  isCityValid: boolean,
  isInterestsValid: boolean
  isTermsAccept?: boolean,
}

export const initialCompleteAuthValidateModal: CompleteAuthValidateModal = {
  isLoginValid: false,
  isEmailValid: false,
  passwordRegister: false,
  passwordSymbols: false,
  passwordNumber: false,
  passwordEqual: false,
  isFirstNameValid: false,
  isSecondNameValid: false,
  isThirdNameValid: false,
  isBirthDateValid: false,
  isCountryValid: false,
  isCityValid: false,
  isInterestsValid: false,
  isTermsAccept: false,
}
