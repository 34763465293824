import {makeAutoObservable, runInAction} from "mobx";
import {IRootStore} from "./RootStore";
import networkService from "../../services/NetworkService";
import {CategoryModel} from "../../types/dictionaries/categories/CategoryModel";
import {CategoryResponseModel} from "../../types/dictionaries/categories/CategoryResponseModel";
import {SortModel} from "../../types/dictionaries/sorting/SortModel";
import {SortResponseModel} from "../../types/dictionaries/sorting/SortResponseModel";
import {PrivacyModel} from "../../types/dictionaries/privacies/PrivacyModel";
import {PrivacyResponseModel} from "../../types/dictionaries/privacies/PrivacyResponseModel";

export default class MainStore {
  isGlobalLoading: boolean = true
  privacies: PrivacyModel[] = []
  category: CategoryModel[] = []
  sorts: SortModel[] = []

  private rootStore: IRootStore
  constructor(rootStore: IRootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore

    this.loadMainData().then(
      onSuccess => {

      },
      onError => {

      }
    )
  }

  loadMainData = async () => {
    this.setIsGlobalLoading(true)
    try {
      await this.loadPrivacy()
      await this.loadCategory()
      await this.loadSorts()
      await this.rootStore.authStore.checkIsUserAuth()
      runInAction(() => this.setIsGlobalLoading(false))
    } catch (e) {
      alert("Ошибка загрузки данных, попробуйте перезагрузить страницу " + JSON.stringify(e.response.data))
    }
  }

  setIsGlobalLoading = (value: boolean) => {
    this.isGlobalLoading = value
  }

  loadPrivacy = async () => {
    try {
      const {data}: PrivacyResponseModel = await networkService.post('dictionary/getAllPrivacy')
      runInAction(() =>
        this.privacies = data.filter(item => item.code !== 'link')
      )
      return Promise.resolve()
    } catch (e) {
      // @ts-ignore
      return Promise.reject(e.response.data)
    }
  }

  loadCategory = async () => {
    try {
      const {data}: CategoryResponseModel = await networkService.post('dictionary/getAllCategory')
      runInAction(() =>
        this.category = data.sort((a, b) => a.parentCategories > b.parentCategories ? 1 : -1)
      )
      return Promise.resolve()
    } catch (e) {
      // @ts-ignore
      return Promise.reject(e.response.data)
    }
  }

  loadSorts = async () => {
    try {
      const {data}: SortResponseModel = await networkService.post('dictionary/getAllSortTypes')
      runInAction(() => {
        this.sorts = data
      })
      return Promise.resolve()
    } catch (e) {
      // @ts-ignore
      return Promise.reject(e.response.data)
    }
  }

}

export interface IMainStore extends MainStore {
}
