export type AuthDataModel = {
  login: string,
  password: string,
  passwordRepeat: string,
  email: string
}

export const initAuthModel: AuthDataModel = {
  login: "",
  password: '',
  passwordRepeat: '',
  email: undefined
}
