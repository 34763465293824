import {observer} from "mobx-react-lite";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {
  CircularProgress,
  DialogActions,
  DialogContent, DialogContentText,
  DialogProps, DialogTitle,
} from "@mui/material";
import CBButton from "../../../../uikit/baseComponent/buttons/mainButton";
import {Colors, ComponentSize, ComponentType, GrayScale} from "../../../../uikit/enums";
import {baseButtonStyles} from "../../../../utils/baseButtonStyles";
import {defaultFontStyles} from "../../../../uikit/fontStyle";
import {baseStyles} from "../../../../utils/baseStyles";
import {CommentItem} from "../../../pagesComponents/studyCoursePage/discussion/CommentItem";
import {StyleSheet} from "../../../../utils/styles";
import {stores} from "../../../../pages/_app";
import {GPTRequestType} from "../../../../stores/GPTStore";
import CommentAnalytics from "../../../pagesComponents/studyCoursePage/discussion/CommentAnalytics";

interface IProps {

}
const DiscussionModal = observer(({}: IProps) => {
  const [scroll, setScroll] = useState<DialogProps['scroll']>('paper')
  const descriptionElementRef = useRef<HTMLElement>(null);
  useEffect(() => {
    if (stores.materialDialogStore.isOpen) {
      const {current: descriptionElement} = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [stores.materialDialogStore.isOpen]);

  useEffect(() => {
    if (!stores.materialDialogStore.isOpen) {
      stores.gptStore.clearAnalysisData()
    }

    (async () => {
      await stores.gptStore.getAnalysisForFeedback(stores?.courseEditViewStore?.currentCourse?.id)
    })()
  }, [stores.materialDialogStore.isOpen]);

  const handleClose = () => {
    stores.gptStore.clearFeedbackData();
    stores.materialDialogStore.setCurrentState(false)
  }

  const renderFeedbacks = useCallback(() => {
    return (
      <div>
        {stores.gptStore?.analysisFeedbacks?.map((item, index) => {
          return (
            <CommentAnalytics key={`${item.text}${index}`} analyticsData={item}/>
          )
        })}
      </div>
    )
  }, [])

  return (
    <>
      <DialogTitle id="scroll-dialog-title" sx={styles.title}
                   children={`Обсуждения курса "${stores?.courseEditViewStore?.currentCourse?.title}"`}/>
      {!stores.gptStore.isGetAnalysisFeedbacksInProcess && !!stores.gptStore.analysisFeedbacks ? (
        <>
          <DialogContent dividers={scroll === 'paper'}>
            <DialogContentText
              id="scroll-dialog-description"
              ref={descriptionElementRef}
              tabIndex={-1}
              sx={{width: '900px', height: 'auto'}}
            >
              {renderFeedbacks()}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <CBButton
              disabled={false}
              onClick={handleClose}
              children={'Закрыть'}
              size={ComponentSize.SMALL}
              type={ComponentType.PRIMARY}
              style={baseButtonStyles.buttonDiscard}
            />
          </DialogActions>
        </>
      ) : (
        <div style={styles.progressContainer}>
          <CircularProgress sx={styles.progress}/>
        </div>
      )
      }
    </>
  )
})

const styles = StyleSheet.create({
  title: {
    ...defaultFontStyles.displaySmallBold,
  },
  content: {
    ...defaultFontStyles.linkSmall,
  },
  progressContainer: {
    display: 'flex',
    ...baseStyles.centeredView,
    width: '1048px',
    height: '616px'
  },
  progress: {
    alignSelf: 'center',
    color: Colors.PRIMARY_COLOR,
  },
  checkbox: {
    '&.MuiCheckbox-root': {
      padding: '3px 10px'
    },
    '&.Mui-checked': {
      color: '#32BD9C'
    }
  }
})

export default DiscussionModal