import {makeAutoObservable, runInAction} from "mobx";
import {IRootStore} from "./RootStore";
import {initUserDataModel, UserDataModel} from "../../types/user/UserDataModel";
import {AuthDataValidateModel, initAuthDataValidateModel} from "../../types/auth/AuthDataValidateModel";
import {getUserId} from "../../services/localStorage/LocalStorageScope";
import networkService from "../../services/NetworkService";
import {ChangePasswordModel, initChangePasswordModel} from "../../types/user/ChangePasswordModel";
import {initUserPrivacySettingsModel, UserPrivacySettingsModel} from "../../types/user/UserPrivacySettingsModel";
import {UserResponseDataModel} from "../../types/user/UserResponseDataModel";
import {StorageDataBySubscribeModel} from "../../types/userStorage/StorageDataBySubscribeModel";
import {FormatedStorageModel} from "../../types/userStorage/FormatedStorageModel";
import {isNullOrEmpty} from "../../utils/StringUtils";
import {CategoryModel} from "../../types/dictionaries/categories/CategoryModel";
import {stores} from "../../pages/_app";
import {arraysEqual} from "../../utils/ArrayUtils";
import {
  initUserAdditionalDataModel,
  UserAdditionalDataModel
} from "../../types/user/UserAdditionalDataModel";

export class UserStore {
  userData: UserDataModel = initUserDataModel
  changeMainInfoBuffer: UserDataModel = initUserDataModel
  userStorageData: StorageDataBySubscribeModel | null = null
  isUserStorageDataLoading: boolean = false
  isUserDataLoading: boolean = false
  isMainDataSave: boolean = false
  changePasswordData: ChangePasswordModel = initChangePasswordModel
  validChangePassword: AuthDataValidateModel = initAuthDataValidateModel
  isChangePasswordProcess: boolean = false
  userPrivacySettings: UserPrivacySettingsModel = initUserPrivacySettingsModel
  userPrivacySettingsBuffer: UserPrivacySettingsModel = initUserPrivacySettingsModel
  isUserPrivacySettingsLoading: boolean = false
  isUserPrivacySettingsProcess: boolean = false
  isUserInterestsSettingsProcess: boolean = false
  changeUserInterests: string | null = null
  userAdditionalData: UserAdditionalDataModel = initUserAdditionalDataModel
  userAdditionalDataBuffer: UserAdditionalDataModel = initUserAdditionalDataModel
  isUserAdditionalDataLoading: boolean = false
  isUserAdditionalDataProcess: boolean = false

  private rootStore: IRootStore
  constructor(rootStore: IRootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  get isUserPrivacySettingsChanged() {
    return JSON.stringify(this.userPrivacySettingsBuffer) !== JSON.stringify(this.userPrivacySettings)
  }

  resetChangeInterestsUser = () => {
    this.changeUserInterests = null
  }

  saveChangeInterestsUser = (ids: number[]) => {
    this.changeUserInterests = this.changeMainInfoBuffer.interests = (ids as number[]).join('|')
  }

  get isChangeInterestsValid() {
    if (this.changeUserInterests === null)
      return false

    const ids = this.changeUserInterests.split('|').map(item => +item).filter(item => item !== 0)
    return ids.length > 0 && ids.length < 5
  }

  get isChangeInterestsChanged() {
    const baseListIds = typeof this.rootStore.userStore.userData?.interests === "object"
      ? this.rootStore.userStore.userData?.interests?.map(item => +item.id)
      : this.rootStore.userStore.userData?.interests.split('|').map(item => +item).filter(item => item !== 0)


    if (baseListIds.length === 0 || this.changeUserInterests === null)
      return false

    const changedIds = this.changeUserInterests.split("|").map(item => +item).filter(item => item !== 0)


    if (changedIds.length !== baseListIds.length)
      return true

    return !arraysEqual(changedIds, baseListIds)
  }

  get getValidPasswordState() {
    return this.validChangePassword.passwordRegister
      && this.validChangePassword.passwordLength
      && this.validChangePassword.passwordEqual
      && this.validChangePassword.passwordNumber
      && this.validChangePassword.passwordSymbols
      && this.validChangePassword.passwordLatin
      && (this.changePasswordData.oldPassword?.length || 0) > 0
      && this.changePasswordData.oldPassword !== this.changePasswordData.password
  }

  get isChangePasswordDataChanged() {
    return JSON.stringify(this.changePasswordData) !== JSON.stringify(initChangePasswordModel)
  }

  get isCategoryLengthValidInBuffer() {
    return (this.changeMainInfoBuffer.interests?.length || 0) > 0
  }

  get isFirstNameValidInBuffer() {
    return isNullOrEmpty(this.changeMainInfoBuffer.firstName)
      ? false
      : this.changeMainInfoBuffer.firstName?.length < 51
      && /^\p{sc=Cyrillic}+([-\s]?\p{sc=Cyrillic})*$/gu.test(this.changeMainInfoBuffer.firstName)
  }

  get isSecondNameValidInBuffer() {
    return isNullOrEmpty(this.changeMainInfoBuffer.secondName)
      ? false
      : this.changeMainInfoBuffer.secondName?.length < 51
      && /^\p{sc=Cyrillic}+([-\s]?\p{sc=Cyrillic})*$/gu.test(this.changeMainInfoBuffer.secondName)
  }

  get isThirdNameValidInBuffer() {
    return isNullOrEmpty(this.changeMainInfoBuffer.thirdName)
      ? true
      : this.changeMainInfoBuffer.thirdName?.length < 51
      && /^\p{sc=Cyrillic}+([-\s]?\p{sc=Cyrillic})*$/gu.test(this.changeMainInfoBuffer.thirdName)
  }

  get isEmailValidInBuffer() {
    return isNullOrEmpty(this.changeMainInfoBuffer.email)
      ? false
      : /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/gmus.test(this.changeMainInfoBuffer.email!)
      && this.changeMainInfoBuffer.email.length < 256
  }

  get isBirthDateValidInBuffer() {
    const birthDateTime = Date.parse(this.changeMainInfoBuffer.birthDate)
    return isNullOrEmpty(this.changeMainInfoBuffer.birthDate)
      ? false
      : birthDateTime >= Date.parse("1900-01-01")
      && birthDateTime < Date.now()
  }

  get isCountryValidInBuffer() {
    return isNullOrEmpty(this.changeMainInfoBuffer.country)
      ? false
      : this.changeMainInfoBuffer.country?.length < 51
      && /^(?!\p{Ll})\p{sc=Cyrillic}[\p{sc=Cyrillic}]*([-\s]?\p{sc=Cyrillic})*$/gu.test(this.changeMainInfoBuffer.country)
  }

  get isCityValidInBuffer() {
    return isNullOrEmpty(this.changeMainInfoBuffer.city)
      ? false
      : this.changeMainInfoBuffer.city?.length < 51
      && /^(?!\p{Ll})\p{sc=Cyrillic}[\p{sc=Cyrillic}]*([-\s]?\p{sc=Cyrillic})*$/gu.test(this.changeMainInfoBuffer.city)
  }
  
  get isAboutMeLenghtValidInBuffer() {
    return (this.changeMainInfoBuffer.aboutMe?.length || 0) < 201
  }

  get validateChange() {
    return this.isFirstNameValidInBuffer
      && this.isSecondNameValidInBuffer
      && this.isThirdNameValidInBuffer
      && this.isEmailValidInBuffer
      && this.isBirthDateValidInBuffer
      && this.isCountryValidInBuffer
      && this.isCityValidInBuffer
      && this.isCategoryLengthValidInBuffer
      && this.isAboutMeLenghtValidInBuffer
  }

  get isMainDataChange() {
    return JSON.stringify(this.changeMainInfoBuffer) !== JSON.stringify(this.userData)
  }

  get userFileStorageData(): FormatedStorageModel {
    const userStorageSize = parseFloat(((this.userStorageData?.userStorage?.fileStorageSize || 0) / 1073741824).toFixed(3))
    const userStorageSizeBySubscribe = parseFloat(((this.userStorageData?.userSubscribe?.fileStorageSize || 0) / 1024).toFixed(3))
    return {userStorageSize, userStorageSizeBySubscribe}
  }

  get userVideoStorageData(): FormatedStorageModel {
    const userStorageSize = parseFloat(((this.userStorageData?.userStorage?.videoStorageSize || 0) / 3600).toFixed(3))
    const userStorageSizeBySubscribe = this.userStorageData?.userSubscribe?.videoStorageSize || 0
    return {userStorageSize, userStorageSizeBySubscribe}
  }

  get userFIO() {
    const fio = `${(this.userData.secondName || '')}${' ' + (this.userData.firstName || '')}${' ' + (this.userData.thirdName || '')}`.trim()
    if (fio === '')
      return null
    else return fio
  }

  get userBirthday() {
    return this.userData.birthDate
  }

  get userCity() {
    return this.userData.city
  }

  get userEmail() {
    return this.userData.email
  }

  loadUserProfile = async (isEmptyUser: boolean = false) => {
    try {
      runInAction(() => this.isUserDataLoading = true)
      const {data}: UserResponseDataModel = await networkService.post("user/profile.get", {
        userId: getUserId()
      })
      runInAction(() => {
        this.userData = {...data,
          email: data.email === null ? '' : data.email,
          firstName: data.firstName === null ? '' : data.firstName,
          secondName: data.secondName === null ? '' : data.secondName,
          thirdName: data.thirdName === null ? '' : data.thirdName,
          birthDate: data.birthDate === null ? '' : data.birthDate,
          country: data.country === null ? '' : data.country,
          city: data.city === null ? '' : data.city,
          aboutMe: data.aboutMe === null ? '' : data.aboutMe,
        }
        this.changeMainInfoBuffer = {...data,
          email: data.email === null ? '' : data.email,
          firstName: data.firstName === null ? '' : data.firstName,
          secondName: data.secondName === null ? '' : data.secondName,
          thirdName: data.thirdName === null ? '' : data.thirdName,
          birthDate: data.birthDate === null ? '' : data.birthDate,
          country: data.country === null ? '' : data.country,
          city: data.city === null ? '' : data.city,
          aboutMe: data.aboutMe === null ? '' : data.aboutMe,
        }

        this.isUserDataLoading = false
      })
      !isEmptyUser && await this.loadUserStorageData(false)
      return Promise.resolve()
    } catch (e) {
      // @ts-ignore
      return Promise.reject(e.response.data)
    }
  }

  loadUserPrivacySettings = async () => {
    try {
      runInAction(() => this.isUserPrivacySettingsLoading = true)
      const {data} = await networkService.post("user/getUserPrivacyById")
      runInAction(() => {
        this.userPrivacySettings = data
        this.userPrivacySettingsBuffer = data
        this.isUserPrivacySettingsLoading = false
      })
      return Promise.resolve()
    } catch (e) {
      // @ts-ignore
      return Promise.reject(e.response.data)
    }
  }

  saveUserPrivacySettings = async () => {
    try {
      runInAction(() => this.isUserPrivacySettingsProcess = true)
      const {data} = await networkService.post("user/changeUserPrivacySettings", this.userPrivacySettingsBuffer)
      runInAction(() => {
        this.userPrivacySettings = data
        this.userPrivacySettingsBuffer = data
        this.isUserPrivacySettingsProcess = false
      })
      return Promise.resolve()
    } catch (e) {
      // @ts-ignore
      return Promise.reject(e.response.data)
    }
  }

  cancelMainInfoBufferChanges = () => {
    this.changeMainInfoBuffer = this.userData
  }

  cancelUserPrivacySettingsBufferChanges = () => {
    this.userPrivacySettingsBuffer = this.userPrivacySettings
  }

  cancelChangePasswordData = () => {
    this.changePasswordData = initChangePasswordModel
  }

  clearUserData = () => {
    this.userData = initUserDataModel
  }

  setNewUserData = (value: UserDataModel) => {
    this.userData = value
  }

  setUserPrivacySettings = (key: 'privateFio' | 'privateAge' | 'privateEmail' | 'privateCity', value: boolean) => {
    this.userPrivacySettingsBuffer[key] = value
  }

  validatePassword = () => {
    if (this.changePasswordData.password !== undefined) {
      this.validChangePassword.passwordLength = this.changePasswordData.password.length >= 6
      this.validChangePassword.passwordRegister = /[A-Z]+/gmus.test(this.changePasswordData.password) && /[a-z]+/.test(this.changePasswordData.password)
      this.validChangePassword.passwordSymbols = /[!@#$%^&*?"()_={}<>:.~]+/gmus.test(this.changePasswordData.password)
      this.validChangePassword.passwordNumber = /\d/gmus.test(this.changePasswordData.password)
      this.validChangePassword.passwordEqual = this.changePasswordData.password === this.changePasswordData.passwordRepeat && this.changePasswordData.password.length !== 0
      this.validChangePassword.passwordLatin = /[a-zA-Z]+/gmus.test(this.changePasswordData.password)
    }
  }

  changePassword = async () => {
    try {
      runInAction(() => this.isChangePasswordProcess = true)
      const {data} = await networkService.post("user/changePassword", {
        oldPassword: this.changePasswordData.oldPassword,
        password: this.changePasswordData.password,
        passwordRepeat: this.changePasswordData.passwordRepeat
      })
      runInAction(() => {
        if (data === true) {
          this.changePasswordData = initChangePasswordModel
          this.validChangePassword = initAuthDataValidateModel
        }
        this.isChangePasswordProcess = false
      })
      return Promise.resolve()
    } catch (e) {
      // @ts-ignore
      return Promise.reject(e.response.data)
    }
  }

  changeMainInfo = (
    key:
      'login'
      | 'email'
      | 'firstName'
      | 'secondName'
      | 'thirdName'
      | 'birthDate'
      | 'country'
      | 'city'
      | 'aboutMe'
      | 'interests'
      | 'timeZone'
      | 'sex',
    value: string | null | number[] | number
  ) => {
    if (key === "interests" && typeof value === 'object') {
      this.changeMainInfoBuffer.interests = (value as number[]).join('|')
    } else {
      // @ts-ignore
      this.changeMainInfoBuffer[key] = !value ? value : `${value}`
    }
  }

  setPasswordChangeData = (key: 'oldPassword' | 'password' | 'passwordRepeat', value: string | undefined) => {
    this.changePasswordData[key] = value
    this.validatePassword()
  }

  //TODO добавить валидацию на почту
  saveMainData = async () => {
    try {
      runInAction(() => this.isMainDataSave = true)
      const {data}: UserResponseDataModel = await networkService.post("user/changeMainInfo", {
        email: this.changeMainInfoBuffer.email === '' ? null : this.changeMainInfoBuffer.email,
        firstName: this.changeMainInfoBuffer.firstName === '' ? null : this.changeMainInfoBuffer.firstName,
        secondName: this.changeMainInfoBuffer.secondName === '' ? null : this.changeMainInfoBuffer.secondName,
        thirdName: this.changeMainInfoBuffer.thirdName === '' ? null : this.changeMainInfoBuffer.thirdName,
        birthDate: this.changeMainInfoBuffer.birthDate === '' ? null : this.changeMainInfoBuffer.birthDate,
        country: this.changeMainInfoBuffer.country === '' ? null : this.changeMainInfoBuffer.country,
        city: this.changeMainInfoBuffer.city === '' ? null : this.changeMainInfoBuffer.city,
        timeZone: this.changeMainInfoBuffer.timeZone === '' ? Intl.DateTimeFormat().resolvedOptions().timeZone : this.changeMainInfoBuffer.timeZone,
        aboutMe: this.changeMainInfoBuffer.aboutMe === '' ? null : this.changeMainInfoBuffer.aboutMe,
        interests: null //По позже надо исправить, при сохранении основных данных интересы не учитываются
      })
      runInAction(() => {
        this.userData = data
        this.changeMainInfoBuffer = data
        this.isMainDataSave = false
      })
      return Promise.resolve()
    } catch (e) {
      // @ts-ignore
      return Promise.reject(e.response.data)
    }
  }

  saveInterests = async () => {
    if (this.changeUserInterests === null)
      return Promise.reject()

    try {
      runInAction(() => this.isUserInterestsSettingsProcess = true)
      const {data}: UserResponseDataModel = await networkService.post("user/changeMainInfo", {
        email: this.userData.email,
        firstName: this.userData.firstName,
        secondName: this.userData.secondName,
        thirdName: this.userData.thirdName,
        birthDate: this.userData.birthDate,
        country: this.userData.country,
        city: this.userData.city,
        timeZone: this.userData.timeZone,
        aboutMe: this.userData.aboutMe,
        interests: this.changeUserInterests
      })
      runInAction(() => {
        this.userData = data
        this.changeUserInterests = null
        this.isUserInterestsSettingsProcess = false
      })
      return Promise.resolve()
    } catch (e) {
      // @ts-ignore
      return Promise.reject(e.response.data)
    }
  }

  loadUserStorageData = async (withReloadRerender: boolean = true) => {
    if (!this.userData.isUseConstructor)
      return

    try {
      runInAction(() => this.isUserStorageDataLoading = withReloadRerender)
      const {data} = await networkService.post("userStorage/getUserStoragesData")
      runInAction(() => {
        this.userStorageData = data
        this.isUserStorageDataLoading = false
      })
      return Promise.resolve()
    } catch (e) {
      runInAction(() => this.isUserStorageDataLoading = false)
      // @ts-ignore
      return Promise.reject(e)
    }
  }

  get isUserCreatedFromAuthor() {
    return isNullOrEmpty(this.rootStore.userStore.userData.email)
  }

  loadUserAdditionalData = async () => {
    try {
      runInAction(() => this.isUserAdditionalDataLoading = true);
      // TODO Добавить запрос на загрузку дополнительной информации пользователя
      const data = await this.userAdditionalData;
      runInAction(() => {
        this.userAdditionalData = data;
        this.userAdditionalDataBuffer = data;
        this.isUserAdditionalDataLoading = false;
      })
      return Promise.resolve();
    } catch (e) {
      return Promise.reject(e.response.data);
    }
  }

  saveUserAdditionalData = async () => {
    try {
      runInAction(() => this.isUserAdditionalDataProcess = true)
      // TODO Добавить запрос на сохранение дополнительной информации пользователя
      const data = await this.userAdditionalData
      runInAction(() => {
        this.userAdditionalData = data
        this.userAdditionalDataBuffer = data
        this.isUserAdditionalDataProcess = false
      })
      return Promise.resolve()
    } catch (e) {
      return Promise.reject(e.response.data)
    }
  }

  changeAdditionalData = (
    key:
      'education'
      | 'income'
      | 'laborStatus'
      | 'laborArea'
      | 'familyStatus'
      | 'childrenAmount'
      | 'expectations'
      | 'vkProfileConnected'
      | 'telegramProfileConnected',
    value: string | boolean | null
  ) => {
    const resultKey: string = key;
    this.userAdditionalDataBuffer[resultKey] = value;
  }

  get isAdditionalDataChanged () {
    return JSON.stringify(this.userAdditionalDataBuffer) !== JSON.stringify(this.userAdditionalData);
  }

  cancelAdditionalDataBufferChanges() {
    this.userAdditionalDataBuffer = this.userAdditionalData;
  }
}

export interface IUserStore extends UserStore {
}
