import {IRootStore} from "../../stores/global/RootStore";
import {makeAutoObservable, runInAction} from "mobx";
import XAPI, {Actor, ContextActivity, Statement, StatementObject} from "@xapi/xapi";
import {PageDetails} from "../../types/materials/course/PageDetails";
import {QuestionType} from "../../types/materials/test/QuestionType";
import {XAPIUserAnswers} from "../../types/xapi/XAPIUserAnswers";
import {XAPIVerbsObjects} from "./XAPIVerbsObjects";
import {TypeOfContent} from "../../types/materials/TypeOfContent";
import { v4 as uuidv4 } from 'uuid'
import moment from "moment";
import {BaseCourseHelper} from "./helpers/BaseCourseHelper";
import {BaseCourseTestHelper} from "./helpers/BaseCourseTestHelper";
import {stores} from "../../pages/_app";
import {BaseCourseVideoHelper} from "./helpers/BaseCourseVideoHelper";
import {BaseCourseArticleHelper} from "./helpers/BaseCourseArticleHelper";

export class XAPIService {
  private rootStore: IRootStore

  constructor(rootStore: IRootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore

    this.initXAPI()
  }

  private initXAPI() {
    const auth = XAPI.toBasicAuth(
      '381e7e28234bb88e3886cf21a44ce3b7efca17e741f8224de7d6f16b1de48f3d',
      '9fd13506a45983459a544e5ccfbfebd0957ef16adb0b7bd60958d370b490f512'
    );
    this.xAPIInstance = new XAPI({
      endpoint: 'https://lrs.coursebit-ser.ru/xapi',
      auth: auth
    });
  }

  xAPIInstance: XAPI | null = null
  currentUser: Actor | null = null
  currentSessionsId: string | undefined = undefined
  successTest: Map<string, boolean> = new Map<string, boolean>()
  testResult: Map<string, number> = new Map<string, number>()
  testAttempt: Map<string, number> = new Map<string, number>()

  clear = () => {
    this.currentSessionsId = undefined
    this.currentUser = null
  }

  get sessionId() {
    if (!this.currentSessionsId)
      this.currentSessionsId = uuidv4()

    return this.currentSessionsId
  }

  loadCurrentUser = () => {
    this.currentUser = {
      objectType: 'Agent',
      account: {
        homePage: `https://coursebit.ru/user/${this.rootStore.userStore.userData.id}`,
        name: this.rootStore.userStore.userData.id.toString(),
      },
    }
  }

  //---------------------------------Course statements block------------------------------------------
  get currentCourseObject(): StatementObject {
    if (!this.rootStore.userCourseStore.currentUserCourse?.idUserCourse)
      throw Error('Can`t find user data for this course')

    return BaseCourseHelper.getCourseObject(this.rootStore.userCourseStore.currentUserCourse)
  }

  getCourseItemObject = (page: PageDetails): StatementObject => {
    return BaseCourseHelper.getCourseItemObject(page)
  }

  get currentContextParent(): ContextActivity {
    if (!this.rootStore.userCourseStore.currentUserCourse?.idUserCourse)
      throw Error('Can`t find user data for this course')

    return BaseCourseHelper.currentContextParent(this.rootStore.userCourseStore.currentUserCourse)
  }

  sendLaunchEvent = async () => {
    return await BaseCourseHelper.sendLaunchCourseEvent(
      this.currentUser,
      this.rootStore.userCourseStore.currentUserCourse,
      this.currentCourseObject,
      this.xAPIInstance
    )
  }

  sendStartCourseEvent = async () => {
    return await BaseCourseHelper.sendEndInitCourseDataEvent(
      this.currentUser,
      this.rootStore.userCourseStore.currentUserCourse,
      this.currentCourseObject,
      this.xAPIInstance,
      this.sessionId
    )
  }

  sendStartLearningCourseEvent = async (timeStartLearningMaterialInMilliseconds: number) => {
    return await BaseCourseHelper.sendStartLearningCourseEvent(
      this.currentUser,
      this.rootStore.userCourseStore.currentUserCourse,
      this.currentCourseObject,
      this.xAPIInstance,
      this.sessionId,
      timeStartLearningMaterialInMilliseconds
    )
  }

  sendCompleteLearningCourseEvent = async () => {
    return await BaseCourseHelper.sendCompleteLearningCourseEvent(
      this.currentUser,
      this.rootStore.userCourseStore.currentUserCourse,
      this.currentCourseObject,
      this.xAPIInstance,
      this.sessionId
    )
  }

  sendTerminateCourseSessionEvent = async () => {
    return await BaseCourseHelper.sendTerminateLearningCourseEvent(
      this.currentUser,
      this.rootStore.userCourseStore.currentUserCourse,
      this.currentCourseObject,
      this.xAPIInstance,
      this.sessionId
    )
  }

  sendCompleteCourseItemEvent = async (page: PageDetails, timeStartLearningMaterialInMilliseconds: number, userAnswers: [] = []) => {
    if (this.currentUser === null)
      return Promise.reject('User not found')

    if (!this.rootStore.userCourseStore.currentUserCourse?.idUserCourse)
      return Promise.reject('Course user data not found')

    const statement: Statement = {
      actor: this.currentUser,
      verb: XAPIVerbsObjects.passed,
      object: this.getCourseItemObject(page),
      result: {
        success: true,
        duration: `${moment.duration(Date.now() - timeStartLearningMaterialInMilliseconds, "ms").toISOString()}`,
        response: this.getResponseForCourseItem(page.typeContent)
      },
      context: {
        registration: this.rootStore.userCourseStore.currentUserCourse?.idUserCourse,
        contextActivities: {
          parent: [this.currentContextParent]
        },
        extensions: {
          'https://w3id.org/xapi/cmi5/context/extensions/sessionid': this.sessionId
        }
      },
      timestamp: new Date().toISOString()
    }
    try {
      const data = await this.xAPIInstance?.sendStatement({
        statement: statement
      })
      return Promise.resolve()
    } catch (e) {
      return Promise.reject(e)
    }
  }

  //-----------------------------------Article statements block-------------------------------------------
  sendArticleStartLearningEvent = async (page: PageDetails) => {
    return await BaseCourseArticleHelper.sendArticleStartLearningEvent(
      this.currentUser,
      this.rootStore.userCourseStore.currentUserCourse,
      page,
      this.xAPIInstance,
      this.sessionId,
      this.currentContextParent
    )
  }

  sendArticleCompleteLearningEvent = async (page: PageDetails, timeStartLearningMaterialInMilliseconds: number) => {
    return await BaseCourseArticleHelper.sendArticleCompleteLearningEvent(
      this.currentUser,
      this.rootStore.userCourseStore.currentUserCourse,
      page,
      this.xAPIInstance,
      this.sessionId,
      this.currentContextParent,
      timeStartLearningMaterialInMilliseconds
    )
  }

  sendArticleCancelLearningEvent = async (page: PageDetails, timeStartLearningMaterialInMilliseconds: number) => {
    return await BaseCourseArticleHelper.sendArticleCancelLearning(
      this.currentUser,
      this.rootStore.userCourseStore.currentUserCourse,
      page,
      this.xAPIInstance,
      this.sessionId,
      this.currentContextParent,
      timeStartLearningMaterialInMilliseconds
    )
  }

  sendArticleOpenForRelearningEvent = async (page: PageDetails) => {
    return await BaseCourseArticleHelper.sendArticleOpenForRelearning(
      this.currentUser,
      this.rootStore.userCourseStore.currentUserCourse,
      page,
      this.xAPIInstance,
      this.sessionId,
      this.currentContextParent
    )
  }

  sendArticleCloseOfRelearningEvent = async (page: PageDetails, timeStartLearningMaterialInMilliseconds: number) => {
    return await BaseCourseArticleHelper.sendArticleCloseOfRelearning(
      this.currentUser,
      this.rootStore.userCourseStore.currentUserCourse,
      page,
      this.xAPIInstance,
      this.sessionId,
      this.currentContextParent,
      timeStartLearningMaterialInMilliseconds
    )
  }
  //-----------------------------------Test statements block-----------------------------------------------
  sendStartTestEvent = async (page: PageDetails) => {
    return await BaseCourseTestHelper.sendTestTryStartLearning(
      this.currentUser,
      page,
      stores.userCourseStore.currentUserCourse,
      this.sessionId,
      this.xAPIInstance
    )
  }

  sendTestCancelEvent = async (page: PageDetails) => {
    return await BaseCourseTestHelper.sendTestTryCancelLearning(
      this.currentUser,
      page,
      stores.courseServiceStore.correctAnswers,
      stores.userCourseStore.currentUserCourse,
      this.sessionId,
      this.xAPIInstance
    )
  }

  sendTestOpenForRelearningEvent = async (page: PageDetails) => {
    return await BaseCourseTestHelper.sendTestOpenForRelearning(
      this.currentUser,
      page,
      stores.userCourseStore.currentUserCourse,
      this.sessionId,
      this.xAPIInstance
    )
  }

  sendTestCloseOfRelearningEvent = async (page: PageDetails) => {
    return await BaseCourseTestHelper.sendTestCloseOfRelearning(
      this.currentUser,
      page,
      stores.userCourseStore.currentUserCourse,
      this.sessionId,
      this.xAPIInstance
    )
  }

  sendCompleteTestItemEvent = async (page: PageDetails, timeStartLearningMaterialInMilliseconds: number, userAnswers: XAPIUserAnswers[]) => {
    return await BaseCourseTestHelper.sendTestTryEndLearning(
      this.successTest.get(`${page.content.id}`),
      this.currentUser,
      page,
      stores.userCourseStore.currentUserCourse,
      this.sessionId,
      userAnswers,
      this.xAPIInstance
    )
  }
  sendCompleteQuestionItemEvent = async (question: QuestionType, userAnswers: XAPIUserAnswers, currentAnswerId: number) => {
    return await BaseCourseTestHelper.sendTestAnswerChoose(
      this.currentUser,
      question,
      userAnswers,
      currentAnswerId,
      stores.userCourseStore.currentUserCourse,
      this.sessionId,
      userAnswers.correctAnswers.correct,
      this.xAPIInstance
    )
  }

  sendAnswerCompleteEvent = async (page: PageDetails, timeStartLearningMaterialInMilliseconds: number, userAnswers: XAPIUserAnswers[]) => {
    return await BaseCourseTestHelper.sendTestAnswerAnswered(
      this.currentUser,
      userAnswers,
      timeStartLearningMaterialInMilliseconds,
      stores.userCourseStore.currentUserCourse.idUserCourse,
      page,
      this.sessionId,
      this.xAPIInstance
    )
  }

  //------------------------------------Video statements block----------------------------------------
  sendVideoStartEvent = async (page: PageDetails) => {
    return await BaseCourseVideoHelper.sendVideoStartEvent(
      this.currentUser,
      page,
      stores.userCourseStore.currentUserCourse,
      this.sessionId,
      this.xAPIInstance
    )
  }

  sendVideoEndEvent = async (page: PageDetails,) => {
    return await BaseCourseVideoHelper.sendVideoEndLearning(
      this.currentUser,
      page,
      stores.userCourseStore.currentUserCourse,
      this.sessionId,
      this.xAPIInstance
    )
  }

  sendVideoCancelEvent = async (page: PageDetails) => {
    return await BaseCourseVideoHelper.sendVideoCancelEvent(
      this.currentUser,
      page,
      stores.userCourseStore.currentUserCourse,
      this.sessionId,
      this.xAPIInstance
    )
  }

  sendVideoOpenForRelearningEvent = async (page: PageDetails) => {
    return await BaseCourseVideoHelper.sendVideoOpenForRelearningEvent(
      this.currentUser,
      page,
      stores.userCourseStore.currentUserCourse,
      this.sessionId,
      this.xAPIInstance
    )
  }

  sendVideoCloseOfRelearningEvent = async (page: PageDetails) => {
    return await BaseCourseVideoHelper.sendVideoCloseOfRelearningEvent(
      this.currentUser,
      page,
      stores.userCourseStore.currentUserCourse,
      this.sessionId,
      this.xAPIInstance
    )
  }

  sendVideoPausedEvent = async (page: PageDetails) => {
    return await BaseCourseVideoHelper.sendVideoPausedEvent(
      this.currentUser,
      page,
      stores.userCourseStore.currentUserCourse,
      this.sessionId,
      this.xAPIInstance
    )
  }

  sendVideoResumeEvent = async (page: PageDetails) => {
    return await BaseCourseVideoHelper.sendVideoResumeEvent(
      this.currentUser,
      page,
      stores.userCourseStore.currentUserCourse,
      this.sessionId,
      this.xAPIInstance
    )
  }

  sendVideoRewindToStartEvent = async (page: PageDetails) => {
    return await BaseCourseVideoHelper.sendVideoRewindToStartEvent(
      this.currentUser,
      page,
      stores.userCourseStore.currentUserCourse,
      this.sessionId,
      this.xAPIInstance
    )
  }

  sendVideoRewindToEndEvent = async (page: PageDetails) => {
    return await BaseCourseVideoHelper.sendVideoRewindToEndEvent(
      this.currentUser,
      page,
      stores.userCourseStore.currentUserCourse,
      this.sessionId,
      this.xAPIInstance
    )
  }

  sendVideoRewindToSomePathEvent = async (page: PageDetails) => {
    return await BaseCourseVideoHelper.sendVideoRewindToSomePathEvent(
      this.currentUser,
      page,
      stores.userCourseStore.currentUserCourse,
      this.sessionId,
      this.xAPIInstance
    )
  }
  //------------------------------------getStatements------------------------------------------
  getQuestionStatement = async (questionId: number) => {
    try {
      const data = await this.xAPIInstance?.getStatements({
        registration: this.rootStore.userCourseStore.currentUserCourse?.idUserCourse?.toString(),
        activity: `https://coursebit.ru/course/pages/test/question/${questionId}`
      })

      if (data === undefined)
        return Promise.reject('data is undefined')

      return Promise.resolve(data?.data.statements[0])
    } catch (e) {
      return Promise.reject(e)
    }
  }

  getTestStatement = async () => {
    try {
      const data = await this.xAPIInstance?.getStatements({
        registration: this.rootStore.userCourseStore.currentUserCourse?.idUserCourse?.toString(),
        activity: `https://coursebit.ru/course/test/${this.rootStore.courseServiceStore.currentPageId}`
      })

      if (data === undefined)
        return Promise.reject('data is undefined')

      return Promise.resolve(data?.data.statements)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  getStatements = async () => {

    try {
      const data = await this.xAPIInstance?.getStatements({
        registration: this.rootStore.userCourseStore.currentUserCourse?.idUserCourse?.toString(),
        activity: `https://coursebit.ru/course/${this.rootStore.userCourseStore.currentUserCourse?.id}`,
        related_activities: true
      })

      if (data === undefined)
        return Promise.reject('data is undefined')

      return Promise.resolve(data?.data.statements)
    } catch (e) {
      console.log(JSON.stringify(e))
      return Promise.reject(e)
    }
  }

  getStatementss = async () => {
    const dataStates: Map<string, Statement[]> = new Map<string, Statement[]>()

    const courseCopies = [
      // "340954c4-329c-4241-91bf-91f35eebe221",
      // "19d61763-71fb-4344-b3a3-4b1788e1ae49",
      // "a8d5d275-35bc-43b9-bbb4-1055376c1919",
      // "1af43a67-7cec-4825-95c5-75d72579a7d6",
      // "9f3da2b0-045b-4a4f-8411-b064912043b5",
      // "6b3a9d09-74ff-4ffd-9d17-75d741588127",
      // "2383faa9-8ca2-47e7-8254-dcc0c0726aa6"
    ]

    console.log('11111111111')

    for (const courseCopiesKey of courseCopies) {
      try {
        const data = await this.xAPIInstance?.getStatements({
          registration: courseCopiesKey,
          related_activities: true
        })

        console.log(data)

        dataStates[courseCopiesKey] = data.data.statements
      } catch (e) {
        console.log(`e`, e)
      }
    }

    console.log(JSON.stringify(dataStates))
  }

  //---------------------------------getResponseForCourseItem-----------------------------------------
  private getResponseForCourseItem = (contentType: TypeOfContent) => {
    switch (contentType.code) {
      case 'article': return 'Прочтение статьи завершено'
      case 'test': return 'Прохождение теста завершено'
    }
  }

  private getResponseQuestionForQuestionItem = () => {
    return `Пользователь ответил на вопрос`
  }

  private getResponseTest = () => {
    return `Пользователь завершил тест`
  }
}

export interface IXAPIService extends XAPIService {}
