import {baseStyles} from "../../../../utils/baseStyles";
import React from "react";
import {baseTextStyles} from "../../../../utils/baseTextStyles";
import {styles} from "./styles";
import {observer} from "mobx-react-lite";
import LoadingButton from "@mui/lab/LoadingButton";
import {useRouter} from "next/router";
import {stores} from "../../../../pages/_app";
import {Routers} from "../../../../utils/Routers";
import {SimpleInput} from "../../../baseComponents/inputs/simpleInput";

interface IProps {
  socials?: [],
  onSignUpClick: () => void,
}

export const SignInAuthBlock = observer(({socials, onSignUpClick}: IProps) => {
  const router = useRouter()

  if (stores.authStore === undefined)
    return <></>

  const onAuthClick = () => {
    stores.authStore.signIn().then(onSuccess => {
        stores.materialDialogStore.setMessageViewState(true, 'Авторизация прошла успешно')
        stores.menuHeaderStore.setIsAuthShow(stores.menuHeaderStore.currentOpenModal, false)
        router.replace(Routers.MARKETPLACE_BITORIUM)
      },
      onError => {
        stores.materialDialogStore.setMessageViewState(true, 'Ошибка авторизации, попробуйте позднее')
      }
    )
  }

  return (
    <div style={{...baseStyles.verticalContainer, justifyContent: 'center', height: '400px', width: '400px', display: 'flex'}}>
      <div style={styles.signInLogoContainer}>
        <img src={'/logos/marketplaceLogoMulticolor.svg'} style={styles.signInLogo}/>
      </div>
      <p style={styles.signInWelcomeTitle}>Добро пожаловать в COURSEBIT</p>
      <p style={styles.signInWelcomeText}>
        Авторизируйтесь, чтобы получить доступ к инструментам создания и монетизации учебного контента
      </p>
      <SimpleInput title={'Логин'} placeholder={"Введите ваш логин"}
                   onTextChange={text => stores.authStore.setLoginForAuth(text)}
                   inputText={stores.authStore.authData.login}
                   size={'small'} stylesForToolTip={{marginLeft: 340, marginBottom: 18}}
                   type={'text'}
                   style={{...baseStyles.horizontalMargins30, marginBottom: 15, marginTop: 25, flex: 1}}
                   isValid={!!stores.authStore.authValidate.isLoginValid}
                   isValidText={"Введите логин"} required={true}
      />
      <SimpleInput title={'Пароль'} placeholder={"Введите ваш пароль"}
                   onTextChange={text => stores.authStore.setPasswordForAuth(text)} type={'password'}
                   style={{marginTop: 10, ...baseStyles.horizontalMargins30, flex: 1}}
                   isValid={!!stores.authStore.getValidateSignInStatePassword} size={'small'}
                   isValidText={"Введите действительный пароль"} required={true}
                   stylesForToolTip={{top: '14px', right: '60px'}}
      />
      <div style={styles.signInPasswordSettings}>
        <p style={{color: '#AAAAAA', cursor: "pointer", ...baseTextStyles.montserratS12W500}}>Забыли пароль?</p>
      </div>
      <LoadingButton
        children={"Авторизация"}
        loading={stores.authStore.isAuthProcess}
        disabled={!stores.authStore.getValidateSignInState}
        style={{...baseStyles.horizontalMargins30}}
        onClick={onAuthClick}
        sx={{
          backgroundColor: '#32BD9C',
          color: 'white',
          '&:disabled': {
            backgroundColor: '#cccccc',
            color: '#8d8d8d',
          },
          '&:hover': {
            backgroundColor: '#1a8570',
            color: 'white',
          }
        }}
      />
      <div style={{...baseStyles.horizontalContainerWrap, marginTop: 10, ...baseStyles.horizontalMargins30}}>
        <p style={{color: '#262626', ...baseTextStyles.montserratS12W500}}>Ещё не зарегистрированы?</p>
        <p style={{color: '#32BD9C', marginLeft: 5, cursor: "pointer", ...baseTextStyles.montserratS12W600}}
           onClick={() => onSignUpClick()}>Создайте аккаунт!
        </p>
      </div>
    </div>
  )
})

