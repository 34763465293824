import {observer} from "mobx-react-lite";
import {
	CircularProgress,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogProps,
	DialogTitle,
	Stack
} from "@mui/material";
import {PageDetails} from "../../../../types/materials/course/PageDetails";
import React, {useEffect, useRef} from "react";
import {stores} from "../../../../pages/_app";
import {baseStyles} from "../../../../utils/baseStyles";
import TestAnswerItem from "./TestAnswerItem";
import {StatusTag, StatusTagType} from "../../../IndependentComponents/StatusTag";
import CBText from "../../../../uikit/baseComponent/text";
import {Colors, ComponentSize, ComponentType, FontStyle, LearnModalType} from "../../../../uikit/enums";
import CBButton from "../../../../uikit/baseComponent/buttons/mainButton";
import {baseButtonStyles} from "../../../../utils/baseButtonStyles";
import {StyleSheet} from "../../../../utils/styles";
import {defaultFontStyles} from "../../../../uikit/fontStyle";
import {DialogType} from "../../MaterialDialogStore";

interface IProps {
  page: PageDetails,
  scroll: DialogProps['scroll'],
	type: LearnModalType
}

const TestLearnedItem = observer(({page, scroll, type}: IProps) => {
  useEffect(() => {
    if (page.content.questions === undefined)
      return

    page.content.questions?.map(item => {
      stores.courseServiceStore.setCorrectAnswers([...stores.courseServiceStore.correctAnswers, {
        questionId: item.questionId,
        correct: item.answers?.filter((answer) => answer.isCorrect) || []
      }])
    })

	}, [])

	useEffect(() => {
		if (page.content.questions === undefined || !stores.materialDialogStore.isOpen
				|| stores.materialDialogStore.currentDialogType !== DialogType.seeLearnedMaterial || type !== LearnModalType.LEARNED_TEST) {
			return
		}

		setTimeout(() => {
			stores.xAPIService.sendTestOpenForRelearningEvent(page)
		}, 1000)
	}, [stores.materialDialogStore.isOpen, type, page, stores.materialDialogStore.currentDialogType])

	useEffect(() => {
		stores.courseServiceStore.getQuestionStatement(page)
	}, [])

	const descriptionElementRef = useRef<HTMLElement>(null);
	useEffect(() => {
		if (stores.materialDialogStore.isOpen) {
			const {current: descriptionElement} = descriptionElementRef;
			if (descriptionElement !== null) {
				descriptionElement.focus();
			}
		}
	}, [stores.materialDialogStore.isOpen]);

  const handleClose = async () => {
    await stores.xAPIService.sendTestCloseOfRelearningEvent(page)
    setTimeout(() => {
			stores.materialDialogStore.setCurrentState(false)
		}, 500)
  }

	return (
		!!stores.courseServiceStore.questionStatement
			? <>
				<DialogTitle id="scroll-dialog-title" sx={{...styles.title}} children={`${page?.content.title}`}/>
				<DialogContent dividers={scroll === 'paper'}>
					<DialogContentText
						id="scroll-dialog-title"
						sx={{...styles.title, marginLeft: 3}}
					>
						<Stack direction='row' alignItems='center' spacing={2} margin='20px 0'>
							<CBText
								children={`Тест`}
								fontStyle={FontStyle.LARGE}
							/>
							<StatusTag status={StatusTagType.NOT_LEARNED} text={'Изучено'} style={styles.tag}/>
							{!!stores.xAPIService.testAttempt.get(`${page.content.id}`) &&
								<CBText
									children={`Затрачено попыток ${stores.xAPIService.testAttempt.get(`${page.content.id}`)}`}
								/>
							}
						</Stack>
					</DialogContentText>
					<DialogContentText
						id="scroll-dialog-description"
						ref={descriptionElementRef}
						tabIndex={-1}
						sx={styles.content}
					>
						{page.content.questions?.map((question, index) => (
							<TestAnswerItem question={question} page={page}/>
						))}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<CBButton
						disabled={false}
						onClick={handleClose}
						children={'Закрыть'}
						size={ComponentSize.SMALL}
						type={ComponentType.PRIMARY}
						style={baseButtonStyles.buttonDiscard}
					/>
				</DialogActions>
			</>
			: <div style={styles.progressContainer}>
				<CircularProgress sx={styles.progress}/>
			</div>
	)
})

const styles = StyleSheet.create({
	title: {
		...defaultFontStyles.displaySmallBold,
	},
	tag: {
		borderRadius: 20,
		marginTop: 2,
		whiteSpace: 'nowrap',
	},
	content: {
		width: '900px',
		marginLeft: 3,
		marginRight: 3,
		...defaultFontStyles.linkSmall
	},
	progressContainer: {
		display: 'flex',
		...baseStyles.centeredView,
		width: '400px',
		height: '500px'
	},
	progress: {
		alignSelf: 'center',
		color: Colors.PRIMARY_COLOR,
	}
})

export default TestLearnedItem
