import {observer} from "mobx-react-lite";
import {
	CircularProgress,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogProps,
	DialogTitle
} from "@mui/material";
import {PageDetails} from "../../../../types/materials/course/PageDetails";
import {baseStyles} from "../../../../utils/baseStyles";
import React, {useEffect, useRef, useState} from "react";
import {stores} from "../../../../pages/_app";
import CBButton from "../../../../uikit/baseComponent/buttons/mainButton";
import {Colors, ComponentSize, ComponentType, LearnModalType} from "../../../../uikit/enums";
import {StyleSheet} from "../../../../utils/styles";
import {baseButtonStyles} from "../../../../utils/baseButtonStyles";
import {defaultFontStyles} from "../../../../uikit/fontStyle";
import {DialogType} from "../../MaterialDialogStore";

interface IProps {
	scroll: DialogProps['scroll'],
	page: PageDetails,
	type: LearnModalType
}

const ArticleLearnedItem = observer(({scroll, page, type}: IProps) => {
	const [isPdf, setIsPdf] = useState(false)
	const [urlFile, setUrlFile] = useState(page.content.content?.replace('file:', '') || '')

	useEffect(() => {
		if (!page.content.content)
			return
		if ((page.content.content.indexOf('file:') ?? -1) >= 0 || (page.content.content.indexOf('blob:') ?? -1) >= 0) {
			setIsPdf(true)
			setUrlFile(page.content.content?.replace('file:', '') || '')
		}
	}, [])

	useEffect(() => {
		if (page === undefined || !stores.materialDialogStore.isOpen
				|| stores.materialDialogStore.currentDialogType !== DialogType.seeLearnedMaterial || type !== LearnModalType.LEARNED_ARTICLE) {
			return
		}

		setTimeout(() => {
			stores.xAPIService.sendArticleOpenForRelearningEvent(page)
		}, 1000)
	}, [stores.materialDialogStore.isOpen, type, page, stores.materialDialogStore.currentDialogType])

	const descriptionElementRef = useRef<HTMLElement>(null);
	useEffect(() => {
		if (stores.materialDialogStore.isOpen) {
			const {current: descriptionElement} = descriptionElementRef;
			if (descriptionElement !== null) {
				descriptionElement.focus();
			}
		}
	}, [stores.materialDialogStore.isOpen]);

	const handleClose = () => {
		stores.xAPIService.sendArticleCloseOfRelearningEvent(page, stores.courseServiceStore.timeStartLearningPage)
		stores.materialDialogStore.setCurrentState(false)
	}

	return (
		page.content !== undefined
			? <>
				<DialogTitle id="scroll-dialog-title" sx={styles.title} children={`${page?.content.title}`}/>
				<DialogContent sx={{overflowX: 'hidden'}} dividers={scroll === 'paper'}>
					<DialogContentText
						id="scroll-dialog-description"
						ref={descriptionElementRef}
						tabIndex={-1}
						sx={{width: '900px', height: isPdf ? '750px' : 'auto'}}
					>
						{!isPdf ?
							<div id={'materialBody'} style={styles.content}
									 dangerouslySetInnerHTML={{__html: page?.content.content || ''}}/>
							: <object data={urlFile + '#toolbar=0'} type={'application/pdf'} width={'100%'} height={'100%'}/>
						}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<CBButton
						disabled={false}
						onClick={handleClose}
						children={'Закрыть'}
						size={ComponentSize.SMALL}
						type={ComponentType.PRIMARY}
						style={baseButtonStyles.buttonDiscard}
					/>
				</DialogActions>
			</>
			: <div style={styles.progressContainer}>
				<CircularProgress sx={styles.progress}/>
			</div>
	)
})

const styles = StyleSheet.create({
	title: {
		...defaultFontStyles.displaySmallBold,
	},
	content: {
		...defaultFontStyles.linkSmall,
	},
	progressContainer: {
		display: 'flex',
		...baseStyles.centeredView,
		width: '400px',
		height: '500px'
	},
	progress: {
		alignSelf: 'center',
		color: Colors.PRIMARY_COLOR,
	}
})

export default ArticleLearnedItem
