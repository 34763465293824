import {DialogProps} from "@mui/material";
import {observer} from "mobx-react-lite";
import {useEffect, useState} from "react";
import {PageDetails} from "../../../../types/materials/course/PageDetails";
import {stores} from "../../../../pages/_app";
import ArticleLearnedItem from "./ArticleLearnedItem";
import TestLearnedItem from "./TestLearnedItem";
import VideoLearned from "./VideoLearned";
import {LearnModalType} from "../../../../uikit/enums";
import {DialogType} from "../../MaterialDialogStore";

const enum MaterialsType {
  'article' = 'article',
  'test' = 'test',
  'video' = 'video'
}

const SeeLearnedMaterial = observer(() => {
  const [scroll, setScroll] = useState<DialogProps['scroll']>('paper')
  const [page, setPages] = useState<PageDetails | undefined>(undefined)

  useEffect(() => {
    if (!!stores.courseServiceStore.learnedPage && stores.materialDialogStore.isOpen && stores.materialDialogStore.currentDialogType === DialogType.seeLearnedMaterial) {
      setPages(stores.courseServiceStore.learnedPage)
    }

    if (!stores.materialDialogStore.isOpen || stores.materialDialogStore.currentDialogType !== DialogType.seeLearnedMaterial) {
      setPages(undefined)
    }

    return () => {
      setPages(undefined)
    }
  }, [stores.courseServiceStore.learnedPage, stores.materialDialogStore.isOpen, stores.materialDialogStore.currentDialogType])

  switch (page?.typeContent.code) {
    case MaterialsType.article: {
      return <ArticleLearnedItem page={page} scroll={scroll} type={LearnModalType.LEARNED_ARTICLE}/>
    }
    case MaterialsType.test: {
      return <TestLearnedItem page={page} scroll={scroll} type={LearnModalType.LEARNED_TEST}/>
    }
    case MaterialsType.video: {
      return <VideoLearned page={page} scroll={scroll} type={LearnModalType.LEARNED_VIDEO}/>
    }
    default: return <></>
  }
})

export default SeeLearnedMaterial
