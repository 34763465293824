import {observer} from "mobx-react-lite";
import {stores} from "../../../pages/_app";
import {
  DialogActions,
  DialogContent,
  MenuItem, Skeleton,
  Stack
} from "@mui/material";
import {useUnload} from "../../../utils/useUnload";
import {ChoosingInterests} from "../../IndependentComponents/ChoosingInterests";
import React, {useEffect} from "react";
import CBButton from "../../../uikit/baseComponent/buttons/mainButton";
import {Colors, ComponentSize} from "../../../uikit/enums";
import {CBDropdown} from "../../../uikit/baseComponent/dropdown";
import CBText from "../../../uikit/baseComponent/text";
import {StyleSheet} from "../../../utils/styles";

const InterestsUserSettings = observer(() => {
  useUnload(stores.userStore.isChangeInterestsChanged)

  const handleClose = () => {
    if (stores.userStore.isChangeInterestsChanged) {
      if (!window.confirm("Возможно, внесенные изменения не сохранятся.")) return
      stores.materialDialogStore.setCurrentState(false);
      stores.userStore.resetChangeInterestsUser();
      stores.userStore.loadUserProfile()
    } else {
      stores.materialDialogStore.setCurrentState(false);
    }
  }

  useEffect(() => {
    stores.userStore.resetChangeInterestsUser()
  }, [])

  return(
    <Stack height='100%'>

      <DialogContent sx={styles.content}>

      <Stack rowGap='30px'>
      <Stack rowGap='20px'>
        <CBText>Выберете как вы хотите использовать платформу</CBText>

        <CBDropdown size={ComponentSize.LARGE} onChange={(value) => console.log(value)} value={'Я студент'} disabled>
          <MenuItem value='Я студент'>Я студент</MenuItem>
          <MenuItem value='Я преподователь'>Я преподователь</MenuItem>
        </CBDropdown>
      </Stack>

      <Stack>
        {
          // TODO Есть реализация селектора категорий в UIkit
        }
        <ChoosingInterests
          onSave={ids => stores.userStore?.saveChangeInterestsUser(ids)}
          defaultValues={typeof stores.userStore.userData?.interests === "object" ? stores.userStore.userData?.interests?.map(item => +item.id) : []}
          style={styles.interests}
        />
      </Stack>
      </Stack>

      </DialogContent>

        <DialogActions sx={{...styles.actions, ...(!stores.adaptiveService.isMinDesktopMini && {flexWrap: 'wrap', rowGap: '20px', width: '100%', padding: '0', marginTop: '60px'})}}>
          <CBButton
            style={{...styles.button, ...(!stores.adaptiveService.isMinDesktopMini && {width: '100%', margin: '0 !important'})}}
            loading={stores.userStore.isUserInterestsSettingsProcess}
            disabled={!stores.userStore.isChangeInterestsChanged || !stores.userStore.isChangeInterestsValid}
            children='Сохранить'
            onClick={() => {
              stores.userStore.saveInterests().then(
                onSuccess => {

                },
                onError => {

                }
              )
            }}
          />
          <CBButton
            style={{...styles.button, ...(!stores.adaptiveService.isMinDesktopMini && {width: '100%', margin: '0 !important'})}}
            disabled={stores.userStore.isUserPrivacySettingsProcess}
            children='Отменить'
            onClick={handleClose}
            background={Colors.ERROR}
            backgroundHover={Colors.ERROR_DARK}
          />
        </DialogActions>
    </Stack>
  )
})

const styles = StyleSheet.create({
  interests: {
    height: 'auto',
    overflow: 'auto',
    maxHeight: '85px',
  },
  content: {
    width: '100%',
    padding: '0'
  },
  actions: {
    columnGap: '20px',
  },
  button: {
    width: '209px'
  }
})

export default InterestsUserSettings