import React, {CSSProperties, useEffect, useState} from "react";
import {Colors, ComponentSize, GrayScale, InputsType} from "../../enums";
import {StyleSheet} from "../../../utils/styles";
import {AutocompleteRenderInputParams, IconButton, InputAdornment, SxProps, TextField} from "@mui/material";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import ClearIcon from '@mui/icons-material/Clear';
import {defaultFontStyles} from "../../fontStyle";
import {stores} from "../../../pages/_app";
import CalendarSvg from '../../../public/icons/input/calendar.svg'

interface IProps {
	defaultValue?: string | number,
	onChange: (value: string | number) => void,
	label: string,
	isValid?: boolean | 'disabled',
	disabled?: boolean,
	onClearClick?: () => void,
	leftIcon?: JSX.Element,
	helperText?: string,
	size?: ComponentSize,
	type?: InputsType,
	style?: CSSProperties,
	required?: boolean,
	onFocus?: () => void,
	inputLabelProps?: object,
	maxLength?: number,
	params?: AutocompleteRenderInputParams,
}

const CBInput = ({
									 defaultValue, label, isValid = 'disabled', disabled = false, onChange,
									 onClearClick, helperText = '', size = ComponentSize.MEDIUM, type = InputsType.TEXT,
									 leftIcon, style, onFocus, inputLabelProps, required, maxLength, params
								 }: IProps) => {
	const [isShow, setIsShow] = useState(false)
	const [query, setQuery] = useState<string | number>(defaultValue ? defaultValue : (type === InputsType.NUMBER ? 0 : ''));
	const [date, setDate] = React.useState<string | number>(defaultValue);


	useEffect(() => {
		onChange(query);
	}, [query])

	useEffect(() => {
		if (type === 'date') {
			setQuery(date)
		}
	}, [date])

	useEffect(() => {
		if (!stores.materialDialogStore.isOpen) {
			setQuery(defaultValue ? defaultValue : type === InputsType.NUMBER ? 0 : '');
		} else {
			setQuery(defaultValue ? defaultValue : (type === InputsType.NUMBER ? 0 : ''))
		}
	}, [stores.materialDialogStore.isOpen])

	const handleFocus = () => {
		!!onFocus && onFocus()
	}

	const handleClearInput = () => {
		onClearClick && onClearClick();
		setQuery(type === 'number' ? 0 : '');
	}

	const handleClickShowPassword = () => {
		setIsShow(!isShow)
	}

	const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
	}

	const handleTheme = () => {
		if (isValid === 'disabled') return 'primary';
		if (String(query).length > 0) {
			return isValid ? 'success' : 'error';
		}
		return 'primary'
	}

	const renderEye = () => (
		<InputAdornment position="end">
			<IconButton onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge="end">
				{isShow ?
					<VisibilityOff
						color={isValid ? 'primary' : 'error'}
						sx={{
							'&.Mui-colorError': {
								color: Colors.ERROR
							},
							'&.MuiIcon-colorPrimary': {
								color: Colors.SUCCESS
							},
						}}
					/>
					: <Visibility
						color={isValid ? 'primary' : 'error'}
						sx={{
							'&.Mui-colorError': {
								color: Colors.ERROR
							},
							'&.MuiIcon-colorPrimary': {
								color: Colors.SUCCESS
							},
						}}
					/>
				}
			</IconButton>
		</InputAdornment>
	)

	const renderCalendar = () => (
		<InputAdornment position="end">
			<IconButton edge="end" sx={{
				marginRight: '0',
				'& input': {
					position: 'absolute',
					right: '0',
					top: '50%',
					transform: 'translateY(-50%)',
					border: 'none',
					boxSizing: 'border-box',
					outline: '0',
					width: '40px',
					height: '40px',
					padding: '0',
					color: 'transparent',
					background: 'transparent',
					fontSize: '0',
					opacity: '0',

					'&[type="date"]::-webkit-calendar-picker-indicator': {
						background: 'transparent',
						bottom: '0',
						color: 'transparent',
						cursor: 'pointer',
						height: 'auto',
						left: '0',
						position: 'absolute',
						right: '0',
						top: '0',
						width: 'auto',
					},
				}

			}}>
				<input onChange={(event) => setDate(event.target.value)} value={date} type="date"/>
				<CalendarSvg width={24} height={24}/>
			</IconButton>
		</InputAdornment>
	)

	const renderCrossIcon = (): JSX.Element => {
		return !!params
			?
			(<InputAdornment sx={{'& svg': {...styles.clearIcon}}} position="end" onClick={() => {
				onClearClick();
				setQuery('');
			}}>
				{params?.InputProps.endAdornment}
			</InputAdornment>)
			: (<InputAdornment position="end">
				<IconButton edge="end" sx={{padding: '4px', marginRight: '4px'}}
										onClick={handleClearInput}>
					<ClearIcon color={isValid ? 'primary' : 'error'}
										 sx={styles.clearIcon}
					/>
				</IconButton>
			</InputAdornment>);
	}

	const getInputSize = (): SxProps => {
		let finalStyle: SxProps | React.CSSProperties = {
			...styles.initialStyle,
			'& .MuiInputBase-adornedEnd:not(.Mui-focused) > .MuiOutlinedInput-notchedOutline': {
				backgroundColor: String(query).length === 0 ? GrayScale.INPUT_BACKGROUND : 'auto',
				border: String(query).length === 0 ? 'none' : 'auto'
			},
			'& .MuiInputLabel-formControl': {
				top: leftIcon ? '50% !important' : '0 !important',
				left: leftIcon ? '42px' : '8px',
			},
			'& .MuiFormHelperText-filled': {
				color: isValid === 'disabled' ? Colors.LABEL_COLOR : Colors.PRIMARY_DARK,
			},
		}
		switch (size) {
			case ComponentSize.LARGE: {
				finalStyle = {
					...finalStyle,
					'& .MuiInputBase-input': {
						paddingTop: '28px !important',
						paddingBottom: '7px !important'
					},
					'& .MuiOutlinedInput-root': {
						height: '60px'
					},
					'& label.MuiFormLabel-filled, label.Mui-focused': {
						top: '14px !important'
					},
				}
				break
			}
			case ComponentSize.MEDIUM: {
				finalStyle = {
					...finalStyle,
					'& .MuiInputBase-input': {
						paddingTop: '22px',
						paddingBottom: '2px'
					},
					'& .MuiOutlinedInput-root': {
						height: '50px'
					},
					'& label.MuiFormLabel-filled, label.Mui-focused': {
						top: '14px !important'
					},
					'& .MuiInputLabel-root': {
						top: !!leftIcon ? '25px !important' : '-3px !important',
					}
				}
				break
			}
			case ComponentSize.SMALL: {
				finalStyle = {
					...finalStyle,
					'& .MuiInputBase-input': {
						paddingTop: '14px',
						paddingBottom: '0'
					},
					'& .MuiOutlinedInput-root': {
						height: '40px'
					},
					'& label.MuiFormLabel-filled, label.Mui-focused': {
						top: '12px !important'
					},
					'& .MuiInputLabel-root': {
						top: !!leftIcon ? '20px !important' : '-8px !important'
					}
				}
				finalStyle = {...finalStyle, ...defaultFontStyles.textXSmall}
				break
			}
		}
		finalStyle = {...finalStyle, ...style}
		return finalStyle
	}

	return (
		<TextField
			{...params}
			color={handleTheme()}
			size={'medium'}
			sx={getInputSize()}
			label={label}
			type={!!isShow ? 'text' : type}
			required={required}
			value={query}
			error={!isValid && (type === InputsType.TEXT ? String(query).length > 0 : query < 1)}
			InputLabelProps={inputLabelProps}
			helperText={helperText}
			onSelect={(event: React.ChangeEvent<HTMLInputElement>) => !!params && setQuery(event.target.value)}
			onChange={(event: React.ChangeEvent<HTMLInputElement>) => !params && setQuery(event.target.value)}
			onFocus={handleFocus}
			InputProps={{
				...(!!params && type !== InputsType.NUMBER && params.InputProps),
				startAdornment: leftIcon,
				endAdornment: !disabled && <>{type === InputsType.PASSWORD && renderEye()}{type === 'date' && renderCalendar()}{type === 'text' ? String(query).length > 0 && renderCrossIcon() : type === 'number' ? Number(query) > 0 && renderCrossIcon() || query < 0 && renderCrossIcon() : null}</>
			}}
			inputProps={{
				...(!!params && type !== InputsType.NUMBER && params.inputProps),
				...(maxLength !== undefined ? {maxLength: maxLength} : {}),
				...(type === InputsType.DATE ? {min: "1900-01-01", max: "2015-12-31"} : {})
			}}
			disabled={disabled}
		/>
	)
}


const styles = StyleSheet.create({
	initialStyle: {
		'&.MuiFormControl-root': {
			border: '0',
			boxSizing: 'border-box',
			outline: 'none',
			color: GrayScale.TITLE_ACTIVE_COLOR,
			height: 'fit-content'
		},

		'& .MuiAutocomplete-inputRoot': {
			padding: '0 0 0 15px'
		},

		'& input': {
			...defaultFontStyles.textMedium,
			paddingLeft: '23px',
			zIndex: '10',
			position: 'relative',

			'&[type="date"]::-webkit-calendar-picker-indicator': {
				background: 'transparent',
			}
		},

		' input[type=number]::-webkit-inner-spin-button': {
			marginBottom: '7px',
			marginTop: '-11px'
		},

		'& .MuiInputAdornment-positionEnd, svg.MuiSvgIcon-root': {
			zIndex: '10',
			color: GrayScale.INPUT_ADORNMENT
		},

		'& label.MuiFormLabel-filled ~ div svg.MuiSvgIcon-root, & label.Mui-focused ~ div svg.MuiSvgIcon-root': {
			color: GrayScale.TITLE_ACTIVE_COLOR
		},

		'& .MuiSvgIcon-root ~ input': {
			paddingLeft: '12px !important',
		},
		'& .MuiFormLabel-root': {
			...defaultFontStyles.textMedium,
			color: GrayScale.PLACEHOLDER_COLOR,
			transition: 'all .1s ease-in-out'
		},

		'& fieldset': {
			top: '0',
			borderRadius: '16px',
			borderColor: GrayScale.LINE_COLOR
		},

		'& fieldset > legend': {
			display: "none"
		},

		'& label.MuiFormLabel-filled, & label.Mui-focused': {
			color: `${Colors.LABEL_COLOR} !important`,
		},

		'&:has(div.Mui-focused) fieldset': {
			borderColor: `${GrayScale.TITLE_ACTIVE_COLOR} !important`
		},
		'&:has(div.Mui-disabled)': {
			opacity: '0.5',
		},

		'&:has(div.MuiInputBase-colorSuccess) fieldset': {
			borderColor: `${Colors.PRIMARY_DARK} !important`,
			backgroundColor: Colors.PRIMARY_LIGHT
		},

		'&:has(div.MuiInputBase-colorSuccess) label.MuiFormLabel-filled, &:has(div.MuiInputBase-colorSuccess) label.Mui-focused, &:has(div.MuiInputBase-colorSuccess) svg': {
			color: `${Colors.PRIMARY_DARK} !important`
		},

		'&:has(div.Mui-focused):has(div.Mui-error) fieldset': {
			borderColor: `${Colors.ERROR} !important`,
		},
		'&:has(div.Mui-error) fieldset.MuiOutlinedInput-notchedOutline': {
			backgroundColor: Colors.ERROR_LIGHT
		},
		'& label.MuiFormLabel-colorError': {
			color: `${Colors.ERROR} !important`
		},

		'& label.MuiFormLabel-colorError ~ div > fieldset': {
			borderColor: `${Colors.ERROR} !important`,
			backgroundColor: Colors.ERROR_LIGHT
		},

		'& label.MuiFormLabel-colorError ~ p': {
			color: `${Colors.ERROR} !important`
		},

		'&:has(div.Mui-error) label.MuiFormLabel-filled, &:has(div.Mui-error) label.Mui-focused, &:has(div.Mui-error) svg, & label.MuiFormLabel-colorError ~ div svg.MuiSvgIcon-root': {
			color: `${Colors.ERROR} !important`
		},
		'& p': {
			...defaultFontStyles.textXSmall,
			margin: '0',
			marginTop: '5px',
			color: GrayScale.LABEL_COLOR,

			'&.Mui-error': {
				color: Colors.ERROR,
			}
		},
	},
	clearIcon: {
		width: '26px',
		height: '26px'
	}
})

export default CBInput