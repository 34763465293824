import {baseStyles} from "../../../utils/baseStyles";
import {MutatingDots} from "react-loader-spinner";

export const Loader = () => (
  <div style={{
    ...baseStyles.verticalContainer,
    backgroundColor: "#F6F6F6",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    height: "100vh",
    width: '100vw',
    position: 'fixed'
  }}>
    <MutatingDots
      height="100"
      width="100"
      color={'#54dcc1'}
      secondaryColor={'#32BD9C'}
    />
  </div>
)
