import {useRouter} from "next/router";
import {useEffect} from "react";

export const useUnload = (isUnsavedChanges: boolean) => {
  const router = useRouter()
  const warningText = "Возможно, внесенные изменения не сохранятся."

  const handleWindowClose  = (e: BeforeUnloadEvent) => {
    if (!isUnsavedChanges) return
    e.preventDefault()
    return e.returnValue = warningText
  }
  const handleBrowseAway = () => {
    if (!isUnsavedChanges) return
    if (window.confirm(warningText)) return
    router.events.emit('routeChangeError')
    throw 'routeChange aborted'
  }
  useEffect(() => {
    if (router.isReady) {
      window.addEventListener("beforeunload", handleWindowClose)
      router.events.on('routeChangeStart', handleBrowseAway)
    }
    return () => {
      window.removeEventListener("beforeunload", handleWindowClose)
      router.events.off('routeChangeStart', handleBrowseAway)
    }
  }, [isUnsavedChanges])
}