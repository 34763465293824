import networkService from "../services/NetworkService";
import { CourseActionConstants } from "../services/xapi/CourseActionConstants";
import {ChapterDetails} from "../types/materials/course/chapter/ChapterDetails";
import {makeAutoObservable, runInAction} from "mobx";
import {PageDetails} from "../types/materials/course/PageDetails";
import {XAPIUserAnswers} from "../types/xapi/XAPIUserAnswers";
import {XAPICheckBoxAnswers} from "../types/xapi/XAPICheckBoxAnswers";
import {XAPIRadioButtonAnswers} from "../types/xapi/XAPIRadioButtonAnswers";
import {QuestionType} from "../types/materials/test/QuestionType";
import {AnswerType} from "../types/materials/test/AnswerType";
import {XAPICorrectAnswers} from "../types/xapi/XAPICorrectAnswers";
import {IRootStore} from "./global/RootStore";
import {CourseLiveCycleEvents, XAPICourseLiveCycleObjects, XAPIVerbsObjects} from "../services/xapi/XAPIVerbsObjects";
import {Activity, Statement} from "@xapi/xapi";

export const MaterialState = {
  completed: 'completed',
  notStarted: 'notStarted',
  inProcess: 'inProcess'
}

export class CourseServiceStore {
  private rootStore: IRootStore

  constructor(rootStore: IRootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  currentPageId: number = 0
  currentChapterId: number = 0
  currentQuestionId: number = 0
  completePagesId: number[] = []
  currentTestId: number | undefined = undefined
  correctAnswers: XAPICorrectAnswers[] = []
  currentAnswer: AnswerType | undefined = undefined
  learnedPage: PageDetails | undefined = undefined
  userAnswers: XAPIUserAnswers[] = []
  currentUserAnswer: XAPIUserAnswers[] = []
  questionStatement: Statement[] = []
  isCourseComplete: boolean = false
  isCourseStart: boolean = false
  isMaterialComplete: boolean = false
  isCorrectAnswerSettingProcess: boolean = false
  questionStatementLoadingProcess: boolean = false

  timeStartLearningPage: number = 0

  clear = () => {
    this.currentPageId = 0
    this.currentChapterId = 0
    this.completePagesId = []
    this.isCourseComplete = false
    this.isMaterialComplete = false
  }

  generateTimeStartLearningPage = () => {
    this.timeStartLearningPage = Date.now()
  }

  setIsCorrectAnswerSettingProcess = (value: boolean) => {
    this.isCorrectAnswerSettingProcess = value
  }

  loadCurrentState = async () => {
    await this.rootStore.xAPIService.loadCurrentUser()
    this.rootStore.xAPIService.sessionId
    if (this.rootStore.userCourseStore.currentUserCourse?.courseStateCode !== CourseActionConstants.FINISH_COURSE
      && this.rootStore.userCourseStore.currentUserCourse?.courseStateCode !== CourseActionConstants.REQUEST_CERTIFICATE
      && this.rootStore.userCourseStore.currentUserCourse?.courseStateCode !== CourseActionConstants.BUILD_CERTIFICATE
      && this.rootStore.userCourseStore.currentUserCourse?.courseStateCode !== CourseActionConstants.GIVE_CERTIFICATE) {
      await this.rootStore.xAPIService.sendLaunchEvent()
    }
    const data = await this.rootStore.xAPIService.getStatements()
    this.findCourseStartEvent(data)
    this.getPagesFromStatements(data)

    //TODO зарефакторить
    if (this.completePagesId.length === 0 && data.findIndex(item => item.verb.id === XAPICourseLiveCycleObjects[CourseLiveCycleEvents.USER_STARTED_COURSE].id) > -1) {
      this.currentChapterId = (this.rootStore.userCourseStore.currentUserCourse?.chapters?.length || 0) > 0 && this.rootStore.userCourseStore.currentUserCourse?.chapters !== null
        ? this.rootStore.userCourseStore.currentUserCourse?.chapters[0].id || 0
        : 0
    }

    // const lastCompletePageId = this.completePagesId.length === 0 ? 0 : this.completePagesId[this.completePagesId.length - 1]
    // this.currentPageId = lastCompletePageId
    // this.currentChapterId = this.rootStore.userCourseStore.currentUserCourse?.chapters?.find(item => (item.pages?.findIndex(page => page.id === lastCompletePageId) || 1) > -1)?.id || 0
    await this.goToNextPage()
    if (this.isCourseNotStart) {
      runInAction(() => this.checkCourseOnStartEventAndStartItIfPossible(data))
    }
    if (this.rootStore.userCourseStore.currentUserCourse?.courseStateCode !== CourseActionConstants.FINISH_COURSE
      && this.rootStore.userCourseStore.currentUserCourse?.courseStateCode !== CourseActionConstants.REQUEST_CERTIFICATE
      && this.rootStore.userCourseStore.currentUserCourse?.courseStateCode !== CourseActionConstants.BUILD_CERTIFICATE
      && this.rootStore.userCourseStore.currentUserCourse?.courseStateCode !== CourseActionConstants.GIVE_CERTIFICATE) {
      await this.rootStore.xAPIService.sendStartCourseEvent()
    }
    runInAction(() => {
      this.rootStore.userCourseStore.disablePageLoading()
    })
  }

  findCourseStartEvent = (statements: Statement[]) => {
    this.isCourseStart = statements.findIndex(item => item.verb.id === XAPICourseLiveCycleObjects[CourseLiveCycleEvents.USER_STARTED_COURSE].id) > -1
  }

  get isCourseNotStart() {
    return this.currentPageId === 0 && this.currentChapterId === 0 && !this.isCourseComplete && !this.isCourseStart
  }

  get countChapters() {
    return this.rootStore.userCourseStore.currentUserCourse?.chapters?.length || 0
  }

  get countCompleteChapters() {
    if (this.isCourseComplete) {
      return this.countChapters
    }

    const currentChapterIndex = this.rootStore.userCourseStore.currentUserCourse?.chapters?.findIndex(item => item.id === this.currentChapterId)
    return (currentChapterIndex || 0) < 0 ? 0 : currentChapterIndex
  }

  get currentContent() {
    const pages: PageDetails[] = []
    this.rootStore.userCourseStore.currentUserCourse?.chapters?.forEach(item => pages.push(...item.pages || []))
    return pages.find((element) => element.id === this.currentPageId)
  }

  setIsMaterialComplete = (value: boolean) => {
    this.isMaterialComplete = value
  }

  get sumDuration() {
    const pages: PageDetails[] = []
    this.rootStore.userCourseStore.currentUserCourse?.chapters?.forEach(item => pages.push(...item.pages || []))
    const duration = pages.map(item => item.typeContent.code === 'video' ? (item.content.duration ?? 0) / 60 : item.content.duration).reduce((sum, currentDuration) => (sum || 0) + (currentDuration || 0), 0) || 0
    var hours = Math.floor(duration / 60);
    var minutes = (duration % 60).toFixed(0);
    return `${hours} ч ${minutes} мин`
  }

  get currentCompleteDuration() {
    const pages: PageDetails[] = []
    this.rootStore.userCourseStore.currentUserCourse?.chapters?.forEach(item => pages.push(...item.pages || []))
    const duration = pages
      .filter(page => (this.completePagesId.find(item => item === page.id) || -1) > -1)
      .map(item => item.typeContent.code === 'video' ? (item.content.duration ?? 0) / 60 : item.content.duration)
      .reduce((sum, current) => (sum || 0) + (current || 0), 0) || 0
    var hours = Math.floor(duration / 60);
    var minutes = (duration % 60).toFixed(0);
    return `${hours} ч ${minutes} мин`
  }

  get currentProgress() {
    const pages: PageDetails[] = []
    this.rootStore.userCourseStore.currentUserCourse?.chapters?.forEach(item => pages.push(...item.pages || []))

    return (this.completePagesId.length / pages.length) * 100
  }

  isCourseStartProcess = false

  startCourse = async () => {
    this.isCourseStartProcess = true
    const lastCompletePageId = this.completePagesId.length === 0 ? 0 : this.completePagesId[this.completePagesId.length - 1]

    if (lastCompletePageId === 0) {
      await this.rootStore.xAPIService.sendStartLearningCourseEvent(!!this.rootStore.userCourseStore.currentUserCourse?.timePurchaseCourse ? Date.parse(this.rootStore.userCourseStore.currentUserCourse?.timePurchaseCourse) : Date.now())
      this.currentChapterId = (this.rootStore.userCourseStore.currentUserCourse?.chapters?.length || 0) > 0 && this.rootStore.userCourseStore.currentUserCourse?.chapters !== null
        ? this.rootStore.userCourseStore.currentUserCourse?.chapters[0].id || 0
        : 0
      await this.sendCourseEventOnServer(CourseActionConstants.START_COURSE)
      this.rootStore.userCourseStore.setCourseStateCode(CourseActionConstants.START_COURSE)
      await this.goToNextPage()
    }
    this.isCourseStartProcess = false
  }

  setCorrectAnswers = (value: XAPICorrectAnswers[]) => {
    this.correctAnswers = value
  }

  setCurrentAnswer = (value: AnswerType) => {
    this.currentAnswer = value
  }

  getCurrentChapterState = (chapterId: number) => {
    if (this.isCourseComplete)
      return MaterialState.completed

    if (chapterId === this.currentChapterId)
      return MaterialState.inProcess

    const currentChapterIndex = this.rootStore.userCourseStore.currentUserCourse?.chapters?.findIndex(item => item.id === this.currentChapterId) || 0
    const requestedChapterIndex = this.rootStore.userCourseStore.currentUserCourse?.chapters?.findIndex(item => item.id === chapterId) || 0

    return (requestedChapterIndex < currentChapterIndex)
      ? MaterialState.completed
      : MaterialState.notStarted
  }

  setCurrentChapter = (chapterId) => {
    this.currentChapterId = chapterId
  }
  setLearnedPage = (value: PageDetails) => {
    this.learnedPage = value
  }

  getCurrentPageState = (pageId: number) => {
    if (pageId === this.currentPageId)
      return MaterialState.inProcess

    const pages: PageDetails[] = []
    this.rootStore.userCourseStore.currentUserCourse?.chapters?.forEach(item => pages.push(...item.pages || []))

    const currentPageIndex = pages.findIndex(item => item.id === this.currentChapterId) || 0
    const requestedPageIndex = pages.findIndex(item => item.id === pageId) || 0

    return (requestedPageIndex < currentPageIndex)
      ? MaterialState.completed
      : MaterialState.notStarted
  }

  validateButton = (page: PageDetails) => {
    return this.userAnswers.length < (page.content.questions?.length || 0)
  }

  setUserAnswer = (page: PageDetails, checkBoxAnswers: XAPICheckBoxAnswers[], radioButtonAnswers: XAPIRadioButtonAnswers[], correctAnswers, questionId, question: QuestionType, type: string) => {
    const currentQuestion = page.content.questions?.find(item => item.questionId === questionId)
    let saveAnswer: XAPIUserAnswers[] = []


    if (questionId === 0 || !questionId) {
      return
    }
    saveAnswer = [...this.userAnswers.filter(item => item.questionId !== questionId)]

    switch (type) {
      case "checkbox": {
        this.userAnswers = [...saveAnswer, {
          questionId: questionId,
          correctAnswers: correctAnswers.find(item => item.questionId === questionId),
          userAnswers: checkBoxAnswers || []
        }]
        break
      }
      case "radiobutton": {
        this.userAnswers = [...saveAnswer, {
          questionId: questionId,
          correctAnswers: correctAnswers.find(item => item.questionId === questionId),
          userAnswers: radioButtonAnswers || []
        }]
        break
      }
    }
  }

  clearAnswerData = () => {
    console.warn(`clearAnswerData`)
    this.userAnswers = []
    this.currentUserAnswer = []
    this.correctAnswers = []
  }

  setCurrentUserAnswer = (page: PageDetails, checkBoxAnswers: XAPICheckBoxAnswers[], radioButtonAnswers: XAPIRadioButtonAnswers[], correctAnswers, questionId, question: QuestionType, type: string) => {
    const currentQuestion = page.content.questions?.find(item => item.questionId === questionId)

    this.currentUserAnswer = []

    if (questionId === 0)
      return

    this.currentUserAnswer.map((item) => {
      if (item.questionId === questionId && !!item)
        this.currentUserAnswer.filter(item => item.questionId === questionId)
    })

    switch (type) {
      case "checkbox": {
        this.currentUserAnswer = [...this.currentUserAnswer,{
          questionId: questionId,
          correctAnswers: correctAnswers.find(item => item.questionId === questionId),
          userAnswers: checkBoxAnswers || []
        }]
        break
      }
      case "radiobutton": {
        this.currentUserAnswer = [...this.currentUserAnswer,{
          questionId: questionId,
          correctAnswers: correctAnswers.find(item => item.questionId === questionId),
          userAnswers: radioButtonAnswers || []
        }]
        break
      }
    }
    const xApiAnswer = this.currentUserAnswer.find(item => item.questionId === (currentQuestion?.questionId || 0))

    if (currentQuestion !== undefined && xApiAnswer !== undefined){
      this.setCurrentQuestionId(currentQuestion.questionId)
      this.rootStore.xAPIService.sendCompleteQuestionItemEvent(currentQuestion , xApiAnswer, this.currentAnswer.id).then(
        onSuccess => {
        },
        onError => {
        }
      )
    } else {
      return
    }
  }

  setCurrentPage = (pageId: number) => {
    this.currentPageId = pageId
  }

  setCurrentQuestionId = (questionId: number) => {
    this.currentQuestionId = questionId
  }

  setCurrentTestId = (testId: number) => {
    this.currentTestId = testId
  }

  private isChapterIsParentPage = (item: ChapterDetails, pageId: number) => {
    if (item.pages === null) {
      return false
    }

    let pageIndex: number | undefined = item.pages.findIndex(page => page.id === pageId)

    return pageIndex !== undefined ? pageIndex > -1 : false
  }

  getTestStatements = async () => {
    let testResult: boolean | undefined = undefined
    const data = await this.rootStore.xAPIService.getTestStatement()
    data.map((item, index) => {
      if (!!item.result && index === 0) {
        testResult = item.result.success
      }
    })
    return testResult
  }

  goToNextPage = async (page: PageDetails | null = null) => {
    this.setIsMaterialComplete(true)
    if (this.currentPageId === 0 && this.currentChapterId === 0 && this.completePagesId.length !== 0) {
      this.currentChapterId = this.rootStore.userCourseStore.currentUserCourse?.chapters
        ?.find((item: ChapterDetails) => this.isChapterIsParentPage(item, this.completePagesId[this.completePagesId.length - 1]))
        ?.id || 0

      this.currentPageId = this.completePagesId[this.completePagesId.length - 1]
    }
    const currentChapter = this.rootStore.userCourseStore.currentUserCourse?.chapters?.find(item => item.id === this.currentChapterId)
    this.completePagesId = this.currentPageId === 0 || this.completePagesId.findIndex(item => item === this.currentPageId) > -1
      ? this.completePagesId
      : [...this.completePagesId, this.currentPageId]

    if (this.isCurrentPageIsLastInChapter(currentChapter)) {
      const nextChapter = this.getNextChapter(this.currentChapterId)
      if (nextChapter === undefined) {
        this.isCourseComplete = true
        this.currentChapterId = 0
        this.currentPageId = 0
        if (this.rootStore.userCourseStore.currentUserCourse?.courseStateCode === CourseActionConstants.START_COURSE) {
          await this.rootStore.xAPIService.sendCompleteLearningCourseEvent()
          await this.sendCourseEventOnServer(CourseActionConstants.FINISH_COURSE)
          await this.rootStore.userCourseStore.loadUserCourseById(this.rootStore.userCourseStore.currentUserCourse?.idUserCourse, true)
        }
        return
      }

      if (nextChapter?.pages === null)
        this.currentPageId = 0
      else this.currentPageId = nextChapter?.pages[0]?.id || 0

      this.currentChapterId = nextChapter?.id || 0
    } else {
      const currentPageIndex = currentChapter?.pages?.findIndex(item => item.id === this.currentPageId) || 0
      this.currentPageId = currentChapter?.pages === null ? 0 : currentChapter?.pages[currentPageIndex + 1].id || 0
    }
    this.setIsMaterialComplete(false)
  }

  isChapterExpanded = (chapterId: number) => {
    const chapterState = this.getCurrentChapterState(chapterId)
    return chapterState === MaterialState.inProcess
  }

  getActiveStep = (chapterId: number) => {
    if (this.isCourseComplete)
      return (this.getChapterById(chapterId)?.pages?.length || 0)

    const chapterState = this.getCurrentChapterState(chapterId)

    switch (chapterState) {
      case "completed": return (this.getChapterById(chapterId)?.pages?.length || 0)
      case "notStarted": return -1
      case "inProcess": {
        return this.getChapterById(chapterId)?.pages?.findIndex(item => item.id === this.currentPageId) || 0
      }
    }
  }

  getChapterById = (chapterId: number) => {
    return this.rootStore.userCourseStore.currentUserCourse?.chapters?.find(item => item.id === chapterId)
  }

  getQuestionStatement = async (page: PageDetails) => {
    try {
      runInAction(() => this.questionStatementLoadingProcess = true)

      for (let i = 0; i < (page.content.questions?.length ?? 0); i++) {
        if (!page.content.questions)
          continue

        const newValue = await this.rootStore.xAPIService.getQuestionStatement(page.content.questions[i].questionId)
        runInAction(() => {
          this.questionStatement = [...this.questionStatement, newValue]
        })
      }

      runInAction(() => {
        this.questionStatementLoadingProcess = false
      })

    }
    catch (e) {

    }
  }

  private isCurrentPageIsLastInChapter = (currentChapter: ChapterDetails | undefined) => {
    if (currentChapter === undefined)
      return false

    return currentChapter?.pages?.findIndex(item => item.id === this.currentPageId) === (currentChapter?.pages?.length || 0) - 1
  }

  private getNextChapter = (currentChapterId: number) => {
    const indexOfCurrentChapter = this.rootStore.userCourseStore.currentUserCourse?.chapters?.findIndex(item => item.id === currentChapterId)

    if (indexOfCurrentChapter === undefined || this.rootStore.userCourseStore.currentUserCourse?.chapters === null)
      return undefined

    if ((this.rootStore.userCourseStore.currentUserCourse?.chapters?.length || 0) - 1 === indexOfCurrentChapter)
      return undefined

    return this.rootStore.userCourseStore.currentUserCourse?.chapters[indexOfCurrentChapter + 1]
  }

  private getPagesFromStatements = (statements: Statement[]) => {
    statements
      .filter(item => {
        return item.verb.id === XAPICourseLiveCycleObjects[CourseLiveCycleEvents.ARTICLE_END_LEARNING].id
                || item.verb.id === XAPICourseLiveCycleObjects[CourseLiveCycleEvents.TEST_TRY_END_LEARNING].id
                || item.verb.id === XAPICourseLiveCycleObjects[CourseLiveCycleEvents.VIDEO_END_LEARNING].id
      })
      .sort(
      (
        statementA,
        statementB) =>
          Date.parse(statementA.timestamp || '0') > Date.parse(statementB.timestamp || '0') ? 1 : -1
      )
      .forEach(statement => {
        if (instanceOfActivity(statement.object) && statement.object.id && statement.object.id.startsWith('https://coursebit.ru/course/pages/')) {
          const pageId = statement.object.id.replace('https://coursebit.ru/course/pages/', '').split('/')[1]
          if (this.completePagesId.indexOf(+pageId) < 0)
            this.completePagesId = [...this.completePagesId, +pageId]
        }
      })
    if (this.rootStore.userCourseStore.currentUserCourse?.courseStateCode === CourseActionConstants.FINISH_COURSE) {
      this.isCourseComplete = true
    }
  }

  private checkCourseOnStartEventAndStartItIfPossible = async (statements: Statement[]) => {
    const statementOnStartCourse = statements.filter(statement => statement.verb.id === XAPIVerbsObjects.passed.id)?.find(statement => statement.result?.response === 'Начал курс')

    if (statementOnStartCourse !== undefined ) {
      this.setFirstPageInFirstChapter()
      if (this.rootStore.userCourseStore.currentUserCourse?.courseState === 'Приобретён') {
        await this.sendCourseEventOnServer(CourseActionConstants.START_COURSE)
      }
    }
  }

  private setFirstPageInFirstChapter = () => {
    if (this.rootStore.userCourseStore.currentUserCourse?.chapters !== undefined
      && this.rootStore.userCourseStore.currentUserCourse?.chapters !== null
      && (this.rootStore.userCourseStore.currentUserCourse?.chapters?.length || 0) > 0
      && (this.rootStore.userCourseStore.currentUserCourse.chapters[0] !== null)
      && (this.rootStore.userCourseStore.currentUserCourse.chapters[0].pages?.length || 0) > 0
      && this.rootStore.userCourseStore.currentUserCourse?.chapters[0].pages !== null
    ) {
      this.currentPageId = this.rootStore.userCourseStore.currentUserCourse?.chapters[0].pages[0].id || 0
      this.currentChapterId = this.rootStore.userCourseStore.currentUserCourse?.chapters[0].id || 0
    }
  }

  sendCourseEventOnServer = async (state: string, copyCourseId: string = this.rootStore.userCourseStore.currentUserCourse?.idUserCourse, targetUserId: number | null = null ) => {
    try {
      const {data} = await networkService.post("userCourses/setUserCourseState", {
        copyCourseId: copyCourseId,
        state: state,
        targetUserId: targetUserId
      })
      return Promise.resolve()
    } catch (e) {
      // @ts-ignore
      return Promise.reject(e.response.data)
    }
  }
}

export interface ICourseServiceStore extends CourseServiceStore {
}

function instanceOfActivity(object: any): object is Activity {
  return 'id' in object;
}
