import {StyleSheet} from "../../../utils/styles";
import {observer} from "mobx-react-lite";
import React, {useEffect, useRef, useState} from "react";
import {
  DialogProps,
} from "@mui/material";
import {stores} from "../../../pages/_app";
import {GPTRequestType} from "../../../stores/GPTStore";
import CBGptSummary from "./dialogs/CBGPTSummary";
import CBGptUpgrades from "./dialogs/CBGPTUpgrades";
import CBGptComplexityReason from "./dialogs/CBGPTComplexityReason";
import CBGptCourseQuestions from "./dialogs/CBGPTCourseQuestions";
import CBGptChapterQuestions from "./dialogs/CBGPTChapterQuestions";

interface IProps {
  mainTitle: string,
  requestType: GPTRequestType
  materialTitle: string
}
const CBGPTDialog = observer(({mainTitle, requestType, materialTitle}: IProps) => {
  const [scroll, setScroll] = useState<DialogProps['scroll']>('paper')

  useEffect(() => {
    if (!stores.materialDialogStore.isOpen) {
      stores.gptStore.clearGptResponses()
    }
  }, [stores.materialDialogStore.isOpen]);

  switch (requestType) {
    case GPTRequestType.SUMMARY: {
      return <CBGptSummary mainTitle={mainTitle} scroll={scroll} articleTitle={materialTitle}/>
    }
    case GPTRequestType.UPGRADE: {
      return <CBGptUpgrades mainTitle={mainTitle} scroll={scroll} articleTitle={materialTitle}/>
    }
    case GPTRequestType.COMPLEXITY_REASON: {
      return <CBGptComplexityReason mainTitle={mainTitle} scroll={scroll} articleTitle={materialTitle}/>
    }
    case GPTRequestType.GENERATE_COURSE_QUESTIONS: {
      return <CBGptCourseQuestions mainTitle={mainTitle} scroll={scroll} materialTitle={materialTitle}/>
    }
    case GPTRequestType.GENERATE_CHAPTER_QUESTIONS: {
      return <CBGptChapterQuestions mainTitle={mainTitle} scroll={scroll} materialTitle={materialTitle}/>
    }
  }
})


export default CBGPTDialog