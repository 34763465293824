import {Extensions} from "@xapi/xapi/dist/types/resources/statement";
import {browserName, getUA, isMobile, parseUserAgent} from "react-device-detect";

export class BaseXAPIHelper {

  static getDeviceExtensions(): Extensions {
    if (typeof window === 'undefined')
      return {}

    return {
      'https://coursebit.ru/additionalInfo/isMobile': isMobile,
      'https://coursebit.ru/additionalInfo/browserName': browserName,
      'https://coursebit.ru/additionalInfo/userAgent': getUA,
      'https://coursebit.ru/additionalInfo/screenWidth': window.screen.width,
      'https://coursebit.ru/additionalInfo/screenHeight': window.screen.height,
    }
  }

  // static getOtherExtensions(): Extensions {
  //   if (typeof window === 'undefined')
  //     return {}
  //
  //   return {
  //     'https://coursebit.ru/additionalInfo/isMobile': isMobile,
  //     'https://coursebit.ru/additionalInfo/browserName': browserName,
  //     'https://coursebit.ru/additionalInfo/userAgent': getUA,
  //     'https://coursebit.ru/additionalInfo/screenWidth': window.screen.width,
  //     'https://coursebit.ru/additionalInfo/screenHeight': window.screen.height,
  //   }
  // }

}