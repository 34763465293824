import {StyleSheet} from "../../../utils/styles";

export const authBaseHorizontalMargin = {
    marginLeft: 30,
    marginRight: 30
}

export const styles = StyleSheet.create({
  overlay: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0,0,0,0.75)',
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
    zIndex: 5
  },
  content: {
    width: '400px',
    position: "relative",
    margin: 0,
    borderRadius: 10,
    border: 'none',
  },
  studentOverlay: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0,0,0,0.75)',
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
    zIndex: 5
  },
  studentContent: {
    position: "relative",
    margin: 0,
    width: 500,
    minHeight: 480,
    borderRadius: 10,
    border: 'none',
    inset: 0,
    padding: 0
  },
  studentSignUpContent: {
    position: "relative",
    margin: 0,
    width: 900,
    minHeight: 570,
    borderRadius: 10,
    border: 'none',
    inset: 0,
    padding: 0
  },
  signInAuthBlock: {
    flex: 2,
  },
  studentSignInAuthBlock: {
    flex: 2,
    minHeight: 400,
  },
  signInSlider: {
    flex: 1.7,
    background: 'linear-gradient(90deg, #8DED97 -29.53%, #30B7A7 68.75%)',
    minHeight: 480,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center"
  },

  signUpAuthBlock: {
    flex: 12,
    minHeight: 480,
  },
  signUpGradient: {
    flex: 1,
    background: 'linear-gradient(90deg, #8DED97 -140.93%, #30B7A7 103.07%)',
    minHeight: 480,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center"
  },
  studentSignUpGradient: {
    flex: 1,
    background: 'linear-gradient(90deg, #8DED97 -140.93%, #30B7A7 103.07%)',
    minHeight: 480,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center"
  },
})
