import {QuestionChangeType} from "./QuestionChangeType";

export type StructureChangeType = {
  testId: number,
  orderIndex: number,
  testStructure: QuestionChangeType[]
}

export const initialStructureChange: StructureChangeType = {
  testId: 0,
  orderIndex: 0,
  testStructure: []
}
