import {StyleSheet} from "../../../../utils/styles";
import {authBaseHorizontalMargin} from "../styles";

export const styles = StyleSheet.create({
  signInLogoContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "end",
    alignContent: "end",
    marginTop: 30,
    ...authBaseHorizontalMargin
  },
  signInLogo: {
    width: 150,
    height: "auto"
  },
  signInLogoText: {
    textAlign: "end",
    fontFamily: 'Montserrat Alternates',
    fontWeight: "bold",
    lineHeight: 0.75,
    color: '#D8D8D8',
    fontSize: 12,
    marginLeft: 5
  },
  signUpLeftBlock: {
    flex: 1
  },
  signUpRightBlock: {
    flex: 1
  },
  signUpWelcomeTitle: {
    fontFamily: 'Roboto',
    fontWeight: "bold",
    color: '#3D3D3D',
    fontSize: 16,
    ...authBaseHorizontalMargin,
    marginTop: 30
  },
  signUpWelcomeText: {
    fontFamily: 'Montserrat',
    color: '#3D3D3D',
    fontSize: 12,
    fontWeight: 500,
    ...authBaseHorizontalMargin,
    marginTop: 5,
  },
  linkStyle: {
    textDecoration: "none",
    color: '#32BD9C'
  },

})
