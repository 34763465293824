import React, {CSSProperties, useCallback} from "react";
import {Colors, ComponentSize, ComponentType, Gradient, GrayScale} from "../../enums";
import {StyleSheet} from "../../../utils/styles";
import {ButtonBase, SxProps} from "@mui/material";

import CircularProgress from '@mui/material/CircularProgress'
import {defaultFontStyles} from "../../fontStyle";

interface IProps {
	children: string | JSX.Element,
	color?: Colors | GrayScale | Gradient,
	background?: Colors | GrayScale,
	backgroundHover?: Colors | GrayScale | Gradient,
	width?: string | number,
	style?: CSSProperties,
	disabled?: boolean,
	loading?: boolean,
	onClick: () => void,
	type?: ComponentType,
	size?: ComponentSize
}

const CBButton = ({
										children, onClick, color = Colors.PRIMARY_LIGHT, width = 'auto', disabled = false, style,
										loading = false, size = ComponentSize.MEDIUM, type = ComponentType.PRIMARY, background = Colors.PRIMARY_COLOR, backgroundHover = Colors.PRIMARY_DARK
									}: IProps) => {

	const getButtonStyle = useCallback(() => {
		let finalStyle: CSSProperties | SxProps = {
			...styles.initialStyle,
			background: background,
			width: width,
			'&:hover': {
				background: backgroundHover
			}
		}
		switch (type) {
			case ComponentType.PRIMARY: {
				finalStyle = {...finalStyle, ...styles.primary, ...{ color, '&:hover': {background: backgroundHover}} }
				break
			}
			case ComponentType.SECONDARY: {
				finalStyle = {...finalStyle, ...styles.secondary}
				break
			}
			case ComponentType.SUBTITLE: {
				finalStyle = {...finalStyle, ...styles.subtitle}
				break
			}
			case ComponentType.TEXT: {
				finalStyle = {...finalStyle, ...styles.text}
				break
			}
			case ComponentType.ERROR: {
				finalStyle = {...finalStyle, ...styles.error}
				break
			}
		}
		switch (size) {
			case ComponentSize.LARGE: {
				finalStyle.height = '60px'
				break
			}
			case ComponentSize.MEDIUM: {
				finalStyle.height = '50px'
				break
			}
			case ComponentSize.SMALL: {
				finalStyle.height = '40px'
			}
		}
		finalStyle = {...finalStyle, ...(loading ? styles.loadingDisabled : {}), ...style}
		return finalStyle
	}, [color, type, width, size, background, backgroundHover])

	const renderLoader = useCallback(() => {
		let loaderSize: '30px' | '40px' | '50px' = '40px'
		switch (size) {
			case ComponentSize.LARGE: {
				loaderSize = '50px'
				break
			}
			case ComponentSize.MEDIUM: {
				loaderSize = '40px'
				break
			}
			case ComponentSize.SMALL: {
				loaderSize = '30px'
			}
		}

		const color = style && Object?.keys(style)?.find(key => key === 'color');
		const loaderColor = style && style[color];

		return (
			type === ComponentType.TEXT ? (
				<p style={{...defaultFontStyles.textMedium, color: loaderColor ?? Colors.PRIMARY_COLOR, textAlign: 'center'}}
					 children={'Loading'}/>
			) : (
				<CircularProgress
					size={loaderSize}
					color={"primary"}
					sx={{
						'&.MuiCircularProgress-colorPrimary': {
							color: loaderColor ?? Colors.PRIMARY_COLOR
						}
					}}
				/>
			)
		)
	}, [loading, color])
// TODO - Разобраться с цветом активной кнопки
	return (
		<ButtonBase
			style={style?.color && {color: style.color}}
			sx={getButtonStyle}
			onClick={onClick}
			disabled={disabled || loading}
			children={!loading ? children : renderLoader()}
		/>
	)
}


const styles = StyleSheet.create({
	initialStyle: {
		borderRadius: '20px',
		paddingLeft: '30px',
		paddingRight: '30px',
		textAlign: "center",
		...defaultFontStyles.textMedium,
	},
	primary: {
		color: GrayScale.BACKGROUND_COLOR,
		'&:disabled': {
			background: Colors.PRIMARY_COLOR,
			opacity: 0.5
		}
	},
	loadingDisabled: {
		// @ts-ignore
		'&:disabled': {
			opacity: 1
		}
	},
	secondary: {
		color: Colors.PRIMARY_COLOR,
		border: '2px solid #26AF7E',
		background: 'transparent',
		// @ts-ignore
		'&:hover': {
			border: '2px solid #00966D'
		},
		'&:disabled': {
			opacity: 0.5
		}
	},
	subtitle: {
		color: Colors.PRIMARY_COLOR,
		border: '2px solid #D9E9E4',
		background: 'transparent',
		// @ts-ignore
		'&:hover': {
			border: '2px solid #A0BDBB'
		},
		'&:disabled': {
			opacity: 0.5
		}
	},
	text: {
		color: Colors.PRIMARY_COLOR,
		background: 'transparent',
		// @ts-ignore
		'&:hover': {
			color: Colors.PRIMARY_DARK
		},
		'&:disabled': {
			opacity: 0.5
		}
	},
	error: {
		color: GrayScale.BACKGROUND_COLOR,
		background: Colors.ERROR,
		'&:hover': {
			background: Colors.ERROR_DARK
		},
		'&:disabled': {
			opacity: 0.5
		}
	}
})

export default CBButton
