import {observer} from "mobx-react-lite";
import CategoriesSelector from "../../../IndependentComponents/CategoriesSelector";
import {stores} from "../../../../pages/_app";
import {ChoosingInterests} from "../../../IndependentComponents/ChoosingInterests";

const ThirdStep = observer(() => (
  <div style={{marginTop: '20px', flex: 1}}>
    <ChoosingInterests
      onSave={(ids) => {
        stores.endRegistrationStore.setCompleteAuthData('interests', ids)
      }}
      defaultValues={[]}
      enableSaveOnEmptySelect={true}
    />
  </div>
))

export default ThirdStep
