import {PublicArticleObject} from "./PublicArticleObject";

export type SortedArticle = {
  countArticles: number,
  countPages: number,
  articles: PublicArticleObject[],
}

export const initSortedArticle: SortedArticle = {
  countArticles: 0,
  countPages: 0,
  articles: []
}
