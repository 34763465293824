import React, {CSSProperties} from 'react'
import {FontStyle, FontType, Gradient, GrayScale, ProgressAnimation} from "../../enums";
import {StyleSheet} from "../../../utils/styles";
import {LinearProgress, linearProgressClasses} from "@mui/material";
import CBText from "../text";
import {baseStyles} from "../../../utils/baseStyles";

interface IProps {
  value: number,
  style?: CSSProperties,
  progressAnimation?: ProgressAnimation | 'buffer',
  isShowLabel?: boolean,
  isComplexity?: boolean
}

export const CBLineProgress = ({value, style, progressAnimation = ProgressAnimation.DETERMINATE, isShowLabel, isComplexity}: IProps) => {
  return (
    <div>
      <LinearProgress
        variant={progressAnimation}
        value={value}
        sx={{
          ...styles.progressContainer,
          [`& .${linearProgressClasses.bar}`]: {
            transform: 'none !important',
            transition: 'width .4s linear',
            borderRadius: '10px',
            width: `${value}%`,
            background: isComplexity ? value >= 85 ? Gradient.GRADIENT_COMPLEXITY_HIGH : Gradient.GRADIENT_COMPLEXITY_LOW : value >= 75 ?  Gradient.GRADIENT_ACCENT : Gradient.GRADIENT_PRIMARY,
          },
          ...style,
        }}
      />
      {isShowLabel && (
        <div style={{...baseStyles.horizontalContainer, marginTop: 16}}>
          <CBText children={`${value}%`} type={FontType.TEXT} fontStyle={FontStyle.LINKMEDIUM} color={GrayScale.BODY_COLOR} style={{marginRight: 12}}/>
          <CBText children={`Done`} type={FontType.TEXT} fontStyle={FontStyle.LINKMEDIUM} color={GrayScale.LABEL_COLOR} />
        </div>
      )}
    </div>
  )
}


const styles = StyleSheet.create({
  progressContainer: {
    height: 12,
    marginTop: '5px',
    borderRadius: '10px',
    transform: 'none !important',
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: '#EEEEEE',
    },
  }
})
