import {observer} from "mobx-react-lite";
import {StyleSheet} from "../../../../utils/styles";
import {baseTextStyles} from "../../../../utils/baseTextStyles";
import {stores} from "../../../../pages/_app";
import {baseStyles} from "../../../../utils/baseStyles";
import {SimpleInput} from "../../../baseComponents/inputs/simpleInput";
import TrueIcon from "../../../../public/icons/true-false/true.svg";
import FalseIcon from "../../../../public/icons/true-false/false.svg";
import Image from "next/image";
import React from "react";

const FirstStep = observer(() => (
  <div style={{marginTop: '40px', ...baseStyles.horizontalContainer, width: '100%'}}>
    <div style={{flex: 1, marginRight: '10px'}}>
      <SimpleInput title={'Логин'} size={'medium'}
                   onTextChange={(text) => stores.endRegistrationStore.setCompleteAuthData('login', text)}
                   isValid={!!stores.endRegistrationStore.completeAuthValidateData.isLoginValid && !stores.endRegistrationStore.loginIsUsed} style={{flex: 1}}
                   isValidText={!!stores.endRegistrationStore.loginIsUsed ? 'Такой логин уже используется' : "Логин может содержать латинские символы и цифры,<br/>а его длина не должна превышать 20 символов"}
                   inputText={stores.endRegistrationStore.completeAuthData.login || ''} stylesForToolTip={{top: 10, right: 5}}
      />
      <SimpleInput title={'Пароль'} size={'medium'} type={'password'}
                   onTextChange={(text) => stores.endRegistrationStore.setCompleteAuthData('password', text)}
                   isValid={!!stores.endRegistrationStore.getValidatePassword()} style={{marginTop: '15px', flex: 1}}
                   isValidText={'Пароль не соответсвует требованиям'}
                   inputText={stores.endRegistrationStore.completeAuthData.password || ''} stylesForToolTip={{top: '26px', right: 30}}
      />
      <SimpleInput title={'Пароль еще раз'} size={'medium'} type={'password'}
                   onTextChange={(text) => stores.endRegistrationStore.setCompleteAuthData('passwordRepeat', text)}
                   isValid={!!stores.endRegistrationStore.getValidatePassword()} style={{marginTop: '15px', marginBottom: '25px', flex: 1}}
                   isValidText={'Пароль не соответсвует требованиям'}
                   inputText={stores.endRegistrationStore.completeAuthData.passwordRepeat || ''} stylesForToolTip={{top: '26px', right: 30}}
      />
    </div>
    <div style={{flex: 1, marginLeft: '10px'}}>
      <SimpleInput title={'Почта'} size={'medium'}
                   onTextChange={(text) => stores.endRegistrationStore.setCompleteAuthData('email', text)}
                   isValid={!!stores.endRegistrationStore.completeAuthValidateData.isEmailValid || !stores.userStore.isUserCreatedFromAuthor} style={{flex: 1}}
                   isValidText={'Введенная почта некоректна'}
                   inputText={stores.userStore.isUserCreatedFromAuthor ? stores.endRegistrationStore.completeAuthData.email || '' : stores.userStore.userData.email} stylesForToolTip={{top: 10, right: 5}}
                   disable={!stores.userStore.isUserCreatedFromAuthor}
                   helperText={stores.userStore.isUserCreatedFromAuthor ? undefined : 'Вы указывали данную почту на первом этапе регистрации'}
      />
      <div style={{marginTop: '15px'}}>
        <p style={{...styles.itemTextFalse}}>Требования к паролю</p>
        <div style={baseStyles.horizontalContainer}>
          <Image src={stores.endRegistrationStore.completeAuthValidateData?.passwordRegister ? TrueIcon : FalseIcon} style={{width: 10, height: 10}} width={10} height={10}/>
          <p style={stores.endRegistrationStore.completeAuthValidateData?.passwordRegister ? styles.itemTextTrue : styles.itemTextFalse}>Использование верхнего и нижнего регистра</p>
        </div>
        <div style={baseStyles.horizontalContainer}>
          <Image src={stores.endRegistrationStore.completeAuthValidateData?.passwordSymbols ? TrueIcon : FalseIcon} style={{width: 10, height: 10}} width={10} height={10}/>
          <p style={stores.endRegistrationStore.completeAuthValidateData?.passwordSymbols ? styles.itemTextTrue : styles.itemTextFalse}>Использование символов</p>
        </div>
        <div style={baseStyles.horizontalContainer}>
          <Image src={stores.endRegistrationStore.completeAuthValidateData?.passwordNumber ? TrueIcon : FalseIcon} style={{width: 10, height: 10}} width={10} height={10}/>
          <p style={stores.endRegistrationStore.completeAuthValidateData?.passwordNumber ? styles.itemTextTrue : styles.itemTextFalse}>Использование цифр</p>
        </div>
        <div style={baseStyles.horizontalContainer}>
          <Image src={stores.endRegistrationStore.completeAuthValidateData?.passwordEqual ? TrueIcon : FalseIcon} style={{width: 10, height: 10}} width={10} height={10}/>
          <p style={stores.endRegistrationStore.completeAuthValidateData?.passwordEqual ? styles.itemTextTrue : styles.itemTextFalse}>Введённые пароли совпадают</p>
        </div>

      </div>
    </div>
  </div>
))

export default FirstStep

const styles = StyleSheet.create({
  itemTextFalse: {
    ...baseTextStyles.montserratS12W500,
    fontSize: 12,
    color: '#b1b1b1',
    marginLeft: 5
  },
  itemTextTrue: {
    ...baseTextStyles.montserratS12W500,
    fontSize: 12,
    color: '#32BD9C',
    marginLeft: 5
  }
})