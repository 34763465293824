import {makeAutoObservable} from "mobx";
import {IRootStore} from "./global/RootStore";

export class ReCaptchaStore {
  private rootStore: IRootStore

  constructor(rootStore: IRootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  verify: boolean = false
  sitekey: string = '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI' //TODO Стандартный гугл ключ для локалхоста. Нужно заменить на проде.

  onVerify = (value: string | null) => {
    this.verify = !!value
  }

  resetVerify = () => {
    this.verify = false
  }
}

export interface IReCaptchaStore extends ReCaptchaStore {
}
