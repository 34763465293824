import {
	CircularProgress,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogProps,
	DialogTitle,
	Stack
} from "@mui/material";
import {PageDetails} from "../../../../types/materials/course/PageDetails";
import {observer} from "mobx-react-lite";
import {baseStyles} from "../../../../utils/baseStyles";
import {stores} from "../../../../pages/_app";
import React, {useEffect, useRef, useState} from "react";
import TestQuestionItem from "./TestQuestionItem";
import {StatusTag, StatusTagType} from "../../../IndependentComponents/StatusTag";
import CBButton from "../../../../uikit/baseComponent/buttons/mainButton";
import {Colors, ComponentSize, ComponentType, FontStyle, GrayScale, LearnModalType} from "../../../../uikit/enums";
import {baseButtonStyles} from "../../../../utils/baseButtonStyles";
import {StyleSheet} from "../../../../utils/styles";
import {defaultFontStyles} from "../../../../uikit/fontStyle";
import CBText from "../../../../uikit/baseComponent/text";
import {DialogType} from "../../MaterialDialogStore";

interface IProps {
  page: PageDetails,
  scroll: DialogProps['scroll']
	type: LearnModalType
}

const LearningTest = observer(({ page, scroll, type}: IProps) => {
  const descriptionElementRef = useRef<HTMLElement>(null);
	const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (page === undefined || !stores.materialDialogStore.isOpen
				|| stores.materialDialogStore.currentDialogType !== DialogType.learningMaterial || type !== LearnModalType.LEARN_TEST) {
      return
    }

		stores.xAPIService.sendStartTestEvent(page)
  }, [stores.materialDialogStore.isOpen, type, page, stores.materialDialogStore.currentDialogType])

  useEffect(() => {
    if (stores.materialDialogStore.isOpen) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [stores.materialDialogStore.isOpen]);

  const handleTestEndClick = async () => {
    if (page !== undefined) {
			setIsLoading(true)
      await stores.xAPIService.sendAnswerCompleteEvent(page, stores.courseServiceStore.timeStartLearningPage, [...stores.courseServiceStore.userAnswers])
      await stores.xAPIService.sendCompleteTestItemEvent(page, stores.courseServiceStore.timeStartLearningPage, [...stores.courseServiceStore.userAnswers])
			if (stores.xAPIService.successTest.get(`${page.content.id}`)) {
				!!stores.xAPIService.testResult.get(`${page.content.id}`) && stores.materialDialogStore.setMessageViewState(
					true,
					`Тест успешно пройден. Результат ${(stores.xAPIService.testResult.get(`${page.content.id}`) * 100).toFixed(0)}%`,
					5000
				)
				await stores.courseServiceStore.goToNextPage(page)
				stores.materialDialogStore.setCurrentState(false)
				setIsLoading(false)
			} else {
				console.error(`failed`)
				!!stores.xAPIService.testResult.get(`${page.content.id}`) && stores.materialDialogStore.setMessageViewState(
					true,
					`Тест не пройден. Результат ${(stores.xAPIService.testResult.get(`${page.content.id}`) * 100).toFixed(0)}%`,
					5000
				)
				stores.materialDialogStore.setCurrentState(false)
				setIsLoading(false)
			}
    }
  }

  const handleClose = () => {
    if (page !== undefined) {
      stores.xAPIService.sendTestCancelEvent(page)
				.then(r => stores.materialDialogStore.setCurrentState(false))
    }
  }

	return (
		!stores?.courseServiceStore.isCorrectAnswerSettingProcess && page?.content.questions !== undefined
			? <>
				<DialogTitle id="scroll-dialog-title" sx={{...styles.title}} children={`${page?.content.title}`}/>
				<DialogContent dividers={scroll === 'paper'}>
					<DialogContentText
						id="scroll-dialog-title"
						sx={{...styles.title, marginLeft: 3}}
					>
						<Stack direction='row' alignItems='center' spacing={2} margin='20px 0'>
							<CBText
								children={`Тест`}
								fontStyle={FontStyle.LARGE}
							/>
							<StatusTag status={StatusTagType.NOT_LEARNED} text={'Не изучено'} style={styles.tag}/>
							{/*<CBText*/}
							{/*	children={`Попытка в текущей сессии №${stores.xAPIService.testAttempt.get(`${page.content.id}`)}`}*/}
							{/*	fontStyle={FontStyle.LARGE}*/}
							{/*/>*/}
						</Stack>
					</DialogContentText>
					<DialogContentText
						id="scroll-dialog-description"
						ref={descriptionElementRef}
						tabIndex={-1}
						sx={styles.content}
					>
						{stores.materialDialogStore.isOpen && page.content.questions?.map((question, index) => (
							<TestQuestionItem question={question} page={page}/>
						))
						}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<CBButton
						disabled={false}
						onClick={handleClose}
						children={'Закрыть'}
						size={ComponentSize.SMALL}
						type={ComponentType.PRIMARY}
						style={baseButtonStyles.buttonDiscard}
					/>
					<CBButton
						disabled={stores.courseServiceStore.validateButton(page) || isLoading}
						loading={isLoading}
						style={{color: GrayScale.OFF_WHITE_COLOR}}
						size={ComponentSize.SMALL}
						color={GrayScale.OFF_WHITE_COLOR}
						type={ComponentType.PRIMARY}
						children={'Завершить изучение'}
						onClick={handleTestEndClick}
					/>
				</DialogActions>
			</>
			: <div style={styles.progressContainer}>
				<CircularProgress sx={styles.progress}/>
			</div>
	)
})

const styles = StyleSheet.create({
	title: {
		...defaultFontStyles.displaySmallBold,
	},
	tag: {
		borderRadius: 20,
		marginTop: 2,
		whiteSpace: 'nowrap'
	},
	content: {
		width: '900px',
		marginLeft: 3,
		marginRight: 3,
		...defaultFontStyles.linkSmall
	},
	progressContainer: {
		display: 'flex',
		...baseStyles.centeredView,
		width: '400px',
		height: '500px'
	},
	progress: {
		alignSelf: 'center',
		color: Colors.PRIMARY_COLOR,
	}
})

export default LearningTest
