import {observer} from "mobx-react-lite";
import React, {CSSProperties} from "react";
import {DialogType} from "./MaterialDialogStore";
import {stores} from "../../pages/_app";
import EndStudentRegistrationDialogItem from "./Confirm/Registration/EndStudentRegistration";
import CreateQuestionDialogItem from "./Materials/Test/CreateQuestion";
import CreateChapterDialogItem from "./Materials/Course/CreateChapter";
import UploadUserProfilePhoto from "./UploadFile/UploadUserProfilePhoto";
import UploadArticleCover from "./UploadFile/UploadArticleCover";
import UploadCourseCover from "./UploadFile/UploadCourseCover";
import UploadCourseIcon from "./UploadFile/UploadCourseIcon";
import UploadUserProfileCover from "./UploadFile/UploadUserProfileCover";
import UploadPdfFile from "./UploadFile/UploadPdfFile";
import UserSettings from "./Settings/UserSettings";
import SupportDialog from "./Support/SupportDialog";
import LearnMaterialDialog from "./learnCourse/LearningMaterial/LearnMaterialDialog";
import SeeLearnedMaterial from "./learnCourse/LearnedMaterial/SeeLearnedMaterial";
import {CBModal, CBModalType} from "../../uikit/baseComponent/modal";
import CBModalWithInput from "../../uikit/components/modals/CBModalWithInput";
import CBModalWithCheckbox from "../../uikit/components/modals/CBModalWithCheckbox";
import {Routers} from "../../utils/Routers";
import {PageProfileNames} from "../../stores/MenuProfileStore";
import {useRouter} from "next/router";
import {SxProps} from "@mui/material";
import CBCertificateEditor from "../../uikit/MaterialDialog/Certificate/CBCertificateEditor";
import CBChooseCategoriesCourse from "../../uikit/MaterialDialog/Course/CBChooseCategoriesCourse";
import ChooseArticleCategories from "./Materials/Article/ChooseArticleCategories";
import CBGPTDialog from "./GPTDialog/CBGPTDialog";
import {GPTRequestType} from "../../stores/GPTStore";
import DiscussionModal from "./analytics/discussion";

interface IProps {
  dialogContent: DialogType
  dialogType?: CBModalType,
  title?: string,
  id?: number,
  callback?: () => void,
  bottomStyles?: CSSProperties | SxProps
}

export const MaterialDialog = observer(({dialogContent, dialogType, title, id, callback, bottomStyles}: IProps)=> {
  const router = useRouter()

  const createCourseProps = {
    title: 'Новый курс',
    description: 'Создайте новый курс и поделитесь своими знаниями',
    placeholder: 'Введите название нового курса',
    discardButton: 'Отменить',
    acceptButton: 'Создать',
    onAccess: () => stores.constructorMaterialsStore.createNewCourse().then(
    onSuccess => {
      stores.materialDialogStore.setMessageViewState(true, 'Курс успешно создан')
      stores.constructorMaterialsStore.setTitleForNewCourse('')
      stores.constructorMaterialsStore.loadAuthorsCourses()
      stores.materialDialogStore.setCurrentState(false)
      router.push(Routers.PERSONAL_PROFILE_WITH_PARAMETER_MATERIAL.replace(':pageType', PageProfileNames.authorCourses).replace(':materialId', onSuccess.id.toString()))
    },
    onError => {
      stores.materialDialogStore.setMessageViewState(true, 'Ошибка создания курса, попробуйте позднее')
    }
  ),
  onDiscard: () => stores.materialDialogStore.setCurrentState(false),
  validationInput: (stores.constructorMaterialsStore.newCourse?.title?.length || 0) > 5 && (stores.constructorMaterialsStore.newCourse?.title?.length || 0) < 501,
  onChangeInput: (text) => stores.constructorMaterialsStore.setTitleForNewCourse(text),
  helperTextInput: (stores.constructorMaterialsStore.newCourse?.title?.length || 0) > 500 ? "Количество символов превысило 500" : "",
  onClearClickInput: () => stores.constructorMaterialsStore.setTitleForNewCourse(''),
  discardButtonDisabled: stores.constructorMaterialsStore.isCourseCreateProcess,
  acceptButtonDisabled: !stores.constructorMaterialsStore.validateTitle,
  acceptButtonLoading: stores.constructorMaterialsStore.isCourseCreateProcess
  }

  const deleteCourseProps = {
    title: 'Удаление Курса',
    description: `Вы действительно хотите удалить курс "${title}"`,
    agreement: 'Я осведомлён о том, что удаленный материал не подлежит восстановлению',
    discardButtonDisabled: stores?.courseEditViewStore.isCourseLoadSaveProcess,
    onDiscard: () => stores.materialDialogStore.setCurrentState(false),
    discardButton: 'Отменить',
    acceptButtonLoading: stores.courseEditViewStore.isCourseLoadSaveProcess,
    acceptButton: 'Удалить',
    onAccess: () => stores.courseEditViewStore.deleteCurrentCourse().then(() => {
      stores.materialDialogStore.setMessageViewState(true, 'Курс успешно удален')
      stores.constructorMaterialsStore.loadAuthorsCourses()
      stores.materialDialogStore.setCurrentState(false)
    }).catch(() =>  {
      stores.materialDialogStore.setMessageViewState(true, 'Ошибка удаления курса, попробуйте позднее')
      stores.materialDialogStore.setCurrentState(false)
    })
  }

  const deleteArticleProps = {
    title: 'Удаление статьи',
    description: `Вы хотите удалить статью "${stores.constructorMaterialsStore.currentDeleteArticleTitle}"`,
    agreement: 'Я осведомлён о том, что удаленный материал не подлежит восстановлению',
    discardButton: 'Отменить',
    acceptButton: 'Удалить',
    discardButtonDisabled: stores.constructorMaterialsStore.isArticleCreateProcess,
    acceptButtonLoading: stores?.constructorMaterialsStore.isArticleDeleteProcess,
    onDiscard: () => {
      stores.materialDialogStore.setCurrentState(false),
        stores.constructorMaterialsStore.setCurrentDeleteArticleTitle(undefined);
    },
    onAccess: () => {
      stores.courseEditViewStore.deletePageById(stores.courseEditViewStore.currentPageId).then(
        onSuccess => {
          stores.materialDialogStore.setMessageViewState(true, 'Статья успешно удалена');
        },
        onError => {
          stores.materialDialogStore.setMessageViewState(true, 'Ошибка удаления статьи, попробуйте позднее')
        }
      )
      stores.materialDialogStore.setCurrentState(false);
    }
  }

  const createArticleWithChooseProps = {
    title: 'Создание статьи',
    description: 'Создание самостоятельной статьи',
    placeholder: 'Введите название новой статьи',
    discardButton: 'Отменить',
    acceptButton: 'Создать',
    onAccess: () => stores?.constructorMaterialsStore.createNewArticle().then(
      onSuccess => {
        stores.materialDialogStore.setMessageViewState(true, 'Статья успешно создана')
        stores.materialDialogStore.setCurrentState(false)
        router.push(Routers.PERSONAL_PROFILE_WITH_PARAMETER_MATERIAL.replace(':pageType', PageProfileNames.authorArticles).replace(':materialId', onSuccess.id.toString()))
      },
      onError => {
        stores.materialDialogStore.setMessageViewState(true, 'Ошибка создания статьи, попробуйте позднее')
      }
    ),
    onDiscard: () => stores.materialDialogStore.setCurrentState(false),
    validationInput: (stores.constructorMaterialsStore.newArticle?.title?.length || 0) > 5 && (stores.constructorMaterialsStore.newArticle?.title.length || 0) < 501,
    onChangeInput: (text) => stores.constructorMaterialsStore.setTitleForNewArticleWithCourseId(text),
    helperTextInput: (stores?.constructorMaterialsStore.newArticle?.title?.length || 0) > 500 ? 'Количество символов превысило 500' : '',
    onClearClickInput: () => stores.constructorMaterialsStore.setTitleForNewArticleWithCourseId(''),
    discardButtonDisabled: stores.constructorMaterialsStore.isArticleCreateProcess,
    acceptButtonDisabled: stores.constructorMaterialsStore.isSingleArticle ? (stores.constructorMaterialsStore.newArticle?.title?.length || 0) < 6 || (stores.constructorMaterialsStore.newArticle?.title?.length || 0) > 500 : true,
    acceptButtonLoading: stores?.constructorMaterialsStore.isArticleCreateProcess,
    callback: () => stores.constructorMaterialsStore.loadAuthorsCourses(),
    callbackWithDeps: () => {
      if (stores.materialDialogStore.isOpen === true) {
        stores.constructorMaterialsStore.setTitleForNewArticleWithCourseId("")
        stores.constructorMaterialsStore.setCourseIdForNewArticle(0, "")
        stores.constructorMaterialsStore.setIsSingleArticle(true)
      }
    },
    deps: stores.materialDialogStore.isOpen,
  }

  const createArticleWithoutChooseProps = {
    title: 'Новая страница - статья',
    placeholder: 'Введите название новой статьи',
    discardButton: 'Отменить',
    acceptButton: 'Создать',
    onAccess: () => {
      stores.constructorMaterialsStore.createNewArticle().then(
        onSuccess => {
          stores.materialDialogStore.setMessageViewState(true, 'Статья успешно создана')
          stores.courseEditViewStore.currentCourse?.id !== undefined && stores?.courseEditViewStore.getCurrentStructure(stores?.courseEditViewStore.currentCourse?.id || 0).then(
            onSuccess => {

            },
            onError => {
              stores.materialDialogStore.setMessageViewState(true, 'Ошибка загрузки структуры курса, попробуйте позднее')
            }
          )
        },
        onError => {
          stores.materialDialogStore.setMessageViewState(true, 'Ошибка создания статьи, попробуйте позднее')
        }
      )
      stores.materialDialogStore.setCurrentState(false);
    },
    onDiscard: () => {
      stores.constructorMaterialsStore.clearArticleCreateCache();
      stores.materialDialogStore.setCurrentState(false);
    },
    validationInput: (stores.constructorMaterialsStore.newArticle?.title?.length || 0) > 5 && (stores.constructorMaterialsStore.newArticle?.title?.length || 0) < 501,
    onChangeInput: (text) => stores?.constructorMaterialsStore.setTitleForNewArticle(text),
    helperTextInput: (stores.constructorMaterialsStore.newArticle?.title?.length || 0) > 500 ? 'Количество символов превысило 500' : '',
    onClearClickInput: () => stores.constructorMaterialsStore.setTitleForNewArticle(''),
    discardButtonDisabled: stores.constructorMaterialsStore.isArticleCreateProcess,
    acceptButtonDisabled: stores.constructorMaterialsStore.newArticle?.chapterId > 0
      ? (stores.constructorMaterialsStore.newArticle?.title.length || 0) < 6 || (stores.constructorMaterialsStore.newArticle?.title.length || 0) > 500
      : true,
    acceptButtonLoading: stores.constructorMaterialsStore.isArticleCreateProcess,
    callback: () => {
      stores.constructorMaterialsStore.setIsSingleArticle(false);
      stores.constructorMaterialsStore.setSelectChapterIdForCreateArticles(stores?.courseEditViewStore.currentChapterId || 0)
    }
  }

  const createGroupProps = {
    title: 'Создание новой группы пользователей',
    description: 'При помощи группы пользователей вы сможете создавать пустые аккаунты, а также назначать своим пользователям собственные курсы',
    placeholder: 'Введите название новой группы',
    discardButton: 'Отменить',
    acceptButton: 'Создать',
    onAccess: () => stores.userGroupsStore.createUserGroup().then(
      onSuccess => {
        stores.materialDialogStore.setMessageViewState(true, 'Группа успешно создана')
        stores.materialDialogStore.setCurrentState(false)
      },
      onError => {
        stores.materialDialogStore.setMessageViewState(true, 'Ошибка создания группы, попробуйте позднее')
      }
    ),
    onDiscard: () => stores.materialDialogStore.setCurrentState(false),
    validationInput: (stores.userGroupsStore.newGroupTitle?.length || 0) > 5 && (stores.userGroupsStore.newGroupTitle.length || 0) < 51,
    onChangeInput: (text) => stores.userGroupsStore.setGroupTitle(text),
    helperTextInput: (stores.userGroupsStore.newGroupTitle?.length || 0) > 50 ? 'Максимальная длина названия не должна превышать 50 символов' : 'Название группы должно содержать 6 символов и более',
    onClearClickInput: () => stores.userGroupsStore.setGroupTitle(''),
    discardButtonDisabled: stores.userGroupsStore.isGroupProcess,
    acceptButtonDisabled: (stores.userGroupsStore.newGroupTitle?.length || 0) < 6 || (stores.userGroupsStore.newGroupTitle.length || 0) > 50,
    acceptButtonLoading: stores.userGroupsStore.isGroupProcess
  }

  const deleteGroupProps = {
    title: 'Удаление группы пользователей',
    description: 'При удалении группы пользователей, все неактивные пользователи удаляться с платформы Coursebit',
    additionalDescription: 'Если у группы установлен курс и его уже начали проходить некоторые пользователи из группы, функция удаление группы отчистит группу от пользователей, которые не активировали свои учетные записи, либо не начали проходить курс, оставив в ней пользователей, проходящих установленный курс.',
    agreement: 'Подтверждаю удаление группы и всех её не активных пользователей',
    discardButton: 'Отменить',
    acceptButton: 'Удалить',
    discardButtonDisabled: stores.userGroupsStore.isGroupProcess,
    acceptButtonLoading: stores?.userGroupsStore.isGroupProcess,
    onDiscard: () => stores.materialDialogStore.setCurrentState(false),
    onAccess: () => stores.userGroupsStore.deleteCurrentUserGroup().then(
      onSuccess => {
        if (onSuccess === true)
          stores.materialDialogStore.setMessageViewState(true, 'Группа успешно удалена')
        stores.materialDialogStore.setCurrentState(false)
      },
      onError => {
        stores.materialDialogStore.setMessageViewState(true, 'Ошибка удаления группы, попробуйте позднее')
      }
    ),
  }

  const setNewCourseForGroupProps = {
    title: 'Выберите курс',
    placeholder: 'Выберите курс',
    discardButton: 'Отменить',
    acceptButton: 'Назначить',
    onAccess: () => stores.userGroupsStore.setCourseForGroup().then(
      onSuccess => {
        stores.materialDialogStore.setMessageViewState(true, 'Курс успешно назначен')
        stores.materialDialogStore.setCurrentState(false)
      },
      onError => {
        stores.materialDialogStore.setMessageViewState(true, 'Ошибка назначения курса, попробуйте позднее')
      }
    ),
    onDiscard: () => stores.materialDialogStore.setCurrentState(false),
    onChangeInput: (text) => stores.userGroupsStore.setSelectedCourseForGroup(stores.constructorMaterialsStore.authorsCourses.find(course => course.title.toLowerCase() === String(text).toLowerCase().trim())?.id),
    onClearClickInput: () => stores.userGroupsStore.setSelectedCourseForGroup(undefined),
    validationInput: !!stores.constructorMaterialsStore.authorsCourses.find(course => course.id === stores.userGroupsStore.selectedCourseForGroup),
    discardButtonDisabled: stores.userGroupsStore.isSetCourseProcess,
    acceptButtonDisabled: !((stores.userGroupsStore.selectedCourseForGroup || 0) > 0),
    acceptButtonLoading: stores?.userGroupsStore.isSetCourseProcess,
    callback: () => stores?.constructorMaterialsStore.loadAuthorsCourses(),
    optionsAutocomplete: stores.constructorMaterialsStore.authorsCourses.filter(item => item.title !== null) || []
  }

  const deleteCourseInGroupProps = {
    title: 'Удаление курса',
    description: 'При удалении курса он удалится и у пользователей которые уже возможно начали его проходить',
    placeholder: 'Причина удаления',
    discardButton: 'Отменить',
    acceptButton: 'Удалить',
    onClearClickInput: () => stores.userGroupsStore.setSelectedCourseForGroup(undefined),
    validationInput: (stores.userGroupsStore.reason?.length || 0) > 6 && (stores.userGroupsStore.reason?.length || 0) < 250,
    helperTextInput: (stores.userGroupsStore.reason?.length || 0) === 0 ? 'Укажите причину по которой хотите удалить курс' : !((stores.userGroupsStore.reason?.length || 0) > 6 && (stores.userGroupsStore.reason?.length || 0) < 250) ? 'Текст причины должен содержать больше 6 символов и меньше 250' : null,
    onAccess: () => stores.userGroupsStore.removeCourseFromGroup().then(
      onSuccess => {
        stores.materialDialogStore.setMessageViewState(true, 'Курс успешно удален')
        stores.materialDialogStore.setCurrentState(false)
      },
      onError => {
        stores.materialDialogStore.setMessageViewState(true, 'Ошибка удаления курса, попробуйте позднее')
      }
    ),
    onDiscard: () => stores.materialDialogStore.setCurrentState(false),
    onChangeInput: (value) => stores.userGroupsStore.setReason(value),
    discardButtonDisabled: stores.userGroupsStore.isRemoveCourseProcess,
    acceptButtonDisabled: !stores.userGroupsStore.validReason,
    acceptButtonLoading: stores.userGroupsStore.isRemoveCourseProcess,
  }

  const deleteUnusedUsersInGroupProps = {
    title: 'Удаление не активированных пользователей',
    description: 'Удалить не активированные аккаунты из группы и платформы Coursebit?',
    agreement: 'Подтверждаю удаление неактивных пользователей',
    discardButton: 'Отменить',
    acceptButton: 'Удалить',
    acceptButtonLoading: stores?.userGroupsStore.isGroupProcess,
    onAccess: () => stores.userGroupsStore.deleteUnusedUsersInGroup().then(
      onSuccess => {
        stores.materialDialogStore.setMessageViewState(true, 'Не активированные пользователи успешно удалены')
        stores.materialDialogStore.setCurrentState(false)
      },
      onError => {
        stores.materialDialogStore.setMessageViewState(true, 'Ошибка удаления пользователей, попробуйте позднее')
      }
    ),
    discardButtonDisabled: stores.userGroupsStore.isGroupProcess,
    onDiscard: () => stores.materialDialogStore.setCurrentState(false)
  }

  const addUserByLoginInGroupProps = {
    title: 'Добавить пользователя в группу по логину',
    description: 'Введите логин пользователя',
    placeholder: 'Логин пользователя',
    discardButton: 'Отменить',
    acceptButton: 'Добавить',
    onClearClickInput: () => stores.userGroupsStore.changeCurrentUserInGroupLogin(undefined),
    validationInput: (stores.userGroupsStore.currentUserInGroupLogin?.length || 0) > 4 && (stores.userGroupsStore.currentUserInGroupLogin?.length || 0) < 21,
    onChangeInput: (value) => {
      if (stores.userGroupsStore.invalidCurrentUserInGroupLogin?.length > 0) {
        stores.userGroupsStore.changeAddingUserInGroupError(undefined)
      }
      stores.userGroupsStore.changeCurrentUserInGroupLogin(value)
    },
    helperTextInput: !stores.materialDialogStore.messageView ? ((stores.userGroupsStore.currentUserInGroupLogin?.length || 0) > 20 ? 'Максимальная длина логина не должна превышать 20 символов' : (stores.userGroupsStore.currentUserInGroupLogin?.length || 0) < 5 ? 'Логин не может быть пустым и должен быть не короче 5 символов' : null) : 'Заданного пользователя не существует',
    acceptButtonDisabled: (stores.userGroupsStore.currentUserInGroupLogin?.length || 0) < 5 || (stores.userGroupsStore.currentUserInGroupLogin.length || 0) > 20,
    acceptButtonLoading: stores.userGroupsStore.isGroupProcess,
    onAccess: () => stores.userGroupsStore.addUserInGroupByLogin(stores.userGroupsStore.currentUserInGroupLogin).then(
      onSuccess => {
        stores.materialDialogStore.setCurrentState(false)
      },
      onError => {
        stores.materialDialogStore.setMessageViewState(true, 'Заданного пользователя не существует');
        stores.userGroupsStore.changeAddingUserInGroupError('Заданного пользователя не существует')
        stores.userGroupsStore.changeCurrentUserInGroupLogin('');
      }
    ),
    onDiscard: () => stores.materialDialogStore.setCurrentState(false),
    discardButtonDisabled: stores.userGroupsStore.isGroupProcess
  }

  const generateUserInGroupProps = {
    title: 'Генерация пустых аккаунтов для группы пользователей',
    description: 'Сгенерируйте нужное количество пустых аккаунтов, которые можно раздать своим обучающимся',
    placeholder: 'Введите количество генерируемых аккаунтов',
    discardButton: 'Отменить',
    acceptButton: 'Сгенерировать',
    onClearClickInput: () => stores.userGroupsStore.changeGeneratedUsersCount(0),
    validationInput: stores.userStore.userStorageData?.userStorage?.countStudents !== undefined && stores.userStore.userStorageData.userStorage?.countStudents !== null
    && stores.userStore.userStorageData.userSubscribe?.countStudents !== undefined && stores.userStore.userStorageData.userSubscribe?.countStudents !== null ? stores.userGroupsStore.generatedUsers + stores?.userStore.userStorageData?.userStorage?.countStudents < +stores?.userStore.userStorageData?.userSubscribe?.countStudents + 1 && stores.userGroupsStore.generatedUsers > 0 : true,
    onChangeInput: (value) => stores.userGroupsStore.changeGeneratedUsersCount(+value),
    defaultValue: 0,
    helperTextInput: stores.userGroupsStore.generatedUsers + stores?.userStore.userStorageData?.userStorage?.countStudents > stores?.userStore.userStorageData?.userSubscribe?.countStudents || stores.userGroupsStore.generatedUsers < 1 ? 'Значение должно быть больше 0 и не должно суммарно превышать количество обучающихся в вашем тарифном плане' : null,
    acceptButtonDisabled: (stores.userStore.userStorageData?.userStorage?.countStudents !== undefined && stores.userStore.userStorageData?.userStorage?.countStudents !== null
      && stores.userStore.userStorageData?.userSubscribe?.countStudents !== undefined && stores.userStore.userStorageData.userSubscribe?.countStudents !== null) ? stores.userGroupsStore.generatedUsers + stores?.userStore.userStorageData?.userStorage?.countStudents > stores?.userStore.userStorageData.userSubscribe.countStudents || stores.userGroupsStore.generatedUsers < 1 : true,
    acceptButtonLoading: stores.userGroupsStore.isGroupProcess,
    onAccess: () => stores.userGroupsStore.generateUserInGroup(stores.userGroupsStore.generatedUsers).then(
      onSuccess => {
        stores.materialDialogStore.setMessageViewState(true, 'Новые пользователи успешно сгенерированы')
        stores.materialDialogStore.setCurrentState(false)
      },
      onError => {
        stores.materialDialogStore.setMessageViewState(true, 'Ошибка генерации, попробуйте позднее')
      }
    ),
    onDiscard: () => stores.materialDialogStore.setCurrentState(false),
    discardButtonDisabled: stores.userGroupsStore.isGroupProcess
  }

  const deleteTestProps = {
    title: 'Удаление теста',
    description: `Вы хотите удалить тест "${stores.testEditViewStore.currentDeleteTestTitle !== undefined ? stores.testEditViewStore.currentDeleteTestTitle : ''}"`,
    agreement: 'Я осведомлён о том, что удаленный материал не подлежит восстановлению',
    discardButton: 'Отменить',
    acceptButton: 'Удалить',
    acceptButtonLoading: stores.constructorMaterialsStore.isTestDeleteProcess,
    onAccess: () => {
      stores.courseEditViewStore.deletePageById(stores.testEditViewStore.currentDeleteTestId || 0).then(() => {
        stores.materialDialogStore.setMessageViewState(true, 'Тест успешно удален')
        stores?.courseEditViewStore.currentCourse?.id !== undefined && stores?.courseEditViewStore.getCurrentStructure(stores?.courseEditViewStore.currentCourse?.id).then(
          onSuccess => {

          },
          onError => {

          }
        )
        stores.materialDialogStore.setCurrentState(false)
      }).catch(() => {
        stores.materialDialogStore.setMessageViewState(true, 'Ошибка удаления теста, попробуйте позднее')
        stores.materialDialogStore.setCurrentState(false)
      })
    },
    discardButtonDisabled: stores.constructorMaterialsStore.isTestDeleteProcess,
    onDiscard: () => stores.materialDialogStore.setCurrentState(false)
  }

  const deleteQuestionProps = {
    title: 'Удаление вопроса',
    description: `Вы хотите удалить вопрос ${stores.testEditViewStore?.deleteQuestionTitle !== undefined ? stores.testEditViewStore?.deleteQuestionTitle : ''}`,
    agreement: 'Я осведомлён о том, что удаленный материал не подлежит восстановлению',
    discardButton: 'Отменить',
    acceptButton: 'Удалить',
    acceptButtonLoading: stores.constructorMaterialsStore.isArticleDeleteProcess,
    onAccess: () => {
      stores.testEditViewStore.deleteQuestion(stores.testEditViewStore?.deleteQuestionId || 0).then(
        onSuccess => {
          stores.materialDialogStore.setCurrentState(false)
          stores.materialDialogStore.setMessageViewState(true, 'Вопрос успешно удален')
        },
        onError => {
          stores.materialDialogStore.setMessageViewState(true, 'Ошибка удаления вопроса, попробуйте позднее')
        }
      )
    },
    discardButtonDisabled: stores.constructorMaterialsStore.isArticleCreateProcess,
    onDiscard: () => stores.materialDialogStore.setCurrentState(false)
  }

  const restoreArticleEditDataProps = {
    title: 'Сборос изменений статьи',
    description: 'Сброс изменений приведёт к удалению всех новых правок в данной статье, которые не были сохранены',
    discardButton: 'Отменить',
    acceptButton: 'Сбросить',
    acceptButtonLoading: stores.articleEditViewStore.isArticleLoadSaveProcess,
    onAccess: () => {
      stores.articleEditViewStore.restoreArticle().then(
        onSuccess => {
          stores.materialDialogStore.setCurrentState(false)
          stores.materialDialogStore.setMessageViewState(true, 'Данные успешно сброшены')
        },
        onError => {
          stores.materialDialogStore.setMessageViewState(true, 'Ошибка сброса данных, попробуйте позднее')
        })
    },
    discardButtonDisabled: stores.articleEditViewStore.isArticleLoadSaveProcess,
    onDiscard: () => stores.materialDialogStore.setCurrentState(false)
  }

  const createTestProps = {
    title: 'Новая страница - тест',
    placeholder: 'Введите название нового теста',
    discardButton: 'Отменить',
    acceptButton: 'Создать',
    onClearClickInput: () => stores.constructorMaterialsStore.setTitleForNewTest(''),
    validationInput: (stores.constructorMaterialsStore.newTest?.title?.length || 0) > 5 && (stores.constructorMaterialsStore.newTest?.title.length || 0) < 501,
    onChangeInput: (text) => stores.constructorMaterialsStore.setTitleForNewTest(text),
    helperTextInput: (stores.constructorMaterialsStore.newTest?.title?.length || 0) > 500 ? "Количество символов превысило 500" : null,
    acceptButtonDisabled: stores.constructorMaterialsStore.newTest?.chapterId === undefined ? true : stores.constructorMaterialsStore.newTest.chapterId === 0 ? true : stores.constructorMaterialsStore.newTest.chapterId > 0 ? (stores.constructorMaterialsStore.newTest.title.length || 0) < 6 || ((stores.constructorMaterialsStore.newTest.title.length || 0) > 500) : true,
    acceptButtonLoading: stores.constructorMaterialsStore.isTestCreateProcess,
    onAccess: () => stores.constructorMaterialsStore.createNewTestByTitle().then(
      onSuccess => {
        stores.materialDialogStore.setMessageViewState(true, 'Тест успешно создан')
        stores.courseEditViewStore.currentCourse?.id !== undefined && stores.courseEditViewStore.getCurrentStructure(stores?.courseEditViewStore.currentCourse?.id || 0).then(
          onSuccess => {

          },
          onError => {

          }
        )
        stores.materialDialogStore.setCurrentState(false)
      },
      onError => {
        stores.materialDialogStore.setMessageViewState(true, 'Ошибка создания теста, попробуйте позднее')
      }
    ),
    onDiscard: () => stores.materialDialogStore.setCurrentState(false),
    discardButtonDisabled: stores.constructorMaterialsStore.isTestCreateProcess,
    callback: () => {
      stores.constructorMaterialsStore.setChapterIdForNewTest(stores?.courseEditViewStore.currentChapterId || 0)
    }
  }

  const deleteChapterProps = {
    title: 'Удаление Раздела',
    description: 'Все материалы входящие в раздел будут удалены',
    agreement: 'Я осведомлён о том, что удаленный материал не подлежит восстановлению',
    discardButton: 'Отменить',
    acceptButton: 'Удалить',
    acceptButtonLoading: stores?.courseEditViewStore.isChapterDeleteProcess,
    onAccess: () => {
      stores?.courseEditViewStore.deleteChapterById(stores.courseEditViewStore.currentChapterId || 0, true).then(
        onSuccess => {
          stores.materialDialogStore.setMessageViewState(true, 'Раздел успешно удален');
          stores.materialDialogStore.setCurrentState(false);
        },
        onError => {
          stores.materialDialogStore.setMessageViewState(true, 'Ошибка удаления раздела, попробуйте позднее');
          stores.materialDialogStore.setCurrentState(false)
        }
      )
    },
    discardButtonDisabled: stores.courseEditViewStore.isChapterDeleteProcess,
    onDiscard: () => stores.materialDialogStore.setCurrentState(false)
  }

  const confirmExitProps = {
    title: 'Вы действительно хотите выйти?',
    description: 'Все несохраненные данные будут удалены',
    discardButton: 'Отменить',
    acceptButton: 'Продолжить',
    acceptButtonLoading: stores.articleEditViewStore.isArticleLoadSaveProcess,
    onAccess: () => callback(),
    onDiscard: () => stores.materialDialogStore.setCurrentState(false)
  }

  const deleteVideoProps = {
    title: 'Удаление видео',
    description: `Вы хотите удалить видео ${title !== undefined ? title : ''}`,
    agreement: 'Я осведомлён о том, что удаленный материал не подлежит восстановлению',
    discardButton: 'Отменить',
    acceptButton: 'Удалить',
    acceptButtonLoading: stores?.constructorMaterialsStore.isVideoDeleteProcess,
    onAccess: () => {
      stores.courseEditViewStore.deletePageById(stores.videoEditViewStore.currentDeleteVideoId || 0).then(() => {
        stores.materialDialogStore.setMessageViewState(true, 'Видео успешно удалено')
        stores.userStore.loadUserStorageData(false)
        stores.courseEditViewStore.currentCourse?.id !== undefined && stores.courseEditViewStore.getCurrentStructure(stores?.courseEditViewStore.currentCourse?.id).then(
          onSuccess => {

          },
          onError => {

          }
        )
        stores.materialDialogStore.setCurrentState(false)
      }).catch(() => {
        stores.materialDialogStore.setMessageViewState(true, 'Ошибка удаления видео, попробуйте позднее')
        stores.materialDialogStore.setCurrentState(false)
      })
    },
    discardButtonDisabled: stores.constructorMaterialsStore.isVideoDeleteProcess,
    onDiscard: () => stores.materialDialogStore.setCurrentState(false)
  }

  const restoreTestEditData = {
    title: 'Сборос изменений теста',
    description: 'Сброс изменений приведёт к удалению всех новых правок в данном тесте, которые не были сохранены',
    discardButton: 'Отменить',
    acceptButton: 'Сбросить',
    acceptButtonLoading: stores.testEditViewStore.isTestLoadSaveProcess,
    onAccess: () => {
      Promise.all([
        stores.testEditViewStore.loadCurrentTest(stores.testEditViewStore.currentTest.id),
        stores.testEditViewStore.getTestStructure(stores.testEditViewStore.currentTest.id)
      ]).then(
        onSuccess => {
          stores.materialDialogStore.setMessageViewState(true, 'Данные успешно сброшены');
        },
        onError => {
          stores.materialDialogStore.setMessageViewState(true, 'Ошибка сброса данных, попробуйте позднее');
        }
      )
      stores.materialDialogStore.setCurrentState(false);
    },
    discardButtonDisabled: stores.testEditViewStore.isTestLoadSaveProcess,
    onDiscard: () => stores.materialDialogStore.setCurrentState(false)
  }

  const restoreCourseEditData = {
    title: 'Сборос изменений курса',
    description: 'Сброс изменений приведёт к удалению всех новых правок в данном курсе, которые не были сохранены',
    discardButton: 'Отменить',
    acceptButton: 'Сбросить',
    acceptButtonLoading: stores.courseEditViewStore.isCourseLoadSaveProcess,
    onAccess: () => {
      stores.courseEditViewStore.loadCurrentCourse(stores.courseEditViewStore.currentCourse.id).then(
        onSuccess => {
          stores.materialDialogStore.setMessageViewState(true, 'Данные успешно сброшены');
        },
        onError => {
          stores.materialDialogStore.setMessageViewState(true, 'Ошибка сброса данных, попробуйте позже');
        }
      )
      stores.materialDialogStore.setCurrentState(false);
    },
    discardButtonDisabled: stores.courseEditViewStore.isCourseLoadSaveProcess,
    onDiscard: () => stores.materialDialogStore.setCurrentState(false)
  }

  const createVideoProps = {
    title: 'Новая страница - видео',
    placeholder: 'Введите название нового видео',
    discardButton: 'Отменить',
    acceptButton: 'Создать',
    onAccess: () => stores.constructorMaterialsStore.createNewVideoByTitle().then(
      onSuccess => {
        stores.materialDialogStore.setMessageViewState(true, 'Видео успешно создано')
        stores.courseEditViewStore.currentCourse?.id !== undefined && stores.courseEditViewStore.getCurrentStructure(stores?.courseEditViewStore.currentCourse?.id || 0).then(
          onSuccess => {

          },
          onError => {

          }
        )
        stores.materialDialogStore.setCurrentState(false)
      },
      onError => {
        stores.materialDialogStore.setMessageViewState(true, 'Ошибка создания видео, попробуйте позднее')
      }
    ),
    onDiscard: () => {
      stores.materialDialogStore.setCurrentState(false);
    },
    validationInput: (stores.constructorMaterialsStore.newVideo?.title?.length || 0) > 5 && (stores.constructorMaterialsStore.newVideo?.title?.length || 0) < 20,
    onChangeInput: (text) => stores.constructorMaterialsStore.setTitleForNewVideo(text),
    helperTextInput: (stores.constructorMaterialsStore.newVideo?.title?.length || 0) > 20 ? 'Количество символов превысило 20' : '',
    onClearClickInput: () => stores.constructorMaterialsStore.setTitleForNewVideo(''),
    discardButtonDisabled: stores.constructorMaterialsStore.isVideoCreateProcess,
    acceptButtonDisabled: stores.constructorMaterialsStore.newVideo?.chapterId > 0
      ? (stores.constructorMaterialsStore.newVideo?.title.length || 0) < 6 || (stores.constructorMaterialsStore.newVideo?.title.length || 0) > 500
      : true,
    acceptButtonLoading: stores.constructorMaterialsStore.isVideoCreateProcess,
    callbackWithDeps: () => {
      if (stores.materialDialogStore.isOpen === true) {
        stores.constructorMaterialsStore.setTitleForNewVideo('')
        if (stores.courseEditViewStore.currentCourse?.id !== undefined) {
          // @ts-ignore
          stores.constructorMaterialsStore.setChapterIdForNewVideo(stores.courseEditViewStore.currentCourse?.id)
        } else stores.constructorMaterialsStore.setChapterIdForNewVideo(0)
      }
    },
    deps: stores.materialDialogStore.isOpen
  }

  const getDialogBody = () => {
    switch (dialogContent) {
      case DialogType.endRegistration: return <EndStudentRegistrationDialogItem/>
      case DialogType.createCourse: return <CBModalWithInput {...createCourseProps}/>
      case DialogType.deleteCourse: return <CBModalWithCheckbox {...deleteCourseProps}/>
      case DialogType.createArticleWithChoose: return <CBModalWithInput {...createArticleWithChooseProps} />
      case DialogType.createArticleWithoutChoose: return <CBModalWithInput {...createArticleWithoutChooseProps} />
      case DialogType.deleteArticle: return <CBModalWithCheckbox {...deleteArticleProps} />
      case DialogType.deleteTest: return <CBModalWithCheckbox {...deleteTestProps}/>
      case DialogType.createQuestion: return <CreateQuestionDialogItem />
      case DialogType.deleteQuestion: return <CBModalWithCheckbox {...deleteQuestionProps}/>
      case DialogType.restoreArticleEditData: return <CBModalWithCheckbox {...restoreArticleEditDataProps} type='confirmation' />
      case DialogType.restoreTestEditData: return <CBModalWithCheckbox {...restoreTestEditData} type='confirmation' />
      case DialogType.restoreCourseEditData: return <CBModalWithCheckbox {...restoreCourseEditData} type='confirmation' />
      case DialogType.createTest: return <CBModalWithInput {...createTestProps} />
      case DialogType.createChapter: return <CreateChapterDialogItem/>
      case DialogType.deleteChapter: return <CBModalWithCheckbox {...deleteChapterProps}/>
      case DialogType.uploadUserProfilePhoto: return <UploadUserProfilePhoto />
      case DialogType.uploadArticleCover: return <UploadArticleCover />
      case DialogType.uploadCourseCover: return <UploadCourseCover/>
      case DialogType.uploadCourseIcon: return <UploadCourseIcon/>
      case DialogType.uploadUserProfileCover: return <UploadUserProfileCover />
      case DialogType.uploadPdfFile: return <UploadPdfFile callback={callback}/>
      case DialogType.confirmExit: return <CBModalWithCheckbox {...confirmExitProps} type='confirmation'/>
      case DialogType.userSettings: return <UserSettings />
      case DialogType.createGroup: return <CBModalWithInput {...createGroupProps} />
      case DialogType.deleteGroup: return <CBModalWithCheckbox {...deleteGroupProps} />
      case DialogType.deleteUnusedUsersInGroup: return <CBModalWithCheckbox {...deleteUnusedUsersInGroupProps} />
      case DialogType.generateUserInGroup: return <CBModalWithInput {...generateUserInGroupProps} type='number' />
      case DialogType.setNewCourseForGroup: return <CBModalWithInput {...setNewCourseForGroupProps} type='autocomplete'/>
      case DialogType.deleteCourseInGroup: return <CBModalWithInput {...deleteCourseInGroupProps}/>
      case DialogType.supportDialog: return <SupportDialog/>
      case DialogType.learningMaterial: return <LearnMaterialDialog />
      case DialogType.createVideo: return <CBModalWithInput {...createVideoProps} />
      case DialogType.deleteVideo: return <CBModalWithCheckbox {...deleteVideoProps}/>
      case DialogType.seeLearnedMaterial: return <SeeLearnedMaterial/>
      case DialogType.addUserByLoginInGroup: return <CBModalWithInput {...addUserByLoginInGroupProps} />
      case DialogType.chooseCategories: return <ChooseArticleCategories />
      case DialogType.chooseCategoriesCourse: return <CBChooseCategoriesCourse />
      case DialogType.openCertificateEditor: return <CBCertificateEditor />
      case DialogType.gptSummary: return <CBGPTDialog mainTitle={'Краткое содержание статьи'} requestType={GPTRequestType.SUMMARY} materialTitle={stores.marketplaceArticlePageStore?.article?.title ?? ''}/>
      case DialogType.gptUpgrades: return <CBGPTDialog mainTitle={'Улучшение контента статьи'} requestType={GPTRequestType.UPGRADE} materialTitle={stores.articleEditViewStore?.currentArticle?.title ?? ''}/>
      case DialogType.gptArticleComplexityReason: return <CBGPTDialog mainTitle={'Сложность материала'} requestType={GPTRequestType.COMPLEXITY_REASON} materialTitle={stores.articleEditViewStore?.currentArticle?.title ?? ''}/>
      case DialogType.gptCourseQuestions: return <CBGPTDialog mainTitle={'Контрольные вопросы по курсу'} requestType={GPTRequestType.GENERATE_COURSE_QUESTIONS} materialTitle={stores.courseEditViewStore?.currentCourse?.title ?? ''}/>
      case DialogType.gptChapterQuestions: return <CBGPTDialog mainTitle={'Контрольные вопросы по разделу'} requestType={GPTRequestType.GENERATE_CHAPTER_QUESTIONS} materialTitle={stores.courseEditViewStore?.currentStructure?.chapters[stores.courseEditViewStore?.currentChapterId]?.title ?? ''}/>
      case DialogType.discussionAnalytics: return <DiscussionModal/>
      default: return <></>
    }
  }

  const isDialogShow = () => {
    return !!stores.materialDialogStore.isOpen
            && dialogContent === stores.materialDialogStore.currentDialogType
  }

  return(
    <>
      <CBModal
        isShow={isDialogShow()}
        type={dialogType}
        bottomStyles={bottomStyles}
        onClose={() => {
          // TODO Организовать получше процесс закрытия модального окна (желательно синхронизировать с кнопкой закрыть)
          switch (dialogContent) {
            case DialogType.userSettings:
              if (stores.userStore.isMainDataChange
                || stores.userStore.isChangeInterestsChanged
                || stores.userStore.isUserPrivacySettingsChanged
                || stores.userStore.isChangePasswordDataChanged
                || stores.userStore.isAdditionalDataChanged) {
                if (!window.confirm("Возможно, внесенные изменения не сохранятся.")) return;
              }
              stores.userStore.cancelMainInfoBufferChanges();
              stores.userStore.resetChangeInterestsUser();
              stores.userStore.cancelUserPrivacySettingsBufferChanges();
              stores.userStore.cancelChangePasswordData();
              stores.userStore.cancelAdditionalDataBufferChanges();
              stores.materialDialogStore.setCurrentState(false);
              stores.userStore.loadUserProfile();
              break;
            default:
              stores.materialDialogStore.setCurrentState(false);
              stores.courseServiceStore.clearAnswerData();
          }
        }}
        children={getDialogBody()}
      />
    </>
  )
})
