import {observer} from "mobx-react-lite";
import {Avatar, Button, ButtonBase, Skeleton} from "@mui/material";
import {baseStyles} from "../../../utils/baseStyles";
import {stores} from "../../../pages/_app";
import {NotificationsActiveOutlined} from "@mui/icons-material";
import {StyleSheet} from "../../../utils/styles";
import {ExpandMenu} from "../expandMenu";
import {BaseProfileView} from "./baseProfileView";
import {useState} from "react";

interface IProps {
  isDarkTheme: boolean
}

export const ProfileView = observer(({isDarkTheme}: IProps) => {
  const [isOpen, setIsOpen] = useState(false)

  return(
    <>
      <ButtonBase style={styles.container} sx={{
        width: '270px',
        bgcolor: isDarkTheme ? 'rgba(58,58,58,0.56)' : 'transparent',
        // border: isDarkTheme ? 'none' : '0.5px dashed #cecece',
        '&:hover': {
          bgcolor: isDarkTheme ? 'rgba(43,49,46,0.51)' : '#EEEEEE'
        }
      }} onClick={() => setIsOpen(true)}>
        {
          stores.userStore.isUserDataLoading
            ? <Skeleton variant={"rounded"} width={250} height={50}/>
            : <BaseProfileView isDarkTheme={isDarkTheme} />
        }

      </ButtonBase>
      <ExpandMenu isOpen={isOpen} onClose={() => setIsOpen(false)}/>
    </>

  )
})

const styles = StyleSheet.create({
  container: {
    ...baseStyles.horizontalContainer,
    alignItems: 'center',
    borderRadius: '10px',
    padding: '10px'
  },
})