import {deleteLocalStorageItem, getItemByKey, setLocalStorageItem} from "./LocalStorage";
import {ACCESS_TOKEN, REFRESH_TOKEN, SESSION_COURSE_ID, USER_ID} from "./Const";

export const setAccessToken = (token: string) => {
  setLocalStorageItem(ACCESS_TOKEN, token)
}

export const getAccessToken = () => getItemByKey(ACCESS_TOKEN)

export const setRefreshToken = (token: string) => {
  setLocalStorageItem(REFRESH_TOKEN, token)
}

export const getRefreshToken = () => getItemByKey(REFRESH_TOKEN)

export const setUserId = (userId: string) => {
  setLocalStorageItem(USER_ID, userId)
}

export const getUserId = () => getItemByKey(USER_ID)


export const getSessionCourseId = () => getItemByKey(SESSION_COURSE_ID)
export const clearSessionCourseId = () => deleteLocalStorageItem(SESSION_COURSE_ID)
export const setSessionCourseId = (sessionCourseId: string) => {
  setLocalStorageItem(SESSION_COURSE_ID, sessionCourseId)
}
