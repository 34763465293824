import {observer} from "mobx-react-lite";
import {DialogActions, DialogContent, Stack} from "@mui/material";
import {stores} from "../../../pages/_app";
import {ValidationPasswordInfo} from "../../AuthComponents/validationPasswordInfo";
import {useUnload} from "../../../utils/useUnload";
import CBButton from "../../../uikit/baseComponent/buttons/mainButton";
import {Colors, ComponentSize, InputsType} from "../../../uikit/enums";
import React from "react";
import {StyleSheet} from "../../../utils/styles";
import CBInput from "../../../uikit/baseComponent/input/input";
import CBText from "../../../uikit/baseComponent/text";

const SecurityUserSetting = observer(() => {
	useUnload(stores.userStore.isChangePasswordDataChanged)

	const handleClose = () => {
		if (stores.userStore.isChangePasswordDataChanged) {
			if (!window.confirm("Возможно, внесенные изменения не сохранятся.")) return
			stores.materialDialogStore.setCurrentState(false);
			stores.userStore.cancelChangePasswordData();
			stores.userStore.loadUserProfile()
		} else {
			stores.materialDialogStore.setCurrentState(false);
		}
	}

	return (
		<>
			<Stack height='100%'>
				<DialogContent sx={styles.content}>
					<Stack rowGap='10px'>
					<CBText>Смена пароля</CBText>
					<Stack direction={!stores.adaptiveService.isMinDesktopMini ? 'column' : 'row'} columnGap='20px' rowGap='20px'>
						<Stack rowGap='10px' flex={2.78}>
							<CBInput size={ComponentSize.LARGE} type={InputsType.PASSWORD} defaultValue={stores?.userStore.changePasswordData.oldPassword || ''} onChange={(value: string) => stores.userStore.setPasswordChangeData("oldPassword", value)} label='Старый пароль'/>
							<CBInput size={ComponentSize.LARGE} type={InputsType.PASSWORD} defaultValue={stores.userStore.changePasswordData.password || ''}
											 onChange={(value: string) => stores.userStore.setPasswordChangeData("password", value)}
											 helperText={stores.userStore.changePasswordData.password.length !== 0 && (stores.userStore.changePasswordData.password === stores.userStore.changePasswordData.oldPassword && stores?.userStore.changePasswordData.password !== undefined ? 'Новый пароль совпадает с веденным старым паролем' : undefined)}
											 label='Новый пароль'/>
							<CBInput size={ComponentSize.LARGE} type={InputsType.PASSWORD} defaultValue={stores.userStore.changePasswordData.passwordRepeat || ''} onChange={(value: string) => stores.userStore.setPasswordChangeData("passwordRepeat", value)} label='Новый пароль ещё раз'/>
						</Stack>

						<Stack justifyContent='center' flex={3}>
							<ValidationPasswordInfo style={{marginRight: 30}} observableValue={stores.userStore.validChangePassword}/>
						</Stack>
					</Stack>
					</Stack>
				</DialogContent>
			</Stack>

			<DialogActions sx={{...styles.actions, ...(!stores.adaptiveService.isMinDesktopMini && {flexWrap: 'wrap', rowGap: '20px', width: '100%', padding: '0', marginTop: '60px'})}}>
				<CBButton
					style={{...styles.button, ...(!stores.adaptiveService.isMinDesktopMini && {width: '100%', margin: '0 !important'})}}
					loading={stores.userStore.isChangePasswordProcess}
					disabled={!stores.userStore.getValidPasswordState}
					children='Сохранить'
					onClick={() => {
						stores.userStore.changePassword().then(
							onSuccess => {
								stores.materialDialogStore.setMessageViewState(true, 'Пароль успешно изменён')
							},
							onError => {
								stores.materialDialogStore.setMessageViewState(true, 'Ошибка изменения пароля, проверьте введённые значения или попробуйте ещё раз')
							}
						)
					}}
				/>
				<CBButton
					style={{...styles.button, ...(!stores.adaptiveService.isMinDesktopMini && {width: '100%', margin: '0 !important'})}}
					disabled={stores?.userStore.isChangePasswordProcess}
					children='Отменить'
					onClick={handleClose}
					background={Colors.ERROR}
					backgroundHover={Colors.ERROR_DARK}
				/>
			</DialogActions>
		</>
	)
})

const styles = StyleSheet.create({
	actions: {
		columnGap: '20px',
	},
	content: {
		width: '100%',
		padding: '0'
	},
	button: {
		width: '209px'
	}
})

export default SecurityUserSetting