import {observer} from "mobx-react-lite";
import {StyleSheet} from "../../../../utils/styles";
import {stores} from "../../../../pages/_app";
import {TypeOfQuestionModal} from "../../../../stores/TestEditViewStore";
import {CircularProgress, IconButton, Stack} from "@mui/material";
import {baseStyles} from "../../../../utils/baseStyles";
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import {useEffect} from "react";
import QuestionDragAndDrop from "../../../IndependentComponents/DragAndDrops/TestDND/QuestionDragAndDrop";
import CBButton from "../../../../uikit/baseComponent/buttons/mainButton";
import {ComponentSize, ComponentType, FontStyle, FontType} from "../../../../uikit/enums";
import CBText from "../../../../uikit/baseComponent/text";
import CBInput from "../../../../uikit/baseComponent/input/input";
import CBTextarea from "../../../../uikit/baseComponent/textarea";

const CreateQuestionDialogItem = observer(() => {

  useEffect(() => {
    if (!stores.materialDialogStore.isOpen)
      stores.testEditViewStore.clearQuestionData()
  }, [stores.materialDialogStore.isOpen])

  const handleAddAnswer = () => {
    stores?.testEditViewStore.setQuestionStructure('create', Date.now())
  }

  const renderCreateQuestionModal = () => {
    return (
      <div>
        {stores.testEditViewStore.isQuestionCreateProcess
          ? <div style={styles.progressContainer}>
            <CircularProgress sx={styles.progress}/>
          </div>
          : <Stack sx={styles.mainContainer}>
            <CBText type={FontType.TITLE} fontStyle={FontStyle.SMALLBOLD}>Новый вопрос</CBText>
            <CBInput required={true} style={{width: '100%'}} isValid={stores.testEditViewStore.validateCreateQuestionTitle || false}
                     helperText={stores.testEditViewStore.newQuestion.title?.length > 0 && !stores.testEditViewStore.validateCreateQuestionTitle && 'Название вопроса должно содержать 6 и более символов'}
                     onChange={(value: string) => stores.testEditViewStore.setNewQuestionData('title', value)} label='Название вопроса'/>
            <CBTextarea style={{width: '100%'}} maxLength={500} label='Описание вопроса' placeholder='Введите описание вопроса' value=''
                        onChange={(value) => stores.testEditViewStore.setNewQuestionData('description', value)}
                        isValid={stores.testEditViewStore.validateCreateQuestionDescription || false}
                        helperText={stores.testEditViewStore.newQuestion?.description?.length > 0 && `Описание вопроса должно содержать меньше 501 символов. Сейчас ${stores.testEditViewStore.newQuestion?.description.length || 0} символов`}
            />

            <Stack direction='row'>
              <CBText fontStyle={FontStyle.SMALL}>{'Ответы'.toUpperCase()}</CBText>
              <IconButton disableRipple={true}
                          onClick={() => handleAddAnswer()}
              >
                <AddCircleOutlineOutlinedIcon sx={styles.addAnswerButton}/>
              </IconButton>
            </Stack>
            {stores.testEditViewStore.currentQuestionStructure !== undefined
              ? <QuestionDragAndDrop />
              : <div style={{height: 70}}/>
            }

            <Stack direction='row' style={styles.buttonsContainer}>
              <CBButton style={{flex: '1'}} size={ComponentSize.SMALL} onClick={() => stores.materialDialogStore.setCurrentState(false)} type={ComponentType.ERROR} disabled={stores.testEditViewStore.isQuestionCreateProcess}>Отменить</CBButton>
              <CBButton style={{flex: '1'}} size={ComponentSize.SMALL} onClick={() => {
                stores.testEditViewStore.createQuestions().then(
                  onSuccess => {
                    stores.materialDialogStore.setCurrentState(false)
                    stores.materialDialogStore.setMessageViewState(true, 'Вопрос успешно создан')
                    stores.testEditViewStore.currentTest?.id !== undefined && stores.testEditViewStore.getTestStructure(stores?.testEditViewStore.currentTest?.id, false).then(
                      onSuccess => {

                      },
                      onError => {

                      }
                    )
                    stores.materialDialogStore.setCurrentState(false)
                  },
                  onError => {
                    stores.materialDialogStore.setMessageViewState(true, 'Ошибка создания вопроса, попробуйте позднее')
                  }
                )
              }} loading={stores.testEditViewStore.isQuestionCreateProcess} disabled={!stores.testEditViewStore.validateCreateButton}>Создать</CBButton>
            </Stack>
          </Stack>
        }
      </div>
    )
  }

  const renderChangeQuestionModal = () => (
    <div>
      {stores.testEditViewStore.isQuestionCreateProcess
        ? <div style={styles.progressContainer}>
          <CircularProgress sx={styles.progress}/>
        </div>
        : <Stack sx={styles.mainContainer}>
          <CBText type={FontType.TITLE} fontStyle={FontStyle.SMALLBOLD}>Редактирование вопроса</CBText>
          <CBInput required={true} style={{width: '100%'}} isValid={stores.testEditViewStore.validateQuestionTitle || false}
                   defaultValue={stores.testEditViewStore.currentQuestionData?.title || ''}
                   helperText={stores.testEditViewStore.newQuestion.title?.length > 0 && !stores.testEditViewStore.validateQuestionTitle && 'Название вопроса должно содержать 6 и более символов'}
                   onChange={(value: string) => stores.testEditViewStore.changeQuestionData('title', value)} label='Название вопроса'/>
          <CBTextarea style={{width: '100%'}} maxLength={500} label='Описание вопроса' placeholder='Введите описание вопроса' value={stores.testEditViewStore.currentQuestionData?.description || ''}
                      onChange={(value: string) => stores.testEditViewStore.changeQuestionData('description', value)}
                      isValid={stores.testEditViewStore.validateQuestionDescription || false}
                      helperText={stores.testEditViewStore.currentQuestionData?.description?.length > 0 && `Описание вопроса должно содержать меньше 501 символов. Сейчас ${stores.testEditViewStore.currentQuestionData?.description?.length || 0} символов`}
          />

          <Stack direction='row'>
            <CBText fontStyle={FontStyle.SMALL}>{'Ответы'.toUpperCase()}</CBText>
            <IconButton disableRipple={true}
                        onClick={() => handleAddAnswer()}
            >
              <AddCircleOutlineOutlinedIcon sx={styles.addAnswerButton}/>
            </IconButton>
          </Stack>
          {stores?.testEditViewStore.currentQuestionData?.answers !== undefined
            ? <QuestionDragAndDrop />
            : <div style={{height: 70}}/>
          }

          <Stack direction='row' style={styles.buttonsContainer}>
            <CBButton style={{flex: '1'}} size={ComponentSize.SMALL} onClick={() => stores.materialDialogStore.setCurrentState(false)} type={ComponentType.ERROR} disabled={stores.testEditViewStore.isQuestionCreateProcess}>Отменить</CBButton>
            <CBButton style={{flex: '1'}} size={ComponentSize.SMALL} onClick={() => {
              stores.testEditViewStore.changeQuestion().then(
                onSuccess => {
                  stores.materialDialogStore.setMessageViewState(true, 'Вопрос успешно сохранен')
                  stores.testEditViewStore.getQuestionData(stores?.testEditViewStore.currentTest?.id || 0)
                  stores.materialDialogStore.setCurrentState(false)
                },
                onError => {
                  stores.materialDialogStore.setMessageViewState(true, 'Ошибка сохранения вопроса, попробуйте позднее')
                }
              )
            }} loading={stores.testEditViewStore.isQuestionCreateProcess} disabled={!stores.testEditViewStore.validateQuestionChange}>Изменить</CBButton>
          </Stack>
        </Stack>
      }
    </div>
  )

  switch (stores.testEditViewStore.currentOpenModal) {
    case TypeOfQuestionModal.create: {
      return renderCreateQuestionModal()
    }
    case TypeOfQuestionModal.change: {
      return renderChangeQuestionModal()
    }
    default: return  <></>
  }
})

export default CreateQuestionDialogItem

const styles = StyleSheet.create({
  progressContainer: {
    display: 'flex',
    ...baseStyles.centeredView,
    maxWidth: '400px',
    width: '100%',
    height: '500px'
  },
  progress: {
    alignSelf: 'center',
    color: '#32BD9C'
  },
  mainContainer: {
    rowGap: '20px',
    maxWidth: '594px',
    width: '100%'
  },
  buttonsContainer: {
    columnGap: '10px',
    marginTop: '30px'
  },
  addAnswerButton: {
    color: '#32BD9C',
    marginTop: -1,
    width: 20
  }
})
