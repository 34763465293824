import {makeAutoObservable, runInAction} from "mobx";
import {IRootStore} from "./RootStore";
import networkService from "../../services/NetworkService";
import {
    getAccessToken,
    getRefreshToken,
    getUserId,
    setAccessToken,
    setRefreshToken,
    setUserId
} from "../../services/localStorage/LocalStorageScope";
import {clearStorage} from "../../services/localStorage/LocalStorage";
import {TypeOfAuth} from "../../types/auth/TypeOfAuth";
import {AuthDataModel, initAuthModel} from "../../types/auth/AuthDataModel";
import {AuthDataValidateModel, initAuthDataValidateModel} from "../../types/auth/AuthDataValidateModel";
import {AuthResponseModel} from "../../types/auth/TokenDataModel";
import {isNullOrEmpty} from "../../utils/StringUtils";
import {PageNames} from "../menuHeaderStore";

export class AuthStore {
  authData: AuthDataModel = initAuthModel
  authValidate: AuthDataValidateModel = initAuthDataValidateModel
  isUserAuth: boolean = false
  isAuthChecked: boolean = false
  isAuthProcess: boolean = false
  loginIsUsed: boolean = false


  private rootStore: IRootStore

  constructor(rootStore: IRootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  resetValidate = () => {
    this.authValidate = initAuthDataValidateModel
  }

  resetAuthData = () => {
    this.authData = initAuthModel
  }

  get getValidateSignInStatePassword() {
    return this.authValidate.passwordRegister
      && this.authValidate.passwordLength
      && this.authValidate.passwordNumber
      && this.authValidate.passwordSymbols
  }

  get getValidateSignInState() {
    return this.getValidateSignInStatePassword
      && this.authValidate.isLoginValid
  }

  checkLoginIsAlreadyUsed = async (login: string) => {
    try {
      const {data} = await networkService.post('auth/checkLoginIsAlreadyUsed', {
        login: login
      })
      runInAction(() => {
        this.loginIsUsed = data.isUsed
      })
      return Promise.resolve()
    } catch (e) {
      return Promise.reject(e)
    }
  }

  checkIsUserAuth = async (isEmptyUser: boolean = false) => {
    this.isUserAuth = !!getAccessToken() && !!getRefreshToken() && !!getUserId()
    if (this.isUserAuth) {
      await this.rootStore.userStore.loadUserProfile(isEmptyUser)
    }
    runInAction(() => this.isAuthChecked = true)
  }

  logout = () => {
    if (this.isUserAuth) {
      this.isUserAuth = false
      this.rootStore.userStore.clearUserData()
      clearStorage()
    }
  }

  setPasswordForAuth = (value: string) => {
    this.authData.password = value
    this.validateAuthData()
  }

  setLoginForAuth = (value: string) => {
    this.authData.login = value
    this.validateAuthData()
  }

  setEmailForAuth = (value: string) => {
    this.authData.email = value
    this.validateAuthData()
  }

  setTermsUseValue = (value: boolean) => {
    this.authValidate.isTermsAccept = value
    this.validateAuthData()
  }

  validateAuthData = () => {
    if (this.rootStore.menuHeaderStore.currentOpenModal === TypeOfAuth.signUpByEmail || this.rootStore.menuHeaderStore.currentOpenModal === TypeOfAuth.none && this.rootStore.menuHeaderStore.currentPage === PageNames.about) {
      this.validateEmail()
    } else {
      this.validateLogin()
      this.validatePassword()
    }
  }

  validatePassword = () => {
    this.authValidate.passwordLength = this.authData.password.length >= 6
    this.authValidate.passwordRegister = /[A-Z]+/gmus.test(this.authData.password) && /[a-z]+/.test(this.authData.password)
    this.authValidate.passwordSymbols = /[!@#$%^&*?"()_={}<>:.~]+/gmus.test(this.authData.password)
    this.authValidate.passwordNumber = /\d/gmus.test(this.authData.password)
    this.authValidate.passwordEqual = this.authData.password === this.authData.passwordRepeat && this.authData.password.length !== 0
    this.authValidate.passwordLatin = /[a-zA-Z]+/gmus.test(this.authData.password)
  }

  validateLogin = () => {
    this.authValidate.isLoginValid =
      this.authData.login.length > 4
      && this.authData.login.length < 21
      && /[a-zA-Z]+/gmus.test(this.authData.login)
  }

  validateEmail = () => {
    this.authValidate.isEmailValid = isNullOrEmpty(this.authData.email)
      ? false
      : /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/gmus.test(this.authData.email!)
      && this.authData.email.length < 256
  }

  signIn = async () => {
    try {
      runInAction(() => this.isAuthProcess = true)
      const {data}: AuthResponseModel = await networkService.post("auth/constructor/signin", {
        login: this.authData.login,
        password: this.authData.password
      })
      setUserId(`${data.userId}`)
      setAccessToken(data.token)
      setRefreshToken(data.refreshToken)
      await this.checkIsUserAuth(true)
      this.resetValidate()
      this.resetAuthData()
      this.isAuthProcess = false
      return Promise.resolve()
    } catch (e) {
      this.isAuthProcess = false
      // @ts-ignore
      return Promise.reject(e)
    }
  }

  signUpByEmail = async () => {
    try {
      runInAction(() => this.isAuthProcess = true)

      if (!this.authValidate.isEmailValid)
        Error("Email not valid")

      const {data}: AuthResponseModel = await networkService.post("auth/signUp", {
        email: this.authData.email
      })
      setUserId(`${data.userId}`)
      setAccessToken(data.token)
      setRefreshToken(data.refreshToken)
      await this.checkIsUserAuth(true)
      this.resetValidate()
      this.resetAuthData()
      return Promise.resolve()
    } catch (e) {
      // @ts-ignore
      return Promise.reject(e)
    } finally {
      runInAction(() => {
        this.isAuthProcess = false
      })
    }
  }
}

export interface IAuthStore extends AuthStore {
}
