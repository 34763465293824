import { DialogProps } from "@mui/material";
import {observer} from "mobx-react-lite";
import {useEffect, useState } from "react";
import { stores } from "../../../../pages/_app";
import { PageDetails } from "../../../../types/materials/course/PageDetails";
import LearningVideo from "./LearningVideo";
import LearningArticle from "./LearningArticle";
import LearningTest from "./LearningTest";
import {LearnModalType} from "../../../../uikit/enums";

export enum MaterialsType {
  'article' = 'article',
  'test' = 'test',
  'video' = 'video'
}

const LearnMaterialDialog = observer(() => {
  const [scroll, setScroll] = useState<DialogProps['scroll']>('paper')
  const [page, setPages] = useState<PageDetails | undefined>(undefined)

  useEffect(() => {
    if (!!stores.courseServiceStore.currentContent) {
      stores.courseServiceStore.setIsCorrectAnswerSettingProcess(true)
      setPages(undefined)
      stores.courseServiceStore.clearAnswerData()
      setPages(stores.courseServiceStore.currentContent)

      if (stores.courseServiceStore.currentContent?.content.questions === undefined)
        return

      stores.courseServiceStore.currentContent?.content.questions.map((item) => {

        stores.courseServiceStore.setCorrectAnswers([...stores.courseServiceStore.correctAnswers, {
          questionId: item.questionId,
          correct: item.answers?.filter((answer) => answer.isCorrect) || []
        }])
      })
      stores.courseServiceStore.setIsCorrectAnswerSettingProcess(false)
    }

    if (!stores.materialDialogStore.isOpen) {
      stores.courseServiceStore.setCorrectAnswers([])
    }
  }, [stores.courseServiceStore.currentContent, stores.materialDialogStore.isOpen])

  switch (page?.typeContent.code) {
    case MaterialsType.video: {
      return <LearningVideo
        page={page}
        scroll={scroll}
        type={LearnModalType.LEARN_VIDEO}
      />
    }
    case MaterialsType.article: {
      return <LearningArticle
        page={page}
        scroll={scroll}
        type={LearnModalType.LEARN_ARTICLE}
      />
    }
    case MaterialsType.test: {
      return <LearningTest
        scroll={scroll}
        page={page}
        type={LearnModalType.LEARN_TEST}
      />
    }

    default: return <></>
  }
})

export default LearnMaterialDialog
