import {makeAutoObservable, runInAction} from "mobx";
import networkService from "../services/NetworkService";
import {IRootStore} from "./global/RootStore";
import {DialogType} from "../components/MaterialDialog/MaterialDialogStore";
import {CourseObject} from "../types/materials/course/CourseObject";
import {ArticleObject} from "../types/materials/article/ArticleObject";
import {ArticleCreateType} from "../types/materials/article/ArticleCreateType";
import {ArticleCreateTypeWithCourse} from "../types/materials/article/ArticleCreateTypeWithCourse";
import {TestCreateType} from "../types/materials/test/TestCreateType";
import {stores} from "../pages/_app";

export enum MaterialType {
  'courses' = 'courses',
  'articles' = 'articles',
  'test' = 'test'
}

export class ConstructorMaterialsStore {

  private rootStore: IRootStore

  constructor(rootStore: IRootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  authorsCourses: CourseObject[] = []
  cachedAuthorsCourses: CourseObject[] = []
  newCourse: CourseObject | null = null
  selectedCoursesId: number[] = []
  selectedTestId: number[] = []
  authorArticles: ArticleObject[] = []
  cachedAuthorArticles: ArticleObject[] = []
  newArticle: ArticleCreateType | null = null
  newArticleWithCourseId: ArticleCreateTypeWithCourse | null = null
  newTest: TestCreateType | null = null
  newVideo: TestCreateType | null = null
  selectedArticlesId: number[] = []
  isSingleArticle: boolean = false
  showDeleteDialog: boolean = false
  currentDeleteItemId: number = -1
  currentDeleteTypeMaterial: string = ''
  isDeleteSomeone: boolean = false
  isCourseCreateProcess: boolean = false
  isArticleCreateProcess: boolean = false
  isArticleDeleteProcess: boolean = false
  isArticlesLoadingProcess: boolean = false
  isCourseLoadingProcess: boolean = false
  isTestCreateProcess: boolean = false
  isTestDeleteProcess: boolean = false
  isVideoCreateProcess: boolean = false
  isVideoDeleteProcess: boolean = false
  currentDeleteArticleTitle: string | undefined = undefined

  setTitleForNewTest = (value: string) => {
    if (this.newTest === null) {
      this.newTest = {
        title: value,
        chapterId: 0
      }
    } else
      this.newTest.title = value
  }

  setTitleForNewVideo = (value: string) => {
    if (this.newVideo === null) {
      this.newVideo = {
        title: value,
        chapterId: 0
      }
    } else
      this.newVideo.title = value
  }

  setChapterIdForNewVideo = (id: number) => {
    if (this.newVideo === null) {
      this.newVideo = {
        title: '',
        chapterId: id
      }
    } else
      this.newVideo.chapterId = id
  }

  get validateTitle() {
    return (this.newCourse?.title?.length || 0) > 5
  }

  setChapterIdForNewTest = (id: number) => {
    if (this.newTest === null) {
      this.newTest = {
        title: '',
        chapterId: id
      }
    } else
      this.newTest.chapterId = id
  }

  createNewTestByTitle = async () => {
    try {
      runInAction(() => this.isTestCreateProcess = true)
      const {data} = await networkService.post('/constructor/test/createByTitle', {
        title: this.newTest?.title,
        chapterId: this.rootStore.courseEditViewStore.currentChapterId
      })
      runInAction(() => {
        this.rootStore.courseEditViewStore.setCurrentChapterId(0)
        this.isTestCreateProcess = false
      })
      return Promise.resolve()
    }
    catch (e) {
      // @ts-ignore
      return Promise.reject(e.response.data)
    }
  }

  createNewVideoByTitle = async () => {
    try {
      runInAction(() => this.isVideoCreateProcess = true)
      const {data} = await networkService.post('/constructor/video/createVideoByTitle', {
        title: this.newVideo?.title,
        chapterId: this.rootStore.courseEditViewStore.currentChapterId
      })
      runInAction(() => {
        this.rootStore.courseEditViewStore.setCurrentChapterId(0)
        this.isVideoCreateProcess = false
      })
      return Promise.resolve()
    }
    catch (e) {
      // @ts-ignore
      return Promise.reject(e.response.data)
    }
  }

  setSelectCourseIdForCreateArticles = (value: number | null) => {
    if (this.newArticleWithCourseId !== null) {
      this.newArticleWithCourseId.courseId = value || 0
      if (value !== null)
        this.setIsSingleArticle(false)
    }
  }

  setCourseIdForNewArticle = (courseId: number, courseTitle: string) => {
    if (this.newArticleWithCourseId === null) {
      this.newArticleWithCourseId = {
        title: '',
        courseId: courseId,
        courseTitle: courseTitle
      }
    } else {
      this.newArticleWithCourseId.courseId = courseId
      this.newArticleWithCourseId.courseTitle = courseTitle
    }
  }

  setTitleForNewArticleWithCourseId = (title) => {
    if (this.newArticle === null) {
      this.newArticle = {
        title: title,
        chapterId: 0,
        chapterTitle: ''
      }
    } else {
      this.newArticle.title = title
    }
  }

  setSelectChapterIdForCreateArticles = (value: number | null) => {
    if (this.newArticle !== null) {
      this.newArticle.chapterId = value || 0
      if (value !== null)
        this.setIsSingleArticle(false)
    }
  }

  setCurrentDeleteArticleTitle = (value: string) => {
    this.currentDeleteArticleTitle = value
  }

  setDeleteDialogState = (value: boolean, id: number, typeMaterial: string = MaterialType.courses, isDeleteSomeone: boolean = false) => {
    switch (typeMaterial) {
      case MaterialType.courses: {
        if (isDeleteSomeone && this.selectedCoursesId.length === 0)
          return
        this.rootStore.materialDialogStore.setCurrentState(true, DialogType.deleteCourse)
        break
      }
      case MaterialType.articles: {
        runInAction(() => this.setCurrentDeleteArticleTitle(stores.courseEditViewStore.currentStructureCash?.pages.find(article => article.id === id)?.content.title))
        this.rootStore.materialDialogStore.setCurrentState(true, DialogType.deleteArticle)
        break
      }
      case MaterialType.test: {
        this.rootStore.materialDialogStore.setCurrentState(true, DialogType.deleteTest)
        break
      }
    }


    //TODO удалить после чистки проекта от старого конструктора
    this.showDeleteDialog = value
    this.currentDeleteItemId = id
    this.currentDeleteTypeMaterial = typeMaterial
    this.isDeleteSomeone = isDeleteSomeone
  }

  selectCourse = (id: number) => {
    if (this.authorsCourses.find(item => item.id === id) === undefined)
      return

    if (this.selectedCoursesId.indexOf(id) === -1) {
      this.selectedCoursesId = [...this.selectedCoursesId, id]
    } else {
      const indexCourse = this.selectedCoursesId.indexOf(id)
      this.selectedCoursesId.splice(indexCourse, 1)
    }
  }

  selectArticle = (id: number) => {
    if (this.authorArticles.find(item => item.id === id) === undefined)
      return

    if (this.selectedArticlesId.indexOf(id) === -1) {
      this.selectedArticlesId = [...this.selectedArticlesId, id]
    } else {
      const indexCourse = this.selectedArticlesId.indexOf(id)
      this.selectedArticlesId.splice(indexCourse, 1)
    }
  }

  setTitleForNewCourse = (title: string) => {
    this.newCourse = {
      id: 0,
      userId: this.rootStore.userStore.userData.id,
      title: title,
      description: '',
      privacyId: 2,
      tags: '',
      createDate: '',
      urlCover: '',
      urlIcon: '',
      isDraft: true,
      categories: [],
      skills: '',
      certificateTemplate: null
    }
  }

  setTitleForNewArticle = (title: string) => {
    if (this.newArticle === null) {
      this.newArticle = {
        title: title,
        chapterId: 0,
        chapterTitle: ''
      }
    } else {
      this.newArticle.title = title
    }
  }

  clearArticleCreateCache = () => {
    this.newArticle = null
  }

  setChapterIdForNewArticle = (chapterId: number, chapterTitle: string) => {
    if (this.newArticle === null) {
      this.newArticle = {
        title: "",
        chapterId: chapterId,
        chapterTitle: chapterTitle
      }
    } else {
      this.newArticle.chapterId = chapterId
      this.newArticle.chapterTitle = chapterTitle
    }
  }

  loadAuthorsCourses = async () => {
    try {
      runInAction(() => this.isCourseLoadingProcess = true)
      const {data} = await networkService.post(`constructor/materials/course.getAllCoursesByAuthorId`)
      runInAction(() => {
        this.authorsCourses = data
        this.cachedAuthorsCourses = this.authorsCourses
      })
      runInAction(() => this.isCourseLoadingProcess = false)
      return Promise.resolve()
    } catch (e) {
      // @ts-ignore
      return Promise.reject(e.response.data)
    }
  }

  getSortedCourses = async (type: 'category' | 'search' | 'sort' | 'page', value: number | string | null) => {
    const data = this.cachedAuthorsCourses
		try {
			runInAction(() => {
				let sortedData;
				switch (type) {
					case "search":
						sortedData = data.filter(course => course.title.toLowerCase().includes(String(value).trim()));
						break;
					case "category":
						sortedData = value === '' ? data : data.filter(course => {
              const booleanItems = course.categories.map(category => category.id === value)
              return booleanItems.find(i => i === true)
            });
						break;
					case "sort":
						if (value === 1) {
							sortedData = this.authorsCourses.sort();
						} else if (value === 2) {
							sortedData = this.authorsCourses.sort().reverse();
						} else if (value === 3) {
							sortedData = this.authorsCourses.sort((a, b) => new Date(b.createDate).getTime() - new Date(a.createDate).getTime());
						} else if (value === 4) {
							sortedData = this.authorsCourses.sort((a, b) => new Date(a.createDate).getTime() - new Date(b.createDate).getTime());
						}
						break;
					default:
						sortedData = data;
						break;
				}
				this.authorsCourses = sortedData;
			})
			return Promise.resolve();
		} catch (e) {
			return Promise.reject(e.response.data)
		}
	}

  loadAuthorsArticles = async (isEnableLoading: boolean = true) => {
    try {
      runInAction(() => this.isArticlesLoadingProcess = isEnableLoading)
      const {data} = await networkService.post(`constructor/course/page/getSingleArticlesByAuthorId`)
      runInAction(() => {
        this.authorArticles = data
        this.cachedAuthorArticles = this.authorArticles
      })
      runInAction(() => setTimeout(() => this.isArticlesLoadingProcess = false, 1000))
      return Promise.resolve()
    } catch (e) {
      // @ts-ignore
      return Promise.reject(e.response.data)
    }
  }

  getSortedArticles = async (type: 'category' | 'search' | 'sort' | 'page', value: number | string | null) => {
    const data = this.cachedAuthorArticles
		try {
			runInAction(() => {
				let sortedData;
				switch (type) {
					case "search":
						sortedData = data.filter(article => article.title.toLowerCase().includes(String(value).trim()));
						break;
					case "category":
						sortedData = value === '' ? data : data.filter(article => {
              const booleanItems = article.categories.map(category => category.id === value)
              return booleanItems.find(i => i === true)
            });
						break;
					case "sort":
						if (value === 1) {
							sortedData = this.authorArticles.sort();
						} else if (value === 2) {
							sortedData = this.authorArticles.sort().reverse();
						} else if (value === 3) {
							sortedData = this.authorArticles.sort((a, b) => new Date(b.createDate).getTime() - new Date(a.createDate).getTime());
						} else if (value === 4) {
							sortedData = this.authorArticles.sort((a, b) => new Date(a.createDate).getTime() - new Date(b.createDate).getTime());
						}
						break;
					default:
						sortedData = data;
						break;
				}
				this.authorArticles = sortedData;
			})
			return Promise.resolve();
		} catch (e) {
			return Promise.reject(e.response.data)
		}
	}

  createNewCourse = async (): Promise<CourseObject> => {
    try {
      runInAction(() => this.isCourseCreateProcess = true)
      const {data} = await networkService.post<CourseObject>(`constructor/materials/course.createNewCourseByAuthorIdAndTitle`, {
        userId: this.rootStore.userStore.userData.id,
        title: this.newCourse?.title
      })
      await this.loadAuthorsCourses()
      runInAction(() => this.isCourseCreateProcess = false)
      return Promise.resolve(data)
    } catch (e) {
      // @ts-ignore
      return Promise.reject(e.response.data)
    }
  }

  setIsSingleArticle = (value: boolean) => {
    this.isSingleArticle = value
  }

  createNewArticle = async (): Promise<ArticleObject> => {
    runInAction(() => {
      this.isArticleCreateProcess = true
    })
    try {
      if (this.newArticle !== null && this.newArticle.chapterId > 0 && !this.isSingleArticle) {
        const {data} = await networkService.post<ArticleObject>(`constructor/course/page/createArticle`, {
          title: this.newArticle?.title,
          chapterId: this.rootStore.courseEditViewStore.currentChapterId
        })
        await this.loadAuthorsArticles()
        runInAction(() => {
          this.rootStore.courseEditViewStore.setCurrentChapterId(0)
          this.setSelectChapterIdForCreateArticles(null)
          this.isArticleCreateProcess = false
        })
        return Promise.resolve(data)
      } else {
        const {data} = await networkService.post<ArticleObject>('constructor/course/page/createSingleArticle', {
          title: this.newArticle?.title,
          courseId: null
        })
        runInAction(() => {
          this.setIsSingleArticle(false)
        })
        await this.loadAuthorsArticles(false)
        runInAction(() => this.isArticleCreateProcess = false)
        return Promise.resolve(data)
      }
    } catch (e) {
      // @ts-ignore
      return Promise.reject(e.response.data)
    }
  }

  deleteSelectedMaterials = async () => {
    let requestUrl = ''
    let deletedListIds: number[] = []
    let fieldName = ''
    let loadMethod: (() => Promise<any> | void) = () => {}

    switch (this.currentDeleteTypeMaterial) {
      case MaterialType.courses: {
        requestUrl = 'constructor/materials/course.deleteCourses'
        deletedListIds = this.selectedCoursesId
        fieldName = 'coursesId'
        loadMethod = this.loadAuthorsCourses
        break
      }

      case MaterialType.articles: {
        requestUrl = 'constructor/course/page/deleteArticles'
        deletedListIds = this.selectedArticlesId
        fieldName = 'articlesId'
        loadMethod = this.loadAuthorsArticles
        break
      }
    }

    let isCourseDeleted = this.currentDeleteTypeMaterial === MaterialType.courses

    if (deletedListIds.length === 0) return

    this.rootStore.mainStore.setIsGlobalLoading(true)
    try {
      const {data} = await networkService.post(requestUrl, {
        ...(isCourseDeleted ? {"userId": this.rootStore.userStore.userData.id} : {}),
        [fieldName]: deletedListIds
      })
      await loadMethod()
      runInAction(() => this.selectedCoursesId = [])
      return Promise.resolve()
    } catch (e) {
      // @ts-ignore
      return Promise.reject(e.response.data)
    } finally {
      runInAction(() => {
        this.setDeleteDialogState(false, 0, '')
        this.rootStore.mainStore.setIsGlobalLoading(false)
      })
    }
  }

  deleteMaterialById = async (localCurrentDeleteItemId: number = -1) => {
    let requestUrl = ''
    let fieldName = ''
    let loadMethod: (() => Promise<any> | void) = () => {}

    runInAction(() => this.isArticleDeleteProcess = true)

    switch (this.currentDeleteTypeMaterial) {
      case MaterialType.courses: {
        requestUrl = 'constructor/materials/course.deleteCourses'
        fieldName = 'coursesId'
        loadMethod = this.loadAuthorsCourses
        break
      }

      case MaterialType.articles: {
        requestUrl = 'constructor/course/page/deleteArticles'
        fieldName = 'articlesId'
        loadMethod = this.loadAuthorsArticles
        break
      }
      case MaterialType.test: {
        requestUrl = 'constructor/test/delete'
        fieldName = 'testId'
      }
    }

    let isCourseDeleted = this.currentDeleteTypeMaterial === MaterialType.courses

    if (localCurrentDeleteItemId === -1 && this.currentDeleteItemId < 0)
      return

    //this.rootStore.mainStore.setIsGlobalLoading(true)
    try {
      const {data} = await networkService.post(requestUrl, {
        ...(isCourseDeleted ? {"userId": this.rootStore.userStore.userData.id} : {}),
        [fieldName]: [localCurrentDeleteItemId >= 0 ? localCurrentDeleteItemId : this.currentDeleteItemId]
      })
      switch (this.currentDeleteTypeMaterial) {
        case MaterialType.courses: await this.loadAuthorsCourses()
          break;
        case MaterialType.articles: await this.loadAuthorsArticles(false)
          break;
      }
      return Promise.resolve()
    } catch (e) {
      // @ts-ignore
      this.setDeleteDialogState(false, 0, '')
      this.isArticleDeleteProcess = false
      this.currentDeleteItemId = -1
      // @ts-ignore
      return Promise.reject(e.response.data)
    } finally {
      runInAction(() => {
        this.setDeleteDialogState(false, 0, '')
        this.isArticleDeleteProcess = false
        this.currentDeleteItemId = -1
        //this.rootStore.mainStore.setIsGlobalLoading(false)
      })
    }
  }
}

export interface IConstructorMaterialsStore extends ConstructorMaterialsStore{}
