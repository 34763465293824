import {StyleSheet} from "./styles";

export const DefaultBackground: string = '/defaultImages/imageExample.jpg'
export const DefaultCourseImage: string = '/defaultImages/defaultCourseImage.jpg'

export const baseStyles = StyleSheet.create({
  verticalContainer: {
    display: "flex",
    flexDirection: "column"
  },
  horizontalContainer: {
    display: "flex",
    flexDirection: "row",
  },
  verticalContainerWrap: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap"
  },
  horizontalContainerWrap: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap"
  },
  centeredView: {
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center"
  },
  centerOnVertical: {
    alignItems: "center",
    alignContent: "center"
  },
  alignStart: {
    alignItems: 'flexStart',
    alignContent: 'flex-start'
  },
  horizontalMargins30: {
    marginLeft: 30,
    marginRight: 30
  },
  horizontalMargins20: {
    marginLeft: 20,
    marginRight: 20
  },
  bitoriumAsideContainer: {
    maxWidth: '510px',
    width: '100%'
  }
})
