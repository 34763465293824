import React, {CSSProperties, HTMLInputTypeAttribute, useEffect, useState} from "react";
import {IconButton, InputAdornment, styled, TextField, Tooltip} from "@mui/material";
import {tooltipClasses, TooltipProps} from "@mui/material/Tooltip";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {observer} from "mobx-react-lite";
import {baseTextStyles} from "../../../../utils/baseTextStyles";
import {baseStyles} from "../../../../utils/baseStyles";
import {Image} from "../../Image";
import {defaultFontStyles} from "../../../../uikit/fontStyle";

interface IProps {
  title: string,
  placeholder?: string, //TODO выпилить нахуй, не используется
  inputText?: string,
  onTextChange: (text: string) => void,
  size: "small" | "medium" | undefined,
  maxRows?: number
  onFocus?: () => void,
  type?: HTMLInputTypeAttribute,
  style?: CSSProperties,
  stylesForToolTip?: CSSProperties,
  isValid: boolean,
  isValidText: string,
  required?: boolean,
  multiline?: boolean,
  helperText?: string,
  maxLength?: number,
  inputLabelProps?: object,
  disable?: boolean,
  containerStyle?: CSSProperties
}

export const SimpleInput = observer(({
                                       title,
                                       type = 'text',
                                       size,
                                       maxRows,
                                       inputText,
                                       onTextChange,
                                       required = false,
                                       style,
                                       multiline = false,
                                       isValid = false,
                                       isValidText,
                                       onFocus,
                                       stylesForToolTip,
                                       helperText,
                                       maxLength,
                                       inputLabelProps,
                                       disable = false,
                                       containerStyle
}: IProps) => {
  const [isShow, setIsShow] = useState(false)
  const [query, setQuery] = useState(inputText ? inputText : '')

  useEffect(() => {
    onTextChange(query)
  }, [query])

  const ToolTip = styled(({className, ...props}: TooltipProps) => (
    <Tooltip {...props} classes={{popper: className}}/>
  ))(({theme}) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#cc5c5c',
      color: '#fff',
      boxShadow: theme.shadows[1],
      ...baseTextStyles.montserratS12W500,
    },
  }))

  const handleClickShowPassword = () => {
    setIsShow(!isShow)
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    if(onFocus) {
      return onFocus()
    }
  };

  const renderEye = () => (
    <InputAdornment position="end">
      <IconButton onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge="end">
        {isShow ? <VisibilityOff/> : <Visibility/>}
      </IconButton>
    </InputAdornment>
  )
  //TODO переделать расположение tooltip
  //TODO Возрастной цензор с 6 до 122 лет
  return (
    <>
      <div style={{...baseStyles.horizontalContainer, position: 'relative', ...containerStyle}}>
        <TextField size={size} style={{...style}} error={!isValid} required={required}
                   InputLabelProps={inputLabelProps}
                   sx={{...style, '& .MuiInputBase-root': { ...defaultFontStyles.textMedium }}} helperText={helperText}
                   type={isShow && type === 'password' ? 'text' : type}
                   multiline={multiline} rows={maxRows} value={query} label={title}
                   onChange={(e: React.ChangeEvent<HTMLInputElement>) => setQuery(e.target.value)}
                   onFocus={handleFocus}
                   InputProps={{
                     endAdornment: type === 'password' && renderEye(),
                   }}
                   inputProps={{
                     ...(maxLength !== undefined ? {maxLength: maxLength} : {}),
                     ...(type === 'date' ? {min: "1900-01-01", max: "2015-12-31" } : {})
                   }}
                   disabled={disable}
        />
        {!isValid &&
          <ToolTip title={isValidText}
                   followCursor
                   style={{alignSelf: "end", zIndex: 80, position: 'absolute', cursor: 'help', ...stylesForToolTip}}
                   placement={'right'}
          >
            <IconButton disableRipple={true}>
              <img src={'/icons/warning.svg'} width={15} height={15}/>
            </IconButton>
          </ToolTip>
        }
      </div>
    </>
  )
})
