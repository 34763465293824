import * as React from "react";
import {observer} from "mobx-react-lite";
import {stores} from "../../../pages/_app";
import {StyleSheet} from "../../../utils/styles";
import Image from "next/image";
import {Box, styled, Fade} from "@mui/material";
import {GrayScale} from "../../enums";

export const CBLoader = observer(() => {
  const show = () => {
    return stores.mainStore.isGlobalLoading
  }

  const RotateSpin = styled("div")({
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: 'rotate(0deg) translate(-50%)',
    animation: 'spin 1s infinite ease-out',

    "@keyframes spin": {
      '0%': {
        transform: 'translate(-50%) rotate(0deg)'
      },
      '100%': {
        transform: 'translate(-50%) rotate(360deg)'
      }
    },
  })

  return (
    <>
      <Fade appear={false} in={show()} timeout={{exit: 1200}}>
        <Box sx={styles.modal}>
          <Box sx={styles.container}>
            <Box sx={styles.center}>
              <Image width={100} height={100} src={'/icons/loading/logo.svg'} />
            </Box>
            <RotateSpin>
              <Image width={100} height={100} src={'/icons/loading/load.svg'} />
            </RotateSpin>
          </Box>
        </Box>
      </Fade>
    </>
  )
})

const styles = StyleSheet.create({
  modal: {
    width: "100%",
    height: "100%",
    position: "absolute",
    left: "0",
    top: "0",
    zIndex: '10000',
    background: GrayScale.BACKGROUND_COLOR,
  },
  container: {
    position: 'relative',
    width: "100%",
    height: "100%",
  },
  center: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: 'translate(-50%)',
  },
})
