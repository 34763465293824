export const CourseActionConstants = {
  GIVE_COURSE: "give_course",
  BUY_COURSE: "buy_course",
  REMOVE_GIVEN_COURSE: "remove_given_course",
  START_COURSE: "start_course",
  FINISH_COURSE: "finish_course",
  REQUEST_CERTIFICATE: "request_certificate",
  BUILD_CERTIFICATE: "build_certificate",
  GIVE_CERTIFICATE: "give_certificate",
  DELETE_EMPTY_STUDENT_WITH_COURSE: "delete_empty_student_with_course",
  DELETE_COURSE: "delete_course",
}