import React from 'react';
import CBCategorySelector from "../../components/categorySelector";
import {Stack} from "@mui/material";
import {stores} from "../../../pages/_app";
import {CategoryModel} from "../../../types/dictionaries/categories/CategoryModel";

const CBChooseCategoriesCourse = () => {
	const getFirstSelectedCategory = () => {
		const getFirstParentCategoryDefault = stores.mainStore.category.map((category) => category.parentCategories).at(0)
		const firstSelectedCategoryId = +stores.courseEditViewStore?.currentCategories?.split('|').at(0);

		if (!!firstSelectedCategoryId) {
			return stores.mainStore.category.find((category) => category.id === firstSelectedCategoryId).parentCategories;
		} else {
			return getFirstParentCategoryDefault;
		}
	}

	const getCurrentCategoriesObject = () => {
		let selectedCategoriesArr: CategoryModel[] = []
		const currentCategoriesId: number[] = stores.courseEditViewStore?.currentCategories?.split('|').map(id => +id);
		if (currentCategoriesId?.length > 0) {
			for (let i = 0; i < currentCategoriesId.length; i++) {
				const foundCategory: CategoryModel = stores.mainStore.category.find(category => category.id === currentCategoriesId[i])
				selectedCategoriesArr.push(foundCategory);
			}
		}
		return selectedCategoriesArr;
	}

	return (
		<Stack>
		  <CBCategorySelector onSave={ids => {
		    stores.courseEditViewStore.setCurrentCategories(ids);
		    stores.materialDialogStore.setCurrentState(false);
		  }}
													parentCategory={getFirstSelectedCategory()}
													selectedCurrentCategories={getCurrentCategoriesObject()}
		                      style={{ '& .MuiInputBase-root': {width: '100%'}, '& .MuiBox-root': {flexWrap: 'wrap'} }}/>
		</Stack>
	);
};

export default CBChooseCategoriesCourse;