
/*
Хелпер для бизнес логики стейтменов по самому курсу
Здесь будут функции по формированию стейтментов и их частей для отправки базовых событий по курсу (запуск, открытие и т.д.)
*/

import {UserCourseDetails} from "../../../types/materials/course/UserCourseDetails";
import XAPI, {Actor, ContextActivity, Statement, StatementObject} from "@xapi/xapi";
import {PageDetails} from "../../../types/materials/course/PageDetails";
import {CourseLiveCycleEvents, XAPICourseLiveCycleObjects, XAPIVerbsObjects} from "../XAPIVerbsObjects";
import {BaseXAPIHelper} from "./BaseXAPIHelper";
import moment from "moment/moment";

export class BaseCourseHelper {

  static getCourseObject(userCourseData: UserCourseDetails): StatementObject {
    return {
      id: `https://coursebit.ru/course/${userCourseData.id}`,
      definition: {
        type: 'http://adlnet.gov/expapi/activities/course',
        name: {"ru-RU": userCourseData.title ?? ''},
        description: {"ru-RU": userCourseData.description ?? ''},
        extensions: {
          'https://coursebit.ru/course/courseStateCode': userCourseData.courseStateCode,
          'https://coursebit.ru/course/courseState': userCourseData.courseState,
          'https://coursebit.ru/course/userCourseCopyId': userCourseData.idUserCourse,
        }
      },
      objectType: 'Activity'
    }
  }

  static getCourseItemObject(page: PageDetails): StatementObject {
    return {
      id: `https://coursebit.ru/course/page/${page.id}`,
      definition: {
        type: `http://adlnet.gov/expapi/activities/course/pages/${page.typeContent.name}`,
        name: {"ru-RU": `${page.content.title ?? ''}`},
        description: {"ru-RU": page.content.description || ''},
        extensions: {
          ...(page.typeContent.code !== 'test' ? {'https://coursebit.ru/course/page/content': page.content} : {
            //TODO Раписать объект для теста
          })
        }
      },
      objectType: 'Activity'
    }
  }

  static currentContextParent(userCourseData: UserCourseDetails): ContextActivity {
    return {
      objectType: 'Activity',
      id: `https://coursebit.ru/course/${userCourseData.id}`,
      definition: {
        type: 'http://adlnet.gov/expapi/activities/course',
        name: {"ru-RU": userCourseData.title ?? ''},
        description: {"ru-RU": userCourseData.description ?? ''},
      }
    }
  }

  static sendLaunchCourseEvent = async (
    currentUser?: Actor,
    currentUserCourse?: UserCourseDetails,
    currentCourseObject?: StatementObject,
    xAPIInstance?: XAPI
  ) => {
    if (!currentUser)
      return Promise.reject('User not found')

    if (!currentUserCourse?.idUserCourse)
      return Promise.reject('Course user data not found')

    const statement: Statement = {
      actor: currentUser,
      verb: XAPICourseLiveCycleObjects[CourseLiveCycleEvents.LAUNCH_COURSE],
      object: currentCourseObject,
      context: {
        registration: currentUserCourse?.idUserCourse,
        extensions: BaseXAPIHelper.getDeviceExtensions()
      },
      timestamp: new Date().toISOString()
    }
    console.warn(`sendLaunchCourseEvent ${JSON.stringify(statement)}`)
    try {
      const data = await xAPIInstance?.sendStatement({
        statement: statement
      })
      return Promise.resolve()
    } catch (e) {
      return Promise.reject(e)
    }
  }

  static sendEndInitCourseDataEvent = async (
    currentUser?: Actor,
    currentUserCourse?: UserCourseDetails,
    currentCourseObject?: StatementObject,
    xAPIInstance?: XAPI,
    sessionId?: string
  ) => {
    if (!currentUser)
      return Promise.reject('User not found')

    if (!currentUserCourse?.idUserCourse)
      return Promise.reject('Course user data not found')

    const statement: Statement = {
      actor: currentUser,
      verb: XAPICourseLiveCycleObjects[CourseLiveCycleEvents.INITIALIZED_DATA],
      object: currentCourseObject,
      context: {
        registration: currentUserCourse?.idUserCourse,
        extensions: {
          ...BaseXAPIHelper.getDeviceExtensions(),
          'https://w3id.org/xapi/cmi5/context/extensions/sessionid': sessionId
        }
      },
      timestamp: new Date().toISOString()
    }
    console.warn(`sendEndInitCourseDataEvent ${JSON.stringify(statement)}`)
    try {
      const data = await xAPIInstance?.sendStatement({
        statement: statement
      })
      return Promise.resolve()
    } catch (e) {
      console.log(JSON.stringify(e))
      return Promise.reject(e)
    }
  }

  static sendStartLearningCourseEvent = async (
    currentUser?: Actor,
    currentUserCourse?: UserCourseDetails,
    currentCourseObject?: StatementObject,
    xAPIInstance?: XAPI,
    sessionId?: string,
    timeStartLearningMaterialInMilliseconds?: number
  ) => {
    if (!currentUser)
      return Promise.reject('User not found')

    if (!currentUserCourse?.idUserCourse)
      return Promise.reject('Course user data not found')

    const chaptersArray = currentUserCourse.chapters.map(item => {
      return item
    })

    let pagesArray: PageDetails[] = []

    chaptersArray.forEach(item => {
      pagesArray = [...pagesArray, ...item.pages]
    })

    const extensionChaptersId = chaptersArray.map(item => {
      return {
        id: item.id,
        title: item.title
      }
    })

    const extensionMaterialId = pagesArray.map(item => {
      return {
        chaptersId: item.chapterId,
        materialId: item?.id,
        materialType: item?.typeContent?.code
      }
    })


    const statement: Statement = {
      actor: currentUser,
      verb: XAPICourseLiveCycleObjects[CourseLiveCycleEvents.USER_STARTED_COURSE],
      object: currentCourseObject,
      result: {
        success: true,
        duration: `${moment.duration(Date.now() - timeStartLearningMaterialInMilliseconds, "ms").toISOString()}`,
        response: 'Изучение курса начато'
      },
      context: {
        registration: currentUserCourse?.idUserCourse,
        extensions: {
          ...BaseXAPIHelper.getDeviceExtensions(),
          'https://w3id.org/xapi/cmi5/context/extensions/sessionid': sessionId,
          "https://coursebit.ru/chaptersId": extensionChaptersId,
          "https://coursebit.ru/materialsId": extensionMaterialId
        }
      },
      timestamp: new Date().toISOString()
    }
    console.warn(`sendStartLearningCourseEvent ${JSON.stringify(statement)}`)
    try {
      const data = await xAPIInstance?.sendStatement({
        statement: statement
      })
      return Promise.resolve()
    } catch (e) {
      return Promise.reject(e)
    }
  }

  static sendCompleteLearningCourseEvent = async (
    currentUser?: Actor,
    currentUserCourse?: UserCourseDetails,
    currentCourseObject?: StatementObject,
    xAPIInstance?: XAPI,
    sessionId?: string
  ) => {
    if (!currentUser)
      return Promise.reject('User not found')

    if (!currentUserCourse?.idUserCourse)
      return Promise.reject('Course user data not found')

    const statement: Statement = {
      actor: currentUser,
      verb: XAPICourseLiveCycleObjects[CourseLiveCycleEvents.USER_COMPLETE_COURSE],
      object: currentCourseObject,
      result: {
        success: true,
        response: 'Изучение курса завершено'
      },
      context: {
        registration: currentUserCourse?.idUserCourse,
        extensions: {
          ...BaseXAPIHelper.getDeviceExtensions(),
          'https://w3id.org/xapi/cmi5/context/extensions/sessionid': sessionId
        }
      },
      timestamp: new Date().toISOString()
    }
    console.warn(`sendCompleteLearningCourseEvent ${JSON.stringify(statement)}`)
    try {
      const data = await xAPIInstance?.sendStatement({
        statement: statement
      })
      return Promise.resolve()
    } catch (e) {
      return Promise.reject(e)
    }
  }

  static sendTerminateLearningCourseEvent = async (
    currentUser?: Actor,
    currentUserCourse?: UserCourseDetails,
    currentCourseObject?: StatementObject,
    xAPIInstance?: XAPI,
    sessionId?: string,
  ) => {
    if (!currentUser)
      return Promise.reject('User not found')

    if (!currentUserCourse?.idUserCourse)
      return Promise.reject('Course user data not found')

    const statement: Statement = {
      actor: currentUser,
      verb: XAPICourseLiveCycleObjects[CourseLiveCycleEvents.EXIT_FROM_COURSE],
      object: currentCourseObject,
      result: {
        success: false,
        response: 'Изучение курса прервано'
      },
      context: {
        registration: currentUserCourse?.idUserCourse,
        extensions: {
          ...BaseXAPIHelper.getDeviceExtensions(),
          'https://w3id.org/xapi/cmi5/context/extensions/sessionid': sessionId
        }
      },
      timestamp: new Date().toISOString()
    }
    console.warn(`sendTerminateLearningCourseEvent ${JSON.stringify(statement)}`)
    try {
      const data = await xAPIInstance?.sendStatement({
        statement: statement
      })
      return Promise.resolve()
    } catch (e) {
      return Promise.reject(e)
    }
  }
}
