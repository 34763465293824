import React, {useState} from 'react'
import {CBModal, CBModalType} from '../../baseComponent/modal'
import {Box} from '@mui/material'
import CBText from '../../baseComponent/text'
import {Colors, ComponentSize, ComponentType, FontStyle, FontType, GrayScale} from '../../enums'
import CBButton from '../../baseComponent/buttons/mainButton'
import {StyleSheet} from "../../../utils/styles";
import {stores} from '../../../pages/_app'
import {observer} from 'mobx-react-lite'
import {TypeOfAuth} from '../../../types/auth/TypeOfAuth'

const CBModalSubscribe = observer(() => {
  const [showModal, setShowModal] = useState<boolean>(true)

  const handleShow = () => {
    let lastCheck
    const time = Date.now()
    let moreThanDay = true

    if (typeof window !== 'undefined') {
      lastCheck = localStorage.getItem('lastTimeSubscribeBannerShow')
    }

    if (lastCheck) {
      moreThanDay = time - Number(lastCheck) > 86400000
    }
    return moreThanDay && showModal
  }

  const handleClose = () => {
    const time = Date.now()
    localStorage.setItem('lastTimeSubscribeBannerShow', time.toString())
    setShowModal(false)
  }

  const handleClick = () => {
    stores.menuHeaderStore.setIsAuthShow(TypeOfAuth.signUpByEmail, true)
    setShowModal(false)
  }

  return (
    <Box sx={styles.main}>
      <CBModal isShow={handleShow()} type={CBModalType.BOTTOM} onClose={() => handleClose()} bottomStyles={{
        ...styles.modal,
        right: !stores?.adaptiveService?.isMinDesktopMini ? 0 : stores.adaptiveService.horizontalMarginInPage,
        left: !stores?.adaptiveService?.isMinDesktopMini ? 0 : 'auto',
      }}>
        <Box sx={styles.container}>
          <Box sx={styles.title}>
            <CBText
              style={{display: 'inline'}}
              color={GrayScale.TITLE_ACTIVE_COLOR}
              type={FontType.TITLE}
              fontStyle={FontStyle.SMALLBOLD}
            >
              Месяц премиум подписки
            </CBText>
            <CBText
              style={{display: 'inline'}}
              color={Colors.PRIMARY_COLOR}
              type={FontType.TITLE}
              fontStyle={FontStyle.SMALLBOLD}
            >
              {' бесплатно'}
            </CBText>
          </Box>
          <Box sx={styles.content}>
            <CBText
              style={{display: 'inline'}}
              color={GrayScale.BODY_COLOR}
              type={FontType.TEXT}
              fontStyle={FontStyle.MEDIUM}
            >
              Прими участие в бета-тестировании платформы и получи месяц
            </CBText>
            <CBText
              style={{display: 'inline'}}
              color={Colors.PRIMARY_COLOR}
              type={FontType.TEXT}
              fontStyle={FontStyle.MEDIUM}
            >
              {' премиум-подписки бесплатно'}
            </CBText>
          </Box>
          <CBButton
            style={{marginBottom: '20px'}}
            color={Colors.PRIMARY_COLOR}
            size={ComponentSize.LARGE}
            type={ComponentType.PRIMARY}
            width={'255px'}
            onClick={() => handleClick()}
          >
            Принять участие
          </CBButton>
          <CBButton
            style={{color: GrayScale.BODY_COLOR, marginBottom: '-20px'}}
            color={GrayScale.BODY_COLOR}
            size={ComponentSize.SMALL}
            type={ComponentType.TEXT}
            onClick={() => handleClose()}
          >
            Попробовать позднее
          </CBButton>
        </Box>
      </CBModal>
    </Box>
  )
})

export default CBModalSubscribe

const styles = StyleSheet.create({
  main: {
    '& > div > div': {
      padding: '69px 10px'
    }
  },
  container: {
    margin: '0 20px',
    width: '333px',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    marginBottom: '30px',
    textAlign: 'center',
  },
  content: {
    marginBottom: '45px',
    textAlign: 'center',
  },
  modal: {
    maxWidth: '393px',
    bottom: 0,
    height: 'auto',
    overflow: 'hidden',
  }
})
