import {makeAutoObservable, runInAction} from "mobx";
import { Template } from "@pdfme/ui";
import {IRootStore} from "./global/RootStore";
import {defaultCertificateTemplate} from "../utils/defaultCertificateTemplate";
import {networkService1} from "../services/NetworkService";

export class CreateCertificateStore{
  private rootStore: IRootStore

  constructor(rootStore: IRootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  template: Template  = {
    schemas: [],
    basePdf: '',
    sampledata: [],
    columns: []
  }
  templateFile: File | undefined = undefined
  setTemplate = (value) => {
    this.template = value
  }
  saveJsonFile = () =>  {
    if (typeof window !== 'undefined') {
      const blob = new Blob([JSON.stringify(this.template)], {
        type: 'application/json',
      });
     this.templateFile = new File( [blob], `${this.rootStore.courseEditViewStore.currentCourse?.title}_template`, {type: blob.type})
    this.rootStore.courseEditViewStore.changeCourseData("newCertificateTemplate", URL.createObjectURL(this.templateFile) )
    }
  }

  getCertificateTemplate = async () => {
    if (!this.rootStore.courseEditViewStore.currentCourse?.certificateTemplate){
      runInAction(() => {
        console.log('Certificate is empty, default template is loaded')
        this.template = defaultCertificateTemplate
      })
      return
    }

    try {
      const {data} = await networkService1.get(this.rootStore.courseEditViewStore.currentCourse?.certificateTemplate)
      runInAction(() => {
        console.log('Certificate template is', data)
        this.setTemplate(data)
      })
       return Promise.resolve()
    }
    catch (e) {
      // @ts-ignore
      console.log('Loading certificate template failed. Error: ', (e.response.data))
      return Promise.reject(e)
    }


  }

}

export interface ICreateCertificateStore extends CreateCertificateStore{}