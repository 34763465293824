import {makeAutoObservable} from "mobx";
import {TypeOfAuth} from "../types/auth/TypeOfAuth";
import {IRootStore} from "./global/RootStore";

export enum PageNames {
  'none',
  'about',
  'support',
  'documents',
  'signInGuest',
  'signInStudent',
  'signInAuthor',
  'bitorium',
  'personalProfile',
  'logout'
}

export class MenuHeaderStore {
  private rootStore: IRootStore

  constructor(rootStore: IRootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  currentOpenModal: TypeOfAuth = TypeOfAuth.none
  isOpen: boolean = false
  currentPage: PageNames = PageNames.about

  setIsAuthShow = (type: TypeOfAuth, value: boolean) => {
    this.isOpen = value
    this.currentOpenModal = type
  }

  setCurrentPage = (page: PageNames) => {
    this.currentPage = page
  }

}

export interface IMenuHeaderStore extends MenuHeaderStore {}
