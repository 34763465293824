import React from 'react';
import {StyleSheet} from "../../../utils/styles";
import {Gradient} from "../../enums";
import {Stack, SxProps} from "@mui/material";
import Image from "next/image";
import CBText from "../text";

interface IProps {
	style?: React.CSSProperties | SxProps,
	onclick?: () => void
}

const CBLogo = ({style, onclick}: IProps) => {
	return (
		<Stack direction='row' alignItems='center' sx={style} onClick={onclick}>
			<Image src={"/logos/logoIcon.svg"} width={38} height={38}/>
			<CBText children='COURSEBIT' style={styles.logoText}/>
		</Stack>
	);
};

const styles = StyleSheet.create({
	logoText: {
		marginLeft: '8px',
		fontFamily: 'Roboto',
		fontSize: '21.86px',
		fontWeight: '700',
		lineHeight: '24.44px',
		letterSpacing: '-0.65px',
		textTransform: 'uppercase',
		background: Gradient.GRADIENT_PRIMARY,
		'-webkit-background-clip': 'text',
		'-webkit-text-fill-color': 'transparent',
		'background-clip': 'text',
		'text-fill-color': 'transparent',
	},
})

export default CBLogo;