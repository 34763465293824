import {makeAutoObservable} from "mobx";
import {IRootStore} from "./global/RootStore";

export enum PageDocumentsNames {
  'userTerms' = 'userTerms',
  'privacyPolicy' = 'privacyPolicy',
  'personalData' = 'personalData'
}

export class DocumentStore {
  private rootStore: IRootStore

  constructor(rootStore: IRootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }
  currentPage: PageDocumentsNames = PageDocumentsNames.privacyPolicy

  setCurrentPage = (page : PageDocumentsNames) => {
    this.currentPage = page
  }
}

export interface IDocumentStore extends DocumentStore {}
