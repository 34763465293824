import {observer} from "mobx-react-lite";
import {StyleSheet} from "../../../utils/styles";
import {stores} from "../../../pages/_app";
import {LoadingButton} from "@mui/lab";
import {Button} from "@mui/material";
import {baseStyles} from "../../../utils/baseStyles";
import {useEffect, useState} from "react";
import {FileExtensionsTypes} from "../../../stores/UploadFileStore";

interface IProps {
  callback?: () => void
}

const UploadPdfFile = observer(({callback}: IProps) => {
  const [urlFile, setUrlFile] = useState('')

  useEffect(() => {
    setUrlFile(stores.articleEditViewStore.checkFileOnContent() || '')
  }, [])

  useEffect(() => {
    if (stores.materialDialogStore.isOpen)
      stores.uploadFileStore.setFile(undefined, FileExtensionsTypes.uploadDocumentDefault)
  }, [stores.materialDialogStore.isOpen])


  return (
    <div style={{width: '700px'}}>
      <div style={{margin: '30px'}}>
        <p style={styles.title} children={'Загрузка файла'}/>
        <p style={styles.description} children={'Загрузите ваш PDF файл'}/>
        <Button
          variant={"outlined"}
          style={{width: '100%'}}
          size={'small'}
          component='label'
          disabled={stores.uploadFileStore.isUploadFileProcess || stores.uploadFileStore.isDeleteFileProcess}
          sx={{color: '#32BD9C', borderColor: '#32BD9C', '&:hover': {color: '#308670', borderColor: '#308670'}}}
        >
          Выбрать файл
          <input
            type='file'
            accept={'application/pdf'}
            onChange={(event) => {
              stores.uploadFileStore.setFile(event.target.files !== null ? event.target.files[0] : undefined, FileExtensionsTypes.uploadDocumentDefault)
            }}
            hidden
          />
        </Button>
        <div style={{...baseStyles.horizontalContainer, marginTop: '20px'}}>
          <LoadingButton
            variant={"contained"}
            children={"Загрузить"}
            loading={stores.uploadFileStore.isUploadFileProcess}
            loadingIndicator={`${stores.uploadFileStore.uploadFileProgress}%`}
            disabled={!stores.uploadFileStore.selectedFile || stores.uploadFileStore.isDeleteFileProcess}
            style={{flex: 2, marginRight: '20px'}}
            size={"small"}
            onClick={() => {

              if (!!stores.uploadFileStore.selectedFile)
                stores.articleEditViewStore.changeArticleContent(URL.createObjectURL(stores.uploadFileStore.selectedFile))
              stores.materialDialogStore.setCurrentState(false)
            }}
            sx={{bgcolor: '#32BD9C', '&:hover': { bgcolor: '#32BD9C' }}}
          />
          <Button
            variant={"outlined"}
            children={"Отменить"}
            disabled={stores.uploadFileStore.isUploadFileProcess || stores.uploadFileStore.isDeleteFileProcess}
            onClick={() => {
              stores.materialDialogStore.setCurrentState(false)
              if (!!callback)
                callback()
            }}
            style={{flex: 1}}
            size={"small"}
            sx={{color: '#FE594E', borderColor: '#FE594E', '&:hover': { borderColor: '#ff9a93' }}}/>
        </div>
        <LoadingButton
          variant={"contained"}
          children={"Удалить файл"}
          loading={stores.uploadFileStore.isDeleteFileProcess}
          loadingIndicator={`Удаление...`}
          disabled={urlFile === ''}
          style={{width: '100%', marginTop: '20px'}}
          size={'small'}
          onClick={() => {
            stores.articleEditViewStore.setIsPdfFile(false)
            stores.articleEditViewStore.changeArticleContent('delete')
            stores.materialDialogStore.setCurrentState(false)
          }}
          sx={{bgcolor: '#FE594E', '&:hover': { bgcolor: '#FE594E' }, '&:disabled': { bgcolor: '#773c36' }}}
        />
      </div>
    </div>
  )
})

export default UploadPdfFile

const styles = StyleSheet.create({
  title: {
    fontFamily: 'Montserrat',
    fontWeight: '700',
    fontSize: '24px',
  },
  description: {
    fontFamily: 'Montserrat',
    fontWeight: '400',
    fontSize: '14px',
    marginTop: '10px',
    marginBottom: '20px'
  },
  deleteButtonContainer: {
    marginTop: '20px',
    width: '100%',
    height: 'auto',
    display: 'flex',
    backgroundColor: '#1A1A1A'
  }
})
