import {observer} from "mobx-react-lite";
import React, {useCallback, useEffect, useState} from "react";
import {stores} from "../../../pages/_app";
import {StyleSheet} from "../../../utils/styles";
import {CategoryModel} from "../../../types/dictionaries/categories/CategoryModel";
import {Alert, Box, MenuItem, Stack} from "@mui/material";
import {InfoOutlined} from "@mui/icons-material";
import {CSSProperties} from "styled-components";
import {arraysEqual} from "../../../utils/ArrayUtils";
import CBText from "../../../uikit/baseComponent/text";
import {CBDropdown} from "../../../uikit/baseComponent/dropdown";
import {Colors, ComponentSize} from "../../../uikit/enums";
import {CBBadge} from "../../../uikit/baseComponent/badge";

interface IProps {
  onSave: (ids: number[]) => void,
  defaultValues: number[],
  style?: CSSProperties,
  enableSaveOnEmptySelect?: boolean,
  isChooseCategoriesComponent?: boolean
}


export const ChoosingInterests = observer(({onSave, defaultValues, style, enableSaveOnEmptySelect = false, isChooseCategoriesComponent = false}: IProps) => {
  const [selectedCategory, setSelectedCategory] = React.useState<string>('');
  const [selectedIds, setSelectedIds] = useState(defaultValues)

  useEffect(() => {
    const arr: string[] = stores.mainStore.category.map(item => item.parentCategories);
    setSelectedCategory(arr.at(0))
  }, [])

  useEffect(() => {
    (!arraysEqual(selectedIds, defaultValues) || enableSaveOnEmptySelect) && onSave(selectedIds)
  }, [selectedIds])

  const renderList = useCallback(() => {
    const groupArray: {[key: string]: CategoryModel[]} = stores.mainStore.category.reduce((acc, obj) => {
      const property = obj['parentCategories'];
      acc[property] = acc[property] || [];
      acc[property].push(obj);
      return acc;
    }, {});

    const groupKeys = Object.keys(groupArray).sort()

    return(
      <>
        <CBDropdown size={ComponentSize.LARGE} style={{width: '100%'}} onChange={(value) => setSelectedCategory(value)} value={selectedCategory || ''}>
          {
            groupKeys.map(category => <MenuItem value={category}>{category}</MenuItem>)
          }
        </CBDropdown>

      <Box style={{...style}}>

        <Stack direction='row' sx={styles.container}>
        {
          groupArray[selectedCategory]?.map(item => (
            <CBBadge disable={!selectedIds.includes(item.id)} style={{...styles.badge, cursor: 'pointer'}} text={item.title}
            onClick={() => {
              if (!selectedIds.includes(item.id) && selectedIds.length < 4) {
                setSelectedIds([...selectedIds, item.id])
              } else {
                setSelectedIds(prevState => prevState.filter(id => id !== item.id))
              }
            }
            }
            />
          ))
        }
          </Stack>
      </Box>
      </>
    )
  }, [stores.mainStore.category, selectedIds, selectedCategory])

  const renderSelectedList = useCallback((): JSX.Element => {
    const selectedItemsArr: CategoryModel[] = selectedIds.map(id => stores.mainStore.category.find(item => item.id === id));
    return (
      <>
        <Stack direction='row' sx={styles.container}>
          {
            selectedItemsArr.map(item => (
              <CBBadge
                style={styles.badge}
                canBeClosed={true}
                onClose={() => setSelectedIds(prevState => prevState.filter(num => num !== item.id))}
                text={item.title}/>
            ))
          }
        </Stack>
        {
          selectedIds.length < 1 && (
            <Alert severity="warning" icon={<InfoOutlined sx={{color:"black"}}/>} sx={{color: "black", bgcolor: '#FFDE68', alignItems: 'center'}}>
              <CBText>Выберите минимум 1 категорию</CBText>
            </Alert>
          )
        }
      </>
    )}, [selectedIds])

  return(
    <Stack rowGap='20px'>

      <CBText>Выберете от 1 до 4 интересующих вас категорий</CBText>
      {renderList()}

      <CBText color={Colors.LABEL_COLOR}>Выбранные категории</CBText>
      {renderSelectedList()}

    </Stack>
  )
})

const styles = StyleSheet.create({
  container: {
    width: 'fit-content',
    columnGap: '10px',
    rowGap: '10px',
    flexWrap: 'wrap'
  },
  badge: {
    height: '36px'
  }
})