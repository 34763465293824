import React, {useEffect} from "react";
import {styles} from "./styles";
import {observer} from "mobx-react-lite";
import {Button, Checkbox} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import {stores} from "../../../../pages/_app";
import {baseStyles} from "../../../../utils/baseStyles";
import {useRouter} from "next/router";
import {Routers} from "../../../../utils/Routers";
import {baseTextStyles} from "../../../../utils/baseTextStyles";
import {SimpleInput} from "../../../baseComponents/inputs/simpleInput";
import {ValidationPasswordInfo} from "../../validationPasswordInfo";
import {Image} from "../../../baseComponents/Image";
import ReCaptcha from "../../../IndependentComponents/ReCaptcha";

interface IProps {
  onSignInClick: () => void
}

export const SignUpByEmail = observer(({onSignInClick}: IProps) => {

  const router = useRouter()

  useEffect(() => {
    stores.authStore.resetValidate()
    stores?.reCaptchaStore.resetVerify()
  }, [])

  const setEmail = (value: string) => {
    stores.authStore.setEmailForAuth(value)
  }

  const setTermsUse = (value: boolean) => {
    stores.authStore.setTermsUseValue(value)
  }

  const onAuthClick = () => {
    stores.authStore.signUpByEmail().then(
      onSuccess => {
        stores.materialDialogStore.setMessageViewState(true, 'Регистрация прошла успешно')
        stores.menuHeaderStore.setIsAuthShow(stores.menuHeaderStore.currentOpenModal, false)
      },
      onError => {
        stores.materialDialogStore.setMessageViewState(true, 'Ошибка регистрации, попробуйте позднее')
      }
    )
  }

  return (
    <>
      <div style={{...baseStyles.verticalContainer, width: '400px', height: '500px', display: 'flex'}}>
        <div style={styles.signInLogoContainer}>
          <img src={'/logos/marketplaceLogoMulticolor.svg'} style={styles.signInLogo}/>
        </div>
        <p style={styles.signUpWelcomeTitle}>Регистрация</p>
        <p style={styles.signUpWelcomeText}>
          Зарегистрируйте новый аккант, чтобы воспользоваться всеми возможностями платформы
        </p>
        <SimpleInput title={'Почта'} placeholder={'Введите вашу почту'}
                     onTextChange={setEmail} type={'email'} size={'small'}
                     stylesForToolTip={{marginBottom: 24, marginLeft: 340}}
                     style={{marginTop: 30, marginBottom: 20, width: '100%', ...baseStyles.horizontalMargins30}}
                     isValid={!!stores.authStore.authValidate.isEmailValid}
                     isValidText={"Введите вашу почту"} required={true}
        />
        <div style={{
          ...baseStyles.horizontalContainer, ...baseStyles.horizontalMargins20,
          marginTop: 5,
          alignItems: 'start'
        }}>
          <Checkbox sx={{
            '&.Mui-checked': {
              color: '#32BD9C'
            }
          }} onChange={(event) => setTermsUse(event.target.checked)}/>
          <p style={{marginLeft: 5, ...baseTextStyles.montserratS12W500}}>С <a href='http://localhost:3000/'
                                                                               style={styles.linkStyle}>лицензионным
            соглашением</a>, включая <a href='http://localhost:3000/' style={styles.linkStyle}>агентский договор</a>, <a
            href='http://localhost:3000/' style={styles.linkStyle}>офертой для продавцов</a>, <a
            href='http://localhost:3000/' style={styles.linkStyle}>политикой обработки персональных данных</a> и <a
            href='http://localhost:3000/' style={styles.linkStyle}>правилами сервиса</a> ознакомился, полностью принимаю
          </p>
        </div>
        <div style={{...baseStyles.horizontalContainer, ...baseStyles.horizontalMargins30, marginTop: 20}}>
          <ReCaptcha/>
        </div>
        <div style={{
          ...baseStyles.verticalContainer, ...baseStyles.horizontalMargins30,
          marginTop: 15,
          marginBottom: '20px',
          flex: 1,
        }}>
          <LoadingButton
            loading={stores.authStore.isAuthProcess}
            children={'Зарегистрироваться'}
            disabled={(!stores.authStore.authValidate.isEmailValid && !stores.authStore.authValidate.isTermsAccept) || !stores?.reCaptchaStore.verify}
            style={{width: '100%'}}
            onClick={() => onAuthClick()}
            sx={{
              backgroundColor: '#32BD9C',
              color: 'white',
              '&:disabled': {
                backgroundColor: '#cccccc',
                color: '#8d8d8d',
              },
              '&:hover': {
                backgroundColor: '#1a8570',
                color: 'white',
              }
            }}
          />
          <div style={{...baseStyles.horizontalContainerWrap, marginTop: 10}}>
            <p style={{color: '#262626', ...baseTextStyles.montserratS12W500}}>Уже зарегистрированы?</p>
            <p style={{color: '#32BD9C', marginLeft: 5, cursor: "pointer", ...baseTextStyles.montserratS12W600}}
               onClick={() => onSignInClick()}>Войдите в аккаунт!
            </p>
          </div>
        </div>
      </div>
    </>
  )
})
