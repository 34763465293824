import React from 'react'


export const setLocalStorageItem = (key, element) => {
    localStorage.setItem(key, element)
}

export const deleteLocalStorageItem = (key) => {
    localStorage.removeItem(key)
}

export const getItemByKey = (key) => {
    return localStorage.getItem(key)
}

export const clearStorage = () => {
    localStorage.clear()
}