import {makeAutoObservable, runInAction} from "mobx";
import {IRootStore} from "./global/RootStore";
import {UserMinimalDataModel} from "../types/user/UserMinimalDataModel";
import {CategoryModel} from "../types/dictionaries/categories/CategoryModel";
import {initUserPublicModel, UserPublicModel} from "../types/user/UserPublicModel";
import networkService from "../services/NetworkService";
import {ArticleObjectByIdUser, initArticleData} from "../types/materials/article/ArticleObjectByIdUser";

export class UserProfileStore {
  private rootStore: IRootStore

  constructor(rootStore: IRootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  isLoaded: boolean = false
  currentUser: UserPublicModel = initUserPublicModel
  currentUserArticles: ArticleObjectByIdUser = initArticleData

  setIsLoaded = (value: boolean) => {
    this.isLoaded = value
  }

  loadArticleByUserId = async (userId: number, page: number, step: number) => {
    this.currentUserArticles = initArticleData
    try {
      const {data} = await networkService.post('/public/articles/getArticlesByUserId', {
        userId,
        page,
        step
      })
      runInAction(() => this.currentUserArticles = data)
      this.setIsLoaded(true)
      return Promise.resolve()
    } catch (e) {
      this.setIsLoaded(false)
      // @ts-ignore
      return Promise.reject(e.response.data)
    }
  }

  loadUserById = async (userId: number) => {
    try {
      //TODO сделать на беке метод получения минимальной инфы о пользователе
      const {data} = await networkService.post('/public/user/getData', {
        userId
      })
      runInAction(() => this.currentUser = data)
      return Promise.resolve(data)
    }
    catch (e) {
      // @ts-ignore
      return Promise.reject(e.response.data)
    }
  }
}

export interface IUserProfileStore extends UserProfileStore {}
